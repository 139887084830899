import { GeoPoint } from "../Location/Entities";
import { GoogleMapActionKind, GoogleMapAction } from "./GoogleMapActions";
import { DirectionsData } from "./GoogleMapEntities";
import appstore from "../../appStore";

/** Helper class to dispatch GoogleMap actions. It's like an action creator except that it also dispatches the actions. */
export class GoogleMapDispatcher {

    /** The user changes the Pickup location. */
    public PickupSelected(pickupLocation: GeoPoint): void {
        dispatch({
            type: GoogleMapActionKind.PickupSelected,
            location: pickupLocation,
        });
    }

    /** The user changes the Dropoff location. */
    public DropoffSelected(location2: GeoPoint): void {
        dispatch({
            type: GoogleMapActionKind.DropoffSelected,
            location: location2,
        });
    }

    /** The Taxi location or vehicle number has changed */
    public TaxiChanged(location: GeoPoint, vehicleNumber: string): void {
        dispatch({
            type: GoogleMapActionKind.TaxiChanged,
            location,
            vehicleNumber,
        });
    }

    /** User clears the pickup address */
    public PickupCleared(): void {
        dispatch({
            type: GoogleMapActionKind.PickupCleared
        });
    }

    /** User clears the dropoff address */
    public DropoffCleared(): void {
        dispatch({
            type: GoogleMapActionKind.DropoffCleared
        });
    }

    /** Google Directions data has been received */
    public DirectionsAvailable(data: DirectionsData): void {
        dispatch({
            type: GoogleMapActionKind.DirectionsAvailable,
            data,
        });
    }
}

/** This function is deliberately put outside the class to avoid issues with "this"
 *  Its purpose is to type-check the actions as GoogleMapAction.
 */
function dispatch(action: GoogleMapAction) {
    appstore.dispatch(action);
}