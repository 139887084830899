import ReactGA from "react-ga";
import { GetValues } from "../Config/MyAppConfig";

/** The GA tracking key is in the format of UA-123456-7. But the ReactGA package requires it to be with 'underscores' in place of 'hyphens'. */
const GaKey = GetValues().GoogleAnalyticsKey.replace(/-/g, '_');

/** Send different events to google analytics. */
export const LogEventInGA = {
    /**
     * Set the user ID of the logged in user. 
     * This function only sets the user globally and doesn't send any event to google. ReactGA uses that userID when sending page view and click events to google.
     * */
    SetUser: function (userId: string) {
        ReactGA.ga(`gtag_${GaKey}.set`, 'userId', userId);
    },

    /**
     * A user has logged in.
     * */
    UserLogin: function (userId: string) {
        ReactGA.ga(`gtag_${GaKey}.send`, "event", "User", "Login", userId);
    },

    /**
     * A user has signed up.
     * */
    UserSignup: function (userId: string) {
        ReactGA.ga(`gtag_${GaKey}.send`, "event", "User", "Sign Up", userId);
    }
}