import { BookingAuthority } from "../../Services/BookingEntities";
import { SetErrorMessages } from "../Utils/CommonHelpers";
import { ErrorResult } from "../Utils/ErrorEntities";
import { Api } from "../../Services/Api";
import { Dispatch } from "../Dispatch";
import { LogBookingCreation } from "../Booking/LogBookingCreation";
import { BuildBookingRequest } from "./BuildBookingRequest";

/** This helper function to create a booking by transformation the model to entity and call Booking Service */
export async function CreateBookingV1(withSmsVerification: boolean): Promise<BookingAuthority | ErrorResult> {

    const createBookingEntity = BuildBookingRequest(withSmsVerification);
    const isFixedFareBooking = !!createBookingEntity.Payment.IsFixedFare;

    var serviceResult = await Api.Booking.CreateBookingV1b(createBookingEntity);
    Dispatch.UILogicControl.BookingFormApiEnd();

    if (serviceResult.isSuccess) {

        LogBookingCreation(isFixedFareBooking);
        return serviceResult.value;
    }
    else {
        return SetErrorMessages(serviceResult);
    }
}