import { RouteUrls } from "../RouteUrls";
import { BookingStatusV2 } from "../Services/BookingEntitiesV2";

/** Constants that won't differ between environments */
export const Config = {

    HttpRequestTimeoutMilliseconds: 30000,

    /** 
     *  A conservative reduction in the duration of Auth0 JWT tokens to make sure we refresh them early. 
     *  The value is in milliseconds, i.e. 5 minutes.
     */
    TokenExpiryBufferMillis: 300000,

    RetryTimes: 3,

    // Status list to get bookings to display in Schedule view.
    StatusListInScheduleView: [1, 2, 3, 4, 5, 6],

    /** Booking Statuses to use when loading test mode bookings from the diagnostic panel.
     * Avoid the NotDispatched status to get properly interesting data. */
    TestBookingStatusIds: [2, 3, 4, 5, 6],

    // Number of bookings displayed in Trip history
    NoOfBookingsInHistory: 100,

    RecentProfileCreationTimeSeconds: 60, // Unit is seconds

    /** Maximum percentage progress bar filled until accepted (during the statuses 2,3 and 4)*/
    MaxProgressBeforeAccepted: 30,

    /** Expected maximum ETA for pickup (in seconds) */
    TMaxForPickupInSeconds: 600,

    /** Expected maximum ETA for dropoff (in seconds) */
    TMaxForDropoffInSeconds: 1200,

    /** Number of bookings displayed in Recent trip history */
    NoOfRecentBookingsInHistory: 5,

    /** Status list for past booking trips */
    PastBookingTripStatusList: ["7", "8", "9"],

    /** Status list to get recent trip bookings */
    StatusListForRecentTripList: [1, 2, 3, 4, 5, 6, 7, 8, 9],

    /** Maximum number of digits allowed in the phone number field */
    ContactNumberMaxLength: 15,

    /**
     * Credentials
     */
    Credentials: {
        ProfileNameMaxLength: 200,
        EmailMaxLength: 100,
        PasswordMaxLength: 100
    },

    /**
     * Default country information as Australia
     */
    DefaultCountryInfo: {
        CountryCode: "+61",
        CountryName: "Australia",
        CountryIsoCode: "au"
    },

    /**
     * Maximum number of seats allowed for wheelchair bookings. 
     * In most cases, this number represents the number of wheelchair spaces in the taxi.
     */
    WheelchairMaxSeat: 1,

    /** Maximum number of characters allowed in the Notes To Driver input field. */
    DriverNotesMaxLength: 320,

    /** Url paths used by unobtrusive login (inside a hidden iframe). */
    UnobtrusiveLoginUrls: [RouteUrls.SilentLogin, RouteUrls.LoginCallbackV2],

    /** URL paths with static content. Skip startup tasks when the user is in these paths. */
    StaticContentURLs: [RouteUrls.TermsAndConditions, RouteUrls.PrivacyPolicy],

    /** Duration after which to allow the SMS code to be resent, in milliseconds. */
    SmsResendTimeoutMillis: 15000,

    /** Maximum number of my bookings that can be loaded. */
    BookingListCountLimit: 20,

    /** Scope of Auth0 token information */
    Auth0TokenScope: "openid email user_metadata",

    /** List of booking statuses to load user bookings from V2 API to display in schedule view. */
    StatusListInScheduleViewFromV2API: [BookingStatusV2.Pending, BookingStatusV2.Dispatch, BookingStatusV2.Assigned, BookingStatusV2.Accepted, BookingStatusV2.PickedUp],

    /** List of booking statuses to load user bookings from V2 API to display in 'Trip History'. */
    StatusListInHistoryFromV2API: [BookingStatusV2.Pending, BookingStatusV2.Dispatch, BookingStatusV2.Assigned, BookingStatusV2.Accepted, BookingStatusV2.PickedUp, BookingStatusV2.Complete, BookingStatusV2.Cancel, BookingStatusV2.NoJob, BookingStatusV2.NoCarAvailable],
};