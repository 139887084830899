import { VerificationTrigger, UserContactNumberInfo } from "./VerificationEntities";

/** Redux state for the "Verification" slice */
export interface VerificationState {

    /** The user's contact phone number. */
    UserContactNumberInfo: UserContactNumberInfo;

    /** True to show a spinner UI in the Contact Details form. */
    IsLoaderShownInContactDetails: boolean;

    /** What caused the phone verification UI to show: booking creation or signup. */
    Trigger: VerificationTrigger;

    /** The user's verified code  */
    VerificationCode?: string;

    /** The ID of the most recent SMS Verification challenge started. */
    SmsChallengeId: string | null;
}

/** Initial value of Verification */
export const InitVerificationState: VerificationState = {
    UserContactNumberInfo: {},
    IsLoaderShownInContactDetails: false,
    Trigger: VerificationTrigger.Booking,
    SmsChallengeId: null,
};