import { ServiceCallOutcome, NetworkFailResult, WebServerErrorResult, ApplicationErrorResult } from "../../Services/ServiceEntities";
import { ErrorResult } from "./ErrorEntities";

/** This helper function to set error messages when isSuccess false */
export function SetErrorMessages(serviceResult: NetworkFailResult | WebServerErrorResult | ApplicationErrorResult): ErrorResult {

    if (serviceResult.outcome === ServiceCallOutcome.ApplicationError) {
        // Sometimes, the messageId is undefined (e.g. in Unauthorized user message for incorrect verification code). Using httpStatus code in such cases.
        return {
            isError: true,
            errorMessage: serviceResult.apiStatus.Message
        };
    }

    if (serviceResult.outcome === ServiceCallOutcome.WebServerError) {
        return {
            isError: true,
            errorMessage: "Technical error. Please try again."
        };
    }

    if (serviceResult.isTimeout) {
        return {
            isError: true,
            errorMessage: "Request timeout",
            isTimeout: true
        };
    }

    return {
        isError: true,
        errorMessage: "Network error"
    };
}
