import React, { FC } from 'react';
import "./Features.scss";
import { connect } from 'react-redux';
import { Dispatch } from '../Dispatch';
import { FeatureCard } from './FeatureCard';
import { FeaturesState } from './FeaturesState';
import { ApplicationState } from '../../appState';
import { FeatureCardProps } from './FeaturesEntities';
import { ContentURL, getContentUrl } from '../Utils/ContentURL';
import TransparentBlackBg from '../../widgets/transparent-black-background/transparent-black-background';

interface PropsFromStore {
    Features: FeaturesState
}

/**
 * Displays feature list
 */
export const FeatureListCore: FC<PropsFromStore> = ({Features}) => {

    /**
     * Close the What's New panel
     */
    function Close() {

        // Close the feature list panel
        Dispatch.Features.HideWhatsNewFeatures();         
    }
    
    /**
     * Create a feature list for provided ids 
     */
    function CreateFeatureList(featureIdList: number[], canShowAcknowledgeButton = true) {
        // Sort in the descending order.
        const sortedList = featureIdList.sort((id1, id2) => id2 - id1);
        
        const featureList = sortedList.map(id => {
            
            const props: FeatureCardProps = {
                FeatureId: id,
                CanShowAcknowledgeButton: canShowAcknowledgeButton
            }   

            return <FeatureCard {...props}  />
        });

        return featureList;
    }
    
    const newFeatureList = CreateFeatureList(Features.NewFeatureList);

    const acknowledgedFeatureList = CreateFeatureList(Features.AcknowledgedFeatureList, false);

    const openPanelCssClass = Features.IsFeatureListDisplayed && 'whats-new-panel-open';

    return (
        <div>
            { openPanelCssClass && <TransparentBlackBg /> }
            <div className={`whats-new-panel ${openPanelCssClass}`}>
                <div className="whats-new-header-section">
                    <div className="whats-new-header-content">
                        <div className="whats-new-title">WHAT'S NEW</div>
                        <img className="close-panel-button" src={getContentUrl(ContentURL.images.BlackCircleX)}  alt="close" onClick={() => Close()} />
                    </div>
                </div>
                <div className="feature-list">
                    <div>{newFeatureList}</div>
                    { 
                        acknowledgedFeatureList.length > 0 &&
                        <>
                            <div className="last-viewed">
                                <span className="last-viewed-title">Last viewed</span>
                                <hr className="last-viewed-divider" />                       
                            </div>
                            <div>{acknowledgedFeatureList}</div>
                        </>
                    }                 
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state: ApplicationState) : PropsFromStore {
    return {
        Features: state.features
    };
}

export default connect(mapStateToProps)(FeatureListCore);