import React from "react";
import "./TripHistory.scss";
import { BookingStatus } from "../../Services/BookingEntities";
import { getContentUrl, ContentURL } from "../Utils/ContentURL";
import moment from "moment";
import { connect } from "react-redux";
import { ApplicationState } from "../../appState";
import { SimpleUserProfile } from '../../modules/User/ProfileEntitiesV2';
import { RecentTripButtonKind } from '../UILogicControl/UILogicControlEntities';
import { Config } from "../../Config/Config";
import { PopulateSelectedTripDetails } from "../Booking/QuickBook/PopulateSelectedTripDetails";
import { QuickBookSource } from "../Booking/QuickBook/QuickBookEntities";
import { GetValues } from "../../Config/MyAppConfig";
import { GetTripHistory } from "../Booking/BookingLoaders";
import { GenerateBookingTrackingUrl } from "../Booking/BookingHelper";
import { BookingInfo } from "../MyBookings/MyBookingEntities";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { GetTripHistoryV2 } from "../BookingV2/BookingLoadersV2";

interface TripHistoryState {
    listOfBookings: BookingInfo[] | undefined;
    showLoader: boolean;
}

interface PropsFromStore {
    UserProfile: SimpleUserProfile | undefined;
}

/** Component which contains a table of Trip history of the logged in user. */
class TripHistory extends React.Component<PropsFromStore, TripHistoryState> {
    
    private inactiveBookingStatusList = Config.PastBookingTripStatusList;

    constructor(props: PropsFromStore) {
        super(props);

        this.state = {
            listOfBookings: [],
            showLoader: false
        }
    }

    async componentDidMount() {
        this.setState({showLoader: true});
        let tripHistory;

        if (!FeatureFlags.BookingApiV2) {
            tripHistory = await GetTripHistory(Config.StatusListForRecentTripList);
        } else {
            tripHistory = await GetTripHistoryV2(Config.StatusListInHistoryFromV2API);
        }

        // Sort the booking list by booking status, display the booking with status "Booked" to appear at the top of the list.
        if(tripHistory) {
            tripHistory.sort(a => this.inactiveBookingStatusList.includes(a.TrackingInfo.BookingStatusID.toString()) ? 1 : -1);
        }
        this.setState({listOfBookings: tripHistory, showLoader: false});
        
    }    

    /** Returns the display text for the given status ID */
    getStatusDisplayText(statusID: BookingStatus): string {
        if(statusID == BookingStatus.Cancelled) {
            return "Trip cancelled";
        } else if (statusID == BookingStatus.Completed) {
            return "Trip completed";
        } else if (statusID == BookingStatus.NoJob) {
            return "Passenger not found";
        }
        return "Booked";
    }

    /**
     * Populates the selected trip details on the booking form widget
     */
    populateTripDetailsOnBookingForm = (btnClicked: RecentTripButtonKind, trackingResult : BookingInfo) => {

        PopulateSelectedTripDetails(btnClicked, trackingResult, QuickBookSource.TripHistory);

    }

    render() {
        const noBookingsMessage = (<div className="history-message">There are no bookings in your history.</div>);
        const loadingErrorMessage = (<div className="history-message">Oops, there was an error loading the data. Try refreshing the page.</div>);
            
        return (
            <div className="trip-history-main-panel">
                {this.state.listOfBookings ? this.state.listOfBookings.length === 0 && this.state.showLoader === false ? noBookingsMessage
                    :  <table className="history-table">
                            <thead>
                                <tr className="history-table-header">
                                    <th>Booking#</th>
                                    <th>Passenger name</th>
                                    <th>Date and time</th>
                                    <th>Pick-up</th>
                                    <th>Destination</th>
                                    <th>Status</th>
                                    <th>Taxi no.</th>
                                    <th>Trip link</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.listOfBookings!.map(booking => {
                                    
                                    const isActiveBooking = !this.inactiveBookingStatusList.includes(booking.TrackingInfo.BookingStatusID.toString());

                                    // Booking status must appear in green to notify active booking
                                    const activeBookingStatusCssClass = isActiveBooking ? "active-booking-status" : "";

                                    return <tr key={booking.BookingID}>
                                        <td>{booking.DispatchBookingID}</td>
                                        <td>{booking.PassengerName}</td>
                                        <td>{moment(booking.Time, "MM/DD/YYYY HH:mm:SS").format("ddd DD MMM YYYY hh:mma")}</td>
                                        <td>{booking.PickupText}</td>
                                        <td>{booking.DropoffText}</td>
                                        <td className={activeBookingStatusCssClass}>{this.getStatusDisplayText(booking.TrackingInfo.BookingStatusID)}</td>
                                        <td>{booking.TrackingInfo.CarNumber}</td>
                                        <td><a href={GenerateBookingTrackingUrl(booking.BookingID.toString(), booking.AccessCode, GetValues().TrackingLinkBaseUrl)} target="_blank">Link</a></td>
                                        <td><button className="btnTrip-book-again" onClick={ () => this.populateTripDetailsOnBookingForm(RecentTripButtonKind.BookAgain, booking)}>Book again</button></td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    : loadingErrorMessage}
                { this.state.showLoader ? <img alt="loading" src={getContentUrl(ContentURL.images.Loading)} className="loading-history" /> : null }
                
            </div>      
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        UserProfile: state.authentication.UserProfile
    };
}

export default connect(mapStateToProps)(TripHistory);