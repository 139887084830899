import { SimpleUserProfile } from "../User/ProfileEntitiesV2";
import { FeatureFlags } from '../../Config/FeatureFlags';

/** True if the user is linked to any accounts. */
export function CanBookOnAccount(userProfile: SimpleUserProfile | undefined, isMobileDevice: boolean): boolean {

    // master flag
    if (!FeatureFlags.BookOnAccount) return false;

    // device-specific feature flag
    if (isMobileDevice && !FeatureFlags.MobileBookingOnAccount) return false;

    if (!userProfile) return false;

    if (userProfile.Accounts.length <= 0) return false;

    /**
     * Account booking will be disabled if:
     *   1) Mobile device;
     *   and, 2) Any account attached is DVA.
     */
    if (isMobileDevice && userProfile.Accounts.some(account => account.IsDVA)) return false;

    return true;
}