import React from "react";
import "./TncFooter.scss";
import { LayoutSelector } from "../../../widgets/site-footer/LayoutSelector";
import { FeatureFlags } from "../../../Config/FeatureFlags";
import { GetValues } from "../../../Config/MyAppConfig";
import { RouteUrls } from "../../../RouteUrls";
import { LegalConsentStatement } from "../../LegalDocuments/LegalDocsConsent";
import { LegalDocuments } from "../../../Services/LegalDocumentsContracts";
import { ApplicationState } from "../../../appState";
import { connect } from "react-redux";

/**
 * Simple tnc footer for booking form
 */

interface PropsFromStore {
    LegalDocPack: LegalDocuments;
}

const TncFooterCore: React.FC<PropsFromStore> = (props) => {
    return (
        <div className="booking-TC">
            <ul className="disclaimer">
                {
                    FeatureFlags.LegalDocumentsV2 ?
                        <li>
                            <LegalConsentStatement IsAccepted={false} ToggleConsent={() => { return; }} LegalDocsUrls={props.LegalDocPack} ShouldIncludeCheckBox={false} />
                        </li>
                        :
                        <li>
                            {!FeatureFlags.NoTermsAndConditions && (
                                <>
                                    <a href={RouteUrls.TermsAndConditions} target="_blank">Terms and Conditions</a> and &nbsp;
                                </>
                            )}

                            <a href={GetValues().PrivacyUrl} target="_blank">Privacy Policy</a>
                        </li>
                }
            </ul>
            <p className="copy">{GetValues().BrandName} &copy; {(new Date().getFullYear())}</p>

            <LayoutSelector />
        </div>
    );
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        LegalDocPack: state.legalDocuments.LegalDocumentsPack
    }
}

export const TncFooter = connect(mapStateToProps)(TncFooterCore);