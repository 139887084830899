import appstore from "../../appStore";
import { Dispatch } from "../Dispatch";
import { Api } from "../../Services/Api";
import { MapView } from "../../widgets/NavBar/TabEntities";
import { VehicleOption, DataLoadingStatus } from '../Condition/Redux/ConditionEntities';
import services from '../../utils/service-vehicles/ServiceMetadata';
import { RecentTripButtonKind, BookingTabKind } from '../UILogicControl/UILogicControlEntities';
import { AddressInput, ApiGeoPoint, AddressPlaceId } from '../../Services/BookingEntities';
import { GetPlaceIdByAddress, ConvertToPlaceResult, GetPlaceResultByPlaceId, ApplyPickupLocationSelection, ApplyDropoffLocationSelection } from './BookingLocationHelper';
import { AsyncUpdateOutcome } from "../Condition/AsyncUpdateOutcome";
import { PaymentCardErrorType, PayDriverOption } from "../Payment/PaymentEntities";
import { SimpleUserProfile } from "../User/ProfileEntitiesV2";
import { TemplateToBookingAccountInfo } from "../BookingTemplate/BookingTemplateEntities";
import { BookingTemplate } from "../BookingTemplate/BookingTemplateEntities";
import { AccountBookingPayload } from "./BookingEntities";
import { Config } from "../../Config/Config";
import { FormatBusinessNumber } from "../../utils/Formattingutil";
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { WellKnownMessageKind } from "../Utils/ErrorMessages";
import { LogEvent } from '../../utils/LogEvent';
import { ParseContactNumber } from "../Utils/PhoneValidation";
import { PopulateConditionListWithDefaultVehicles } from "../Condition/PopulateConditions";
import { ConvertToPaymentOption } from "../Payment/PaymentHandler";
import { BookingInfo, SelectedBookingDetails } from "../MyBookings/MyBookingEntities";
import { FeatureFlags } from "../../Config/FeatureFlags";

/**
 * Populate the driver notes
 * Populate the account details
 * Populate the pickup and dropoff address
 * Populate the cab details and estimate fare
 */
export async function RecentTripSelection(recentTripData: SelectedBookingDetails) {

    const BookingDetails = recentTripData.BookingDetails;
       
    ClearExistingDetails();

    PopulateAccountDetails(BookingDetails);

    if (!FeatureFlags.BookingApiV2) {
        const locationDetails = await PopulateLocationDetails(BookingDetails);

        PopulateAddresses(locationDetails, recentTripData);
    }
    else {
        // Driver notes
        if (BookingDetails.NotesToDriver) {

            const notes = CleanDriverNoteText(BookingDetails.NotesToDriver);
            
            Dispatch.Booking.AddNotesToDriver({ Notes: notes });
        }        

        // No place ID from V2 API.
        const addressPlaceId: AddressPlaceId = {
            PickupPlaceId: null,
            DropoffPlaceId: null
        };

        await PopulateAddresses(addressPlaceId, recentTripData);
    }

    

    // Populate Passenger Name
    PopulatePassengerName(BookingDetails.PassengerName);

    // Populate passenger contact number.
    ParseAndStoreContactNumber(BookingDetails.CustomerPhone);
}

export async function BookingTemplateSelection(selectedTemplate: BookingTemplate) {
    
    // Change to NewBooking Tab
    Dispatch.UILogicControl.OnBookingTabSelectionChange(BookingTabKind.NewBooking);

    // Navigate to MapView
    Dispatch.Tab.SelectItem(MapView);

    // Populate account details
    PopulateAccountDetailsFromExistingSource(selectedTemplate);
    
    // Switch OFF the strict validation mode
    Dispatch.UILogicControl.OnIsStrictValidationModeOnBookingFormChange(false);

    ClearExistingDetails();

    // Clear the fare details and set the cab details as Next available
    Dispatch.Condition.SelectedCondition({ Service: services.any, Fare: undefined });

    const { DisplayText : pickupText, GoogleMapsPlaceId : pickupPlaceId, ContactName, ContactPhone } = selectedTemplate.Locations[0];

    //#region Populate pickup address 
    
    if (pickupPlaceId) {
        
        const pickUpAddress : AddressInput = {
            placeId : pickupPlaceId,
            placeText : pickupText!
        }

        await PopulatePickupAddress(pickUpAddress);        
    }
    else {
        const { LoadingStatus, FareLoadStatus } = appstore.getState().condition;

        Dispatch.Condition.ConditionDataLoadStatus({ ...LoadingStatus, Status: DataLoadingStatus.Idle });

        Dispatch.Condition.FareLoadStatus({ ...FareLoadStatus, LastInput: null });

        // Populate the condition list with default values, inorder to render the selected vehicle
        PopulateConditionListWithDefaultVehicles();
    }
    
    //#endregion

    //#region  Populate dropoff address 

    const { DisplayText : dropoffText, GoogleMapsPlaceId : dropoffPlaceId } = selectedTemplate.Locations[1];

    if (dropoffPlaceId) {

        const dropOffAddress = {} as AddressInput;        
        dropOffAddress.placeId = dropoffPlaceId;
        dropOffAddress.placeText = dropoffText!;
        
        PopulateDropoffAddress(dropOffAddress);
    }
    
    //#endregion
         
    // Populate cab details
    PopulateCabDetails(selectedTemplate.Conditions);

    // Populate driver notes
    Dispatch.Booking.AddNotesToDriver({ Notes: selectedTemplate.DriverNotes }); 
    
    // Populate passenger name
    Dispatch.Booking.UpdatePassengerDetails({ ContactName: ContactName });

    // Populate passenger contact number
    ParseAndStoreContactNumber(ContactPhone);

    // Populate payment card
    PopulatePaymentMethod(selectedTemplate.PaymentCardId);

    LogEvent.BookingTemplateSelected(selectedTemplate);
}

/** Clear any existing state before applying a favourite or recent booking. */
function ClearExistingDetails() {
    
    // Inorder to update the store with the latest pickup and dropoff addresses, clear the previously stored addresses
    ClearAddresses();

    Dispatch.Condition.ClearPriceGuarantee();
    Dispatch.Condition.ClearFareEstimate();
    Dispatch.UILogicControl.ClearSatssError();
}

function ConvertToAddressInput(addressText: string, placeId: string | null, geoPoint : ApiGeoPoint) : AddressInput {    
    const address = {
        placeId,
        placeText: addressText,
        latitude: geoPoint.Latitude,
        longitude: geoPoint.Longitude,                               
    }

    return address;
}

export function ClearAddresses() {

    // Clear Pickup address
    Dispatch.GoogleMap.PickupCleared();
    Dispatch.Booking.ClearPickup();
    Dispatch.UILogicControl.OnDoesPickupInputHaveValueChange(false);

    // Clear dropoff address
    Dispatch.GoogleMap.DropoffCleared();
    Dispatch.Booking.ClearDropoff();
    Dispatch.UILogicControl.OnDoesDropoffInputHaveValueChange(false);
}

async function LoadPlaceResultUsingPlaceIdOrAddressText(request : AddressInput) {

    let placeId: string | null = request.placeId;

    // Do not fetch placeId by address text, if already present
    if (!placeId) {
        placeId = await GetPlaceIdByAddress(request.placeText);
    }

    const placeResult = await GetPlaceResultByPlaceId(placeId);
    
    // When unable to fetch the PlaceResult for the pickup/dropoff, then return
    if (!placeResult) return null;

    return ConvertToPlaceResult(placeResult, request.placeText);
}

export async function PopulatePickupAddress(request: AddressInput) {

    const place = await LoadPlaceResultUsingPlaceIdOrAddressText(request);

    if (!place) {
        Dispatch.UILogicControl.SetPickupValidity(false);
        return;
    }   
    
    const status = await ApplyPickupLocationSelection(place);
    
    const { LoadingStatus, FareLoadStatus } = appstore.getState().condition;

    // For same pickup suburbId, modify the loading and the fareload status inorder to get latest fare
    if (status === AsyncUpdateOutcome.InputsUnchanged) {
        Dispatch.Condition.ConditionDataLoadStatus({ ...LoadingStatus, Status: DataLoadingStatus.Idle });
        Dispatch.Condition.FareLoadStatus({ ...FareLoadStatus, LastInput: null });
    }

    Dispatch.UILogicControl.OnDoesPickupInputHaveValueChange(true);
}

export async function PopulateDropoffAddress(request: AddressInput) {
   
    const place = await LoadPlaceResultUsingPlaceIdOrAddressText(request);

    if (!place) {        
        Dispatch.UILogicControl.SetDropoffValidity(false);
        return;
    }

    ApplyDropoffLocationSelection(place);    
    
    Dispatch.UILogicControl.OnDoesDropoffInputHaveValueChange(true);
}

async function PopulateLocationDetails(BookingDetails: BookingInfo): Promise<AddressPlaceId> {
   
    let addressPlaceId: AddressPlaceId = {
        PickupPlaceId: null,
        DropoffPlaceId: null
    };

    // Call the BookingLocation API to fetch driver notes and google placeid
    var serviceResult = await Api.Booking.GetMiscBookingDetails({ bookingId: BookingDetails.BookingID, hashCode: BookingDetails.AccessCode });

    if (serviceResult.isSuccess) {
        
        let notes : string | undefined  = "";
        let result = serviceResult.value;

        if (result) {

            // Extract pickup address details
            const pickup = result.Locations.find(x => x.isPickup === true)!;

            notes = CleanDriverNoteText(pickup.remark);
            
            // Extract dropoff address details
            const dropoff = result.Locations.find(x => x.isPickup === false)!;

            addressPlaceId.PickupPlaceId = pickup.googlePlaceId;
            addressPlaceId.DropoffPlaceId = dropoff.googlePlaceId;
            
            // Populate payment card if exists.
            const cardId = result.Payment?.CardId;
            PopulatePaymentMethod(cardId);
        }

        Dispatch.Booking.AddNotesToDriver({ Notes: notes });

    }

    return addressPlaceId;
}

function PopulateAccountDetails(BookingDetails : BookingInfo) {
    
    // Clear previously selected account
    Dispatch.Booking.ClearAccountDetails();
    
    const { AccountNumber, OrderNumber, ContactName } = BookingDetails;

    const { UserProfile } = appstore.getState().authentication;

    if (!UserProfile) return;

    const accountInfo: TemplateToBookingAccountInfo = { AccountNumber: AccountNumber, 
                                                        FileName: ContactName, 
                                                        OrderNumber: OrderNumber };
    
    ValidateAndPopulateAccountAgainstUserProfile(UserProfile, accountInfo);
}

/**
 * Find the account in user profile from specified account number which is derived from booking history and favourite;
 * And assign into booking payload, if there is any;
 * 
 * This function also validate account related information.
 * Validation rule:
 * https://cabcharge.atlassian.net/wiki/spaces/CA/pages/999260447/Book+from+Favourite
 */
export function ValidateAndPopulateAccountAgainstUserProfile(userProfile : SimpleUserProfile, accountInfo: TemplateToBookingAccountInfo) {

    // Populate the account list
    const accountsList = userProfile.Accounts;

    /**
     * Account toggle was off when the favourite or recent trip was made
     */
    if (!accountInfo.AccountNumber) {
        Dispatch.Booking.SetBookOnAccount(false);
        return;
    }

    /**
     * No account linked to this user, but there is a specified account from booking templates/recents/booking history
     */
    if (accountsList.length === 0) {
        ShowDialogSimpleErrorMessage(WellKnownMessageKind.AccountValidationFailure); 
        return;
    }

    /**
     * New business rule:
     * As long as, there are accounts linked to user profile, account toggle is always on;
     *   1) Found matching account: show account information
     *   2) Specified account not found: show "Please select account".
     */
    const bookingOnAccount = true;

    const accIndex = accountsList.findIndex(x => x.AccountNumber == accountInfo.AccountNumber);
        
    // When booking from recent, populate the account details used previously for booking
    if(accIndex >= 0) {
        const account = accountsList[accIndex];

        const accPayload: AccountBookingPayload = {
            FileNumber: accountInfo.FileName,
            OrderNumber: account.IsOrderNumberRequired && accountInfo.OrderNumber || "",            
            SelectedAccountIndex: accIndex,
            SelectedAccount: account                
        }

        /**
         * Order number has value from booking template/recent/booking history, but not required any more from profile for the same account;
         * Ignore, if IsDVA is true.
         */
        if (!!accountInfo.OrderNumber && !account.IsOrderNumberRequired && !account.IsDVA) ShowDialogSimpleErrorMessage(WellKnownMessageKind.AccountValidationFailure); 

        // Set the index of the account, to be selected in the accounts dropdown
        Dispatch.Booking.UpdateAccountDetails(accPayload);
    }

    // Specified account cannot be found in account list linked to user
    Dispatch.UILogicControl.SpecifiedAccountNotFound(accIndex < 0);
    // Set the booking on accounts toggle button
    Dispatch.Booking.SetBookOnAccount(bookingOnAccount);
}

/**
 * Populate the pickup and dropoff address using the location text stored in database
 */
async function PopulateAddresses(addressPlaceId: AddressPlaceId, recentTripData: SelectedBookingDetails) {
    
    // Clear the fare details and set the cab details as Next available
    if (!FeatureFlags.BookingApiV2) {
        Dispatch.Condition.SelectedCondition({ Service: services.any, Fare: undefined, FixedFare: undefined });
    }

    const tripDetail: BookingInfo = recentTripData.BookingDetails;
        
    // Populate the pick-up address request
    const pickupRequest = ConvertToAddressInput(tripDetail.PickupText, addressPlaceId.PickupPlaceId, tripDetail.PickupLocation);

    // Populate the drop-off address request
    const dropoffRequest = ConvertToAddressInput(tripDetail.DropoffText!, addressPlaceId.DropoffPlaceId, tripDetail.DropoffLocation!);

    let pickUp = pickupRequest;
    let dropOff = dropoffRequest;

    // Reverse the addresses when booking on return
    if (recentTripData.TripType == RecentTripButtonKind.BookReturn) {
        pickUp = dropoffRequest;
        dropOff = pickupRequest;
    } 

    Dispatch.UILogicControl.SetPickupValidity(isAddressValid(pickUp));
    Dispatch.UILogicControl.SetDropoffValidity(isAddressValid(dropOff));

    // Populate and save the pickup address if present
    if (pickUp.latitude) {        
        
        await PopulatePickupAddress(pickUp);
        
        PopulateCabDetails(recentTripData.BookingDetails.CarConditionID);
    }    

    // Populate and save the dropoff address if present
    if (dropOff.latitude) PopulateDropoffAddress(dropOff);            
  
}

/** Check if the existing address is valid (can be populated in the input field). */
function isAddressValid(address: AddressInput) {
    if (address.placeId) return true;
    if (address.latitude) return true;
    if (address.placeText === "To be confirmed") return true;

    return false;
}

/**
 * WARNING: changes required. This probably only supports V1 API.
 * @param CarConditionID
 */
function PopulateCabDetails(CarConditionID: number | undefined) {
    
    const { ConditionList, MaxiTaxiLookUp, SelectedCondition } = appstore.getState().condition;   

    if (ConditionList && ConditionList.length > 0) {

        let index = 0;
    
        // By default, the Next Available taxi is selected
        let selectedVehicle: VehicleOption = {...SelectedCondition, Service: services.any};
        
        /**
         * Converting CarConditionID which is a number to string, as it contains string value
         * Converting string to number, as the ID field in the ConditionList is number
         */
        const carId = CarConditionID && parseInt(CarConditionID.toString());

        // The CarConditionID is empty for the Next Available taxi
        if (carId) {
                                    
            index = ConditionList.findIndex(x => x.ApiVehicle && x.ApiVehicle!.ApiId == carId);
                
            if (index >= 0) {
                selectedVehicle = ConditionList[index];
            }
            else {

                if (MaxiTaxiLookUp) {
                    
                    // Retrieve Maxi taxi details
                    const condition = Object.values(MaxiTaxiLookUp).find(x => x.ApiId === carId);

                    if (condition) {
                                  
                       // Maxi Taxi do not have the Condition property, so can't use the ID to retreive it's details
                       const maxiVehicle = ConditionList.find(x => x.Service.isMaxiTaxi);      

                        if (maxiVehicle) {
                                                        
                            selectedVehicle = {
                                ...selectedVehicle,
                                Service: { ...maxiVehicle.Service, short: condition.Description },
                                ApiVehicle: condition
                            };
                        }                                               
                    }                    
                }
                               
                // Show error message dialog incase of inserviceable vehicle
                if (selectedVehicle.Service === services.any) ShowDialogSimpleErrorMessage(WellKnownMessageKind.InserviceableVehicle);                
            }                      
        }

        // Change cab details to the selected cab
        Dispatch.Condition.SelectedCondition(selectedVehicle);

        // Collapse the vehicle selector
        Dispatch.Condition.HideVehicleSelectorUI();
    }             
}

export function ParseAndStoreContactNumber(phoneNumber: string | undefined): void {

    // Clear contact number error message 
    Dispatch.Verification.ClearContactNumberError();

    // Set default country information for empty contact number
    if (!phoneNumber) {
        Dispatch.Booking.UpdatePassengerDetails({ 
            PassengerContactNumber: {
                Contactnumber: "",
                CountryInfo: Config.DefaultCountryInfo,
            } 
        });
        return;
    }

    // Handle legacy users with 1300 and 1800 numbers. Users registered on the Desktop website already have +61.
    phoneNumber = FormatBusinessNumber(phoneNumber);

    const contactNumber = ParseContactNumber(phoneNumber);

    // Extract the region code and contact number
    const { IsoRegionCode, NumberPart, CountryCode } = contactNumber;

    Dispatch.Booking.UpdatePassengerDetails({
        PassengerContactNumber: {
            CountryInfo: { CountryIsoCode: IsoRegionCode ? IsoRegionCode.toLowerCase() : "", CountryCode: CountryCode },
            Contactnumber: NumberPart
        }
    });
}

/**
 * Populate the payment method with the specified payment card 
 * If the card is not found, then display error and default the payment option to "Paying the Driver directly"
 */
function PopulatePaymentMethod(paymentCardId: string | undefined) {

    let paymentOption = PayDriverOption;
    
    // Hide previously generated error message if exist
    Dispatch.Payment.SetError(null);

    if (paymentCardId) {
        
        const cardsList = appstore.getState().payment.AllCards;
        const paymentCard = cardsList.find(card => card.CardId === paymentCardId);

        if (paymentCard) {
            paymentOption = ConvertToPaymentOption(paymentCard);
        } else {
            // Card not found in the list of cards belonging to the user.
            Dispatch.Payment.SetError(PaymentCardErrorType.CardNotFound);
        }
    }

    // If no payment option found in the selected booking, default to 'Paying the Driver directly'
    Dispatch.Booking.ChangePaymentMethod(paymentOption);
}

function PopulatePassengerName(passengerName: string | undefined) {
    if (passengerName) {
        // Populate Passenger Name
        Dispatch.Booking.UpdatePassengerDetails({ ContactName: passengerName });

        LogEvent.OnAddingPassengerName();
    }
}

/**
 * Populate account details from booking template or from an old booking (quick book tab or history)
 */
function PopulateAccountDetailsFromExistingSource(BookingTemplate: BookingTemplate) {
    // Clear previously selected account
    Dispatch.Booking.ClearAccountDetails();

    const { UserProfile } = appstore.getState().authentication;

    if (!UserProfile) return;

    const AccountInfo: TemplateToBookingAccountInfo = {
        AccountNumber: BookingTemplate.AccountNumber,
        FileName: "",
        OrderNumber: BookingTemplate.AccountReferenceText
    };

    ValidateAndPopulateAccountAgainstUserProfile(UserProfile, AccountInfo);
}

/** Extract the driver notes entered by the user. i.e.: remove predefined driver notes added automatically to the booking (for account bookings). */
function CleanDriverNoteText(fullNote: string): string {
    // Check for the predefined driver notes 
    const index = fullNote.lastIndexOf("--");

    // Remove the prefined driver notes
    if (index > 0) return fullNote.slice(index + 2).trim();

    return fullNote;
}