import React from "react";
import "./InputField.scss";
import { InputFieldProps, SelectedAddress } from "./InputFieldProps";
import { GooglePlaceAutoComplete } from "../../../widgets/google-maps/GooglePlaceAutoComplete";
import { Config } from "../../../Config/Config";
import { TextField, InputAdornment } from "@material-ui/core";
import { LoadEndIconForTextField } from "../../Booking/BookingHelper";
import { TextFieldEndIcon } from "../../Booking/Widget/TextFieldEndIcon";

/** Custom component for all the input fields of the contact form. */
class InputField extends React.Component<InputFieldProps, {}> {
    
    state = {
        isFocus: false
    };

    /** Get the type of the input field */
    private get fieldType(): string {
        const type = this.props.type;
        if (!type || type === "text") {
            return "text";
        }
        if ( type === "phone") {
            return "tel";
        }
        return type;
    }

    /** Check if the input field is for a phone input to add additional elements and to decide the length. */
    private get isPhone(): boolean {
        return this.props.type === "phone";
    }

    /** Check if the input is an address field to make it a Google place autocomplete type field */
    private get isAddress(): boolean {
        return this.props.type === "address";
    }

    /** Check if the input is an amount field to add currency.*/
    private get isAmount(): boolean {
        return this.props.type === "amount";
    }

    /** Add international code and the flag to (in front of) the input field. Currently, it is hard coded to australia. */
    private get intlCode(): JSX.Element | null {
        return this.isPhone ?
            <div className="contact-input-group-phone">
                <label className="input-label phone-label">{this.props.labelForPhoneNumber}</label>
                <div className="phone-input">
                    <img src={this.props.imgSrc} className="flag-icon" alt="logo" />
                    <div className="intlcode">+61</div>
                </div>
            </div> : null;
    }

    public render() {
        return <div className="input-container">
            {this.intlCode}
            <div className="contact-input-group">
                {this.isAddress ? this.getAddressInput() : this.getStandardInput()}
            </div>
        </div>;
    }

    /** Get text/number input field with the given properties. */
    private getStandardInput = () => {

        // Validate the input
        const isInputInvalid = !this.state.isFocus && !!this.props.isError;
        
        // Show clear text icon, if the input field has valid value and it's not in focussed
        const canShowClearTextIcon = !isInputInvalid && !this.state.isFocus && !!this.props.value;
        
        /** Path of the icon (e.g. clear, error) displayed to the right end of the input field  */
        const endIconPath = LoadEndIconForTextField(canShowClearTextIcon, isInputInvalid);

        const contactNumberLabelAlignmentCssClass = this.isPhone ? "contact-number-label-alignment" : "";

        return (
            <TextField
                fullWidth={true}
                variant="outlined"
                id={this.props.name}
                type={this.fieldType}
                error={isInputInvalid}
                name={this.props.name}
                value={this.props.value}
                label={this.props.label}
                onChange={this.props.updateHandler}
                placeholder={this.props.placeholder}
                onFocus={() => this.updateFocusStatus(true)}
                onBlur={() => this.updateFocusStatus(false)}
                className={contactNumberLabelAlignmentCssClass}
                inputProps={{ maxLength: this.isPhone ? Config.ContactNumberMaxLength : undefined }}
                InputProps={{
                    startAdornment: this.isAmount && <InputAdornment position="start">$</InputAdornment>,
                    endAdornment: <TextFieldEndIcon iconPath={endIconPath} onClick={this.onClearEvent} />
                }}
            />
        );
    }

    /** Assign empty value to input field on click of the cleartext icon */
    onClearEvent = () => {
        if (this.props.clearTextHandler) this.props.clearTextHandler(this.props.name);
    }
    
    /** Update the focus state of the text field */
    updateFocusStatus = (isFocus: boolean) => this.setState({ isFocus: isFocus });
    
    /** Get address inputs field. Reusing GooglePlaceAutoComplete component here. */
    private getAddressInput = () => {
    
        const canShrinkLabelText = this.state.isFocus || !!this.props.doesInputHaveValue || !!this.props.value;
        
        const canShowClearTextIcon = !this.state.isFocus && !!this.props.doesInputHaveValue || !!this.props.value;

        /** Path of the clear icon displayed to the right end of the input field  */
        const endIconPath = LoadEndIconForTextField(canShowClearTextIcon, false);

        return (
            <GooglePlaceAutoComplete
                PlaceholderText=""
                EndIcon={endIconPath}
                PlaceValue={this.props.value}
                LabelText={this.props.label}
                AddressInputFieldType="Pickup"
                onClearEvent={this.clearAddress}
                PreferNearbyTo={this.props.location}
                UpdateFocus={this.updateFocusStatus}
                OnPlaceSelected={this.addressSelected}
                CanShrinkLabelText={canShrinkLabelText}
                onQuickClearClicked={this.props.onQuickClearClicked}
                isQuickClearClicked={!!this.props.isQuickClearClicked}
                reportHasAddressInputValue={this.props.reportHasInputValue}
            />
        );
    }
    
    private clearAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateHandler(e);
    }

    private addressSelected = (place: google.maps.places.PlaceResult, displayAddress: string) => {
        if (this.props.onAddressChosen) {
            this.props.onAddressChosen(this.getAddress(place, displayAddress));
        }
    }

    /**
     * Return the selected address data in the format required by the API.
     */
    private getAddress(place: google.maps.places.PlaceResult, display: string): SelectedAddress {
        let streetNumber: string = '';
        let streetName: string = '';
        let suburb: string = '';

        if (place.address_components) {
            place.address_components.map((i) => {
                if (i.types[0] === "street_number") {
                    streetNumber = i.long_name;
                }
                if (i.types[0] === "route") {
                    streetName = i.long_name;
                }
                if (i.types[0] === "locality") {
                    suburb = i.long_name;
                }
            });
        }

        return {
            streetNumber,
            streetName,
            suburb,
            display
        };
    }
}

export default InputField;