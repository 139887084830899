import React from 'react';
import './BookingContactNumber.scss';
import { connect } from "react-redux";
import { Dispatch } from '../../Dispatch';
import 'react-intl-tel-input/dist/main.css';
import PhoneInput from "react-intl-tel-input";
import { ApplicationState } from '../../../appState';
import { CheckAddPlus } from '../../../utils/Formattingutil';
import { UserContactNumberInfo } from '../../Verification/VerificationEntities';
import { BookingWidgetModeKind } from '../../BookingTemplate/BookingTemplateEntities';
import { Config } from '../../../Config/Config';
import { TextField } from '@material-ui/core';
import { TextFieldEndIcon } from './TextFieldEndIcon';
import { LoadEndIconForTextField } from '../BookingHelper';

interface PropsFromStore {
    BookingWidgetMode: BookingWidgetModeKind;
    UserContactNumberInfo: UserContactNumberInfo;
    IsBookingFormStrictValidationModeOn: boolean;
    PassengerContactNumberInfo?: UserContactNumberInfo;
}

interface ContactDetailsState {    
    isFocus: boolean;    
}

/**
 * For logged-in users with valid phone number,
 *  1. Populate the country flag and phone number from the UserProfile
 *  2. User can edit the number
 *  3. Contact number will not be validated
 *
 * For guest users and logged-in users with invalid UserProfile phone number, 
 * 1. By default, Australian flag will be selected
 * 2. Validate the contact number
 */
class BookingContactNumber extends React.Component<PropsFromStore, ContactDetailsState> {

    private phoneInputRef: React.RefObject<any>;
    private inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: PropsFromStore) {
        super(props);
        this.inputRef = React.createRef();
        this.phoneInputRef = React.createRef();

        this.state = {
            isFocus: false
        }
    }

    componentDidMount() {
        this.SetCountryFlag();               
    }

    componentDidUpdate() {
        this.SetCountryFlag();            
    }

    /**
     *  Set the country flag from store
     */
    SetCountryFlag = () => {
        
        const { PassengerContactNumberInfo } = this.props;

        if (!PassengerContactNumberInfo) return;

        if (!PassengerContactNumberInfo.CountryInfo) return;

        if (!this.phoneInputRef.current) return;

        const passengerProfileCountry =  PassengerContactNumberInfo.CountryInfo.CountryCode;
        
        let iti = this.phoneInputRef.current;

        iti.setNumber(passengerProfileCountry);        
    }

    /** 
     * Update the store on change of the country flag
     */
    OnCountryCodeChanged = (status: any, value: any) => {

        Dispatch.Booking.UpdatePassengerDetails({
            PassengerContactNumber: {
                ...this.props.PassengerContactNumberInfo,
                CountryInfo: {
                    CountryCode: CheckAddPlus(value.dialCode), CountryName: value.name, CountryIsoCode: value.iso2
                }
            }
        });

        this.ClearErrorMessage();
    };

    /**
     * Clear the error message
     */
    ClearErrorMessage = () => {

        const { ErrorMessage } = this.props.UserContactNumberInfo;

        if (ErrorMessage) Dispatch.Verification.ClearContactNumberError();
    }

    /**
     * Restricting the phone number field only to numbers. 
     * Store will not be updated if the user entered other characters.
     */
    AllowOnlyNumericValue = () => {
        
        const numberOnlyRegex = /^[0-9\b]+$/;
        const input = this.inputRef.current!.value;

        if (input === '' || numberOnlyRegex.test(input)) {
            this.UpdateContactNumberInStore(input);
        }       
    }

    /**
     * Hide/Show the error message on focus
     */
    UpdateFocusStatus = (isFocus: boolean) => {

        this.setState({ isFocus: isFocus });
        this.ClearErrorMessage();
    };    

    /** 
     * Update the contact number in store
     */
    UpdateContactNumberInStore = (contactNumber: string = "") => {

        Dispatch.Booking.UpdatePassengerDetails({
            PassengerContactNumber: {
                ...this.props.PassengerContactNumberInfo,                  
                Contactnumber: contactNumber
            }
        });      
    }

    /**
	 * 4 factors together drive invalid effect
	 * 1. In strict validation mode;
     * 2. In booking mode;
	 * 3. Input not focused;
     * 4. ContactNumber is null or empty;
	 */
	IsInputValid = () => {

        const { BookingWidgetMode, PassengerContactNumberInfo, IsBookingFormStrictValidationModeOn } = this.props;

        // Validate in strict validation mode
        if (!IsBookingFormStrictValidationModeOn) return true;        
                
        // Do not validate for template booking
        if (BookingWidgetMode !== BookingWidgetModeKind.Booking) return true;

        // Do not validate, if contact number input is focused
        if (this.state.isFocus) return true;

        // Validate if contact number is empty
        if (PassengerContactNumberInfo && PassengerContactNumberInfo.Contactnumber) return true;
        
        return false;
    };

    render() {
        
        let contactNumber = "";
        let labelText = "Contact number";
        let errMessage : string | undefined  = "";        
        
        const { PassengerContactNumberInfo, UserContactNumberInfo } = this.props;

        // Show error message when invalid contact number is provided
        const isValidPhoneNumber = this.IsInputValid();
        
        errMessage = !isValidPhoneNumber ? "Please enter a contact number" : UserContactNumberInfo.ErrorMessage;
        
        const isInputInvalid = !!errMessage;
        
        const mobileValidityClass = isInputInvalid ? "phone-number-group contact-number-error" : "phone-number-group";        

        // Assign the contact number from store
        contactNumber = PassengerContactNumberInfo?.Contactnumber ?? "";
        
        const canShrinkLabel = this.state.isFocus || !!contactNumber;

        const showClearTextIcon = !this.state.isFocus && !!contactNumber;    

        /** Path of the icon (e.g. clear, error) displayed to the right end of the input field  */
        const endIconPath = LoadEndIconForTextField(!!showClearTextIcon, isInputInvalid);
                       
        return (
            <div className="booking-fields-panel contact-number-panel">
                <div className="contact-number-container">
                    <div className={mobileValidityClass}>
                        <div className="country-code-section">
                            <PhoneInput
                                defaultCountry="au"
                                css={ ['intl-tel-input', 'form-control'] }
                                separateDialCode="true"
                                fieldId={"phoneInput"}
                                localization="false"
                                onSelectFlag={this.OnCountryCodeChanged}
                                preferredCountries={""}
                                ref={this.phoneInputRef}                  
                            />
                        </div>
                    </div>    
                    <TextField
                        type="tel"
                        fullWidth={true}
                        label={labelText}
                        variant="outlined"
                        value={contactNumber}
                        error={isInputInvalid}
                        inputRef={this.inputRef}
                        className="simple-textfield"
                        onChange={this.AllowOnlyNumericValue}
                        InputLabelProps={{shrink: canShrinkLabel}}
                        onBlur={() => this.UpdateFocusStatus(false)}
                        onFocus={() => this.UpdateFocusStatus(true)}
                        inputProps={{maxLength: Config.ContactNumberMaxLength}}
                        InputProps={{
                            endAdornment: <TextFieldEndIcon iconPath={endIconPath} onClick={() => this.UpdateContactNumberInStore()} />
                        }}
                    />
                </div>
                { isInputInvalid && <div className="booking-form-error-message">{errMessage}</div> }
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore  {
    return {        
        UserContactNumberInfo: state.verification.UserContactNumberInfo,
        IsBookingFormStrictValidationModeOn: state.uiLogicControl.BookingForm.IsStrictValidationModeOn,
        PassengerContactNumberInfo: state.booking.Passenger.PassengerContactNumber,
        BookingWidgetMode: state.uiLogicControl.BookingForm.BookingWidgetMode
    };
}

export default connect(mapStateToProps)(BookingContactNumber);