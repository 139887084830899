import React, { Component } from 'react';
import { connect } from "react-redux";
import { ApplicationState } from "../../appState";
import './TncConsentPopup.scss';
import { TncPrivacyState } from './TncPrivacyEntities';
import { AddUserTncAgreementHandler } from './TncPrivacyHelper';
import { Dispatch } from '../Dispatch';
import { DeclineProfileValidationProcess, IsUnderProfileValidationMode } from "../LoginValidation/LoginValidationHelper";
import { VerifyLoginFromLocalStorageAndLogout } from '../../modules/Authentication/AuthHelper';
import { GetBrandedUrl, BrandedImage } from '../Utils/BrandedContentUrls';
import { UILayoutMode } from "../UILogicControl/UILogicControlEntities";
import { SignupTncConsent } from "../Authentication/Login/Signup";

interface PropsFromStore {
    TncPrivacy: TncPrivacyState;
    LayoutMode: UILayoutMode;
}

export interface TncConsentState {
    TncAccepted: boolean;
}

class TncConsentPopup extends Component<PropsFromStore, TncConsentState> {
    constructor(props: PropsFromStore) {
        super(props);

        this.state = {
            TncAccepted: false
        }

        this.ToggleConsent = this.ToggleConsent.bind(this);
    }

    /** Toggle the status of TnC acceptance based on toggle of the checkbox. */
    ToggleConsent() {
        this.setState({ TncAccepted: !this.state.TncAccepted });
    }

    render() {
        return(
            <div className="tnc-consent-popup-panel">
                <HeaderOfDesktop {...this.props} />

                <div className="tnc-consent-popup-body">
                    {!!this.props.TncPrivacy.TncContent && <div className="terms paddingTop" dangerouslySetInnerHTML={{__html: this.props.TncPrivacy.TncContent}}></div>}
                </div>

                <div className="tnc-consent-popup-footer tnc-consent-popup-panel-header-footer-props">
                     <SignupTncConsent TncAccepted={this.state.TncAccepted} ToggleConsent={this.ToggleConsent} />
                     <ButtonsOfTncConsent TncAccepted={this.state.TncAccepted} {...this.props} />
                 </div>
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore  {
    return {
        TncPrivacy: state.tncPrivacy,
        LayoutMode: state.uiLogicControl.LayoutMode
    };
}

export default connect(mapStateToProps)(TncConsentPopup);

/**
 * This is the old header for desktop tnc consent.
 * It's actually covering/replacing the <CommonDialogHeader> for desktop version, as the UI design is different.
 */
const HeaderOfDesktop: React.FC<PropsFromStore> = (props) => {

    if (props.LayoutMode === UILayoutMode.Mobile) return null;

    return (
        <div className="tnc-consent-popup-header tnc-consent-popup-panel-header-footer-props">
            <img src={GetBrandedUrl(BrandedImage.Logo)} width="auto" height="30px" alt="logo" className="logo" />  
            <span>Terms and conditions</span>
        </div>
    );
}

/**
 * Buttons for tncs
 */
export const ButtonsOfTncConsent: React.FC<PropsFromStore & TncConsentState> = (props) => {
    const AcceptBtnClass = props.TncAccepted ? "tnc-consent-popup-btn-accept" : "disable-click tnc-consent-popup-btn-decline"

    return (
        <div className="tnc-btns-group">
            <button className="tnc-consent-popup-btn-decline" type="button" onClick={DeclineTncConsent}>Decline</button>
            <button className={AcceptBtnClass} type="button" onClick={() => AcceptTncConsent(props.TncAccepted, props.TncPrivacy)}>OK</button>
        </div>
    );
}

export function DeclineTncConsent() {

    CloseTncConsentDialog();

    if (IsUnderProfileValidationMode()) { DeclineProfileValidationProcess(); }
}

async function AcceptTncConsent(tncAccepted: boolean, tncPrivacy: TncPrivacyState) {

    // Do not proceed if the checkbox is not checked.
    if (!tncAccepted) return;

    VerifyLoginFromLocalStorageAndLogout();
    
    CloseTncConsentDialog();

    if(tncPrivacy.TncInfo) {
        await AddUserTncAgreementHandler(tncPrivacy.TncInfo.tncId);
    }    
}

function CloseTncConsentDialog() {
    Dispatch.Dialog.CloseTopDialog();
    Dispatch.Dialog.CleanSimpleErrorMessage();
}