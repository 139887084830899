import React, { FC } from 'react';
import './MobileDialog.scss';
import { DialogKind } from './DialogEntities';
import Confirmation from '../Booking/Confirmation';
import Verification from '../Verification/Verification';
import ContactDetails from '../Verification/ContactDetails';
import RemovePaymentCardConfirmation from '../Cards/RemovePaymentCardConfirmation';
import DescriptiveErrorMessage from '../Booking/ErrorMessages/DescriptiveErrorMessage';
import SimpleErrorMessaging from '../../widgets/general-error-message/SimpleErrorMessaging';
import CancelBooking from '../Booking/CancelBooking';
import ShareBooking from '../MyBookings/UI/ShareBooking';
import { CommonDialogHeader } from "./CommonDialogHeader";
import { CommonDialogDescription } from "./CommonDialogDescription";
import Login from "../Authentication/Login/Login";
import ForgotPassword from "../Authentication/Login/ForgotPassword";
import Signup from "../Authentication/Login/Signup";
import TncConsentPopup from '../TncPrivacy/TncConsentPopup';
import SentEnquiry from '../ContactUs/Messages/SentEnquiry';
import DialogClose from './DialogClose';
import { MultiTenantSelector } from "../Authentication/MultiTenant/MultiTenantSelector";
import PriceGuaranteeDialogTip from '../Dialog2/PriceGuaranteeDialog/PriceGuaranteeDialogTip';
import { LegalDocumentsConsentDialog } from '../LegalDocuments/UpdatedLegalDocumentsConsent';
import MobileAddFavourites  from '../Booking/Mobile/MobileAddFavourites';
import IEDeprecationDialogTip from '../Dialog2/IEDeprecationDialog/IEDeprecationDialogTip';
import { ClickAndCollectInfo } from '../ClickAndCollect/ClickAndCollectInfo';
import { PassengerInfo } from '../PassengerService/PassengerInfo';

/**
 * Contains the dialogs which are rendered on both the desktop as well as mobile.
 */
export const ResponsiveDialogHost: FC<{ dialog: DialogKind }> = ({ dialog }) => (
    <>
        <CommonDialogHeader />
        <div className="shared-scroll-wrapper">
            <CommonDialogDescription />
            {(dialog == DialogKind.Confirmation) && <Confirmation />}
            {(dialog == DialogKind.Verification) && <Verification />}
            {(dialog == DialogKind.ContactDetails) && <ContactDetails />}
            {(dialog == DialogKind.SimpleErrorMessaging) && <SimpleErrorMessaging />}
            {(dialog == DialogKind.DescriptiveErrorMessage) && <DescriptiveErrorMessage />}
            {(dialog == DialogKind.RemovePaymentCardConfirmation) && <RemovePaymentCardConfirmation />}
            {(dialog == DialogKind.CancelBooking) && <CancelBooking />}
            {(dialog == DialogKind.ShareBooking) && <ShareBooking />}
            {(dialog == DialogKind.SignUp) && <Signup />}
            {(dialog == DialogKind.LogIn) && <Login />}
            {(dialog == DialogKind.MultiTenantSelector) && <MultiTenantSelector />}
            {(dialog == DialogKind.ForgotPassword) && <ForgotPassword />}
            {(dialog == DialogKind.TncConsent) && <TncConsentPopup />}
            {(dialog == DialogKind.SentEnquiry) && <SentEnquiry />}
            {(dialog == DialogKind.PriceGuaranteeFAQ) && <PriceGuaranteeDialogTip />}
            {(dialog == DialogKind.LegalDocumentsConsent) && <LegalDocumentsConsentDialog />}
            {(dialog == DialogKind.AddFavourite) && <MobileAddFavourites />}
            {(dialog == DialogKind.ClickAndCollectInfo) && <ClickAndCollectInfo />}
            {(dialog == DialogKind.IE11DeprecationWarning) && <IEDeprecationDialogTip />}
            {(dialog == DialogKind.PassengerInfo) && <PassengerInfo />}
        </div>
        <DialogClose />
    </>
);
