import { GeoPoint } from "../../modules/Location/Entities";

/** Value equality test for "pickupLocation" and "dropoffLocation". */
export function areLocationSourcesEqual(x: LocationsSource, y: LocationsSource) {

    return areLocationsEqual(x.pickupLocation, y.pickupLocation) && areLocationsEqual(x.dropoffLocation, y.dropoffLocation);
}

/** Value equality test for two optional GeoPoint values being equal. */
function areLocationsEqual(x: GeoPoint | undefined, y: GeoPoint | undefined): boolean {

    if (x === y) return true;
    if (!x) return false;
    if (!y) return false;

    return (x.latitude === y.latitude) && (x.longitude == y.longitude);
}

/** Duck Typing interface. Works for GoogleMapProps, GoogleMapState, and DirectionsData. */
interface LocationsSource {

    pickupLocation?: GeoPoint;
    dropoffLocation?: GeoPoint;
}