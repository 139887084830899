import React, { ComponentClass } from 'react';
import { withProps } from "recompose";
import { withScriptjs, WithScriptjsProps } from 'react-google-maps';
import { GetGoogleMapsApiUrl } from './GetGoogleMapsApiUrl';

/** Given any component, returns a new component that ensures the Google Maps JavaScript API is loaded before running.
 * Use this enhancer on any component that uses the Google Maps JS API.
 */
export function GoogleMapsEnhance<P>(component: React.ComponentClass<P>) {

    // this adds two new props and the behaviour of loading the Google Maps JS API script file
    const Try1 = withScriptjs(component);

    // this sets good default values into the props required by withScriptjs
    const googleProps: WithScriptjsProps = {
        googleMapURL: GetGoogleMapsApiUrl(),
        loadingElement: (<div style={{ height: `100%` }} />),
    };

    const Try2 = withProps<WithScriptjsProps, P>(googleProps)(Try1);
    return Try2;
}