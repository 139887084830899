import { VehicleOption, MaxiTaxiSeaterInfoPayload, MaxiTaxiLookUpType } from "./Redux/ConditionEntities";

/** Calculates the minimum, maximum and average seat count for Maxi Taxis. 
 * The "Average" value will actually be the median, but is normally the same. */
export function CalculateMaxiTaxiSeaterInfo(maxiTaxiLookUp: MaxiTaxiLookUpType): MaxiTaxiSeaterInfoPayload {
    var index: number = 1, Minimum: number = 0, Average: number = 0, Maximum: number = 0;
    const MaxiTaxiLookUpLength = Object.keys(maxiTaxiLookUp).length;
    if (MaxiTaxiLookUpLength === 0) { return { Minimum: Minimum, Average: Average, Maximum: Maximum } }

    /**IE doesn't support  object.values function. Used Object.keys as an alternative to achive the same goal**/
    Object.keys(maxiTaxiLookUp).map(function (condition) {
        const seater = maxiTaxiLookUp[condition].MaxSeat;
        if (index === 1) { Minimum = seater; }
        if (index === Math.round(MaxiTaxiLookUpLength / 2)) { Average = seater; }
        if (index === MaxiTaxiLookUpLength) { Maximum = seater; }
        index++;
    })

    return { Minimum: Minimum, Average: Average, Maximum: Maximum };
};

/** Update MaxiTaxi with meta info. */
export function DecorateMaxiTaxiInConditionList(conditionList: VehicleOption[], maxiTaxiSeaterInfo: MaxiTaxiSeaterInfoPayload, maxiTaxiLookUp: MaxiTaxiLookUpType): VehicleOption[] {
    var newConditionList: VehicleOption[] = [];

    for (var vehicle of conditionList) {
        const newVehicle: VehicleOption = vehicle.Service.isMaxiTaxi === true ? {
            ...vehicle,
            Service: { ...vehicle.Service, short: maxiTaxiLookUp[maxiTaxiSeaterInfo.Average].Description },
            ApiVehicle: maxiTaxiLookUp[maxiTaxiSeaterInfo.Average]
        } : vehicle;
        newConditionList.push(newVehicle);
    }
    return newConditionList;
};