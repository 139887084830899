import React from 'react';
import { TitleStyle, MarkAsRead, SignupLinkHandler } from './FeaturesHelper';
import { getContentUrl, ContentURL } from '../Utils/ContentURL';
import { FeatureId, FeatureCardProps } from './FeaturesEntities';
import { GetValues } from '../../Config/MyAppConfig';

export const MultipleTracking = (props: FeatureCardProps) => {
    return (
        <React.Fragment key={FeatureId.MultipleTracking}>
            <div className="feature-card">
                <div className="feature-date">
                    <span className="feature-date-img"></span>
                    <span>21.09.2020</span>
                </div>
                <div style={TitleStyle(GetValues().BrandColour)} >TRACK MULTIPLE TRIPS</div>
                <div className="feature-desc">Track multiple bookings at once. See the live status of cabs, in detail on maps.</div>
                <div className="feature-image">
                    <img src={getContentUrl(ContentURL.images.WhatsNew.MultipleTracking)} alt="Track Trips" />
                </div>
                <div className="feature-desc"><span>Not signed up?</span> No problem. <a href="#" className="feature-link" onClick={SignupLinkHandler}>Tap here</a> to access favourites, payments and more.</div>
                {
                    // Hide the button, if the card is acknowledged
                    props.CanShowAcknowledgeButton &&
                    <div className="feature-btn">
                        <button className="btn-OK" onClick={() => MarkAsRead(FeatureId.MultipleTracking)}>OK, GOT IT</button>
                    </div>
                }
            </div>
        </React.Fragment>
    );
}