
/** The return value of a call to the Booking Management Service with a payload data type {T} */
export type ServiceResult<T> = FailureResult | SuccessResult<T>;

/** The possible types for a failed call to the Booking Management service */
export type FailureResult = NetworkFailResult | WebServerErrorResult | ApplicationErrorResult;

/** The result where the request doesn't complete: timeout or network down */
export interface NetworkFailResult {
    outcome: ServiceCallOutcome.NoResponseFromServer;
    isSuccess: false;

    /** True for timeout, false for unable to connect (we presume) */
    isTimeout: boolean;
}

/** The result where the web server returns an HTTP error, e.g. 404 for invalid URL */
export interface WebServerErrorResult {
    outcome: ServiceCallOutcome.WebServerError;
    isSuccess: false;
    httpStatus: HttpStatus;
}

/** The result where the application (booking management service) returns an error */
export interface ApplicationErrorResult {
    outcome: ServiceCallOutcome.ApplicationError;
    isSuccess: false;
    httpStatus: HttpStatus;
    apiStatus: ApiStatus;
}

/** Successful result with a payload of type {T}. */
export interface SuccessResult<T> {
    outcome: ServiceCallOutcome.Success;
    isSuccess: true;
    httpStatus: HttpStatus;
    apiStatus: ApiStatus;
    value: T;
}

/** This categorises the major response types. It can be used as a discriminator for the compound type ServiceResult<T>. */
export enum ServiceCallOutcome {

    /** Timeout or unable to connect */
    NoResponseFromServer = "No Response From Server",

    /** HTTP non-success raised by the web server, typically 404 / 500. No ApiResponse will be available. */
    WebServerError = "Web Server Error",

    /** HTTP non-success raised by the web API. 500 but wrapped in an ApiResponse{T} */
    ApplicationError = "Application Error",

    /** HTTP 200-299. Will be wrapped in an ApiResponse{T} as well. */
    Success = "Success",
}

/** HTTP Status Code and Text. Available from any HTTP request that completes (i.e. not timeout) */
export interface HttpStatus {
    Code: number;
    Text: string;
}

/** Api Response. This has the key fields from the BookingManagement ApiResponse{T} type. */
export interface ApiStatus {
    Message: string;

    /** No longer used. */
    MessageId?: number;
}

/** A wrapper provided by the Booking Management Service. */
export interface ApiResponse<T> {

    /** The payload */
    result: T;

    /** This seems to be true if and only if the HTTP status is 200 */
    success: boolean;

    /** This can be lots of things: a human-readable generic error message, OR "OK" OR an http status code as a string. Usually it is a copy of the HTTP Status code. */
    status: string;

    /** per-function error codes. Not included by default. */
    messageID?: number;

    /** Error message. TODO: confirm. I think it will be an empty string by default. Not 100% sure. */
    message: string;

    /** Extended error information. Not included by default. */
    errors?: string[];

    /** 
     *  For forwards compatibility with ApiOutcome.
     *  ApiResponse is 1; ApiOutcome / ApiResult is 2.
     */
    ApiVersion?: number;
}

/** A model of the ApiOutcome data contract in the Booking Management API */
export type ApiOutcome = GoodApiOutcome | BadApiOutcome;

/** 
 *  The shape of ApiOutcome when the operation is successful. 
 *  There is no ErrorMessage in this case.
 */
export interface GoodApiOutcome {

    /** Whether the API operation succeeded. */
    IsSuccess: true;

    /** The error message, if this operation failed. */
    ErrorMessage: null;

    /** The value is currently expected to be 2 */
    ApiVersion: number;
}

/**
 *  The shape of ApiOutcome when the operation fails.
 *  ErrorMessage is populated in this case.
 */
export interface BadApiOutcome {

    IsSuccess: false;

    ErrorMessage: string;

    /** The value is currently expected to be 2 */
    ApiVersion: number;
}

/** ApiResult{T} when the operation is successful. */
export interface GoodApiResult<T> extends GoodApiOutcome {

    /** The payload (actual return value) of the operation */
    Value: T;
}