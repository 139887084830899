import React, { ChangeEvent } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../appState";
import { Dispatch } from "../Dispatch";
import { ContentURL, getContentUrl } from "../Utils/ContentURL";
import './CardSelector.scss';
import { LoginStatusKind } from "../Authentication/AuthEntities";
import CredentialsController from "../Authentication/Login/CredentialsController";
import { LogEvent } from "../../utils/LogEvent";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { PaymentCardErrorType, PaymentHelpText, PaymentOption } from "../Payment/PaymentEntities";
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput} from "@material-ui/core";
import { ValidateServicesForSatss } from "../Condition/ConditionValidation";
import { SnackBar } from "../../widgets/InformationDisplay/Snackbar";
import { PopulatePaymentOptions, ShouldPromptUserToAddCard } from "../Payment/PaymentHandler";
import { BookingWorkFlowState } from "../Booking/Redux/BookingState";
import { CheckForCardExpiry } from "../Payment/PaymentCardValidation";

export interface CardSelectorProps {
    LoginStatus: LoginStatusKind;
    BookingPayload: BookingWorkFlowState;
    ErrorMessage: PaymentCardErrorType | null;
    PaymentOptions: PaymentOption[];
    IsPriceGuaranteeSelected: boolean;
    ShouldPromptUserToAddCard: boolean;
}

/**
 * This component is for selecting payment option while creating a new booking
 */
class CardSelector extends React.Component<CardSelectorProps> {

    /**
     * It is called when a user select a payment option from the list
     * set the selected payment option in booking store 
     */
    onPaymentMethodChange = (e: ChangeEvent<any>) => {

        LogEvent.PaymentMethodChanged();
        
        const selectedCard = this.props.PaymentOptions.find(option => option.Id === e.target.value);
        if (!selectedCard) return;

        Dispatch.Booking.ChangePaymentMethod(selectedCard);
        
        Dispatch.Payment.SetError(null);

        CheckForCardExpiry(selectedCard);

        ValidateServicesForSatss();
    }

    /**
     * It is triggered when user clicks on Add new payment method from the dropdown
    */
    OpenCardRegistrationPanel = () => {

        // Open the signup modal for registering the guest user
        if (this.props.LoginStatus === LoginStatusKind.LoggedOut) {
            if (FeatureFlags.NoSignup) {
                // The user experience here is not confirmed yet. The ideal case is, guest users should never get up to this point.
                return;
            }

            // Clear the error message if exist
            Dispatch.Payment.SetError(null);

            // Open Add payment card screen after signup.
            Dispatch.Payment.ShowAddPaymentCardScreenAfterSignup();
            
            LogEvent.SignupTriggeredByAddNewCard();
            
            new CredentialsController().DoSignup();
            return;
        }

        LogEvent.AddNewCardSelected();
        Dispatch.Payment.ToggleCardRegistrationPanel(true);                
    }

    /**
     * Returns the payment option that is currently selected, unless there is an error with it.
     */
    GetSelectedOption: () => PaymentOption | null = () => {

        // Expired card should still be displayed in the card selector.
        if (this.props.ErrorMessage && this.props.ErrorMessage !== PaymentCardErrorType.CardExpired) return null;

        const option = this.props.BookingPayload.PaymentOption;
        if (!option) return null;

        return option;
    }

    /**
     * Return the payment help text to be displayed below the payment option dropdown.
     */
    GetPaymentHelpText: () => string = () => {

        // Display the below text to guest users, when price guarantee toggle is switched ON
        if (this.props.ShouldPromptUserToAddCard) return "Add a card to use Price Guarantee";

        // Do not show, incase of error
        if (this.props.ErrorMessage) return "";
        
        // Do not show, when no payment option is selected
        if (!this.props.BookingPayload.PaymentOption) return "";
        
        return PaymentHelpText[this.props.BookingPayload.PaymentOption.Kind];
    }

    render() {

        // the actual payment option
        const selectedOption = this.GetSelectedOption();

        // value for the <Select> component
        const selectedValue = selectedOption?.Id ?? "";

        const labelText = !selectedOption ? "Please select" : "";

        const paymentHelperText = this.GetPaymentHelpText();

        return (
            <div className="booking-fields-panel">
                <FormControl variant="outlined" fullWidth={true} className="card-selector-dropdown" error={!!this.props.ErrorMessage}>
                    <InputLabel id="card-selector-label">{labelText}</InputLabel>
                    <Select
                        value={selectedValue}
                        labelId="card-selector-label"
                        onChange={this.onPaymentMethodChange}
                        input={<OutlinedInput label={labelText} />}
                    >
                        {this.RenderSelectEntries()}
                        {this.RenderAddCardOption()}
                    </Select>
                </FormControl>
                {this.props.ErrorMessage && (
                    <div className="booking-form-error-message">{this.props.ErrorMessage}
                    </div>
                )}
                {paymentHelperText && (
                    <div className="payment-help-text">
                        <SnackBar DisplayText={paymentHelperText} />
                    </div>
                )}
            </div>
        );
    }

    /**
     * Renders the normal entries in the dropdown list.
     */
    RenderSelectEntries() {

        return this.props.PaymentOptions.map((option: PaymentOption, index: number) => (
            <MenuItem className="cardMenuItem" key={index} value={option.Id} disableGutters={true}>
                <div>
                    <img className="cardSelectorCardImage" src={getContentUrl(ContentURL.images.PaymentType.Selector[option.Type])} />
                    <span className="cardSelectorCardName"> {option.Name}</span>
                </div>
            </MenuItem>
        ));
    }

    /**
     * Renders the "Add a new card" entry in the dropdown list.
     * Only when CNP is supported.
     */
    RenderAddCardOption = () => {

        if (!FeatureFlags.CardNotPresentPayment) return null;

        return (<MenuItem className="cardMenuItem" onClick={this.OpenCardRegistrationPanel} value="#">
            <img className="cardSelectorAddCardImage" src={getContentUrl(ContentURL.images.buttons.addIconBlack)} />
            <span className="cardSelectorCardName">Add a new card</span>
        </MenuItem>);
    }
}

function mapStateToProps(state: ApplicationState): CardSelectorProps {
    return {
        BookingPayload: state.booking,
        ErrorMessage: state.payment.PaymentCardError,
        LoginStatus: state.authentication.LoginStatus,
        PaymentOptions: PopulatePaymentOptions(state),
        ShouldPromptUserToAddCard: ShouldPromptUserToAddCard(state),
        IsPriceGuaranteeSelected: state.condition.IsPriceGuaranteeSelected
    };
}

export default connect(mapStateToProps)(CardSelector);
