import { VehicleOption, DataLoadingStatus, MaxiTaxiLookUpType, MaxiTaxiSeaterInfoPayload, AsyncLoadState, FareRequestInput, ApiVehicleOption } from "./ConditionEntities";
import services from '../../../utils/service-vehicles/ServiceMetadata';
import { FixedFare } from "../../../Services/FixedFareEntities";
import { Vehicle } from "../../../Services/MakeBookingContracts";

/** Redux state slice for the Conditions / VehicleOptions data */
export interface ConditionState {
    SelectedCondition: VehicleOption,

    /** This will be an empty array when the condition data hasn't been loaded. */
    ConditionList: VehicleOption[],
    MaxiTaxiLookUp: MaxiTaxiLookUpType
    MaxiTaxiSeaterInfo: MaxiTaxiSeaterInfoPayload,

    /** True when the user has entered vehicle selection mode. It will trigger the Vehicle picker UI to show.  */
    IsVehicleSelectionActive: boolean;

    /** 
     * Tracks the Condition load API call progress. The number is the Suburb ID used to run the query. 
     * This is applicable for only V1 (Booking API)  
    */
    LoadingStatus: AsyncLoadState<number>;

    /** Tracks the Get Fare API call progress  */
    FareLoadStatus: AsyncLoadState<FareRequestInput>;

    /** 
     *  Contains the entire list of conditions for the selected suburb.
     *  This seems to be a workaround for a known issue with the V1 API.
     *  One API endpoint would include the Parcel condition, while another would not. This is the raw, unfiltered data.
     */
    AllRawV1Data: ApiVehicleOption[];

    /** Fixed fare for the given addresses. This includes fares for all the vehicle types and additional data such as FixedFarePartitionKey. */
    FixedFare?: FixedFare;

    /** When true, opens the popup to display more info about selected service. */
    ShowServiceInfo: boolean;

    /** Service to display more info about. */
    ActiveService?: VehicleOption;

    /** True when the user has checked the price guarantee toggle to display fixed fare. */
    IsPriceGuaranteeSelected: boolean;
    AllVehicles: Vehicle[];
}

/** Initial value of the selected condition */
export const InitVehicleState: VehicleOption = {    
    Service: services.any,
    Fare: undefined
};

/** Initial value of the condition container */
export const InitConditionContainerState: ConditionState = {   
    SelectedCondition: InitVehicleState,
    ConditionList: [],
    MaxiTaxiLookUp: [],
    MaxiTaxiSeaterInfo: { Minimum: 0, Average: 0, Maximum: 0 },
    IsVehicleSelectionActive: false,
    LoadingStatus: {
        Status: DataLoadingStatus.Idle,
        LastInput: null,
    },
    FareLoadStatus: {
        Status: DataLoadingStatus.Idle,
        LastInput: null,
    },
    AllRawV1Data: [],
    ShowServiceInfo: false,
    IsPriceGuaranteeSelected: false,
    AllVehicles: []
};