import { ReduxStoreSlice } from "../../../ReduxStoreSlice";
import { BookingWorkFlowState, DefaultBookingWorkFlowState } from "./BookingState";
import { ServiceCheckStatus, Verification, Passenger, NotesToDriver, PickupServiceCheckState, AccountBookingPayload, PickupServiceabilityDetails } from "../BookingEntities";
import { PaymentOption } from "../../Payment/PaymentEntities";
import { ApiGeoPoint } from "../../../Services/BookingEntities";
import { FeatureFlags } from "../../../Config/FeatureFlags";
import { PlaceResult } from "../../../Services/BookingEntities";
import { GooglePlaceToAddressV2Details } from "../../../widgets/google-maps/ParseGooglePlace";
import { AddressV2 } from "../../../Services/MakeBookingContracts";
import { DateTime } from "luxon";
import { BookingTimeFutureV2, BookingTimeNowV2, BookingTimeV2 } from "../../BookingV2/BookingV2Entities";

const slice = new ReduxStoreSlice<BookingWorkFlowState>("Booking", DefaultBookingWorkFlowState);

/** Dispatcher for actions in the Booking state slice. */
export const BookingDispatchV2 = {
    ChangePickup: slice.Action("Pickup Address", HandlePickUpAddressChange),
    ChangeDropoff: slice.Action("Dropoff Address", HandleDropOffAddressChange),
    ClearPickup: slice.EmptyAction("Clear Pickup", HandlePickUpAddressClear),
    ClearDropoff: slice.EmptyAction("Clear Dropoff", HandleDropOffAddressClear),
    UpdateBookingTimeForNow: slice.EmptyAction("Update Booking Time For Now", UpdateBookingTimeForNow),
    UpdateBookingTimeForFuture: slice.Action("Update Booking Time For Future", UpdateBookingTimeForFuture),
    ChangeVerification: slice.Action("Change Verification", HandleVerificationChange),
    UpdatePassengerDetails: slice.Action("Passenger Details", HandlePassengerDetailsChange),
    AddNotesToDriver: slice.Action("Notes To Driver", HandleNotesToDriverChange),
    UpdatePickupServiceability: slice.Action("Pickup Serviceability", HandlePickupServiceCheckUpdate),
    EnableDiagnosticUI: slice.EmptyAction("Enable Diagnostic UI", HandleEnableDiagnosticUI),
    SetBookOnAccount: slice.Action("Book On Account", HandleSetBookOnAccount),
    ChangePaymentMethod: slice.Action("Change Payment Option", HandlePaymentOptionChange),
    UpdateAccountDetails: slice.Action("Change Account Details", HandleAccountBookingPayloadChange),
    ClearAccountDetails: slice.EmptyAction("Clear Account Details", HandleClearAccountDetails),
    SetDeviceData: slice.Action("Set Device Data", HandleSetDeviceData),
    SetTemplateName: slice.Action("Change Template Name", HandleTemplateNameChange),
    ClearPaymentOption: slice.EmptyAction("Clear Payment Option", ClearPaymentOption)
};

/** Reducer for the Booking store slice */
export const BookingReducerV2 = slice.MakeCombinedReducer();

/** Handle the PickUp address  for Booking Payload */
function HandlePickUpAddressChange(state: BookingWorkFlowState, pickupAddress: PlaceResult): BookingWorkFlowState {
    return {
        ...state,
        pickup: pickupAddress.address,
        PickupV2: ConvertPlaceResultToAddressV2(pickupAddress)
    };
}

/** Handle the Dropoff condition for Booking Payload */
function HandleDropOffAddressChange(state: BookingWorkFlowState, dropoffAddress: PlaceResult): BookingWorkFlowState {
    return {
        ...state,
        dropoff: dropoffAddress.address,
        DropoffV2: ConvertPlaceResultToAddressV2(dropoffAddress)
    };
}

/** Clear the PickUp address for Booking Payload (both V1 and V2). Also reset the pickup state check. */
function HandlePickUpAddressClear(state: BookingWorkFlowState): BookingWorkFlowState {

    // i.e. clone but remove [pickup] field
    let { pickup, ...newState } = state;

    // this resets the pickup check
    newState.PickupServiceCheck = { status: ServiceCheckStatus.NoInputSelected };

    // also clear V2 address
    newState.PickupV2 = null;

    return newState;
}

/** Clear the Dropoff condition for Booking Payload (both V1 and V2) */
function HandleDropOffAddressClear(state: BookingWorkFlowState): BookingWorkFlowState {

    // i.e. clone but remove [dropoff] field
    const { dropoff, ...newState } = state;

    // also clear V2 address
    newState.DropoffV2 = null;

    return newState;
}

/** Apply a booking time update action to the booking state (book for now). */
function UpdateBookingTimeForNow(state: BookingWorkFlowState): BookingWorkFlowState {

    const timeV2: BookingTimeNowV2 = {
        IsImmediate: true
    };

    return BookingTimeHandler(state, timeV2);
}

/** Apply a booking time update action to the booking state (book for future). */
function UpdateBookingTimeForFuture(state: BookingWorkFlowState, bookingTime: DateTime): BookingWorkFlowState {

    const timeV2: BookingTimeFutureV2 = {
        RequestedDate: bookingTime,
        IsImmediate: false
    };

    return BookingTimeHandler(state, timeV2);
}

/** Generate a shared BookingWorkFlowState for update time now and future. */
function BookingTimeHandler(state: BookingWorkFlowState, timeV2: BookingTimeV2): BookingWorkFlowState {

    return {
        ...state,
        BookingTimeV2: timeV2
    };
}

/** This is doing a kind of PATCH on the Verification property */
function HandleVerificationChange(state: BookingWorkFlowState, newData: Verification): BookingWorkFlowState {

    return {
        ...state,
        Verification: {
            ...state.Verification,
            ...newData
        }
    };
}

/** Handle the Passenger Details for Booking Payload */
function HandlePassengerDetailsChange(state: BookingWorkFlowState, newPaxDetails: Passenger): BookingWorkFlowState {

    /** Passenger details are updated in different components of the UI. Update only the changed props in the store. */
    return {
        ...state,
        Passenger: {
            ...state.Passenger,
            ...newPaxDetails,
        }
    };
}

/** Handle the Notes To Driver for Booking Payload */
function HandleNotesToDriverChange(state: BookingWorkFlowState, notes: NotesToDriver): BookingWorkFlowState {
    return {
        ...state,
        NotesToDriver: notes,
    };
}

/** Handler for the PickupServiceCheckUpdate action. This will update the PickupServiceCheck data. */
function HandlePickupServiceCheckUpdate(bookingState: BookingWorkFlowState, serviceabilityDetails: PickupServiceabilityDetails): BookingWorkFlowState {

    // invalid updates get ignored
    if (!IsProposedStateChangeValid(bookingState, serviceabilityDetails.PickupPlaceId, serviceabilityDetails.ServiceabilityCheckState)) {
        return bookingState;
    }

    return {
        ...bookingState,
        PickupServiceCheck: serviceabilityDetails.ServiceabilityCheckState,
    };
}

/** This is turning into a mini reducer for CreateBookingPayload.PickupServiceCheck. Should i be using recompose or something?? */
function IsProposedStateChangeValid(currentBookingState: BookingWorkFlowState, pickupAddressPlaceId: string, proposedNewState: PickupServiceCheckState): boolean {

    // check is only fitting to BookingController
    if (FeatureFlags.BookingApiV2) return true;

    // updates to indeterminate state are always OK
    if ((proposedNewState.status === ServiceCheckStatus.CheckInProgress) || (proposedNewState.status === ServiceCheckStatus.NoInputSelected)) {
        return true;
    }

    // result update: only honour if it still matches the pickup address
    const existingPickup = currentBookingState.pickup;
    return !!existingPickup && existingPickup.PlaceId === pickupAddressPlaceId;
}

/** Handle a EnableDiagnosticUI action. Just sets the flag to true. */
function HandleEnableDiagnosticUI(state: BookingWorkFlowState): BookingWorkFlowState {
    return {
        ...state,
        ShowDiagnosticUI: true,
    };
}

/** Enable/disable Book on account feature based on the given value */
function HandleSetBookOnAccount(state: BookingWorkFlowState, newValue: boolean): BookingWorkFlowState {
    return {
        ...state,
        IsOnAccount: newValue
    };
}

/** Handle the Payment option change for Booking Payload */
function HandlePaymentOptionChange(state: BookingWorkFlowState, selectedPaymentOption: PaymentOption): BookingWorkFlowState {
    return {
        ...state,
        PaymentOption: selectedPaymentOption
    };
};

/** Handle updating account details for bookings on account. */
function HandleAccountBookingPayloadChange(state: BookingWorkFlowState, accountDetails: AccountBookingPayload): BookingWorkFlowState {
    return {
        ...state,
        AccountData: accountDetails,
    };
}

/** Handle clear account details when Book on account is disabled. */
function HandleClearAccountDetails(state: BookingWorkFlowState): BookingWorkFlowState {
    return {
        ...state,
        AccountData: null
    };
}

/** Handle for setting device data for Booking Payload */
function HandleSetDeviceData(state: BookingWorkFlowState, deviceData: string): BookingWorkFlowState {
    return {
        ...state,
        DeviceData: deviceData
    };
};

/** Handle the Template name */
function HandleTemplateNameChange(state: BookingWorkFlowState, name: string): BookingWorkFlowState {
    return {
        ...state,
        TemplateName: name,
    };
}

/**
 * Convert PlaceResult to AddressV2
 */
function ConvertPlaceResultToAddressV2(result: PlaceResult): AddressV2 {

    const geoLocation: ApiGeoPoint = {
        Latitude: result.address.Lat,
        Longitude: result.address.Long,
    };

    const addressV2Details = GooglePlaceToAddressV2Details(result.place);

    const addressV2: AddressV2 = {
        ...addressV2Details,
        FullTextAddress: result.address.PlaceText,
        GeoLocation: geoLocation
    }

    return addressV2;
}

/** Clear the Payment option on the booking payload */
function ClearPaymentOption(state: BookingWorkFlowState): BookingWorkFlowState {

    const { PaymentOption, ...newCreatePayload } = state;

    return newCreatePayload;
};
