import React from 'react';
import './CommonSnackbar.scss';
import { connect } from 'react-redux';
import { Dispatch } from '../../Dispatch';
import { ApplicationState } from '../../../appState';
import { IconButton, Slide, Snackbar } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';

interface PropsFromStore {
    shouldOpen: boolean;
}

/**
 * 1. Displays a message about informing the user on how to attain a fare estimate.
 * 2. The snackbar will auto-hide after 10 secs if not closed manually. 
 */
const FareEstimateSnackbarCore: React.FC<PropsFromStore> = (props) => {

    const message = "For a FARE ESTIMATE, enter a valid pickup and destination.";

    return (
        <div className="common-snack-bar">          
            <Snackbar            
                message={message}
                onClose={handleClose}
                open={props.shouldOpen}
                autoHideDuration={10000}
                key="fare-estimate-alert"
                TransitionComponent={SlideTransition}
                action={
                    <IconButton
                        color="primary"
                        aria-label="ok"
                        onClick={handleClose}
                    >
                        OK
                    </IconButton>
                }
            />
        </div>
    );
}

/** 
 * Provides slide transition to the caller component. 
 */
const SlideTransition = (props: TransitionProps) => <Slide {...props} direction="up" />;

const handleClose = () => Dispatch.UILogicControl.HideFareEstimateMessage();

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        shouldOpen: state.uiLogicControl.BookingForm.ShouldShowFareEstimateMesssage
    };
}

export const FareEstimateSnackbar = connect(mapStateToProps)(FareEstimateSnackbarCore);