import { ServiceResult } from './ServiceEntities';
import { Post, GetManagementServiceRootUrlWithoutState } from './ServiceManager';
import { LegalDocuments, UserConsentRequest, UserConsentStatus } from './LegalDocumentsContracts';

/** Base URL for the LegalDocumentsController in the Booking Management Service */
function GetBaseUrl(): string {
    return `${GetManagementServiceRootUrlWithoutState()}LegalDocuments/`;
}

/** HTTP API Calls to the LegalDocumentsController in the Booking Management Service. */
class LegalDocumentsServiceClass {

    /** Gets the latest legal documents available for the current brand. */
    public GetLatestLegalDocuments(): Promise<ServiceResult<LegalDocuments>> {
        const url = `${GetBaseUrl()}LatestDocuments`;
        return Post<LegalDocuments>(url, null);
    }

    /** Gets current user's legal docs consent status including whether a new consent required. */
    public GetLegalDocumentsConsentStatus(): Promise<ServiceResult<UserConsentStatus>> {
        const url = `${GetBaseUrl()}UserConsentStatus`;
        return Post<UserConsentStatus>(url, null);
    }

    /** Update the database with current users consent. */
    public UpdateUserConsent(request: UserConsentRequest): Promise<ServiceResult<void>> {
        const url = `${GetBaseUrl()}UpdateConsent`;
        return Post<void>(url, request);
    }
}

/** Singleton LegalDocumentsService instance */
export const LegalDocumentsService = new LegalDocumentsServiceClass();