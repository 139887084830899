import { GetValues } from "../../Config/MyAppConfig";

/** 
 *  Gets the URL that will load the Google Maps API at runtime, with appropriate access and capabilities.
 *  Docs: https://developers.google.com/maps/documentation/javascript/libraries
 */
export function GetGoogleMapsApiUrl(): string {

    var authSegment = GetValues().GoogleMapsApiAuth;

    return `//maps.googleapis.com/maps/api/js?${authSegment}&libraries=geometry,drawing,places`;
}