import React, { Component } from 'react';
import "./BookingTemplate.scss";
import { connect } from 'react-redux';
import { Dispatch } from '../Dispatch';
import { ApplicationState } from '../../appState';
import BookingTemplateList from './BookingTemplateList';
import { ResetBookingForm } from './BookingTemplateHelper';
import { QuickBookExpandedSection, UILayoutMode } from '../UILogicControl/UILogicControlEntities';
import { BookingWidgetModeKind, BookingTemplateLoadKind } from './BookingTemplateEntities';
import { QuickBookLoadingAnimation } from '../Booking/QuickBook/QuickBookLoadingAnimation';
import { BookingTemplateState } from './BookingTemplateState';
import { LogEvent } from '../../utils/LogEvent';
import { Button, IconButton, InputAdornment, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import { DialogKind } from "../Dialog/DialogEntities";

interface PropFromStore {
    TemplateLoadStatus: BookingTemplateLoadKind;
    TemplateList: BookingTemplateState;
    QuickBookExpandedStatus: QuickBookExpandedSection;
}

interface BookingTemplateDetailsProps {
    numberOfTemplates: number;
}

interface SearchNameState {
    searchInputFav: string;
}
/**
 * Displays booking templates on the Quick book tab
 */
class BookingTemplateDetails extends Component<PropFromStore & BookingTemplateDetailsProps, SearchNameState> {

    constructor(props: PropFromStore & BookingTemplateDetailsProps ){
        super(props);
        this.state= {
        searchInputFav:''
        };
    }

    addFavouriteClickHandler = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLParagraphElement>) => {

        // Stop the toggle handler event to be called
        e.stopPropagation();

        LogEvent.CreateFavouriteButtonClicked();

        // Switch ON the template mode
        ResetBookingForm(BookingWidgetModeKind.CreateTemplate);

        Dispatch.Dialog.MobileOnly(DialogKind.AddFavourite);
        
    }

    // Expand/Collapse the template list
    toggleHandler = () => {

        const { numberOfTemplates, QuickBookExpandedStatus } = this.props;

        // Do not allow toggle, when the template list is empty
        if (!numberOfTemplates) return;

        const listState = QuickBookExpandedStatus !== QuickBookExpandedSection.Templates ? QuickBookExpandedSection.Templates : QuickBookExpandedSection.None;

        Dispatch.UILogicControl.ToggleQuickBookSection(listState);
    }

      /** Event Handler for Favorite input search */
    handleSearchChange = (e:{target: { value: string }}) => {
        this.setState({searchInputFav: e.target.value});
        Dispatch.BookingTemplate.ListFilterText(e.target.value);
    }

    handleOnClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
    }

    /** Click event for clear search Favourite Input Text field. Refresh favourite list */
    handleOnClearClick = () => {
        this.setState({searchInputFav: ""})
        Dispatch.BookingTemplate.ListFilterText('');
    }

    render() {

        const { numberOfTemplates, TemplateLoadStatus, QuickBookExpandedStatus } = this.props;

        // Show spinner animation while, the booking template is being fetched
        const showSpinnerAnimation = TemplateLoadStatus === BookingTemplateLoadKind.Idle || TemplateLoadStatus === BookingTemplateLoadKind.Loading;

        const showTemplates = QuickBookExpandedStatus === QuickBookExpandedSection.Templates;

        // Do not show bottom border in collapsed state
        const hideBorderBottomClass = !showTemplates && "fav-title-hide-border-bottom";

        const collapseClass = showTemplates && "fav-arrow-collapse";

        // Do not show arrow, when the template list is empty
        const arrowClass = numberOfTemplates ? `fav-arrow-expand ${collapseClass}` : "";

        // Do not show pointer cursor, when the template list is empty
        const showCursorClass = numberOfTemplates && "fav-show-pointer-cursor";

        const Component = showSpinnerAnimation ? <QuickBookLoadingAnimation /> :  <BookingTemplateList /> ;

        const value = this.state.searchInputFav;

        /** X ixon at the end of the input field to clear search field */
        const endAdornment = () => {
                    if (value === "") return null;
                    return (
                        <InputAdornment position="end">
                            <IconButton onClick={this.handleOnClearClick}><CloseIcon /></IconButton>
                        </InputAdornment>
                    )
        }

        return (
            <div className="fav-panel">
                <div className={`fav-header ${hideBorderBottomClass} ${showCursorClass}`} onClick={this.toggleHandler}>
                    <div className="fav-title">
                        <p className="header">Favourites <span>({numberOfTemplates})</span></p>
                        <div onClick={this.addFavouriteClickHandler}>
                            <Button className= "create-fav"
                            startIcon={<AddIcon/>} 
                            variant="contained" 
                            size="medium" 
                            color="primary">Create 
                            </Button>
                        </div>
                        <p className="fav-text">Saved trips important to you.</p>
                        <div className="input-div">
                            <InputBase className={"input-search"}
                            onClick={this.handleOnClick}
                            onChange={this.handleSearchChange}
                            value= {this.state.searchInputFav}
                            placeholder="Search Favourites"
                            startAdornment = {
                                <InputAdornment position="start"><SearchIcon /></InputAdornment>
                            }
                            endAdornment={endAdornment()}
                            />
                        </div>
                    </div>
                    <span className={arrowClass}></span>
                </div>
                { Component }
            </div>
        );
    }
}

function mapStateToProps(state : ApplicationState) : PropFromStore {
    return {
        QuickBookExpandedStatus: state.uiLogicControl.BookingForm.QuickBookExpandedStatus,
        TemplateLoadStatus: state.uiLogicControl.BookingForm.BookingTemplateLoadingStatus,
        TemplateList: state.bookingTemplates
    }
}

export default connect(mapStateToProps)(BookingTemplateDetails);
