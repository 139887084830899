import { BookingInfo } from "../MyBookings/MyBookingEntities";
import { ExtendedEta } from "./MyBookingEntities";

/** Application State that tracks the user's booking list. */
export interface MyBookingsState {

    /** Unfiltered list of the user's bookings, i.e. all statuses */
    All: BookingInfo[];

    /** ETA calculations for some bookings. This is a lookup by Booking ID. */
    Etas: Record<number, ExtendedEta>;

    /**  Recent list of trips */
    RecentTrips: BookingInfo[];

    /** Selected Booking details to share */
    SelectedBookingToShare?: BookingInfo;

    /** ID of a single booking, which is used by <TaxiTrackingDialog /> for a dialog map to track taxi (desktop only) */
    MapFocusedBookingID?: number;

    /** List of bookings opened via tracking links. */
    TrackingLinkBookings: BookingInfo[];

    /** A booking that has been selected for cancellation. 
     * This is used by the CancelBooking confirmation UI because we didn't feel like using dialog2. */
    CancelBookingCandidate?: BookingInfo;

    /** Timestamp when the last request made to the V2 API to get bulk booking status updates. 
     * Initially null. 
     * After the first request, this contains the value returned with the last request made.
     * The client doesn't do anything with this value except for sending it back to the server with the next request. */
    LastStatusUpdateRequestTimestamp: string | null;
}

/** Initial value of the MyBookings state */
export const MyBookingsInitialState: MyBookingsState = {
    All: [],
    Etas: {},
    RecentTrips: [],
    TrackingLinkBookings: [],
    LastStatusUpdateRequestTimestamp: null
};