import React from 'react';
import { GooglePlaceAutoComplete } from '../../google-maps/GooglePlaceAutoComplete';
import { GooglePlaceToLocation } from '../../google-maps/ParseGooglePlace';
import { KnownLocation, LocationSourceKind } from '../../../modules/Location/Entities';
import { DialogKind } from '../../../modules/Dialog/DialogEntities';
import './LocationChooser.scss';
import DialogClose from '../../../modules/Dialog/DialogClose';
import { Dispatch } from '../../../modules/Dispatch';

/** Location Chooser is a small UI panel where the user can choose their location / region of interest. It uses a Google Autocomplete control. The value is pushed into the Location section of the global state (in the redux store). 
 * It is designed to be embedded in a Dialog. */
export class LocationChooser extends React.Component {

    render() {
        return (
            <div className="location-chooser">
                <DialogClose />
                <h2>Which city content do you want to see?</h2>

                <div className="input-area">
                    <GooglePlaceAutoComplete RegionsOnly={true} OnPlaceSelected={this.onPlaceSelected} AutoFocus={true} />
                </div>

                <p>Please enter your location or the city you are interested in to help us display more relevant information to you</p>
            </div>
        );
    }

    onPlaceSelected = (place: google.maps.places.PlaceResult) => {

        // Careful: place may be a placeholder with only the name property if the user has entered text which Google failed to map to a place.
        if (!place.address_components) {

            // TODO: handle properly
            return;
        }

        const location = GooglePlaceToLocation(place);

        if (location == null) {

            // TODO: handle better
            return;
        }

        const userLocation: KnownLocation = {
            isKnown: true,
            source: LocationSourceKind.UserSelected,
            value: location,
        };

        // push to app state
        Dispatch.Location.userSelectsLocation(userLocation);
        Dispatch.Dialog.CloseDialog(DialogKind.LocationChooser);
    }
}