import React from 'react';
import "./AuthCallback.scss";
import SiteHeader from "../../widgets/Header/SiteHeader";
import { GoogleMapWrapped } from "../../widgets/google-maps/GoogleMapWrapped";
import Auth from "../Authentication/AuthImpl";
import { RouteComponentProps } from 'react-router-dom';
import { ParseAuth0Response, StoreNewAuthToken } from "./AuthHelper";
import { StartLoginSession } from "./LoginSession";
import { WellKnownMessageKind } from "../Utils/ErrorMessages";
import DefaultLoadingPageContent from "../../widgets/DefaultLoadingPageContent/DefaultLoadingPageContent";
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { Auth0SuccessOrError } from "./AuthEntities";
import { LogEvent } from '../../utils/LogEvent';

/**
 * There are 5 purposes of this components:
 * 1> Handles the callback URL generated by Auth0, i.e. new Auth.handleAuthentication();
 * 2> Load the token related info into state;
 * 3> Loading image shows while talking to Auth0, but you won't see this as the responding is super quick;
 * 4> Redirect to booking form;
 * 5> Call GetUserProfile;
 */
export default class AuthCallback extends React.Component<RouteComponentProps, {}> { 
    async componentDidMount() {
        const result: Auth0SuccessOrError = await new Auth().handleAuthentication();

        if (result.IsSuccess) {
            const authToken = ParseAuth0Response(result.SuccessfulResult);
            StoreNewAuthToken(authToken);
            this.props.history.replace('/booking');

            LogEvent.UserSignInSuccess(authToken);
            await StartLoginSession(false);
        }
        else {
            const error = result.ErrorResult!;

            this.props.history.replace('/booking');
            ShowDialogSimpleErrorMessage(WellKnownMessageKind.LoginFailure);
            LogEvent.UserSignInFailed(error);
        }
    }

    render() {
        return (
            <div>
                <SiteHeader/>
                <div className="auth-callback-panel">
                    <GoogleMapWrapped />
                    <DefaultLoadingPageContent blackTransparantLevel='medium'/>
                </div>
            </div>  
        );
    }
}