import appstore from "../../appStore";
import { Config } from "../../Config/Config";
import { Api } from "../../Services/Api";
import { BookingAuthority } from "../../Services/BookingEntities";
import { LoginStatusKind } from "../Authentication/AuthEntities";
import { Dispatch } from "../Dispatch";
import { GetBookingDetailsToShare } from "../MyBookings/GetBookingDetailsToShare";
import { RecentTripLoadKind } from "../UILogicControl/UILogicControlEntities";
import { SetErrorMessages } from "../Utils/CommonHelpers";
import { ErrorResult } from "../Utils/ErrorEntities";
import { BookingInfo } from "../MyBookings/MyBookingEntities";
import { ConvertBookingTrackingResultToBookingInfo, ConvertBookingTrackingResultToBookingInfoBulk } from "../MyBookings/ConvertAPIContractsToInternalModels";
import { ConsiderLoadDriverDetailsOfRefreshedBookings } from "../MyBookings/Logic/RefreshDriverDetails";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { GetTripHistoryV2 } from "../BookingV2/BookingLoadersV2";

/** Helper function to get the list of bookings associated with the logged in user.
 * Request parameters are:
 * StatusIDs: An array of selected satuses to be retrieved.
 * Limit: Number of bookings
 * Skip: No of bookings to be skipped. Useful for pagination
 */
export async function LoadMyBookings(StatusIDs: number[]) {
    const limit = Config.BookingListCountLimit;
    var request = {
        StatusIDs,
        Limit: limit,
        Skip: 0
    };

    var serviceResult = await Api.Booking.GetMyBookings(request);

    if (!serviceResult.isSuccess) {
        return;
    }

    const bookingInfoList = ConvertBookingTrackingResultToBookingInfoBulk(serviceResult.value)
    Dispatch.MyBookings.DataRefresh(bookingInfoList);

    // Consider to load driver details
    ConsiderLoadDriverDetailsOfRefreshedBookings(bookingInfoList);
}

/** Helper function to get the trip history for the logged in user. */
export async function GetTripHistory(StatusIDs: number[], limit = Config.NoOfBookingsInHistory): Promise<BookingInfo[] | undefined> {
    var request = {
        StatusIDs: StatusIDs,
        Limit: limit,
        Skip: 0
    };

    var serviceResult = await Api.Booking.GetMyBookings(request);

    if (!serviceResult.isSuccess) {
        return;
    }

    return ConvertBookingTrackingResultToBookingInfoBulk(serviceResult.value);
}

/** This helper function to Track the Booking */
export async function TrackBookingHelper(bookingId: number, bookingHashCode: string): Promise<BookingInfo | ErrorResult> {

    const bookingKey: BookingAuthority = {
        bookingId: bookingId,
        hashCode: bookingHashCode,
    };

    var serviceResult = await Api.Booking.TrackBookingByKey(bookingKey);

    if (serviceResult.isSuccess) {
        const newBooking = serviceResult.value;
        const newLocalBooking = ConvertBookingTrackingResultToBookingInfo(newBooking);
        Dispatch.MyBookings.Add(newLocalBooking);

        const updatedBooking = await GetBookingDetailsToShare(newLocalBooking);

        // Set the updated booking with ReadAccessCode as the selected booking to share in case the user clicked share from the Confirmation popup.
        Dispatch.MyBookings.SetBookingToShare(updatedBooking);
        return updatedBooking;
    }

    return SetErrorMessages(serviceResult);
}

/**
 * Load the user's recent trips from the API.
 * This is to populate the Quick Book recent trips list.
 * Only meaningful for logged in users.
 */
export async function LoadMyRecentTrips(): Promise<void> {

    const { LoginStatus } = appstore.getState().authentication;

    // Logged-in users can retrieve previous bookings
    if (LoginStatus === LoginStatusKind.LoggedOut) return;

    

    Dispatch.UILogicControl.OnRecentBookingLoadingStatusChange(RecentTripLoadKind.Loading);

    let recentTripHistory;

    if (!FeatureFlags.BookingApiV2) {
        recentTripHistory = await GetTripHistory(Config.StatusListForRecentTripList, Config.NoOfRecentBookingsInHistory);
    } else {
        recentTripHistory = await GetTripHistoryV2(Config.StatusListInHistoryFromV2API, Config.NoOfRecentBookingsInHistory);
    }
    

    // Push only valid data to store
    if (recentTripHistory && recentTripHistory.length > 0) {

        Dispatch.MyBookings.MyRecentTripsRefresh(recentTripHistory);

        // Update loading status, inorder to hide the spinner animation
        Dispatch.UILogicControl.OnRecentBookingLoadingStatusChange(RecentTripLoadKind.Loaded);
    }
    else {
        // Logged-in user has no previous trips
        Dispatch.UILogicControl.OnRecentBookingLoadingStatusChange(RecentTripLoadKind.Empty);
    }
}