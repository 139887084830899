
import services from './ServiceMetadata';

// Vehicle Type Name comes from the Fare Estimate API (Bcc.Api.Model.ViewModel.VehicleType enum).
const byVehicleTypeName = (vehicleTypeName: string) => {

    let name = vehicleTypeName.toLowerCase();

    if (name === "sedan") return services.sedan;
    if (name === "wagon") return services.wagon;
    if (name === "maxi") return services.maxi;
    if (name === "silverservice") return services.silver;
    if (name === "wheelchair") return services.wheelchair;
    if (name === "limo") return services.limo;

    return undefined;
};

// Condition ID will come back from the Booking Status API
const byConditionId = (rawConditionId : number | string | null | undefined) => {
    
    if (!rawConditionId) return services.any;
    const conditionId = Number(rawConditionId);
    if (conditionId === 0) return services.any;
    if (conditionId === 3) return services.silver;
    if (conditionId === 6) return services.parcel;
    if (conditionId === 11) return services.wagon;
    if (conditionId === 12) return services.sedan;
    if (conditionId >= 13 && conditionId <= 15) return services.wheelchair;
    if (conditionId >= 16 && conditionId <= 21) return services.maxi;
    if (conditionId === 26) return services.maxi;
    if (conditionId === 31) return services.limo;
    if (conditionId === 37) return services.taxi;
    if (conditionId >= 101 && conditionId <= 104) return services.maxi;
    return services.any;
};

// This comes from vehicle metadata
const byConditionDisplayName = (conditionDisplayName: string) => {

    if (/sedan/i.test(conditionDisplayName)) return services.sedan;
    else if (/maxi/i.test(conditionDisplayName)) return services.maxi;
    else if (/taxi/i.test(conditionDisplayName) && !/maxi/i.test(conditionDisplayName)) return services.taxi;
    else if (/wagon/i.test(conditionDisplayName)) return services.wagon;
    else if (/silver/i.test(conditionDisplayName)) return services.silver;
    else if (/limo/i.test(conditionDisplayName)) return services.limo;
    else if (/wheel/i.test(conditionDisplayName)) return services.wheelchair;
    else if (/next/i.test(conditionDisplayName)) return services.any;
    return undefined;
};

/**
 * This method accepts the input parameter from the url query string on the booking page e.g. service=parcel
 */
const GetServiceByUrlRef = (serviceName: string) => {

    let name = serviceName.toLowerCase();

    if (name === "silverservice") return services.silver;
    if (name === "sedan") return services.sedan;
    if (name === "wheelchair") return services.wheelchair;
    if (name === "maxitaxi") return services.maxi;
    if (name === "parcel") return services.parcel;

    // By default "Next Available" will be selected, if no match found
    return services.any;

}

export { byVehicleTypeName, byConditionId, byConditionDisplayName, GetServiceByUrlRef };