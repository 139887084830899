import React from "react";
import './PriceGuaranteeModal.scss';
import {Accordion, AccordionSummary, AccordionDetails, Typography, Button} from "@material-ui/core"
import ExpandMoreOutlined from "@material-ui/icons/ExpandMoreOutlined";
import { useState } from "react";
import { Dispatch } from "../../Dispatch";
import { DialogKind } from "../../Dialog/DialogEntities";
import { MyStorage } from "../../../Storage";
import { GetMyBrand, GetValues } from "../../../Config/MyAppConfig";
import { Brand } from "../../../Config/Entities/Brand";

/* This Component UI is for explaining the Price Guarantee feature  */

function PriceGuaranteeDialogTip() {
    const [expandedPanelId, setExpandedPanelId] = useState<string | null>(null);

    const handleChange = (panelId: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpandedPanelId (isExpanded ? panelId : null);
    };

    const closeDialog = () => {        
        MyStorage.HasSeenPriceGuaranteeModalFAQ.StoreData(true);
        Dispatch.Dialog.CloseDialog(DialogKind.PriceGuaranteeFAQ);
    }

    const brandName = GetValues().BrandName;

    const data = [
        {
            'id': 'panel1',
            'heading': 'What is Price Guarantee?',
            'details': `The ${brandName} Price Guarantee is a fixed price set at the time of booking within the ${brandName} app or website to get from point A to B. This is the final price that the customer will pay. No additional charges apply.`
        },
        {
            'id': 'panel2',
            'heading': 'How is Price Guarantee calculated?',
            'details': `When passengers set their pick up and drop off destination in the ${brandName} app or website, our pricing tools plots the fastest route calculated by Google Maps from point A to B. The price is then calculated using a number of factors, including predicted trip time and distance, traffic conditions, state government fees, levies, and tolls. We don’t surge and we don’t change our pricing based on demand.`
        },
        {
            'id': 'panel3',
            'heading': 'What are the benefits of Price Guarantee?',
            'details': 'With Price Guarantee, Passengers can sit back and relax without having to check the meter, along with price certainty when comparing prices when making travel decisions.'
        }
    ]

    const headerTitle = 'Price Guarantee';
    const headerDescription = `The ${brandName} Price Guarantee is a fixed price set at the time of booking to get from point A to B. This is the final price that the customer will pay. No additional charges apply.`

    return (
            <div className="PriceGuarantee-details">
                <Typography variant='h5' color='primary' align='center' gutterBottom> {headerTitle} </Typography>
                <div className="PriceGuarantee-description"><Typography variant='body1'> {headerDescription} </Typography></div>
                {data.map(accordion => {
                    const {id, heading, details } = accordion;
                    return (
                    <Accordion expanded={expandedPanelId === id} onChange={handleChange(id)}>
                        <AccordionSummary expandIcon={<ExpandMoreOutlined/>}>
                            <Typography variant="body2"> {heading} </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography> {details} </Typography>
                        </AccordionDetails>
                    </Accordion>
                    )
                })}
                <Button onClick={closeDialog} size="large" variant='contained' color='primary'>OK</Button>
            </div>
    );
}

export default PriceGuaranteeDialogTip;