import React, { Component } from 'react';
import './Credentials.scss';
import { Config } from "../../../Config/Config";
import { DecideCredentialErrorMessagePerItemStyle } from "../AuthHelper";
import { PasswordErrorType } from "./LoginEntities";
import { Dispatch } from '../../Dispatch';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ContentURL, getContentUrl } from '../../Utils/ContentURL';

interface ResponsivePasswordInputProps {
    /**
     * This prop is used to decide the password validation complexity.
     * If true  -> Both light and strong validations are required;
     * If false -> Only light validation rule is needed.
     */
    EnforcePasswordComplexity: boolean;
    EnterPressedEvent?: () => void;

    /**
     * Callback function to send password back to the component which refers this component, such as ResetPassword component, rather than Redux store.
     */
    CustomChangeHandler?: (password: string) => void;

    Placeholder?: string;
}


interface ResponsivePasswordInputState {
    isPwdShown: boolean;

    /**
     * This is only used for login pop for inline style, and ask the customer to enter a password.
     */
    isInlineErrorShown: boolean;

    /**
     * Below states are used to support password validation UX.
     * These 4 states together determines whether the password error panel shows.
     */
    isPwdLengthInvalid: boolean;
    isPwdLowerCaseInvalid: boolean;
    isPwdUpperCaseInvalid: boolean;
    isPwdNumericInvalid: boolean;
    /**
     * This state is used to decided whether the password error panel is shown or not.
     * This is used in the scenarios that password panel needs show based on above 4 states, but the customer would like to close it temporarily.
     */
     isPwdPanelshown: boolean;
    /**
     * This state is used to decided whether label info turn error/red if password is invalid.
     */
     isPasswordInvalid: boolean;
    /**
     * This state is used for success form validation. 
     * This state is used to display message: 'Password length strong' when all the password condition is satisfied 
     */
     showPasswordValidMessage: boolean
}

/** 
 * This enum is a typing to define the scope of password validation, which is only used when this.props.EnforcePasswordComplexity is on for component CredentialsPassword.
 */
const enum PasswordValidationScope {
    FullScan = 'This will check password on length, lower case, upper case and numeric',
    AlphanumericOnly = 'This will check password on only lower case, upper case and numeric'
}

/**
 * Renders the password input in the new login/signup form.
 */
class ResponsivePasswordInput extends Component<ResponsivePasswordInputProps, ResponsivePasswordInputState> {
    private inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: ResponsivePasswordInputProps) {
        super(props);
        this.inputRef = React.createRef();

        this.state = {
            isPwdShown: false,
            isInlineErrorShown: false,
            isPwdLengthInvalid: false,
            isPwdLowerCaseInvalid: false,
            isPwdUpperCaseInvalid: false,
            isPwdNumericInvalid: false,
            isPwdPanelshown: false,
            isPasswordInvalid: false,
            showPasswordValidMessage: false
        }

        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.togglePasswordShown = this.togglePasswordShown.bind(this);

        this.togglePasswordErrorPanelShown = this.togglePasswordErrorPanelShown.bind(this);
        this.IsAllPasswordPrerequisitesSatisfiedWhenComplexityOn = this.IsAllPasswordPrerequisitesSatisfiedWhenComplexityOn.bind(this);
        this.inputOnBlur = this.inputOnBlur.bind(this);
        this.enterBtnPressed = this.enterBtnPressed.bind(this);
        this.createHideIcon = this.createHideIcon.bind(this);
    }

    onPasswordChange() {
        const password = this.inputRef.current!.value;

        if(this.props.CustomChangeHandler) {
            this.props.CustomChangeHandler(this.validatePassword(password) ? password : "");
            return;
        }

        if (this.validatePassword(password)) {
            this.setState({showPasswordValidMessage: true})
            Dispatch.Auth.CredentialPassword(password);
        }
        else {
            Dispatch.Auth.ClearCredentialPassword();
            this.setState({showPasswordValidMessage: false, isPasswordInvalid: true})
        }
    }

    togglePasswordShown() {
        this.setState({ isPwdShown: !this.state.isPwdShown });
    }

     /**
     * This function is used to set state.isPwdPanelshown
     * If input focus, show;
     * If not focus, not show.
     * This is exactly same behaviour from Auth0 Lock
     */
      togglePasswordErrorPanelShown(shown: boolean) {
        this.setState({ isPwdPanelshown: shown});
    }

    /**
     * When onBlur of password input, it will do 2 things:
     * 1> Hide error message panel, if there is;
     * 2> Validate password.
     */
    inputOnBlur() {
        this.togglePasswordErrorPanelShown(false);
        this.onPasswordChange();
    }

    /**
     * Validate password:
     */
    validatePassword(pwd: string): boolean {
        let result: boolean;

        if (this.props.EnforcePasswordComplexity) {
            result = this.firstLevelValidation(pwd) && this.secondLevelValidation(pwd);
        }
        else {
            result = this.firstLevelValidation(pwd);
        }

        return result;
    }

    /**
     * This is also light validation on password, which only check it's not empty.
     * This is catering for both log-in and sign-up.
     * 
     * Validation rules:
     * (1) Max length 100 (this constriction is on JSX input level);
     * (2) At least 8 characters in length;
     * (3) Lower case letters;
     * (4) Upper case letters;
     * (5) Numbers.
     */
    secondLevelValidation(pwd: string): boolean {
        const length = pwd.length;
        const testerHasLower = /[a-z]/;
        const testerHasUpper = /[A-Z]/;
        const testerHasNumber = /\d/;
        let result: boolean = true;

        if (length < 8) {
            this.setState({ isPwdLengthInvalid: true });
            result = false;
        }
        else {
            this.setState({ isPwdLengthInvalid: false });
        }

        if (!testerHasLower.test(pwd)) {
            this.setState({ isPwdLowerCaseInvalid: true });
            result = false;
        }
        else {
            this.setState({ isPwdLowerCaseInvalid: false });
        }

        if (!testerHasUpper.test(pwd)) {
            this.setState({ isPwdUpperCaseInvalid: true });
            result = false;
        }
        else {
            this.setState({ isPwdUpperCaseInvalid: false });
        }

        if (!testerHasNumber.test(pwd)) {
            this.setState({ isPwdNumericInvalid: true });
            result = false;
        }
        else {
            this.setState({ isPwdNumericInvalid: false });  
        }

        return result;
    }

    /**
     * This is also light validation on password, which only check it's not empty.
     * This is catering for both log-in and sign-up.
     */
    firstLevelValidation(pwd: string): boolean {
        if (pwd.length == 0) {
            this.setState({ isInlineErrorShown: true });

            if (this.props.EnforcePasswordComplexity) { 
                this.setState({ isPwdLengthInvalid: true, isPwdUpperCaseInvalid: true, isPwdLowerCaseInvalid: true, isPwdNumericInvalid: true});
            }

            return false;
        }

        this.setState({ isInlineErrorShown: false });
        return true;
    }

    /**
     * This function returns true when all below password, when this.props.EnforcePasswordComplexity is on, all prerequisites satisfied.
     * Return false, if any not satisfied.
     * 
     * There is a pamameter called scope: PasswordValidationScope, please check discription of scopes in AuthEntities.PasswordValidationScope.
     */
    IsAllPasswordPrerequisitesSatisfiedWhenComplexityOn(scope: PasswordValidationScope): boolean {
        if (scope === PasswordValidationScope.FullScan) {
            return !this.state.isPwdLengthInvalid && !this.state.isPwdLowerCaseInvalid && !this.state.isPwdUpperCaseInvalid && !this.state.isPwdNumericInvalid;
        }
        else if (scope === PasswordValidationScope.AlphanumericOnly) {
            return !this.state.isPwdLowerCaseInvalid && !this.state.isPwdUpperCaseInvalid && !this.state.isPwdNumericInvalid;
        }

        return false; // This statement is only catering for TS requirement
    }

    /**
     * When enter button clicked within the input of password
     */
    enterBtnPressed(e: React.KeyboardEvent<HTMLDivElement>) {
        if (!!this.inputRef.current!.value && e.keyCode == 13) {
            this.props.EnterPressedEvent && this.props.EnterPressedEvent();
        } 
    }

    /**
     * Create materialUI hide/eye icon 
     */
    createHideIcon() {
        return (<InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.togglePasswordShown}
                    >{this.state.isPwdShown ? <Visibility /> : <VisibilityOff />}</IconButton>
               </InputAdornment>);
    }

    render() {
        /**
         * Password error messsage
         */
        const errorMsgLength = DecideCredentialErrorMessagePerItemStyle(this.state.isPwdLengthInvalid, "small", PasswordErrorType.Incorrect);
        const errorMsgAlphanumeric = DecideCredentialErrorMessagePerItemStyle(!this.IsAllPasswordPrerequisitesSatisfiedWhenComplexityOn(PasswordValidationScope.AlphanumericOnly) , "small", PasswordErrorType.Incorrect);
        const errorMsgUpper = DecideCredentialErrorMessagePerItemStyle(this.state.isPwdUpperCaseInvalid, "large", PasswordErrorType.Missing);
        const errorMsgLower = DecideCredentialErrorMessagePerItemStyle(this.state.isPwdLowerCaseInvalid, "large", PasswordErrorType.Missing);
        const errorMsgNumber = DecideCredentialErrorMessagePerItemStyle(this.state.isPwdNumericInvalid, "large", PasswordErrorType.Missing);
        const placeholder = this.props.Placeholder ? this.props.Placeholder : "Password";
        const isError = this.state.isPwdLengthInvalid || this.state.isPwdLowerCaseInvalid || this.state.isPwdNumericInvalid || this.state.isPwdUpperCaseInvalid;
        const infoMessage = "Use at least 8 characters including numbers, and both uppercase and lowercase letters ";
        const infoMessageStyle = this.state.isPasswordInvalid ? "label-password-info-invalid" : "label-password-info";
        const infoMesssageSuccess = <p> Password length <span className="Highlights">strong</span></p> 
        const infoMesssageSuccessStyle = this.state.showPasswordValidMessage? "label-password-info" : "";
        const imageInfoIcon = this.state.isPasswordInvalid ? getContentUrl(ContentURL.images.RedXCircle) : getContentUrl(ContentURL.images.info.GreyInfoIcon);
        return(
            <>
                <TextField
                    fullWidth={true}
                    variant="outlined"
                    inputRef={this.inputRef}
                    className="simple-textfield"
                    label={placeholder}
                    type={this.state.isPwdShown ? 'text' : 'password'}
                    error={isError}
                    onBlur={this.inputOnBlur} 
                    onChange={this.onPasswordChange}
                    onFocus={() => this.togglePasswordErrorPanelShown(true)} 
                    onKeyUp={(e) => this.enterBtnPressed(e)} 
                    inputProps={{ maxLength: Config.Credentials.EmailMaxLength }}
                    InputProps={{ endAdornment: this.createHideIcon() }}
                /> 
                
                {!this.props.EnforcePasswordComplexity && this.state.isInlineErrorShown && <div className="auth-input-error-message">Please enter a password</div>}
                {this.props.EnforcePasswordComplexity && !this.IsAllPasswordPrerequisitesSatisfiedWhenComplexityOn(PasswordValidationScope.FullScan) && this.state.isPwdPanelshown &&

                <div className="credential-password-message-container">
                    <div className="credential-password-message-arrow"></div>
                    <div className={errorMsgLength.CssClass}><img src={errorMsgLength.Icon} alt="error message icon"/><span>At least 8 characters in length</span></div>
                    <div className={errorMsgAlphanumeric.CssClass}><img src={errorMsgAlphanumeric.Icon} alt="error message icon"/><span>Should contain:</span></div>
                    <div className={errorMsgLower.CssClass}><img src={errorMsgLower.Icon} alt="error message icon"/><span>Lower case letters (a-z)</span></div>
                    <div className={errorMsgUpper.CssClass}><img src={errorMsgUpper.Icon} alt="error message icon"/><span>Upper case letters (A-Z)</span></div>
                    <div className={errorMsgNumber.CssClass}><img src={errorMsgNumber.Icon} alt="error message icon"/><span>Numbers (.i.e 0-9)</span></div>
                </div>}
                
                {
                    this.props.EnforcePasswordComplexity && this.state.showPasswordValidMessage ? <label className={infoMesssageSuccessStyle}><img src={getContentUrl(ContentURL.images.GreenCheckMark)}></img>{infoMesssageSuccess}</label> : ""
                }
                {
                    this.props.EnforcePasswordComplexity && !this.state.showPasswordValidMessage? <label className={infoMessageStyle}><img src={imageInfoIcon}></img>{infoMessage}</label> : ""
                }
            </>
        );
    }
}

export default ResponsivePasswordInput;