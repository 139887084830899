import appstore from "../../appStore";
import { CSSProperties } from "react";
import { Dispatch } from "../Dispatch";
import { FeatureId } from "./FeaturesEntities";
import { LogEvent } from "../../utils/LogEvent";
import services from "../../utils/service-vehicles/ServiceMetadata";
import { BookingTabKind } from "../UILogicControl/UILogicControlEntities";
import CredentialsController from "../Authentication/Login/CredentialsController";
import { PopulateConditionListWithDefaultVehicles } from "../Condition/PopulateConditions";
import { MyStorage } from "../../Storage";
import { SelectVehicleByService } from "../Condition/SelectVehicleByService";

/**
 * Css properties for title
 */
export function TitleStyle(textColor: string): CSSProperties {
    return {
        fontFamily: 'Visby-CF-Bold',
        fontSize: '20px',
        color: textColor,
        margin: '10px 0px'
    }
}

/**
 * Save acknowledged feature
 */
export function MarkAsRead(featureId: FeatureId) {
            
    // Save the feature list to the store
    Dispatch.Features.AcknowledgeFeature(featureId);

    const { AcknowledgedFeatureList } = appstore.getState().features;

    // Save the feature list to the localStorage
    MyStorage.FeatureList.StoreData(AcknowledgedFeatureList);    

    LogEvent.FeatureAcknowledged(featureId);     
}

/**
 * Switch to QuickBook tab
 */
export function SwitchToQuickBoookTab() {

    Dispatch.UILogicControl.OnBookingTabSelectionChange(BookingTabKind.QuickBook);

    LogEvent.OnFavouriteLinkClicked();

    // Close the feature list panel
    Dispatch.Features.HideWhatsNewFeatures();
}

/**
 * Opens the signup modal for register
 */
export function SignupLinkHandler() {

    Dispatch.Payment.HideAddPaymentCardScreenAfterSignup();
        
    new CredentialsController().DoSignup();

    LogEvent.SignupTriggeredByFavouritesCard();

    // Close the feature list panel
    Dispatch.Features.HideWhatsNewFeatures();
}

/**
 * Selects Parcel delivery as service.
 */
export function ParcelLinkHandler() {

    const conditionList = appstore.getState().condition.ConditionList;

    if (conditionList.length === 0) {
        
        // Populate the default condition list, to extract parcel condtion      
        PopulateConditionListWithDefaultVehicles();       
    }

    LogEvent.OnParcelDeliveryLinkClicked();

    SelectVehicleByService(services.parcel);

    Dispatch.UILogicControl.OnBookingTabSelectionChange(BookingTabKind.NewBooking);

    // Close the feature list panel
    Dispatch.Features.HideWhatsNewFeatures();
}