import { Dispatch } from "../Dispatch";
import { DialogKind } from "./DialogEntities";
import { DialogConfig } from './DialogConfig';

/** 
 * Mobile specific configuration per dialog
 */
export const MobileDialogConfigStore: Partial<Record<DialogKind, Partial<DialogConfig>>> = {

    /** Verification */
    [DialogKind.Verification]: {
        RequireBackButton: true,
        CustomBackButtonBehaviour(): void {
            Dispatch.Dialog.CloseTopDialog();
        }
    },

    /** Contact details */
    [DialogKind.ContactDetails]: {
        RequireBackButton: true,
        CustomBackButtonBehaviour(): void {
            Dispatch.Dialog.CloseTopDialog();
        }
    },

    /** Share booking */
    [DialogKind.ShareBooking]: {
        RequireBackButton: true
    },

    /** Tnc consent which is used in logged-in user validation process */
    [DialogKind.TncConsent]: {
        RequireBackButton: true
    },

    /** Payment Wallet */
    [DialogKind.PaymentWallet]: {        
        RequireBackButton: true,
        CustomBackButtonBehaviour: (history) =>  {
            Dispatch.UILogicControl.CloseMenu();
            history.push('/');
        }
    },

    /** User personal details */
    [DialogKind.UserPersonalDetails]: {
        RequireBackButton: true,
        CustomBackButtonBehaviour: (history) => {
            Dispatch.UILogicControl.CloseMenu();
            history.push('/');
        }
    },

     /** Contact us */
     [DialogKind.ContactUs]: {
        RequireBackButton: true,
        CustomBackButtonBehaviour: (history) =>  {
            Dispatch.UILogicControl.CloseMenu();
            history.push('/');
        }
    },
     /** Legal documents consent V2. */
    [DialogKind.LegalDocumentsConsent]: {
        RequireBackButton: true
    },
}

/** 
 * Mobile specific configuration per dialog
 */
export const DesktopDialogConfigStore: Partial<Record<DialogKind, Partial<DialogConfig> | undefined>> = { 

    /** Tnc consent which is used in logged-in user validation process */
    [DialogKind.TncConsent]: {
        HideCommonDialogHeader: true
    },
    [DialogKind.LegalDocumentsConsent]: {
        HideCommonDialogHeader: true
    }
}

/** 
 * Configuration for descriptive error message dialog
 */
export const DescriptiveDialogConfig: Partial<Record<DialogKind, Partial<DialogConfig> | undefined>> = { 

    /** Descriptive error message for "Try again" button */
    [DialogKind.DescriptiveErrorMessage]: {
        RequireCloseButton: true
    }
}
