import React from 'react';
import { connect } from "react-redux";
import 'react-intl-tel-input/dist/main.css';
import PhoneInput from "react-intl-tel-input";
import './MyBookings.scss';
import { ContentURL, getContentUrl } from '../../Utils/ContentURL';
import { DropLeadingZero } from '../../../utils/Formattingutil';
import { ApplicationState } from '../../../appState';
import { ShareBookingRequest } from '../../../Services/BookingEntities';
import { Api } from '../../../Services/Api';
import { Config } from '../../../Config/Config';
import { Dispatch } from '../../Dispatch';
import { DialogKind } from '../../Dialog/DialogEntities';
import { UILayoutMode } from '../../UILogicControl/UILogicControlEntities';
import { GenerateBookingTrackingUrl } from '../../Booking/BookingHelper';
import { IsValidMobileNumber } from '../../Utils/PhoneValidation';
import { BookingInfo } from '../MyBookingEntities';

interface PropsFromStore {
    BookingToShare: BookingInfo;
    IsMobileDevice: boolean;
}

interface ShareBookingState {
    countryCode: string;
    errorMessage: string;
    isValidContactNumber: boolean | null;
    isButtonActive: boolean;
    contactNumber: string;
    isCopied: boolean;
    isSending: boolean;
    isSent: boolean;
    trackingUrl: string;
}

class ShareBooking extends React.Component<PropsFromStore, ShareBookingState> {
    private phoneInputRef: React.RefObject<HTMLInputElement>;

    constructor(props: PropsFromStore) {
        super(props);
        this.phoneInputRef = React.createRef();

        this.state = {
            countryCode: "+61",
            errorMessage: "",
            isValidContactNumber: null,
            isButtonActive: false,
            contactNumber: "",
            isCopied: false,
            isSending: false,
            isSent: false,
            trackingUrl: ""
        }

        this.sendSMS = this.sendSMS.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
        this.copyLinkToClipboard = this.copyLinkToClipboard.bind(this);
    }

    componentDidMount() {
        // BookingToShare.ReadAccessCode and BookingTrackingBaseUrl guaranteed to be defined on this component (the dialog opens only if it is available)
        this.setState({ trackingUrl: GenerateBookingTrackingUrl(this.props.BookingToShare.BookingID.toString(), this.props.BookingToShare.ReadAccessCode!, this.props.BookingToShare.BookingTrackingBaseUrl!) });
    }

    /** 
     * Update the state on change of the country code from the dropdown.
     */
    updateCode = (status: any, value: any) => {
        this.validatePhone();
        this.setState({ countryCode: value.dialCode });
    };

    /** Validate the phone number on change */
    validatePhone() {
        // Make the share button active only if the mobile number length is 8 or higher.
        this.setState({ isButtonActive: this.phoneInputRef.current!.value.length >= 8, isSent: false });

        // Restricting the phone number field only to numbers. State will not be updated if the user entered other characters.
        const numberOnlyRegex = /^[0-9\b]+$/;
        if (this.phoneInputRef.current!.value === '' || numberOnlyRegex.test(this.phoneInputRef.current!.value)) {
            this.setState({ contactNumber: this.phoneInputRef.current!.value })
        }

        // We need to clean error message here if the user start typing
        this.setState({ errorMessage: "", isValidContactNumber: null });
    }

    /** Validate the given phone number and send the SMS. */
    async sendSMS() {
        this.setState({ isValidContactNumber: null, errorMessage: "" });

        const contactCountryCode = this.state.countryCode;
        const contactNumberPart = this.state.contactNumber;
        const contactNumber = contactCountryCode + DropLeadingZero(contactNumberPart);

        if (!this.state.isButtonActive) {
            this.setState({ isValidContactNumber: false, errorMessage: "Please enter a valid mobile number", isButtonActive: false });
            return;
        }

        /** If the SMS already sent and the user clicked on send button again, don't send the SMS and inform the user. */
        if (this.state.isSent) {
            this.setState({ errorMessage: "Already sent the SMS" });
            return;
        }

        /**
         * Send the SMS only if the phone number is a valid mobile number.
         */
        if (IsValidMobileNumber(contactNumber)) {
            this.setState({ isSending: true });

            const booking = this.props.BookingToShare;

            const shareBookingPayload: ShareBookingRequest = {
                BookingID: booking.BookingID,
                DispatchBookingID: booking.DispatchBookingID,
                HashCode: booking.ReadAccessCode!,
                PhoneNumber: contactNumber,
                BookingDateTime: booking.LocalTime ?? booking.Time,
                ApiVersion: booking.ApiVersion
            }

            const result = await Api.Booking.ShareBooking(shareBookingPayload);

            if (result.isSuccess) {
                appInsights.trackEvent("Shared booking successfully!");
                // Hide the spinner and change the button text and the image
                this.setState({ isSending: false, isSent: true, contactNumber: "" });
            } else {
                // Need to know reason for the failure
                appInsights.trackEvent("Share booking failed!", { message: result.outcome });
                // Hide the spinner and change the button text and the image
                this.setState({ isSending: false, isSent: false, errorMessage: "Sending SMS failed. Please try again." });
            }            
        }
        else {
            this.setState({ isValidContactNumber: false, errorMessage: "Please check the number entered and retry", isButtonActive: false });
        }
        
    }

    /** Copy share link to clipboard. */
    copyLinkToClipboard() {
        const bookingLink = this.state.trackingUrl;
        var dummyElement = document.createElement("input");
        document.body.appendChild(dummyElement);
        dummyElement.setAttribute('value', bookingLink);
        dummyElement.select();
        document.execCommand("copy");
        document.body.removeChild(dummyElement);
        this.setState({ isCopied: true });
    }

    /** On click of Done button at the bottom, close the dialog. */
    doneSharing() {
        Dispatch.Dialog.CloseDialog(DialogKind.ShareBooking);
    }

    render() {

        let phoneNumberGroupClass = "send-via-sms-section";
        let shareBtnClass = "share-btn";
        let imageURL = "";
        let btnText = "Share";
        let btnImgClass = "";
        // Display the read-only url to be copied in the popup.
        const shareLink = this.state.trackingUrl;
        let shareLinkClass = "share-link";
        let copyButtonText = "Copy link";

        if (this.state.isValidContactNumber === true) {
            phoneNumberGroupClass = "send-via-sms-section valid-mobile";
        } else if (this.state.isValidContactNumber === false) {
            phoneNumberGroupClass = "send-via-sms-section invalid-mobile"
        }

        if (this.state.isButtonActive) {
            shareBtnClass = "share-btn share-active-btn";
            phoneNumberGroupClass = "send-via-sms-section valid-mobile";
        } else {
            shareBtnClass = "share-btn";
        }        

        if (this.state.isSending) {
            imageURL = getContentUrl(ContentURL.images.Loading);
            btnText = "Sharing";
            btnImgClass = "share-spinner";
        }

        if (this.state.isSent) {
            imageURL = getContentUrl(ContentURL.images.BlackCheck);
            btnText = "Shared";
            btnImgClass = "share-tick";
        }
        if (this.state.isCopied) {
            shareLinkClass = "share-link-copied";
            copyButtonText = "Copied";
        }

        return (
            <div className="share-booking-panel">
                <div className={phoneNumberGroupClass}>
                    <div className="country-code">
                        <PhoneInput
                            defaultCountry={'au'}
                            css={['intl-tel-input', 'form-control']}
                            separateDialCode="true"
                            fieldId={"phoneInput"}
                            localization="false"
                            value={this.state.countryCode}
                            onSelectFlag={this.updateCode}
                            preferredCountries={""}
                        />
                    </div>
                    <div className="share-mobile-number">
                        <input
                            ref={this.phoneInputRef}
                            type="tel"
                            placeholder="Enter mobile number"
                            maxLength={Config.ContactNumberMaxLength}
                            onChange={this.validatePhone}
                            value={this.state.contactNumber}
                            autoFocus />
                    </div>
                    <button className={shareBtnClass} onClick={this.sendSMS}>
                        {btnText}
                        {imageURL ? <img alt="loading" src={imageURL} className={btnImgClass} /> : null}
                    </button>
                </div>

                <p className="share-error">{this.state.errorMessage}</p>

                <p className="send-via-link-title">Share via private link:</p>
                <div className="share-via-link-section">
                    <p className={shareLinkClass}>{shareLink}</p>
                    <button className="copy-link-btn" onClick={this.copyLinkToClipboard}>{copyButtonText}</button>
                    {this.state.isCopied ? <img src={getContentUrl(ContentURL.images.GreenCheckMark)} className="link-copied"></img> : null}
                </div>
                <hr className="section-divider"></hr>
                <button className="done-sharing" onClick={this.doneSharing}>Done</button>
            </div>
        );
    }

}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        BookingToShare: state.myBookings.SelectedBookingToShare!,
        IsMobileDevice: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile
    };
}

export default connect(mapStateToProps)(ShareBooking);