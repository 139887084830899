import { Checkbox } from "@material-ui/core";
import React from "react";
import { LegalDocuments } from "../../Services/LegalDocumentsContracts";
import "./LegalDocuments.scss";

export interface LegalConsentProps {
    IsAccepted: boolean;
    ToggleConsent: () => void;
    LegalDocsUrls: LegalDocuments;

    /** The footer of the booking widget has the exact same component without the checkbox. */
    ShouldIncludeCheckBox: boolean;
}

/**
 * Legal documents consent statement including checkbox and text.
 */
export const LegalConsentStatement: React.FC<LegalConsentProps> = (props) => {

    // Don't show the component until the API call is success and documents are loaded (mostly impacted to the booking widget in slow networks).
    if (!props.LegalDocsUrls.LegalDocumentsVersion) return null;

    /** Generate the display text with the link for Collection Statement including the text goes after the word "Collection Statement" based on the status of other legal docs types. */
    function GenerateCollectionStatementText() {
        if (!props.LegalDocsUrls.CollectionStatementUrl) return null;

        // Display ', ' after Collection Statement text if both Terms and Conditions and Privacy Policy is not null. If only one of them is null, display ' and '. If both null, display nothing.
        const shouldIncludeComma = props.LegalDocsUrls.TermsAndConditionsUrl && props.LegalDocsUrls.PrivacyPolicyUrl;
        const shouldIncludeAndText = (!props.LegalDocsUrls.TermsAndConditionsUrl && props.LegalDocsUrls.PrivacyPolicyUrl) || (props.LegalDocsUrls.TermsAndConditionsUrl && !props.LegalDocsUrls.PrivacyPolicyUrl);

        return (
            <span>
                <a href={props.LegalDocsUrls.CollectionStatementUrl} target="_blank"> Collection Statement</a>
                {shouldIncludeComma && <span>, </span>}
                {shouldIncludeAndText && <span> and</span>}
            </span>
            );
    }

    /** Generate the display text with the link for Privacy Policy including the text goes after the word "Privacy Policy" based on the status of other legal docs types. */
    function GeneratePrivacyPolicyText() {
        if (!props.LegalDocsUrls.PrivacyPolicyUrl) return null;

        return (
            <span>
                <a href={props.LegalDocsUrls.PrivacyPolicyUrl} target="_blank"> Privacy Policy</a>
                {!props.LegalDocsUrls.TermsAndConditionsUrl ? "" : <span> and</span>}
            </span>
        );
    }

    function GenerateTermsAndConditionsText() {
        if (!props.LegalDocsUrls.TermsAndConditionsUrl) return null;

        return (
            <span>
                <a href={props.LegalDocsUrls.TermsAndConditionsUrl} target="_blank"> Terms and Conditions</a>
            </span>
        );
    }

    return (
        <div className="legal-docs-agreement">
            {props.ShouldIncludeCheckBox && <Checkbox checked={props.IsAccepted} onChange={props.ToggleConsent} inputProps={{ 'aria-label': 'primary checkbox' }} />}
            <p className="">I have read and agreed to the 
                {GenerateCollectionStatementText()}
                {GeneratePrivacyPolicyText()}
                {GenerateTermsAndConditionsText()}
            </p>
        </div>
    );
}