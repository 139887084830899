import React from 'react';
import { connect } from "react-redux";
import { DialogKind, DetailedErrorMessage } from '../Dialog/DialogEntities';
import './ContactVerification.scss';
import { ContentURL, getContentUrl } from '../Utils/ContentURL';
import { CustomErrorMessages, DescriptiveErrorMessages } from "../Utils/ErrorMessages";
import { ApplicationState } from '../../appState';
import { VehicleOption } from '../Condition/Redux/ConditionEntities';
import { DropLeadingZero } from '../../utils/Formattingutil';
import { isErrorResult } from '../Utils/Typeguards';
import { AuthenticationState } from '../Authentication/AuthEntities';
import { CheckAddPlus } from '../../utils/Formattingutil';
import { Config } from "../../Config/Config";
import { IsGuest } from "../Verification/IsGuest";
import { RefreshProfileAfterDataChange } from "../Authentication/GetUserProfileService";
import { VerificationTrigger } from './VerificationEntities';
import { Api } from '../../Services/Api';
import { Dispatch } from '../Dispatch';
import AuthImplV2 from "../Authentication/Login/AuthImplV2";
import { CreateCredentialForSignup } from "../Authentication/Login/CredentialHelper";
import CredentialsController from "../Authentication/Login/CredentialsController";
import { IsUnderProfileValidationMode, CloseOffContactNumberUpdateTaskInProfileValidation } from "../LoginValidation/LoginValidationHelper";
import { VerifyLoginFromLocalStorageAndLogout } from "../Authentication/AuthHelper";
import { VerificationState } from './VerificationState';
import { FeatureFlags } from '../../Config/FeatureFlags';
import SilentLogin from '../Authentication/Login/SilentLogin';
import { AuthType } from '../Authentication/Login/LoginEntities';
import { UILayoutMode } from '../UILogicControl/UILogicControlEntities';
import { CleanUserContactNumberInfo } from '../Utils/ContactNumberHelper';
import { LoadMyRecentTrips } from '../Booking/BookingLoaders';
import { CreateBookingCommon } from '../Booking/CreateBookingCommon';
import { TrackBookingHelper } from '../Booking/BookingLoaders';
import { BookingWorkFlowState } from '../Booking/Redux/BookingState';
import { LogEvent } from '../../utils/LogEvent';
import { StartSmsOutcome, StartSmsVerification } from './StartSmsVerification';
import { ChangePhoneNumber, ChangePhoneOutcome } from './ChangePhoneNumber';
import appstore from '../../appStore';
import { BookingAuthority } from '../../Services/BookingEntities';

interface PropsFromStore {
    bookingpayloadFromStore: BookingWorkFlowState;
    selectedCondition: VehicleOption;
    errorMessage: DetailedErrorMessage;
    authentication: AuthenticationState;
    verification: VerificationState;
    IsSilentLoginActive: boolean;
    IsMobileDevice: boolean;
}

interface VerificationComponentState {
    code1: string;
    code2: string;
    code3: string;
    code4: string;

    /** An empty string is used when there is no error message. */
    verificationErrorMessage: string;

    /** Wether the display a link to resend the SMS. */
    resendCode: boolean;

    /** Whether to display a text indicating that a new (re-sent) code has been sent. */
    sentCode: boolean;

    /** Whether to display a loading (spinner) icon on the UI. */
    showLoader: boolean;

    /** 
     *  Whether the SMS verification succeeded, failed, or is outstanding (null).
     *  Determines the colour of the border on the verification code text boxes.
     */
    isVerified: boolean | null;

    /**
     * This flag is set when you start typing the first digit of the code.
     * It changes the border colour of the verification code text boxes. 
     */
    isStartInput: boolean;
}

/**
 * This component is to pop up a dialog for the user to enter an SMS verification code to complete the user/mobile number verification process.
 * It is used for 2 scenarios:
 *     1> For guest booking flow;
 *        No authentication & no profile;
 *        Need user to fill in mobile (ContactDetails) before this dialog;
 * 
 *     2> For signed in user who has valid mobile number but never verified;
 *        2 checkpoints:
 *        a) Sign in; b) Booking;
 * 
 *     Ues function this.isGuestOrAuthorisedWithEmptyContactNumber() to decide which scenario it is.      
 */
class Verification extends React.Component<PropsFromStore, VerificationComponentState> {

    private noOfAttempts: number;
    private timeout: number;
    private contactNumber: string = "";

    constructor(props: PropsFromStore) {
        super(props);

        this.state = {
            code1: "",
            code2: "",
            code3: "",
            code4: "",
            verificationErrorMessage: "",
            resendCode: false,
            sentCode: false,
            showLoader: false,
            isVerified: null,
            isStartInput: false
        }

        this.noOfAttempts = 1;
        this.timeout = Config.SmsResendTimeoutMillis;

        this.sendVerificationCode = this.sendVerificationCode.bind(this);
        this.resendVerificationCode = this.resendVerificationCode.bind(this);
        this.goBackToContactDetails = this.goBackToContactDetails.bind(this);

        if (this.props.verification.Trigger === VerificationTrigger.Signup) {
            if (this.props.authentication.Credentials.ContactNumber) 
                this.contactNumber = this.props.authentication.Credentials.CountryInfo!.CountryCode + DropLeadingZero(this.props.authentication.Credentials.ContactNumber!);
        }
        else {
            if (this.props.verification.UserContactNumberInfo.Contactnumber && this.props.verification.UserContactNumberInfo.CountryInfo) {
                this.contactNumber = this.props.verification.UserContactNumberInfo.CountryInfo.CountryCode + DropLeadingZero(this.props.verification.UserContactNumberInfo.Contactnumber.toString());  
            }
            else if (this.props.authentication.UserProfile && this.props.authentication.UserProfile.ContactPhone != "") {
                this.contactNumber = CheckAddPlus(this.props.authentication.UserProfile.ContactPhone);
            }
        }
    }

    componentDidMount() {        
       LogEvent.OnVerificationPageLoad();
        // Provide the link to request new access code after 15 seconds of the popup load.
        var resendtimeout = setTimeout(() => { 
            this.setState({ resendCode: true, sentCode: false });
        }, this.timeout);

        /** 
         * If the verification code is already in the store on component mount, populate the input boxes with the code.
         * Use case is when the booking creation failed and the user clicked on Try again button on the error message. 
         * It will close the error message and open the verification page with the verification code filled and the spinner is displayed. Also, send the 'createBooking' request in the background.
         */
        if(IsGuest(this.props.authentication.AuthToken, this.props.authentication.UserProfile)) {
            const verificationCode = this.props.bookingpayloadFromStore.Verification.Code
            if (verificationCode) {
                this.setState({
                    showLoader: true,
                    isVerified: null,
                    code1: verificationCode.substr(0, 1),
                    code2: verificationCode.substr(1, 1),
                   code3: verificationCode.substr(2, 1),
                   code4: verificationCode.substr(3, 1)
               });
               document.getElementById("4")!.focus();   
                this.CreateBookingUsingPreviousVerification();
            }
        }
        
    }

    async sendVerificationCode() {
        LogEvent.OnResendingVerificationCode();
        this.setState({resendCode: false, showLoader: true});

        // After 15 seconds, show the resend link again.
        var resendtimeout = setTimeout(() => { 
             // Display the link only if the number of attempts are less than 4. i.e. Send verification code only 3 times.
            if(this.noOfAttempts < 4) {
                this.setState({resendCode: true, sentCode: false}); 
            }         
        }, this.timeout);

        const result = await StartSmsVerification(this.contactNumber);

        if (result.Outcome === StartSmsOutcome.Success) {
            this.setState({ showLoader: false, sentCode: true });
        }
        else {
            this.setState({ showLoader: false });
        }
    }

    /* On click of Resend code link, call the send verification function and increase the number of attempts by 1. */
    resendVerificationCode() {
        Dispatch.Booking.ChangeVerification({ Code: "" }); /** This is only used for guest booking */
        this.setState({
            code1: "",
            code2: "",
            code3: "",
            code4: "",
            verificationErrorMessage: "",
            isStartInput: true
        }); 
        document.getElementById("1")!.focus();  

        if(this.noOfAttempts === 4) {
            LogEvent.OnResendingVerificationCodeFourthTime();
            Dispatch.Dialog.CloseDialog(DialogKind.Verification);

            if (this.props.verification.Trigger === VerificationTrigger.Signup) { 
                new CredentialsController().GoBackToSignupPopupFromError();
                Dispatch.Verification.HideLoaderInContactDetails();
            }
            else {
                Dispatch.Dialog.SetDescriptiveErrorMessage({ ...DescriptiveErrorMessages.ResendCode, DialogToOpen: DialogKind.ContactDetails }); 
                Dispatch.Dialog.ShowDialog(DialogKind.DescriptiveErrorMessage);
            }
            return;
        }
        this.sendVerificationCode();
        this.noOfAttempts++;
    }

    verifyCode(e: any) {
        if (IsUnderProfileValidationMode()) { VerifyLoginFromLocalStorageAndLogout(); }

        const value = e.target.value;
        const name = e.target.name;

        if (e.target.value !== "" && e.target.id < 4) {
            var nextinput = (parseInt(e.target.id) + 1).toString(); 
            document.getElementById(nextinput)!.focus();
        }        

        /** 
         * Typescript doesn't support setState with dynamic property keys. As a workaround, coerce the object with (as Pick<VerificationState, keyof VerificationState>)
         */
        this.setState({
            [name]: value
        } as Pick<VerificationComponentState, keyof VerificationComponentState>, () => {
            // TODO: Need to confirm this condition with Tom.
            if (this.state.code1 !== "") {
                this.setState({ isStartInput: true });
            }

            if (this.state.code1 !== "" && this.state.code2 !== "" && this.state.code3 !== "" && this.state.code4 !== "") {
                const codes = this.state.code1 + this.state.code2 + this.state.code3 + this.state.code4, { Trigger } = this.props.verification;

                if (Trigger === VerificationTrigger.Booking) {
                    if(IsGuest(this.props.authentication.AuthToken, this.props.authentication.UserProfile) ) {
                        Dispatch.Booking.ChangeVerification({ Code: codes });
    
                        /* Create booking if the verification success.  */
                        this.setState({ showLoader: true, isVerified: null }, () => {
                            this.VerifyThenCreateBooking(codes);
                        });
                    }
                    else { // Authorised && contact phone is not null
                        this.setState({ showLoader: true, isVerified: null }, () => {
                            this.VerifyMobileAsync(codes);
                        });
                    }
                }
                else if (Trigger === VerificationTrigger.Signup) {  
                    this.setState({ showLoader: true, isVerified: null }, () => { this.VerifyMobileToSignup(codes); }); 
                } 
            }
        });
    }

    /** 
     * Verify the mobile
     */
    async VerifyMobileToSignup(verificationCode: string) {

        // there must be an existing SMS challenge
        const smsChallengeId = appstore.getState().verification.SmsChallengeId!;

        const submitResult = await Api.SmsVerification.SubmitResponse(smsChallengeId, verificationCode);

        if (submitResult.isSuccess) {
            
            this.setState({
                isVerified: true,
                verificationErrorMessage: "",
                isStartInput: false
            });
            
            if (FeatureFlags.Auth0RedirectInChildWindow) {
                Dispatch.Verification.SetVerificationCode(verificationCode);
                Dispatch.Auth.ShowSilentLogin();
            }
            else {
                new AuthImplV2().SignUpAuth0(CreateCredentialForSignup(this.props.authentication.Credentials));
            }
        }
        else {
            this.setState({
                verificationErrorMessage: CustomErrorMessages.IncorrectCode,
                resendCode: true,
                isVerified: false,
                sentCode: false,
                isStartInput: false,
                showLoader: false,
            });
        }
    }

    /** 
     * The user is signed in, and was prompted to verify their mobile number.
     * [codes] is the text the user has entered in response.
     */
    async VerifyMobileAsync(codes: string) { 
        const originalPhoneNumber = this.props.authentication.UserProfile!.ContactPhone;

        const result = await ChangePhoneNumber(codes);

        if (result.Outcome === ChangePhoneOutcome.Success) {
            this.setState({ isVerified: true, verificationErrorMessage: "", isStartInput: false });
            await RefreshProfileAfterDataChange();

            if (this.props.authentication.IsBookingNeededAfterVerification) { 
                this.CreateBookingUsingPreviousVerification();
                Dispatch.Auth.IsBookingNeededAfterVerification(false);
            }
            else {
                this.setState({ showLoader: false, resendCode: false }, () => { Dispatch.Dialog.CloseDialog(DialogKind.Verification); }); 
            }
            CleanUserContactNumberInfo();

            if (IsUnderProfileValidationMode()) {
                CloseOffContactNumberUpdateTaskInProfileValidation();
                Dispatch.UILogicControl.BookingFormApiEnd();
            }
        }
        else {

            // TODO: review later. Maybe use the API error message directly
            const message = result.Outcome === ChangePhoneOutcome.CodeFailed ? CustomErrorMessages.IncorrectCode : result.ErrorMessage;

            this.setState({
                verificationErrorMessage: message,
                resendCode: true,
                isVerified: false,
                sentCode: false,
                isStartInput: false,
                showLoader: false,
            });
        }

        // Mobile update related logic for appInsights purpose
        if (originalPhoneNumber !== this.contactNumber) {
            if (result.Outcome === ChangePhoneOutcome.Success) {
                appInsights.trackEvent("Update Mobile Success", { User: JSON.stringify(this.props.authentication.UserProfile!), PreviousMobileNumber: originalPhoneNumber, NewMobileNumber: this.contactNumber });
            }
            else {
                appInsights.trackEvent("Update Mobile Failure", { User: JSON.stringify(this.props.authentication.UserProfile!), TryToUpdateTo: this.contactNumber });
            }
        } 
    }

    /**
     * Use the specified SMS Verification code to complete the current SMS Challenge, then create the booking.
     */
    async VerifyThenCreateBooking(verificationCode: string) {

        const success = await this.CompleteSmsVerification(verificationCode);
        if (!success) {
            this.setState({ showLoader: false });
            return;
        }

        await this.CreateBookingInternal();
    }

    /** 
     *  Create a booking immediately, relying on a previously completed SMS verification.
     *  This supports some non-standard flows.
     */
    async CreateBookingUsingPreviousVerification() {
        await this.CreateBookingInternal();
    }

    async CreateBookingInternal(): Promise<void> {

        const CreateBookingResult = await CreateBookingCommon(true);
        this.setState({showLoader: false, resendCode: false});

        /**
        * Please DO NOT remove this, as it is critical for a correct strict validation for booking.
        */
        Dispatch.UILogicControl.OnIsStrictValidationModeOnBookingFormChange(false);

        if (!isErrorResult(CreateBookingResult)) {
            
            // load the new booking, only for BookingControllerV1 for now
            if (!FeatureFlags.BookingApiV2) {
                await TrackBookingHelper((CreateBookingResult as BookingAuthority).bookingId, (CreateBookingResult as BookingAuthority).hashCode);
            }
            
            Dispatch.Dialog.CloseDialog(DialogKind.Verification);
            Dispatch.Dialog.ShowDialog(DialogKind.Confirmation);
            Dispatch.Booking.ChangeVerification({ Code: "" });
            CleanUserContactNumberInfo();
            LoadMyRecentTrips();
        }
        else if (CreateBookingResult.isTimeout) {
            Dispatch.Dialog.CloseDialog(DialogKind.Verification);
            Dispatch.Dialog.SetDescriptiveErrorMessage({ ...DescriptiveErrorMessages.CreateBookingTimeout });
            Dispatch.Dialog.ShowDialog(DialogKind.DescriptiveErrorMessage);
            LogEvent.BookingCreationTimedOut();
            // Clear the verification code
            Dispatch.Booking.ChangeVerification({ Code: "" });
        }
        else {
            Dispatch.Dialog.CloseDialog(DialogKind.Verification);

            // If the retry count existed, take it to comparison; else, assign 0 and compare with the maximum tries.
            const retryCount = this.props.errorMessage.RetryCount ? this.props.errorMessage.RetryCount : 0;

            // If the retry count is less than 3, prompt an error message with a 'Try again' button. Else, prompt a popup with only a 'OK' button asking the user to call the contact center.
            if (retryCount < 3) {
                Dispatch.Dialog.SetDescriptiveErrorMessage({ ...DescriptiveErrorMessages.CreateBookingFailed, DialogToOpen: DialogKind.Verification, RetryCount: retryCount + 1 });
                Dispatch.Dialog.ShowDialog(DialogKind.DescriptiveErrorMessage);
            }
            else {
                Dispatch.Dialog.SetDescriptiveErrorMessage({ ...DescriptiveErrorMessages.CreateBookingTimeout });
                Dispatch.Dialog.ShowDialog(DialogKind.DescriptiveErrorMessage);
                Dispatch.Booking.ChangeVerification({ Code: "" });
            }
              
            LogEvent.BookingCreationFailed(CreateBookingResult.errorMessage);
        }
    }

    /** 
     *  Attempt to complete the current SMS Challenge.
     *  Returns true if successful.
     */
    async CompleteSmsVerification(verificationCode: string): Promise<boolean> {

        // there must be an existing SMS challenge
        const smsChallengeId = appstore.getState().verification.SmsChallengeId!;

        const submitResult = await Api.SmsVerification.SubmitResponse(smsChallengeId, verificationCode);

        // success
        if (submitResult.isSuccess) {
            LogEvent.ValidVerificationCodeEntered();

            this.setState({
                isVerified: true,
                verificationErrorMessage: "",
                isStartInput: false,
            });

            return true;
        }

        // failure
        LogEvent.InvalidVerificationCodeEntered();

        this.setState({
            verificationErrorMessage: CustomErrorMessages.IncorrectCode,
            resendCode: true,
            isVerified: false,
            sentCode: false,
            isStartInput: false,
        });

        return false;
    }

    /* Function to clear the verification code input boxes on backspace */
    clearCode(e: any) {  
        if (e.which === 8) {
            if (e.target.value === "") {
                if (e.target.id >= 2 && e.target.id <= 4) {
                    var id = parseInt(e.target.id) - 1;
                    document.getElementById(id.toString())!.focus();   
                }
            }
        }
    }

    /** 
     * On click of Back button, close the Verification popup and open the Contact details popup with prepopulated data.
     */
    goBackToContactDetails() {
        Dispatch.Dialog.CloseDialog(DialogKind.Verification);
        Dispatch.Dialog.ShowDialog(DialogKind.ContactDetails);
    }

    render() {
        var verifyCodeClass = this.state.isStartInput ? "orange-border" : this.state.isVerified ? "green-border" : this.state.isVerified === false ? "red-border" : "";
        const displayNumber = CheckAddPlus(this.contactNumber);
        
        return (
            <React.Fragment>
                <div className="contact-details">
                    <div className="popup-number-update">
                        <span className="bold-text">{displayNumber}</span>
                        <img src={getContentUrl(ContentURL.images.Lock.NumberEdit)} alt="Edit mobile number button" width="50px" height="auto" onClick={this.goBackToContactDetails}/>
                    </div>
                    <br/>
                    <p className="popup-number-description-sub">Please enter this code below:</p>

                    { this.state.verificationErrorMessage ? <p className="verification-error invalid-code">{ this.state.verificationErrorMessage }</p> : "" }
                    
                    <div className="verification-code">
                        <input type="tel" id="1" maxLength={1} className={verifyCodeClass} name="code1" value={this.state.code1} onChange={(e) => this.verifyCode(e)} onKeyDown={(e)=> this.clearCode(e)} autoFocus/>
                        <input type="tel" id="2" maxLength={1} className={verifyCodeClass} name="code2" value={this.state.code2} onChange={(e) => this.verifyCode(e)} onKeyDown={(e)=> this.clearCode(e)} />
                        <input type="tel" id="3" maxLength={1} className={verifyCodeClass} name="code3" value={this.state.code3} onChange={(e) => this.verifyCode(e)} onKeyDown={(e)=> this.clearCode(e)} />
                        <input type="tel" id="4" maxLength={1} className={verifyCodeClass} name="code4" value={this.state.code4} onChange={(e) => this.verifyCode(e)} onKeyDown={(e)=> this.clearCode(e)} />
                        <div className="verification-tick">{ this.state.showLoader ? <img alt="loading" src={getContentUrl(ContentURL.images.Loading)} height="55" /> : null }</div>
                    </div>    

                    { this.state.resendCode ? <a className="resend-code" onClick={this.resendVerificationCode}>Request a new access code</a> : null }
                    { this.state.sentCode ? <div className="sent-code"><img src={getContentUrl(ContentURL.images.GreenTick)} alt="" width="15" /><p>New access code sent</p></div> : "" }
                </div>

                { this.props.IsSilentLoginActive && <SilentLogin Credentials={this.props.authentication.Credentials} AuthType={AuthType.Signup} /> }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore  {
    return {
        bookingpayloadFromStore: state.booking,
        selectedCondition: state.condition.SelectedCondition,
        errorMessage: state.dialog.detailedErrorMessage,
        authentication: state.authentication,
        verification: state.verification,
        IsSilentLoginActive: state.authentication.IsSilentLoginActive,
        IsMobileDevice: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile
    };
}

export default connect(mapStateToProps)(Verification);