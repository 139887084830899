
/**
 * FeatureIds for cards appearing on the WhatsNew list.
 * These numbers are used to keep track of acknowledged features and also to make the order of the list.
 * Recently added features should go to the top. Therefore, they are sorted in descending order.
 */
export enum FeatureId {

    Favourites = 1,
    Parcel = 2,
    DriverProfile = 3,
    MultipleTracking = 4,
    SearchYourFavourites = 5,
    DriverVaxed = 6
}

/**
 * Parameters to be passed to each feature card
 */
export interface FeatureCardProps {

    /** Unique feature value */
    FeatureId: FeatureId;
    
    CanShowAcknowledgeButton: boolean;
}