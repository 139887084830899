
import React from 'react';
import { GoogleMapsEnhance } from './GooglePropsEnhancer';
import { GeoPoint } from '../../modules/Location/Entities';
import { TextField, InputAdornment } from '@material-ui/core';
import { TextFieldEndIcon } from '../../modules/Booking/Widget/TextFieldEndIcon';
import { GetValues } from '../../Config/MyAppConfig';

/** Props for the GooglePlaceAutoComplete component */
interface GooglePlaceAutoCompleteProps {
    /** When defined, indicates the center of an area of interest. Google autocomplete will be configured to prefer nearby places. */
    PreferNearbyTo?: GeoPoint;

    /** Callback for google place selection changes */
    OnPlaceSelected: (result: google.maps.places.PlaceResult, placeText: string) => void;

    /** When specified, only suburb / city / state places will be returned. This is suitable for the location selector. */
    RegionsOnly?: true;

    /** Optional placeholder on the input element */
    PlaceholderText?: string;

    /** Input value (optional: need when loading a predefined value to the input field) */
    PlaceValue?: string;

    /** Callback function to clear Pickup or Dropoff */
    onClearEvent?: Function;

    /** Callback function to report if the input has value or not */
    reportHasAddressInputValue?: (isAddressLabelShown: boolean) => void;

    /** When specified, the input field will be autofocused on component mount. (example usage is in location chooser popup) */
    AutoFocus?: true;

    /** This stands for the status of onClicked if there is a quick clear button */
    isQuickClearClicked?: boolean;

    /** Optional callback function to update the statis of onClicked if there is a quick clear button */
    onQuickClearClicked?: (isQuickClearClicked: boolean) => void;

    /** Path of the icon, to be displayed at the start of the input field */
    AddressIcon?: string;

    /** Optional callback function to update the focus */
    UpdateFocus?: (isFocus: boolean, value: string | undefined) => void;

    /** Optional label text on the input element  */
    LabelText?: string;

    /** Denotes whether valid address is entered or not */
    IsAddressInvalid?: boolean;
    
    /** Path of the icon, to be displayed at the end of the input field */
    EndIcon?: string;

    /** Determines whether the address input field is for Pickup or Dropoff */
    AddressInputFieldType? : string;
    
    /** Optional field to shrink the label text for material UI textfield  */
    CanShrinkLabelText? : boolean;

    /** Optional field to display the clear text icon once the input is entered  */
    CanShowClearTextIcon? : boolean;
}

/** Wrapper around the google Places Autocomplete class.
 *  Careful: most of the props don't support updates; they are only used in ComponentDidMount.
 */
class GooglePlaceAutoCompleteCore extends React.Component<GooglePlaceAutoCompleteProps> {

    // A React ref to the <input> DOM element
    // Note that refs are resolved in time to be used in ComponentDidMount.
    private inputRef: React.RefObject<HTMLInputElement>;

    // the underlying Autocomplete control, so we can control it manually after creating it
    // initialised during componentDidMount.
    private autoComplete: google.maps.places.Autocomplete | undefined;

    constructor(props: GooglePlaceAutoCompleteProps) {
        super(props);
        this.inputRef = React.createRef();
    }

    onChangeListener = () => {
        if (this.inputRef.current!.value === "" && this.props.onClearEvent != undefined) this.props.onClearEvent();

        if (this.props.reportHasAddressInputValue != undefined) {
            if (this.inputRef.current!.value === "") {
                this.props.reportHasAddressInputValue(false);
            }
            else {
                this.props.reportHasAddressInputValue(true);    
            }
        }
    }

    onFocusChangeListener = (isFocused: boolean) => {
        if (this.props.UpdateFocus) this.props.UpdateFocus(isFocused, this.inputRef.current?.value);
    }

    onTextClearListener = () => {
        if (this.props.onClearEvent) this.props.onClearEvent();
    }

    inputValueCheckUpdate(placeValueFromProps: string | undefined) {
        if (this.inputRef.current!.value !== placeValueFromProps) {
            this.inputRef.current!.value = placeValueFromProps != undefined ? placeValueFromProps : "";
        }
    }

    componentDidMount() {
        this.inputValueCheckUpdate(this.props.PlaceValue);

        // always Australia
        const options: google.maps.places.AutocompleteOptions = {
            componentRestrictions: {
                country: GetValues().AllowedCountryCodes,
            },
            fields: ['address_components', 'geometry', 'name', 'place_id'], // don't need "Contact Data" or "Atmosphere Data" SKUs, so save some money
        };

        // region filter
        if (this.props.RegionsOnly) {
            options.types = ['(regions)'];
        }

        const autoComplete = new google.maps.places.Autocomplete(this.inputRef.current!, options);
        this.autoComplete = autoComplete;
        
        autoComplete.addListener("place_changed", () => this.props.OnPlaceSelected(autoComplete.getPlace(), this.inputRef.current!.value));

        // vicinity
        this.setPreferredLocationBounds();
    }

    componentWillReceiveProps(nextProps: GooglePlaceAutoCompleteProps) {
        if (nextProps.isQuickClearClicked === true ) {
            this.inputRef.current!.value = "";
            if ( this.props.onQuickClearClicked != undefined ) this.props.onQuickClearClicked(false);   
        }          
        else if(nextProps.PlaceValue) {
            // Update the pickup/destination address in the input field when recent trip is selected
            this.inputValueCheckUpdate(nextProps.PlaceValue);
        }
    }

    /** Support updates to the PreferNearbyTo prop. It has to be done manually since we are manipulating the Google autoComplete control. */
    componentDidUpdate(prevProps: GooglePlaceAutoCompleteProps) {
        if (this.props.PreferNearbyTo && (this.props.PreferNearbyTo !== prevProps.PreferNearbyTo)) {
            this.setPreferredLocationBounds();
        }
    }

    /** Apply bounds on the Autocomplete to prefer locations in the vicinity of the preferred location */
    setPreferredLocationBounds = () => {

        if (this.props.PreferNearbyTo) {
            const leeway = 2.0;
            const sw = new google.maps.LatLng(this.props.PreferNearbyTo.latitude - leeway, this.props.PreferNearbyTo.longitude - leeway);
            const ne = new google.maps.LatLng(this.props.PreferNearbyTo.latitude + leeway, this.props.PreferNearbyTo.longitude + leeway);
            const bounds = new google.maps.LatLngBounds(sw, ne);

            // [autoComplete] guaranteed to exist because it is populated in componentDidMount
            this.autoComplete!.setBounds(bounds);
        }
    }

    render() {

        /** Render the material UI text field for Pickup and Dropoff address fields */
        if (this.props.AddressInputFieldType === "Pickup" || this.props.AddressInputFieldType === "Dropoff") {
                                
            return  <TextField
                        placeholder=""
                        fullWidth={true}
                        variant="outlined"
                        inputRef={this.inputRef}
                        label={this.props.LabelText}
                        autoFocus={this.props.AutoFocus}
                        error={!!this.props.IsAddressInvalid}
                        onChange={() => this.onChangeListener()} 
                        onBlur={() => this.onFocusChangeListener(false)} 
                        onFocus={() => this.onFocusChangeListener(true)} 
                        InputLabelProps={{shrink: this.props.CanShrinkLabelText}}
                        InputProps={{
                            startAdornment: this.props.AddressIcon && (
                                <InputAdornment position="start">
                                    <img src={this.props.AddressIcon} alt="startIcon" height="26" width="26" />
                                </InputAdornment>
                            ),
                            endAdornment: <TextFieldEndIcon iconPath={this.props.EndIcon} onClick={this.onTextClearListener} />
                        }}
                    /> 
        }

        return <input ref={this.inputRef} onChange={this.onChangeListener} placeholder={this.props.PlaceholderText || "Enter Location"} autoFocus={this.props.AutoFocus}/>

    }
}

/** Enhance with google maps loading behaviour */
export const GooglePlaceAutoComplete = GoogleMapsEnhance(GooglePlaceAutoCompleteCore);