import { DropLeadingZero, CheckAddPlus, FormatBusinessNumber } from '../../utils/Formattingutil';
import { CustomErrorMessages, DescriptiveErrorMessages, WellKnownMessageKind } from "../Utils/ErrorMessages";
import appstore from "../../appStore";
import { isErrorResult } from '../Utils/Typeguards';
import { DialogKind } from '../Dialog/DialogEntities';
import { Dispatch } from "../Dispatch";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { FormatPassengerContactNumber, CleanUserContactNumberInfo } from "../Utils/ContactNumberHelper";
import { IsValidMobileNumber, ParseContactNumber } from "../Utils/PhoneValidation";
import { CreateBookingV2 } from "../BookingV2/CreateBookingV2";
import { TrackBookingHelper, LoadMyRecentTrips } from "./BookingLoaders";
import { CreateBookingV1 } from "./CreateBookingV1";
import { ErrorResult } from "../Utils/ErrorEntities";
import { BookingWorkFlowState } from "./Redux/BookingState";
import { Contact, CreateBookingResponse } from "../../Services/MakeBookingContracts";
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { BookingAuthority } from '../../Services/BookingEntities';

/** V1 vs V2 selector for CreateBooking. Make sure all callers use this method! */
export async function CreateBookingCommon(withSmsVerification: boolean): Promise<BookingAuthority | CreateBookingResponse | ErrorResult> {

    if (FeatureFlags.BookingApiV2) {
        return await CreateBookingV2();
    }
    else {
        return await CreateBookingV1(withSmsVerification);
    }
}

/**
 * The behaviour of the "Book" button; hopefully to successfully create a new booking.
 */
export async function CreateBookingWithoutSmsVerification(): Promise<boolean> {

    Dispatch.UILogicControl.BookingFormApiStart();
    Dispatch.UILogicControl.OnIsStrictValidationModeOnBookingFormChange(false);

    const CreateBookingResult = await CreateBookingCommon(false);

    /**
     * Handle the case when this function was invoked from "Contact details"
     */
    if (appstore.getState().dialog.topmostDialog === DialogKind.ContactDetails) {
        Dispatch.Verification.HideLoaderInContactDetails();
        Dispatch.Dialog.CloseDialog(DialogKind.ContactDetails);
    }

    if (!isErrorResult(CreateBookingResult)) {

        Dispatch.Dialog.ShowDialog(DialogKind.Confirmation);

        if (!FeatureFlags.BookingApiV2) {

            await TrackBookingHelper((<BookingAuthority>CreateBookingResult).bookingId, (<BookingAuthority>CreateBookingResult).hashCode);
        }

        // Get the list of recent trips alongwith the newly created booking
        LoadMyRecentTrips();

        CleanUserContactNumberInfo();

        return true;
    }

    if (CreateBookingResult.isTimeout) {
        appInsights.trackEvent("Booking creation timeout");
        Dispatch.Dialog.SetDescriptiveErrorMessage({ ...DescriptiveErrorMessages.CreateBookingTimeout });
        Dispatch.Dialog.ShowDialog(DialogKind.DescriptiveErrorMessage);
        return false;
    }

    appInsights.trackEvent("User booking failed");
    var errorMessage = { ...DescriptiveErrorMessages.CreateBookingFailed };

    /**
     * When an account booking made that addresses are not consistent with region which account belongs to,
     * ODI gives error message "The account number is not valid".
     * 
     * If this case, we show a specific error message.
     * 
     * Tips:
     * Some other scenario will still give error message "The account number is not valid" from ODI.
     * For example, incorrect account number etc.
     * PO confirmed that we only need this error message.
     */
    if (CreateBookingResult.errorMessage.trim() == CustomErrorMessages.InvalidAccountNumber) {
        errorMessage.MessageText = CreateBookingResult.errorMessage.trim();
    }

    // Specific error message for payment preauth failures.
    if (CreateBookingResult.errorMessage.trim() == CustomErrorMessages.PreAuthFailed) {
        ShowDialogSimpleErrorMessage(WellKnownMessageKind.PaymentPreAuthFailed);
        return false;
    }

    Dispatch.Dialog.SetDescriptiveErrorMessage(errorMessage);
    Dispatch.Dialog.ShowDialog(DialogKind.DescriptiveErrorMessage);

    return false;
}

/** Compute driver notes */
export function ComputeDriverNotes(booking: BookingWorkFlowState): string {

    let notes = '';

    // When booking on accounts, prefix the remarks with the predefined driver note(s)
    if(FeatureFlags.AccountsDriverNotePrefix && booking.AccountData && booking.AccountData.SelectedAccount) {           
        notes = '-- Do Not Ask For Cash. No Stops, Go Direct. -- ';
    }

    // Append the user's specified remarks
    if(booking.NotesToDriver){
        return notes + booking.NotesToDriver.Notes;
    }

    return notes;
}

/*
*     1) For DVA accounts, send DVA FileNumber as ContactName
*     2) For Non-DVA accounts, send ContactName from UserProfile
*     3) For Guest and Personel booking, send PassengerName as ContactName 
*/
export function EvaluateContactName(BookingPayload: BookingWorkFlowState): string {

    const authFromStore = appstore.getState().authentication;

    let contactName = !!BookingPayload.Passenger.ContactName ? BookingPayload.Passenger.ContactName : "";

    /** Set ContactName for logged in users from the user profile */
    if (authFromStore.UserProfile) {
        contactName = authFromStore.UserProfile.ContactName;
    }

    // Booking on DVA accounts
    if (BookingPayload.AccountData) {
        const { FileNumber, SelectedAccount } = BookingPayload.AccountData;

        if (SelectedAccount && SelectedAccount.IsDVA) {
            contactName = FileNumber!;
        }
    }

    return contactName;
}

/* 
 * Calculate the contact number related information for booking purpose. 
 * The codes and logic are existing ones from existing codebase.
 * 
 * Todo: Some logic can be refined later.
 */
export function EvaluateContactInformation(isUserLoggedIn: boolean): Contact
{
    // Read from store
    const bookingPayload = appstore.getState().booking;
    const verification = appstore.getState().verification;
    const authFromStore = appstore.getState().authentication;

    let contactPhone = "", passengerContactNumber = "", countryCode = "", verificationCode = "", verificationId = 0;

    /**
     * For authenticated booking, flow/logic has guarantee the UserProfile.contactPhone != empty
     */
    if (isUserLoggedIn) {
        // Handle business numbers before create booking.
        const formattedNumber = FormatBusinessNumber(authFromStore.UserProfile!.ContactPhone);        
        contactPhone = CheckAddPlus(formattedNumber);
        countryCode = ParseContactNumber(contactPhone).CountryCode;            
        passengerContactNumber = contactPhone;

        // If PassengerContactNumber is undefined, assign contactNumber to PassengerContactNumber.
        const passengerContactDetails = bookingPayload.Passenger.PassengerContactNumber;        

        const formattedContactNumber = FormatPassengerContactNumber(passengerContactDetails);

        if (formattedContactNumber) passengerContactNumber = formattedContactNumber;
    }
    else {
        /**
         * For guest booking, flow/logic has guarantee the Verification.contactPhone != empty (from ContactDetails, then Verification)
         */
        countryCode = verification.UserContactNumberInfo.CountryInfo!.CountryCode;
        contactPhone = countryCode + DropLeadingZero(verification.UserContactNumberInfo.Contactnumber!);

        if (!FeatureFlags.BookingApiV2 && IsValidMobileNumber(contactPhone)) {
            verificationCode = bookingPayload.Verification.Code!;
        }

        passengerContactNumber = contactPhone;
    }

    return {
        BookingContactNumber: contactPhone,
        PassengerPhoneNumber: passengerContactNumber,
        CountryCode: countryCode,
        VerificationCode: verificationCode,
        VerificationId: verificationId
    };
}