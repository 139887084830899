import { Api } from "../../Services/Api";
import { Dispatch } from "../Dispatch";

/** Load latest documents pack available for the brand. */
export async function LoadLegalDocuments() {
    var result = await Api.LegalDocuments.GetLatestLegalDocuments();

    if (!result.isSuccess) return;

    Dispatch.LegalDocuments.Refresh(result.value);
}