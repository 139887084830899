import { BookingAuthority } from "../../Services/BookingEntities";
import { CreateBookingResponse } from "../../Services/MakeBookingContracts";
import { ErrorResult } from "./ErrorEntities";

/** 
 * This file can be used to define custom type guards when there is a function that returns a union type. 
 * More about typeguards can be found in https://www.typescriptlang.org/docs/handbook/advanced-types.html
 */

/** 
 * This type guard guarantees that the CreateBookingHelper(or any other function that returns the union of BookingStatusSummary and ErrorResult) returns ErrorResult type.
 * Reused the existing Guard for BookingCancelResult as well 
 */
export function isErrorResult(createBookingResult: BookingAuthority | CreateBookingResponse | ErrorResult): createBookingResult is ErrorResult {
    return (<ErrorResult>createBookingResult).isError !== undefined;
}