import React from "react";
import { connect } from "react-redux";
import "./MobileBookingForm.scss";
import BookingConditionManager from "../../Condition/UI/BookingConditionManager";
import BookingAddressGroup from "../Widget/BookingAddressGroup";
import PassengerName from "../Widget/PassengerName";
import BookingPageLoading from "../Widget/BookingPageLoading";
import BookingInputStyling from "./BookingInputStyling";
import BookingButton from "../Widget/BookingButton";
import BookingContactNumber from "../Widget/BookingContactNumber";
import NotesToDriver from "../Widget/NotesToDriver";
import { BookingStepperKind } from '../BookingEntities';
import BookingDateTime from "../BookingDateTime";
import { ApplicationState } from "../../../appState";
import TrackVisibility from 'react-on-screen';
import { TrackVisibilityBlock } from "./TrackVisibilityBlock";
import { GoogleMapWrapped } from "../../../widgets/google-maps/GoogleMapWrapped";
import GoogleMapWrapperWithGps from "./GoogleMapWrapperWithGps";
import $ from "jquery";
import { TncFooter } from "../Widget/TncFooter";
import CardSelector from "../../Cards/CardSelector";
import MobileBookOnAccountToggleWrapper from "../Mobile/MobileBookOnAccountToggleWrapper";
import { AccountDetails } from "../Account/AccountDetails";
import { NewBookingFormProps, CalculateIsBookingOnAccount } from "../NewBookingForm";
import { UILayoutMode } from "../../UILogicControl/UILogicControlEntities";
import { FareEstimateSnackbar } from "../Widget/FareEstimateSnackbar";
import { SnackbarForPriceGuaranteeTip } from "../Widget/SnackbarForPriceGuaranteeTip";

interface MobileBookingFormProps extends NewBookingFormProps {
    IsAddressFocused: boolean;
}

/**
 * This is a wrapper of booking form for mobile device, which:
 *   1) contains widgets which only
 */
class MobileBookingForm extends React.Component<MobileBookingFormProps> {

    /**
     * When below all satisfied, then scroll to map
     * 1) Any address was focused;
     * 2) No address is focused.
     */
    componentDidUpdate(prevProps: MobileBookingFormProps) {
        if (prevProps.IsAddressFocused && !this.props.IsAddressFocused) {
            const topSectionHeight = $("#app-header").height() + $("#app-tab").height() + 4;
            window.scrollTo({ top: topSectionHeight, left: 0, behavior: 'smooth' });
        }
    }

    render() {

        return (
            <div className="mobile-booking-form-panel">

                <TrackVisibility partialVisibility>
                    <TrackVisibilityBlock/>
                </TrackVisibility>

                <MobileBookOnAccountToggleWrapper />

                <GoogleMapWrapperWithGps ShowGps={true}>
                    <GoogleMapWrapped />
                </GoogleMapWrapperWithGps>

                {this.props.IsBookingOnAccount && <BookingInputStyling Title={BookingStepperKind.AccountDetails}>
                    <AccountDetails />
                </BookingInputStyling>}

                <BookingInputStyling Title={BookingStepperKind.BookingDetails}>
                    <BookingAddressGroup />
                </BookingInputStyling>

                <BookingInputStyling>
                    <BookingDateTime />
                </BookingInputStyling>

                <BookingConditionManager />

                <BookingInputStyling Title={BookingStepperKind.ContactDetails}>
                    <PassengerName />
                </BookingInputStyling>

                <BookingInputStyling>
                    <BookingContactNumber />
                </BookingInputStyling>

                <BookingInputStyling Title={BookingStepperKind.DriverInstruction}>
                    <NotesToDriver />
                </BookingInputStyling>

                {!this.props.IsBookingOnAccount && <BookingInputStyling Title={BookingStepperKind.PaymentMethod}>
                    <CardSelector />
                </BookingInputStyling>}

                <FareEstimateSnackbar />
                
                <SnackbarForPriceGuaranteeTip />
                
                <BookingButton />
                
                <TncFooter />

                <BookingPageLoading />
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): MobileBookingFormProps {
    return {
        IsAddressFocused: state.uiLogicControl.BookingForm.IsAddressFocused,
        IsBookingOnAccount: CalculateIsBookingOnAccount(state.authentication.LoginStatus, state.booking.IsOnAccount, state.uiLogicControl.LayoutMode === UILayoutMode.Mobile)
    };
}

export default connect(mapStateToProps)(MobileBookingForm);
