import auth0 from 'auth0-js';
import { Dispatch } from '../../Dispatch';
import CredentialsController from './CredentialsController';
import { FeatureFlags } from "../../../Config/FeatureFlags"; 
import { ChildMessageKind, MessageFromChild, SignupCredential } from "./LoginEntities";
import { VerificationTrigger } from '../../Verification/VerificationEntities';
import { LogEvent } from '../../../utils/LogEvent';
import { GetValues } from '../../../Config/MyAppConfig';
import { Config } from '../../../Config/Config';
import { GenerateRunTimeUrl } from '../../Utils/GenerateRunTimeUrl';

const auth0Config = GetValues().Auth0!;

/**
 * This is a Ts version of Auth0 API, which is used by interfaces.
 */
export default class AuthImplV2 {
    /**
     * Auth0 object
     */
    webAuth = new auth0.WebAuth({
        domain: FeatureFlags.CustomDomain && auth0Config.CustomDomain ? auth0Config.CustomDomain : auth0Config.ClientDomain,
        clientID: auth0Config.ClientId,
        responseType: 'token id_token',
        redirectUri: FeatureFlags.Auth0RedirectInChildWindow ? GenerateRunTimeUrl('loginreturnv2') : GenerateRunTimeUrl('loginreturn'),

        leeway: 300 // accounts for clock skews, this is the allowed difference in seconds
    });

    ForgotPasswordAuth0(email: string) {
        Dispatch.UILogicControl.StartCredentialsApiRequest();
        appInsights.trackEvent("Forgot password", { Email: email});

        this.webAuth.changePassword({
            connection: auth0Config.Connection,
            email: email
        }, (err: auth0.Auth0Error | null, resp) => {
            Dispatch.UILogicControl.EndCredentialsApiRequest();
            Dispatch.Auth.Auth0SuccessMessage(resp);
            new CredentialsController().DoLogin();
        });
    }

    /**
     * Does an Auth0 login using the specified credentials.
     * When successful, this function never returns, because a browser navigation will be initiated as part of the Auth0 login. The currently running application will end.
     * On failure, the method will return the error details from Auth0.
     */
    LoginAuth0Raw(username: string, password: string): Promise<auth0.Auth0Error | null> {

        return new Promise((resolve) => {

            const options: auth0.CrossOriginLoginOptions = {
                username: username,
                password: password,
                realm: auth0Config.Connection,
                scope: Config.Auth0TokenScope,
            };

            this.webAuth.crossOriginAuthentication.login(options, resolve);
        });
    }

    /** 
     * This method calls the Auth0 login.
     * Handles the errors/exceptions. 
    */
    async LogInAuth0(username: string, password: string) {
        
        const error = await this.LoginAuth0Raw(username, password);

        LogEvent.UserSignInFailed(error!);

        // Check if switch is ON, else abort
        if (!FeatureFlags.Auth0RedirectInChildWindow) return;
                    
        // Check if parent exists, else abort
        if (window.parent === window) return;
            
        const message: MessageFromChild = { MessageKind: ChildMessageKind.LoginResult, LoginResult: { IsSuccess: false, ErrorMessage: "Login failed." }};

        window.parent.postMessage(message, window.location.origin);
    }

     /**
     * Does an Auth0 signup using the specified credentials.
     * On successful,this method will return null.
     * On failure, the method will return the error details from Auth0.
     */
    SignUpAuth0Raw(credential: SignupCredential) : Promise<auth0.Auth0Error | null> {
    
        return new Promise(resolve => {

            const options: auth0.DbSignUpOptions = { 
                connection: auth0Config.Connection, 
                email: credential.Email,
                password: credential.Password,
                username: credential.Email,
                user_metadata: { 
                    first_name: credential.FirstName,
                    last_name: credential.LastName,
                    contact_number: credential.ContactNumber
                }
            };

            this.webAuth.signup(options, resolve);
        });
    }

    /** 
     * 1. This method calls the Auth0 signup.
     * 2. Calls the Auth0 login after successful signup.
     * 3. Handles the errors/exceptions. 
    */
    async SignUpAuth0(credential: SignupCredential) {
        
        const error = await this.SignUpAuth0Raw(credential);

        Dispatch.Verification.WasTriggeredBy(VerificationTrigger.Booking);

        if (error) {

            const errorMessage = this.GetBestErrorDetails(error);

            new CredentialsController().GoBackToSignupPopupFromError(errorMessage);

            LogEvent.UserSignUpFailed(error);

            // Check if switch is ON, else abort
            if (!FeatureFlags.Auth0RedirectInChildWindow) return;
                
            // Check if parent exists, else abort
            if (window.parent === window) return;

            const message: MessageFromChild = { MessageKind: ChildMessageKind.LoginResult, LoginResult: { IsSuccess: false, ErrorMessage: "Signup failed." }};

            window.parent.postMessage(message, window.location.origin);
        }
        else {
            
            this.LogInAuth0(credential.Email, credential.Password);
            
            appInsights.trackEvent("Sign-up success", { Email: credential.Email });
        }
    }

    /**
     * Get the best human-readable error message from a provided Auth0 Error object.
     */
    GetBestErrorDetails(error: auth0.Auth0Error): string {
        const description = error.errorDescription ?? error.error_description ?? error.description ?? error.name ?? error.original?.response?.body?.description ?? "(unknown error)";
        return `${description} (${error.error})`;
    }
}

