import React, { Component } from 'react';
import './DefaultLoadingPageContent.scss';
import { ContentURL, getContentUrl } from '../../modules/Utils/ContentURL';

class DefaultLoadingPageContent extends Component {
    render() {
        const styleDefaultLoading = "default-loading-page-content";
        
        if(this.props.blackTransparantLevel === 'light') {
            styleDefaultLoading += " black-transparant-light";
        }
        else {
            styleDefaultLoading += " black-transparant-medium";
        }

        return(
            <div className="default-loading-page-panel">
                <div className={styleDefaultLoading}><img src={getContentUrl(ContentURL.images.Loading)} alt="Loading"/></div>
            </div>
        );
    }
}

export default DefaultLoadingPageContent;