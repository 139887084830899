import React from "react";
import "./BookingAddressGroup.scss";
import BookingAddressComponent from "../BookingAddressComponent";

/**
 * Wrapper component of grouping of pickup & dropoff
 */
const BookingAddressGroup = () => {    
   
        return(
            <div className="booking-fields-panel">                
                <BookingAddressComponent isPickup={true} />
                <BookingAddressComponent isPickup={false} />
            </div> 
        )
}

export default BookingAddressGroup;