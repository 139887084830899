import appstore from "../../../appStore";
import { BookingAuthority, BookingStatus, BookingDataOwnership } from "../../../Services/BookingEntities";
import { HasBookingChanged } from "./HasBookingChanged";
import { GetDueRefreshCycle, BookingRefreshCycle, NoteRefreshOccurring } from "./BookingRefreshSchedule";
import { Api } from "../../../Services/Api";
import { Dispatch } from "../../Dispatch";
import { ConsiderRefreshDriverDetails } from "./RefreshDriverDetails";
import { BookingInfo, BookingTrackingDetails } from "../MyBookingEntities";
import { ConvertLightweightBookingStatusToBookingTrackingDetailsBulk } from "../ConvertAPIContractsToInternalModels";
import { PerformBookingRefreshV2 } from "./RefreshMyBookingsV2";
import { RefreshVehicleLocations } from "./RefreshVehicleLocations";

/** Refresh all of the "My Bookings" data from the server.
 * This only checks the fields that change over time, like booking status, car number, vehicle location, etc.
 * Be defensive about the amount of work done:
 *      Only perform a service call if there are bookings that need refreshing
 *      Only fire a Redux action if there are genuine changes in the result set. */
export async function ConsiderRefreshingBookings() {

    // check timer readiness
    var refreshCycle = GetDueRefreshCycle();
    if (refreshCycle == null) return;

    // find bookings needing updates   
    const updateCandidates = GetBookingsToRefresh(refreshCycle);
    if (updateCandidates.length == 0) return;

    // List of bookings to be updated with V1 API
    const updateCandidates1 = updateCandidates.filter(b => b.ApiVersion === 1);

    // List of bookings to be updated with V2 API
    const updateCandidates2 = updateCandidates.filter(b => b.ApiVersion === 2);

    // go!
    NoteRefreshOccurring(refreshCycle);    

    // Perform update with each API only if there are update candidates for the API.
    if (updateCandidates1.length > 0) await PerformBookingRefresh(updateCandidates1);

    if (updateCandidates2.length > 0) {
        await PerformBookingRefreshV2(updateCandidates2);
        await RefreshVehicleLocations(updateCandidates2);
    }
}

/** Gets the set of Bookings that are due for a refresh, based on the current refresh cycle. */
function GetBookingsToRefresh(refreshCycle: BookingRefreshCycle): BookingInfo[] {

    const allBookings = appstore.getState().myBookings.All;
    return allBookings.filter(i => ShouldRefreshBooking(i, refreshCycle));
}

/** Returns true if the specified booking should be refreshed during this cycle. */
function ShouldRefreshBooking(booking: BookingInfo, refreshCycle: BookingRefreshCycle): boolean {

    // finished bookings never update
    if (booking.TrackingInfo.BookingStatusID >= BookingStatus.Completed) return false;

    // long timer: everything
    if (refreshCycle == BookingRefreshCycle.AllBookings) return true; 

    // short timer: active only
    return booking.TrackingInfo.BookingStatusID > BookingStatus.NotDispatched;
}

/** Perform a refresh of the specified list of bookings. */
async function PerformBookingRefresh(updateCandidates: BookingInfo[]) {

    // service call to get new data
    const bookingKeys = updateCandidates.map<BookingAuthority>(i => ({
        bookingId: i.BookingID,
        hashCode: i.AccessCode,
    }));

    const serviceResult = await Api.Booking.GetBookingStatusBulk(bookingKeys);

    // abort on error
    if (!serviceResult.isSuccess) {
        // TODO: need to handle this error better. Push an error into redux somewhere
        return;
    }

    const allUpdates = ConvertLightweightBookingStatusToBookingTrackingDetailsBulk(serviceResult.value);
    DoActualRefreshOfBookings(updateCandidates, allUpdates);
    
}

/** Do the refresh of the bookings that have actually changed. */
export function DoActualRefreshOfBookings(updateCandidates: BookingInfo[], allUpdates: BookingTrackingDetails[]) {
    // lookup of original bookings based on ID
    let candidateIdLookup: Record<number, BookingInfo> = {};

    for (let booking of updateCandidates) {
        candidateIdLookup[booking.BookingID] = booking;
    }

    // find the updates with actual changes    
    const dataChanges = allUpdates.filter(bookingUpdate => {

        const originalBooking = candidateIdLookup[bookingUpdate.BookingID];
        return HasBookingChanged(originalBooking.TrackingInfo, bookingUpdate);
    });

    // quick exit: no actual changes
    if (dataChanges.length === 0) return;

    // convert to lookup
    let changeLookup: Record<number, BookingTrackingDetails> = {};

    for (let change of dataChanges) {
        const booking = candidateIdLookup[change.BookingID];
        if (change.BookingStatusID == BookingStatus.Cancelled) {
            // Remove bookings cancelled from other channels.
            if (ShouldRemoveCancelledBooking(booking)) {
                Dispatch.MyBookings.Remove(booking);
            } else {
                changeLookup[change.BookingID] = change;
            }
        } else {
            changeLookup[change.BookingID] = change;
            ConsiderRefreshDriverDetails(booking, change);
        }
    }

    // push to store
    Dispatch.MyBookings.StatusUpdate(changeLookup);
}

/** Decide whether the cancelled booking should be removed from the All bookings list (to be removed from the UI). */
export function ShouldRemoveCancelledBooking(booking: BookingInfo) {
    if (booking.DataOwnership === BookingDataOwnership.ReadOnlyLink) return false;
    if (booking.DataOwnership === BookingDataOwnership.WritableLink) return false;

    return true;
}