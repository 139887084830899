import { Address }  from '../Booking/BookingEntities';
import appstore from "../../appStore";
import { WellKnownMessageKind } from '../Utils/ErrorMessages';
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";

export function IsLongDistance(pickup: Address, dropoff: Address) : boolean {
    const a = new google.maps.LatLng(pickup.Lat, pickup.Long);
    const b = new google.maps.LatLng(dropoff.Lat, dropoff.Long);
    const distance = google.maps.geometry.spherical.computeDistanceBetween(a, b) / 1000;

    if (distance > 200) return true;
    else return false;   
};

export function ConsiderLongDistance() : void {
    const appState = appstore.getState();
    const Pickup =  appState.booking.pickup;
    const Dropoff = appState.booking.dropoff;

    if (Pickup && Dropoff && IsLongDistance(Pickup, Dropoff)){
        ShowDialogSimpleErrorMessage(WellKnownMessageKind.LongTrip); 
    }     
};