import React from 'react';
import { ApplicationState } from '../../../appState';
import { connect } from 'react-redux';
import { LocationData } from '../../../modules/Location/Entities';
import { DialogKind } from '../../../modules/Dialog/DialogEntities';
import "./LocationDisplayPanel.scss";
import { Dispatch } from '../../../modules/Dispatch';
import { GetBrandedUrl, BrandedImage } from '../../../modules/Utils/BrandedContentUrls';

/** This prop is mapped to the "location" slice of the store; see the connect() call below. */
interface PropsFromStore {
    preferredLocation: LocationData;
}

/** A visual panel displaying the current value of the user's location, as stored in redux. 
 * The future aim is to support locations that are not necessarily valid, e.g. unknown or non-serviced locations. This is done by subscribing to the "preferredLocation" component of the store. */
class LocationDisplayPanelCore extends React.Component<PropsFromStore> {

    render() {
        const location = this.props.preferredLocation;

        return (
            // The entire location display panel is clickable and should open the location chooser popup.
            <div className="location-display" onClick={this.onEditDivClicked}>
                <span>{location.isKnown ? location.value.displayName : "(unknown)"}</span>
                <div>
                    <img src={GetBrandedUrl(BrandedImage.EditGlyph)} alt="edit" width="20" />
                </div>
            </div>
        );
    }

    onEditDivClicked = () => {
        Dispatch.Dialog.ShowDialog(DialogKind.LocationChooser);
    }
}

/** Selects the subset of the store's state that this component is interested in. */
function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        preferredLocation: state.location.preferredLocation
    };
}

export const LocationDisplayPanel = connect(mapStateToProps)(LocationDisplayPanelCore);