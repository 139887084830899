import { FC, useEffect } from "react";
import React from "react";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

interface InfoDisplayProps {
    DisplayText: string;
}

/** Generic component that implements material-ui Snackbar. Can be used in different places with custom DisplayText and custom CSS for the position.
 * Uses <Alert> inside the Snackbar to have the Alert behaviour (with icon)*/
export const SnackBar: FC<InfoDisplayProps> = (props) => {    

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={true}
        >
            <Alert
                variant="filled"
                severity="info">
                {props.DisplayText}
            </Alert>
        </Snackbar>
    );
}