import { Moment } from "moment";
import { VehicleLocation, BookingDataOwnership, ApiGeoPoint, BookingStatus } from "../../Services/BookingEntities";
import { RecentTripButtonKind } from "../UILogicControl/UILogicControlEntities";

/** This enum is used to differentiate the two possible ETAs: to pickup or dropoff. */
export enum EtaKind {

    /** An ETA suitable for status 5 (Accepted) Taxis: the time needed to reach the pickup */
    ToPickup = "To Pickup",

    /** An ETA suitable for status 6 (Picked Up) Taxis: the time needed to reach the dropoff */
    ToDropoff = "To Dropoff",
}

/** Detailed information about an ETA. */
export interface ExtendedEta {

    /** The booking this ETA relates to. Used as the lookup key.  */
    BookingId: number;

    /** Whether this ETA is to the pickup or dropoff. */
    Kind: EtaKind;

    /** Exact ETA estimate from Google Maps API, in seconds. */
    BestGuessEtaSeconds: number;

    /** Optimistic ETA, in seconds. */
    OptimisticEtaSeconds: number;

    /** Human-readable display text, e.g. "1 min" */
    EtaDisplayText: string;

    /** When this ETA was captured. Used to decide when the data is out of date. */
    TimeOfCalculation: Moment;

    /** Where the Vehicle was at the time this ETA was computed. May be used to decide when to refresh the data. */
    VehicleLocation: VehicleLocation;
}

/** Display values for Progress and ETA */
export interface ProgressAndEtaDisplay {
    Progress: ProgressDisplay;
    Eta: EtaDisplay;
}

/** Display values for Progress bar */
export interface ProgressDisplay {
    /** Progress width percentage */
    WidthPercent: number;

    /** Inner colour of the progress bar */
    Colour: string;

    /** Should the progress bar be hidden or not */
    IsHidden: boolean;
}

/** Display values for ETA */
export interface EtaDisplay {
    /** Prefix text (ETA, Arrived at etc) */
    PrefixText: string;

    /** Informational text (3 mins, 10:00AM etc) */
    MainText?: string;
}

/** Information of a booking. These are static details */
export interface BookingInfo {

    /** Requested pickup time. DateTime formatted as string */
    Time: string;

    /** 
     * The time of the booking, ISO 8601 and with a UTC offset matching the location of the booking.
     * This could rarely be null if the API was unable to determine it.
     */
    LocalTime: string | null;

    /** Human-readable pickup address */
    PickupText: string;

    /** Human-readable destination address. May be the string "To be confirmed" if not specified on the booking. */
    DropoffText?: string;

    /** A string in like "1 - 4" for a 4-passenger vehicle */
    MaxSeat: string;

    CarConditionID?: number;    

    /** Internal booking ID */
    DispatchBookingID: string;

    /** Main booking ID */
    BookingID: number;

    /** Lat and Long of the pickup location */
    PickupLocation: ApiGeoPoint;

    /** Internal access code of the booking. This value is used to prove the ownership of the booking while performing various operations. 
     * Based on the ownership of the booking, this can be either 'read+write access' or 'read only access' code.
     */
    AccessCode: string;

    /** Passenger name. This can be different from the user name of the profile. */
    PassengerName: string;

    /** Lat and Long of the Dropoff location if available.  */
    DropoffLocation?: ApiGeoPoint;    

    /** Who owns the booking. This influences what actions are available for the user to perform.
     * Populated by the Management service. */
    DataOwnership?: BookingDataOwnership;

    /** Contact name */
    ContactName: string;

    /** Driver remarks */
    NotesToDriver?: string;

    /** Order number */
    OrderNumber?: string;

    /** Account number */
    AccountNumber?: string;

    /** Passenger/Contact phone number */
    CustomerPhone?: string;

    /** Access code used in the tracking URL in share booking. The users with this code has read only access to the particular booking. */
    ReadAccessCode?: string;

    /** Base URL of the booking tracking link. This is combined with the ReadAccessCode to make the share booking link. */
    BookingTrackingBaseUrl?: string;

    /** Whether this booking is a booking made via the Delivery API. */
    IsDeliveryBooking: boolean;

    /** Which API is used to retrieve booking details. This is used to get status updates/tracking details using different APIs. */
    ApiVersion: number;

    /** Tracking (dynamic) details of the booking. */
    TrackingInfo: BookingTrackingDetails;
}

/** Tracking/dynamic information of a booking. */
export interface BookingTrackingDetails {
    /** Main booking ID */
    BookingID: number;

    /** 
    *  Latest known location of the vehicle assigned to the booking.
    */
    VehicleLocation: VehicleLocation | null;

    /** Taxi number, e.g. T1234 */
    CarNumber: string | null;

    DriverName: string | null;

    /** URL to a photo of the driver */
    DriverImage: string | null;

    /** ID of the driver assigned to the booking. 
     * This is not in the initial tracking response and it is populated later with get status call. Therefore it is optional. */
    DriverID: number | null;

    /** ID corresponding to the booking status, e.g. NotDispatched = 1, etc. */
    BookingStatusID: BookingStatus;

    /** The time when the booking first became actively dispatching. 
     * Detected and set by the client in the MyBookings reducer. */
    DispatchStartTime: Moment | null;

    /** The time when the booking status changed to Pickedup for the first time. This is an temporary alternative to the MeterOnTime. 
     * Detected and set by the client in the MyBookings reducer.*/
    PickedupTime: Moment | null;

    /** The time when the booking status changed to Completed for the first time. This is an temporary alternative to the MeterOffTime. 
     * Detected and set by the client in the MyBookings reducer.*/
    DropoffTime: Moment | null;

    /** List of proof of delivery image urls for completed bookings */
    ProofOfDeliveryUrls: string[] | null;

    /** Whether a driver photo exists for the driver assigned to this booking. Only applicable for the V2 API bookings. This is used later to decide whether to call /GetBookingDriverPhoto API. */
    DriverPhotoExists: boolean;
}

/** The selected booking to rebook from trip history. */
export interface SelectedBookingDetails {
    /** Book again or Book return. */
    TripType: RecentTripButtonKind;

    /** Selected booking details to re-book */
    BookingDetails: BookingInfo;
}

/** POD image details for a booking */
export interface PODImageDetails {
    
    /** Identifier for a booking */
    BookingId: number;
   
    /** List of proof of delivery image urls for completed bookings */
    ImageUrls: string[];
}

/** Driver photo details for a booking. */
export interface BookingDriverPhoto {

    /** GB booking ID */
    BookingId: number;

    /** Image source as a base64 string. */
    DriverPhotoSource: string;
}