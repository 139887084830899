import React from "react";
import "./BookingCard.scss";
import { BookingStatus, BookingDataOwnership } from "../../../Services/BookingEntities";
import moment from "moment";
import { ContentURL, getContentUrl } from "../../Utils/ContentURL";
import { ExpandedSection, BookingTrackingProps } from "./ExpandedSection";
import StatusDisplay from "./StatusDisplay";
import ProgressBarAndETA from "./ProgressBarAndETA";
import { ExtendedEta, BookingInfo } from "../MyBookingEntities";
import { GetBookingDetailsToShare } from "../GetBookingDetailsToShare";
import { GetMyDevice, GetValues } from "../../../Config/MyAppConfig";
import { DeviceKind } from "../../../Config/Entities/DeviceKind";
import { GetBrandedUrl, BrandedImage } from "../../Utils/BrandedContentUrls";
import { ProofOfDeliveryImageCarousel } from "./ProofOfDeliveryImageCarousel";
import { DateTime, FixedOffsetZone } from "luxon";

interface BookingProps {
    BookingDetails: BookingInfo;
    Eta?: ExtendedEta;
    ShouldAutoOpen: boolean;
}

interface CardState {
    style: any;
    showMoreInfo: boolean;
    selectedImageIndex: number;
    shouldOpenCarousel: boolean;
}

interface BookingCardFooterProps extends BookingTrackingProps {
    ShowMoreInfo: boolean;
    ToggleMoreInfo: () => void;
}

/** Component contains the details of a specific booking. This is displayed as a single card in My Bookings list */
export default class BookingCard extends React.Component<BookingProps, CardState> {  
    constructor(props: BookingProps) {
        super(props);

        this.state = {
            selectedImageIndex: 0,
            shouldOpenCarousel: false,
            showMoreInfo: false,
            style: {
                opacity: 0,
                transition: 'all 2s ease',
            }
        };

        this.toggleMoreInfo = this.toggleMoreInfo.bind(this);

        this.mountStyle = this.mountStyle.bind(this);
    }

    /** Close the proof of delivery image carousel */
    CloseCarousel = () => {
        this.setState({
            shouldOpenCarousel: false
        });
    }

    /** 
     * 1. Open the image carousel
     * 2. Set the image to open in the carousel
     * */
    showCarouselAt = (imageIndex: number) => {
        this.setState({
            shouldOpenCarousel: true,
            selectedImageIndex: imageIndex
        });
    }

    /** Toggle the expand/collapse of the more info section. */
    toggleMoreInfo() {
        // On open of the expanded section, get and set read access code of the booking (unless it is loaded already). 
        // GetBookingDetailsToShare is async but it doesn't need to await here.
        if (!this.state.showMoreInfo) GetBookingDetailsToShare(this.props.BookingDetails);
        this.setState({showMoreInfo: !this.state.showMoreInfo});
    }

    /** Animate the component on mount */
    mountStyle() {
        this.setState({
            style: {
                opacity: 1,
                transition: 'all 2s ease',
            }
        })
    }

    componentDidMount() {
        setTimeout(this.mountStyle, 10);

        // Card which is closest to top should open automatically (pending column for desktop)
        if (this.props.ShouldAutoOpen) {
            this.setState({ showMoreInfo: true });
            // On open of the expanded section, get and set read access code of the booking (unless it is loaded already). 
            // GetBookingDetailsToShare is async but it doesn't need to await here.
            GetBookingDetailsToShare(this.props.BookingDetails);
        }
    }

    render() {
        let bookingCardClass = this.state.showMoreInfo ? "booking-card elevated-card" : "booking-card";

        bookingCardClass = this.state.shouldOpenCarousel ? `${bookingCardClass} display-pod-carousel-images` : bookingCardClass;

        return (
            <div className={bookingCardClass}>
                {
                    this.state.shouldOpenCarousel ? <ProofOfDeliveryImageCarousel BookingDetails={this.props.BookingDetails} CloseCarousel={this.CloseCarousel} SelectedImageIndex={this.state.selectedImageIndex} />
            :
                <>
                    <HeaderSection BookingDetails={this.props.BookingDetails} />
                    <StatusDisplay BookingDetails={this.props.BookingDetails} />
                    <ProgressBarAndETA BookingDetails={this.props.BookingDetails} Eta={this.props.Eta} />
                    <FooterSection BookingDetails={this.props.BookingDetails} ShowMoreInfo={this.state.showMoreInfo} ToggleMoreInfo={this.toggleMoreInfo}/>
                    {this.state.showMoreInfo && <ExpandedSection BookingDetails={this.props.BookingDetails} ShowCarouselAt={this.showCarouselAt} />}
                </>
            }
            </div>         
        );
    }
};

/**
 * Header section
 */
const HeaderSection: React.FC<BookingTrackingProps> = (props) => {
    const shouldShowDateTime = props.BookingDetails.TrackingInfo.BookingStatusID < BookingStatus.Accepted || props.BookingDetails.TrackingInfo.BookingStatusID == BookingStatus.Cancelled;

    return (
        <div className="card-header">
            <NameSection BookingDetails={props.BookingDetails} /> 
            {/* Right side of the card header. Displays date and time if the car details are not available, else displays driver image and the car number. */}
            {shouldShowDateTime ? <DatetimeSection BookingDetails={props.BookingDetails} /> : <DriverSection BookingDetails={props.BookingDetails} />}                 
        </div>
    );
}

/**
 * Footer section
 */
const FooterSection: React.FC<BookingCardFooterProps> = (props) => {

    const arrowClass = props.ShowMoreInfo ? "more-info-arrow" : "more-info-arrow down-arrow";

    return (
        <div className="card-footer">
            <img src={getContentUrl(ContentURL.images.info.GreyInfoIcon)}></img>
            <span className="booking-ref-text">Booking Ref:</span>
            <span className="booking-ref-number">{props.BookingDetails.DispatchBookingID}</span>
            <div className="toggle-more-area" onClick={props.ToggleMoreInfo}>
                <span className="more-info-text">TRIP DETAILS</span>
                <img src={getContentUrl(ContentURL.images.arrows.ArrowUpBlack)} className={arrowClass}></img>
            </div>                        
        </div>
    );
}

/**
 * Driver section in booking card
 */
const DriverSection: React.FC<BookingTrackingProps> = (props) => {

    /** 
     *  Decide whether to display the 'Call driver' button in the UI. 
     *  The driver connect function only works if these conditions are satisfied. Therefore hide it when not necessary.
     * */
    function ShouldEnableCallDriver() {

        // No driver connect number for this brand
        if (!GetValues().DriverConnectNumber) return false;

        const booking = props.BookingDetails;

        // Not a mobile device.
        if (GetMyDevice() !== DeviceKind.Phone) return false;

        // Not accepted by a driver yet.
        if (booking.TrackingInfo.BookingStatusID < BookingStatus.Accepted) return false;

        // Call driver is not needed if the status is greater than 6 (e.g: Completed, cancelled, NoJob etc.)
        if (booking.TrackingInfo.BookingStatusID > BookingStatus.PickedUp) return false;

        // URL with Test data ownership
        if (booking.DataOwnership === BookingDataOwnership.Test) return false;

        // URL with read only access, but the booking has been made via the Delivery API. Only enable after pickedup (customer's number is the destination contact number).
        if (booking.DataOwnership === BookingDataOwnership.ReadOnlyLink && booking.IsDeliveryBooking && booking.TrackingInfo.BookingStatusID == BookingStatus.PickedUp) return true;

        // URL with read only access, no exceptions.
        if (booking.DataOwnership === BookingDataOwnership.ReadOnlyLink) return false;

        return true;
    }

    const driverImage = props.BookingDetails.TrackingInfo.DriverImage ?? GetBrandedUrl(BrandedImage.DriverDummy);
  
    return (
        <div className="driver-details-section">
            {ShouldEnableCallDriver() && <img onClick={() => { window.location.href = `tel://${GetValues().DriverConnectNumber}`; }} src={getContentUrl(ContentURL.images.Phone.CallDriver)} width="50px" height="auto" className="call-driver"></img>}
            <img src={driverImage} className="driver-image"></img>
            <div>{props.BookingDetails.TrackingInfo.CarNumber}</div>
        </div>  
    );
}

/**
 * Datetime section in booking card
 */
const DatetimeSection: React.FC<BookingTrackingProps> = (props) => {

    const bookingTime = moment(props.BookingDetails.Time, "MM/DD/YYYY HH:mm:SS");
  
    return (
        <div className="">
            <div className="date-and-time">
                <img src={getContentUrl(ContentURL.images.MyBookings.Clock)}></img>
                <div className="time-display">
                    {bookingTime.format("HH:mm")}
                    <span className="meridium-display">{bookingTime.format(" a")}</span>
                </div>
            </div>
            <div className="date-display">{bookingTime.format("ddd DD MMM YYYY")}</div>
            <TimeZoneSection BookingDetails={props.BookingDetails} />
        </div>
    );
}

/**
 * Optionally renders the time zone of the booking location, fitting beneath the date.
 * Only when the UTC offset doesn't match the user's local time.
 */
const TimeZoneSection: React.FC<BookingTrackingProps> = (props) => {

    // this field is required to perform the calculations
    const bookingTime = props.BookingDetails.LocalTime;
    if (!bookingTime) return null;

    const luxonTime = DateTime.fromISO(bookingTime, { setZone: true });
    const bookingOffset = luxonTime.offset;
    const myTimeZoneOffset = luxonTime.toLocal().offset;

    // only display when there is a mismatch
    if (bookingOffset == myTimeZoneOffset) return null;

    const bookingZone = FixedOffsetZone.instance(bookingOffset);

    return (
        <div className="date-display">({bookingZone.name})</div>
    );
}

/**
 * Name section in booking card
 */
const NameSection: React.FC<BookingTrackingProps> = (props) => {

    const bookingTime = moment(props.BookingDetails.Time, "MM/DD/YYYY HH:mm:SS");
    const shouldShowTime = props.BookingDetails.TrackingInfo.BookingStatusID >= BookingStatus.Accepted && props.BookingDetails.TrackingInfo.BookingStatusID != BookingStatus.Cancelled;

    return (
        <div className="name-section">
            <div className="passenger-name">{props.BookingDetails.PassengerName}</div>
            <div className="passenger-name-tooltip">{props.BookingDetails.PassengerName}</div>
            {shouldShowTime && <div className="date-and-time-full">Booked: <span>{bookingTime.format("HH:mm ddd DD MMM YYYY")}</span></div>}
        </div>
    );
}