import React, { Fragment } from "react";
import { connect } from "react-redux";
import StepControl from "./Widget/StepControl";
import NotesToDriver from "./Widget/NotesToDriver";
import BookingDetails from "./BookingDetails";
import CardSelector from "../Cards/CardSelector";
import { ApplicationState } from "../../appState";
import PassengerName from "./Widget/PassengerName";
import { AccountDetails } from "./Account/AccountDetails";
import { LoginStatusKind } from '../Authentication/AuthEntities';
import BookingContactNumber from "./Widget/BookingContactNumber";
import { BookingStepperKind } from './BookingEntities';
import { FareEstimateSnackbar } from "./Widget/FareEstimateSnackbar";
import { Dispatch } from "../Dispatch";
import { FeatureFlags } from '../../Config/FeatureFlags';
import { UILayoutMode } from "../UILogicControl/UILogicControlEntities";
import { SnackbarForPriceGuaranteeTip } from "./Widget/SnackbarForPriceGuaranteeTip";

export interface NewBookingFormProps {
    IsBookingOnAccount: boolean;
}

class NewBookingForm extends React.Component<NewBookingFormProps> {
    
    componentWillUnmount() {
        Dispatch.UILogicControl.HideFareEstimateMessage();
    }

    render() {                      

        return(
            <Fragment>
                {
                    this.props.IsBookingOnAccount && 
                    <Fragment>
                        <StepControl StepKind={BookingStepperKind.AccountDetails} /> 
                        <AccountDetails />
                    </Fragment>
                }
                
                <StepControl StepKind={BookingStepperKind.BookingDetails} /> 
                <BookingDetails />
                
                <StepControl StepKind={BookingStepperKind.ContactDetails} /> 
                <PassengerName />
                <BookingContactNumber />

                <StepControl StepKind={BookingStepperKind.DriverInstruction} /> 
                <NotesToDriver/>                                
                
                {/**
                 * Three scenarios:
                    1> Booking as a guest, show with silent version;
                    2> Logged in && on account, don't show;
                    3> Logged in && not on account, show with default look and behaviours.
                 */}
                {!this.props.IsBookingOnAccount && 
                    <Fragment>
                        <StepControl StepKind={BookingStepperKind.PaymentMethod} /> 
                        <CardSelector />                    
                    </Fragment>
                }

                <FareEstimateSnackbar />
                <SnackbarForPriceGuaranteeTip />
            </Fragment>    
        )
    }
}

function mapStateToProps(state: ApplicationState): NewBookingFormProps {
    return {
        IsBookingOnAccount: CalculateIsBookingOnAccount(state.authentication.LoginStatus, state.booking.IsOnAccount, state.uiLogicControl.LayoutMode === UILayoutMode.Mobile)
    }  
}

/**
 * Calculate if the booking is on account:
 *   1) Logged in;
 *   2) Account toggle is on.
 */
export function CalculateIsBookingOnAccount(loginStatus: LoginStatusKind, isOnAccount: boolean, isMobileDevice: boolean): boolean {

    if (isMobileDevice && !FeatureFlags.MobileBookingOnAccount) return false;

    if (loginStatus !== LoginStatusKind.LoggedIn) return false;

    return isOnAccount;
}

export default connect(mapStateToProps)(NewBookingForm);