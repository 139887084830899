import { Component } from 'react';
import Auth from "../AuthImpl";
import { AuthToken, Auth0SuccessOrError } from "../AuthEntities";
import { ParseAuth0Response } from "../AuthHelper";
import { ChildMessageKind, MessageFromChild } from "./LoginEntities";
import { LogEvent } from '../../../utils/LogEvent';

/**
 * This component handles "/loginreturnv2" route
 * This is a another version of ../../Authentication/AuthCallback
 * Both are used to handle result from Auth0 login immediately after redirect.
 * AuthCallback is used when redirect happens in parent page, while AuthCallbackV2 is used when redirect happens inside the iFrame.
 * 
 * There are 3 purposes of this components:
 * 1> Handles the callback URL generated by Auth0, i.e. new Auth.handleAuthentication();
 * 2> Parse Auth0DecodedHash to Redux wanted AuthToken
 * 3> Post message to parent component no matter sucessful or failed.
 */
class AuthCallbackV2 extends Component {
    constructor(props: {}) {
        super(props);
        this.logInFailureHandler = this.logInFailureHandler.bind(this);
    }

    logInFailureHandler() {
        const message: MessageFromChild = { MessageKind: ChildMessageKind.LoginResult, LoginResult: { IsSuccess: false, ErrorMessage: "Login failed." }};
        window.parent.postMessage(message, window.location.origin);
    }

    async componentDidMount() {

        // Check if parent exists, else abort
        if (window.parent === window) return;

        const result: Auth0SuccessOrError = await new Auth().handleAuthentication();

        if (result.IsSuccess) {
            const authToken: AuthToken = ParseAuth0Response(result.SuccessfulResult);
            const message: MessageFromChild = { MessageKind: ChildMessageKind.LoginResult, LoginResult: { IsSuccess: true, AuthToken: authToken }};
            window.parent.postMessage(message, window.location.origin);

            LogEvent.UserSignInSuccess(authToken);
        }
        else {
            const error = result.ErrorResult!;

            this.logInFailureHandler();
            LogEvent.UserSignInFailed(error);
        }
    }

    render() { return null; }
}

export default AuthCallbackV2;