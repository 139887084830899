import React from 'react';
import './IEDeprecationModal.scss';
import '../../Dialog/Dialog.scss';
import {DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";


const IEDeprecationDialogTip = () => {

    return (
        <div className="IE-message-details">
            <DialogTitle className="alert-dialog-title" >Still using Internet Explorer?</DialogTitle>
            <DialogContent className="alert-dialog-description">
                <DialogContentText> To ensure best performance and functionality of this website, we recommend changing your browser.</DialogContentText>
                <DialogContentText> After 1/1/2022, we won't officially support Internet Explorer.</DialogContentText>
            </DialogContent>
        </div>
    )
}

export default IEDeprecationDialogTip;
