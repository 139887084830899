import { GetManagementServiceRootUrl, Get, Post } from "./ServiceManager";
import { ServiceResult } from "./ServiceEntities";
import { VerificationCodeResult, MobileNumberVerificationRequest, ProfileChanges, ResetPasswordRequest } from "./UserEntities";
import { TncInfoResponse } from "./TncEntities";
import { WebPage } from "../modules//TncPrivacy/TncPrivacyEntities";
import { CheckAddPlus } from '../utils/Formattingutil';
import { SimpleUserProfileBase } from "../modules/User/ProfileEntitiesV2";
import { Config } from "../Config/Config";
import { ParseContactNumber } from "../modules/Utils/PhoneValidation";

/** Base URL for the FareController in the Booking Management Service 
 *  I moved this outside the class to avoid issues with "this". */
function GetBaseUrl(): string {
    return `${GetManagementServiceRootUrl()}User/`;
}

/** Client for the UserController in the Management API */
class UserServiceClass {

    /** Request a verification code be sent to the specified phone by SMS. */
    public SendVerificationCode(toPhoneNumber: string, userId?: string): Promise<ServiceResult<VerificationCodeResult>> {
        var queryStr = "";
        if (userId) {
            queryStr += "&userId=" + userId + "&twoLetterIsoRegionName=" + ParseContactNumber(toPhoneNumber).IsoRegionCode;
        }
        const url = `${GetBaseUrl()}SendVerificationCode?phoneNumber=${encodeURIComponent(CheckAddPlus(toPhoneNumber))}${queryStr}`;
        return Get<VerificationCodeResult>(url);
    }

    /** Send an SMS to my phone when the booking is confirmed. Note: there is no payload beyond ServiceResult (the .value field won't be populated). */
    public SendBookingConfirmationSms(bookingId: number, dispatchBookingId: number, phoneNumber: string): Promise<ServiceResult<void>> {
        const data = {
            BookingId: bookingId,
            DispatchBookingID: dispatchBookingId,
            PhoneNumber: phoneNumber,
        };

        const url = `${GetBaseUrl()}SendConfirmation`;
        return Post<void>(url, data);
    }

    /** 
     *  Load the signed in user's profile in the simplified V2 format, which supports multiple sources of user data.
     *  This important API comes in the middle of the login process, so it has an extended timeout.
     */
    public GetMyProfile(): Promise<ServiceResult<SimpleUserProfileBase>> {
        
        const url = `${GetBaseUrl()}GetMyProfile`;
        return Post<SimpleUserProfileBase>(url, null);
    }

    /** 
     * Request to verify a specific mobile number from 4-digit verification code sent previously. 
     * There is no result payload even for successful payload, thus using null as generic type
     * */
    public VerifyMobileNumber(contactPhone: string, verificationCode: string): Promise<ServiceResult<null>> {
        const url = `${GetBaseUrl()}VerifyMobileNumber?contactPhone=${encodeURIComponent(contactPhone)}&verificationCode=${verificationCode}`;
        return Get<null>(url);
    }

    /** 
     * Request to verify a specific mobile number from 4-digit verification code sent previously.
     * This endpoint is only for guest user, and won't update any profile like VerifyMobileNumber above.
     * There is no result payload even for successful payload, thus using null as generic type.
     * */
    public VerifyMobileNumberAsGuest(mobile: MobileNumberVerificationRequest): Promise<ServiceResult<null>> {
        const url = `${GetBaseUrl()}VerifyMobileNumberAsGuest`;
        return Post<null>(url, mobile);
    }

    /** 
     * Update user profile.
     * Only support editing contact number for now.
     * */
    public UpdateProfile(profile: ProfileChanges): Promise<ServiceResult<null>> {
        const url = `${GetBaseUrl()}UpdateProfile`;
        return Post<null>(url, profile);
    }

    /** 
     * This function is to update the logged in user to be verified, from contact number point of view, in both User and BccUser tables, via Booking API,
     * if SMS verification code got successfully verified under guest and specified phone number.
     * 
     * Background:
     * This function will be used for signup with mobile number.
     * The scenario is that the user has verified hid/her contact number, if it's mobile, before sign up.
     * We need to write this to Bcc user tables.
     * */
    public VerifyMobileNumberFromGuestCode(contactPhone: string, verificationCode: string): Promise<ServiceResult<null>> {
        const url = `${GetBaseUrl()}VerifyMobileNumberFromGuestCode?contactPhone=${encodeURIComponent(contactPhone)}&verificationCode=${verificationCode}`;
        return Get<null>(url);
    }

    /**
     * Request to get the one-source content page for tnc
     */
    public GetTncContent(): Promise<ServiceResult<TncInfoResponse>> {
        const url = `${GetBaseUrl()}GetTncContent`;
        return Get<TncInfoResponse>(url);
    }

    /**
     * Request to get the tnc content
     */
    public ReadTncContentFromFile(): Promise<ServiceResult<string>> {
        const data = `${GetBaseUrl()}GetTermsAndConditions`;
        return Get<string>(data);
    }

    /** 
     * This function is to add a consent of tnc to table dbo.UserTncAgreement, via booking API (MGMT), per userId and tncId;
     * This is only for authenticated user;
     * TincId is retrieved from GetTncContent().
     * */
    public AddUserTncAgreement(tncId: number): Promise<ServiceResult<null>> {
        const url = `${GetBaseUrl()}AddUserTncAgreement?tncId=${tncId}`;
        return Get<null>(url);
    }

    /**
     * Request to get 13cabs privacy page content
     */
    public GetPrivacyPageContent(): Promise<ServiceResult<WebPage>> {
        const url = `${GetBaseUrl()}GetPrivacyPageContent`;
        return Get<WebPage>(url);
    }

    /** 
     * This is to verify whether a user exists or not based on username
     */
    public CheckUserExistenceByUserName(username: string): Promise<ServiceResult<null>> {
        const url = `${GetBaseUrl()}CheckUserExistenceByUserName?username=${encodeURIComponent(username)}`;
        return Get<null>(url);
    }

    /** 
     * Reset password old password & new password in request body
     */
    public ResetPassword(passwords: ResetPasswordRequest): Promise<ServiceResult<void>> {
        const url = `${GetBaseUrl()}ResetPassword`;
        return Post<void>(url, passwords);
    }
}

/** Singleton export for static access to the operations */
export const UserService = new UserServiceClass();