import { Dispatch } from "../../modules/Dispatch";
import { DialogKind } from '../../modules/Dialog/DialogEntities';
import { GetMyDevice } from "../../Config/MyAppConfig";
import { DeviceKind } from "../../Config/Entities/DeviceKind";

/** This is onclick is only enabled for desktop, which open a dialog of map for this booking */
export function OpenMapWithBookingDetails(bookingID: number): void{
    if (GetMyDevice() === DeviceKind.Phone) return;

    Dispatch.MyBookings.AddMapFocusedBookingID(bookingID);
    Dispatch.Dialog.ShowDialog(DialogKind.TaxiTrackingMap);
}

