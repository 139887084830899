import React from "react";
import "./MobileAddFavourites.scss";
import BookingTemplateNameField from '../../BookingTemplate/BookingTemplateNameField';
import ConditionManagerMobile from "../../Condition/UI/BookingConditionManager";
import BookingAddressGroup from "../Widget/BookingAddressGroup";
import PassengerName from "../Widget/PassengerName";
import BookingPageLoading from "../Widget/BookingPageLoading";
import BookingInputStyling from "./BookingInputStyling";
import BookingButton from "../Widget/BookingButton";
import BookingContactNumber from "../Widget/BookingContactNumber";
import NotesToDriver from "../Widget/NotesToDriver";
import { BookingStepperKind } from '../BookingEntities';
import CardSelector from "../../Cards/CardSelector";

/**
 * Wrapper component for mobile version to host either the booking form or a list of the user's bookings (selected by tabs).
 */
const MobileAddFavourites: React.FC = () => {

    return (
        <div className="mobile-add-fav-panel">
            <BookingTemplateNameField />

            <BookingInputStyling Title={BookingStepperKind.BookingDetails}>
                <BookingAddressGroup />
            </BookingInputStyling>

            <ConditionManagerMobile />

            <BookingInputStyling Title={BookingStepperKind.ContactDetails}>
                <PassengerName />
            </BookingInputStyling>

            <BookingInputStyling>
                <BookingContactNumber />
            </BookingInputStyling>

            <BookingInputStyling Title={BookingStepperKind.DriverInstruction}>
                <NotesToDriver />
            </BookingInputStyling>

            <BookingInputStyling Title={BookingStepperKind.PaymentMethod}>
                <CardSelector />
            </BookingInputStyling>
            
            <BookingButton />

            <BookingPageLoading />
        </div>
    );
}

export default MobileAddFavourites;