import { getContentUrl, ContentURL } from "../Utils/ContentURL";

/**
 * Generate the Url of booking for track from mobile website
 * Pattern is https://mobile-domain/b/BookingId/AccessCode
 * 
 * The caller will decide whether the "AccessCode" is "read only" or "read + write".
 */
export function GenerateBookingTrackingUrl(bookingId: string, accessCode: string, baseUrl: string): string {
    return baseUrl + bookingId + "/" + accessCode;
}

/**
 * Get the icon path to be displayed at the end of the text field
 */
export function LoadEndIconForTextField(showClearTextIcon: boolean, showErrorIcon: boolean) {

    // Load clear text icon if input is provided
    if (showClearTextIcon) return getContentUrl(ContentURL.images.BlackClearX);

    // Load error icon for invalid input
    if (showErrorIcon) return getContentUrl(ContentURL.images.ErrorExclamation);

    return '';
}