import { DateTime } from "luxon";
import { ApplicationState } from "../../appState";
import appstore from "../../appStore";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { BookingContactDetails, BookingLocationV1, BookingPaymentV1, BookingRequestV1b, BookingTimingV1, ClientDetails, ConditionV1 } from "../../Services/BookingEntities";
import { HasLogInProperly } from "../Authentication/AuthHelper";
import { ApiVehicleOption } from "../Condition/Redux/ConditionEntities";
import { ConsiderAddingSilverServiceCondition } from "../Condition/ShouldAddSilverServiceCondition";
import { ShouldIncludeCardIdInBooking } from "../Payment/PaymentHandler";
import { UILayoutMode } from "../UILogicControl/UILogicControlEntities";
import { ComputeDriverNotes, EvaluateContactInformation, EvaluateContactName } from "./CreateBookingCommon";

export function BuildBookingRequest(withSmsVerification: boolean): BookingRequestV1b {

    // Get data from the store, all booking information is guaranteed via validation before this step.
    const state = appstore.getState();
    const booking = state.booking;
    const condition = state.condition;
    const SelectedCondition = condition.SelectedCondition;

    const hasLogInProperly: boolean = HasLogInProperly(true);
    const ContactInformation = EvaluateContactInformation(hasLogInProperly);

    // Evaluate booking time
    const timeV2 = booking.BookingTimeV2;

    // CAREFUL: [time] is really for backwards compatibility only.
    let time: string = DateTime.now().toISO();
    let localTime: string | null = null;

    if (timeV2.IsImmediate == false) {
        localTime = timeV2.RequestedDate.toISO();
        time = localTime;
    }

    const pickupLocation: BookingLocationV1 = {
        PlaceId: booking.pickup!.PlaceId,
        ContactDetails: {
            Name: booking.Passenger.ContactName!,
            PhoneNumber: ContactInformation.PassengerPhoneNumber,
        },
        DriverInstructions: ComputeDriverNotes(booking)
    };

    let dropoffLocation = null;
    if (booking.dropoff != null) {
        dropoffLocation = {
            PlaceId: booking.dropoff.PlaceId,
            ContactDetails: {
                Name: booking.Passenger.ContactName!,
                PhoneNumber: ContactInformation.PassengerPhoneNumber,
            },
            DriverInstructions: null
        };
    }

    const mainContactInfo: BookingContactDetails = {
        Name: EvaluateContactName(booking),
        PhoneNumber: ContactInformation.BookingContactNumber
    };

    const paymentInfo: BookingPaymentV1 = {
        DeviceData: booking.DeviceData ?? null,
        PaymentCardId: null,
        Fare: null,
        AccountInfo: null,
        PaymentPreAuthOptIn: ShouldBePreAuthorised(state),
        IsFixedFare: condition.IsPriceGuaranteeSelected
    };

    /** Send fixed fare details with every booking request if available. */
    if (condition.FixedFare && SelectedCondition.FixedFare) {
        paymentInfo.Fare = {
            FixedFareId: SelectedCondition.FixedFare.Id,
            FixedFarePartitionKey: condition.FixedFare.FixedFarePartitionKey
        }
    }

    if (ShouldIncludeCardIdInBooking(booking)) {
        paymentInfo.PaymentCardId = booking.PaymentOption?.Card?.CardId ?? null;
    }

    const SelectedAccountData = appstore.getState().booking.AccountData;

    if (SelectedAccountData) {
        paymentInfo.AccountInfo = {
            AccountNumber: SelectedAccountData.SelectedAccount.AccountNumber,
            OrderNumber: SelectedAccountData.OrderNumber ?? null
        };
    }

    const timingInfo: BookingTimingV1 = {
        Time: time,
        LocalTime: localTime,
        IsImmediate: timeV2.IsImmediate
    };

    const clientInfo: ClientDetails = {
        IsMobile: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile
    }

    const result: BookingRequestV1b = {
        PickupLocation: pickupLocation,
        DropoffLocation: dropoffLocation,
        ContactInfo: mainContactInfo,
        Payment: paymentInfo,
        Pax: booking.Passenger.Pax ?? 0,
        Timing: timingInfo,
        Conditions: null,
        SmsChallengeId: null,
        Client: clientInfo
    };

    // SMS Verification
    if (withSmsVerification) {

        const smsChallengeId = appstore.getState().verification.SmsChallengeId!;
        result.SmsChallengeId = smsChallengeId;
    }

    /**
    * Condition check & setting
    */
    if (!!SelectedCondition.ApiVehicle) {
        const vehicleOptions: ApiVehicleOption[] = [SelectedCondition.ApiVehicle];

        ConsiderAddingSilverServiceCondition(vehicleOptions);

        const conditions = vehicleOptions.map(MapVehicleOptionToCondition);
        result.Conditions = conditions;
    }

    return result;
}

/** Decides whether to make PaymentPreAuthOptIn flag true or false. */
function ShouldBePreAuthorised(state: ApplicationState): boolean {

    if (!FeatureFlags.PreAuth) return false;

    // Only applicable for CNP bookings.
    if (!state.booking.PaymentOption?.Card) return false;

    // Fixed fare details is required for pre-auth bookings.
    if (!state.condition.SelectedCondition.FixedFare) return false;

    return true;
}

/**
 * Convert the internal representation, ApiVehicleOption, to the V1-specific API data contract, ConditionV1.
 */
function MapVehicleOptionToCondition(vehicleOption: ApiVehicleOption): ConditionV1 {
    return {        
        ID: vehicleOption.ApiId
    };
}