import { Reducer } from 'redux';
import { InitTncPrivacyState } from './TncPrivacyState';
import { TncPrivacyActions, TncPrivacyActionTypes  } from './TncPrivacyActions';
import { TncPrivacyState } from './TncPrivacyEntities';
import { GenerateRunTimeUrl } from '../Utils/GenerateRunTimeUrl';

export const TncPrivacyReducer: Reducer<TncPrivacyState, TncPrivacyActions> = (state = InitTncPrivacyState, action) => {
    if (action.type === TncPrivacyActionTypes.TncInfoOnChange) {
        return {
            ...state,
            TncInfo: action.payload
        }
    }
    else if (action.type === TncPrivacyActionTypes.TncContentOnChange) {
        return {
            ...state,
            // The content of the terms and conditions page that we get from the server (via booking API) has a broken link to the privacy page. 
            // This replaces that link with one to our Privacy component.
            TncContent: action.payload.replace(/http:\/\/13cabs.com.au\/\?ca=website\.privacy/g, GenerateRunTimeUrl("Privacy"))
        }
    }
    else if (action.type === TncPrivacyActionTypes.PrivacyInfoOnChange) {
        return {
            ...state,
            PrivacyInfo: action.payload
        }
    }
    else {
        return state;
    }
}