import { GetValues } from "./Config/MyAppConfig";
import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Visby CF',
            'Arial'
        ].join(',')
    },
    palette: {
        primary: {
          main: GetValues().BrandColour
        },
        secondary: { 
            main: '#7ed321'        // Radio button checked green color
        },
        error: {
          main: '#b00020'
        }
    }
});