import React from "react";
import { connect } from 'react-redux';
import "./BookingButton.scss";
import { ApplicationState } from "../../../appState";
import { BookingWidgetModeKind } from '../../BookingTemplate/BookingTemplateEntities';
import { BookingProps, BookingPropsV2 } from "../BookingProps";
import { AreBookingRequisitesReady } from "../../UILogicControl/UILogicControlHelper";
import { CreateBookingTemplate, ValidateBookingTemplateInput } from '../../BookingTemplate/BookingTemplateHelper';
import { Dispatch } from "../../Dispatch";
import { DialogKind } from "../../Dialog/DialogEntities";
import { WellKnownMessageKind, DescriptiveErrorMessages } from "../../Utils/ErrorMessages";
import { LogEvent } from '../../../utils/LogEvent';
import { PaymentCardErrorType } from "../../Payment/PaymentEntities";
import { ValidateContactNumberInput } from "../../Utils/ContactNumberHelper";
import { ShowDialogSimpleErrorMessage } from "../../../widgets/general-error-message/ErrorMessagingHelper";
import { IsVerificationNeeded } from "../../Authentication/AuthHelper";
import { VerifyPhoneForProfile } from "../../Verification/VerifyPhoneForProfile";
import { CreateBookingWithoutSmsVerification } from "../CreateBookingCommon";
import { VerifyPhoneForBooking } from '../../Verification/VerifyPhoneForBooking';
import { ContactNumberValidateOutcome } from '../../Verification/VerificationEntities';
import { HasLogInProperly } from "../../Authentication/AuthHelper";
import { IsPaymentAndServiceValidForPriceGuarantee } from "../../Payment/PaymentHandler";
import { CheckForCardExpiry } from "../../Payment/PaymentCardValidation";

/**
 * Booking button with styles and behaviour
 */
export class BookingButton extends React.Component<BookingProps & BookingPropsV2> { 

    private noOfVerificationAttempts : number;
    
    constructor(props: BookingProps & BookingPropsV2) {
        super(props);
        this.noOfVerificationAttempts = 1;
    }

    onBookButtonClicked = async () => {       
        const userProfile = this.props.Authentication.UserProfile;

        // If signed in user does not have a contact number, immediately fail the booking and show an error message.
        if (userProfile && !userProfile.ContactPhone) {
            Dispatch.Dialog.SetDescriptiveErrorMessage({ ...DescriptiveErrorMessages.CreateBookingFailedWithoutRetry });
            Dispatch.Dialog.ShowDialog(DialogKind.DescriptiveErrorMessage);
            return;
        }

        Dispatch.UILogicControl.OnIsStrictValidationModeOnBookingFormChange(true);

        // Testing V2 can guarantee V1, if FeatureFlags.BookingApiV2 === false
        if (this.props.BookingTimeV2.IsImmediate)
        {
            LogEvent.BookForNowTriggered();
        } 
        else {
            LogEvent.BookForFutureTriggered();
        }

        const isUserLoggedIn = HasLogInProperly(true);

        const isBookingOnAccount = isUserLoggedIn && this.props.SelectedAccountData;

        // Display error message for invalid payment card when not booking on accounts
        if (!isBookingOnAccount && !this.props.PaymentOption) {
            Dispatch.Payment.SetError(PaymentCardErrorType.CardNotSelected);
        }

        // Payment card is mandatory for price guarantee except for Parcel
        if (!this.props.IsServiceAndPaymentMethodValidForPriceGuarantee) {
            Dispatch.Payment.SetError(PaymentCardErrorType.CardNotProvidedForPriceGuarantee);
        }

        // Check if the selected payment card expired (if selected)
        if (this.props.PaymentOption) CheckForCardExpiry(this.props.PaymentOption);
    
        const isContactNumberInvalid = isUserLoggedIn && !ValidateContactNumberInput(false);
            
        if (!AreBookingRequisitesReady() || isContactNumberInvalid) {
            LogEvent.InvalidBookingInputs();
            ShowDialogSimpleErrorMessage(WellKnownMessageKind.BookingStrictValidation); 
        }
        else {
            LogEvent.BookingTriggered();           

            /**
             * Validation:
             * 1> Guest booking with mobile -> go to verification [SMS + tracking];
             * 2> Guest booking wtih landline -> create booking directly [tracking];
             * 3> For logged-in users, all validations are done/handled by "logged-in users validation process", such as:
             *    (a) No contact number;
             *    (b) Contact number is invalid;
             *    (c) Valid mobile but not verified;
             *    Users will be forced to logout, if they decline.
             */
            if (isUserLoggedIn) {
                if (IsVerificationNeeded(userProfile!)) {
                    VerifyPhoneForProfile(userProfile!, true);
                }
                else {
                    CreateBookingWithoutSmsVerification();
                }                
            }
            else {

                /**
                 * Validate the guest users by sending the verification code
                 * Maintain the number of failure attempts count
                 */                
                const result = await VerifyPhoneForBooking(this.noOfVerificationAttempts);
                
                if (result === ContactNumberValidateOutcome.ErrorFound) { 
                    this.noOfVerificationAttempts++;
                }                
                // Reset the number of attempts count, after the maximum limit is exceeded
                else if (result === ContactNumberValidateOutcome.ExceededMaxNumberOfAttempts) {
                    this.noOfVerificationAttempts = 1;
                }
            }
        }
    }

    onSaveFavouriteButtonClicked = () => {
        
        // Display error dialog for invalid template input
        if (!ValidateBookingTemplateInput()) {

            Dispatch.UILogicControl.OnIsStrictValidationModeOnBookingFormChange(true);
            
            ShowDialogSimpleErrorMessage(WellKnownMessageKind.BookingStrictValidation);

            LogEvent.InvalidCreatingTemplateInputs();
            
            return;
        }
        
        // Save the booking template
        CreateBookingTemplate();
    }

    render() {
        const IsTemplateModeOn = this.props.BookingWidgetMode !== BookingWidgetModeKind.Booking;  
        const btnFavStyle = ValidateBookingTemplateInput() ? "book-btn-ready" : "book-btn-silent";
        const btnStyle = AreBookingRequisitesReady() ? "book-btn-ready" : "book-btn-silent";
         
        return (
            <div className="booking-btn-panel">
                {IsTemplateModeOn ? <button className={btnFavStyle} onClick={this.onSaveFavouriteButtonClicked}>Save favourite</button> 
                                  : <button className={btnStyle} onClick={this.onBookButtonClicked}>Book</button> }
            </div> 
        );
    }
}

/**
 * Very important to subscribe below, even they are not used directly within this component:
 * 1> Passenger;
 * 2> PickupServiceCheck;
 * 3> BookingTime;
 * 4> Pickup;
 * 5> Dropoff;
 * They are critical to be subscribe for a proper function of strict validation.
 */
function mapStateToProps(state: ApplicationState): BookingProps & BookingPropsV2 {
    return {
        IsVehicleSelectionActive: state.condition.IsVehicleSelectionActive,
        PickupServiceCheck: state.booking.PickupServiceCheck,
        Passenger: state.booking.Passenger,
        ConditionLoadState: state.condition.LoadingStatus,
        Pickup: state.booking.pickup,
        Dropoff: state.booking.dropoff,
        IsBookingFormStrictValidationModeOn: state.uiLogicControl.BookingForm.IsStrictValidationModeOn,
        Condition: state.condition,
        Authentication: state.authentication,
        Verification: state.verification,
        BookingFormApiRunningStatus: state.uiLogicControl.BookingForm.ApiRunningStatus,
        BookOnAccount: state.booking.IsOnAccount,
        UserProfile: state.authentication.UserProfile,
        PaymentOption: state.booking.PaymentOption,
        SelectedAccountData: state.booking.AccountData,
        ContactNumber: state.verification.UserContactNumberInfo.Contactnumber,
        BookingTemplateName: state.booking.TemplateName,
        BookingWidgetMode: state.uiLogicControl.BookingForm.BookingWidgetMode,
        DoesPickupInputHaveValue: state.uiLogicControl.AddressStatus.Pickup.DoesInputHaveValue,
        DoesDropoffInputHaveValue: state.uiLogicControl.AddressStatus.Dropoff.DoesInputHaveValue,
        IsSelectedVehicleServiceable: state.uiLogicControl.BookingForm.IsSelectedVehicleServiceable,
        SelectedCondition: state.condition.SelectedCondition,
        BookingTimeV2: state.booking.BookingTimeV2,
        PickupV2: state.booking.PickupV2,
        DropoffV2: state.booking.DropoffV2,
        IsServiceAndPaymentMethodValidForPriceGuarantee: IsPaymentAndServiceValidForPriceGuarantee(state) 
    };
}

export default connect(mapStateToProps)(BookingButton);