import appstore from "../../appStore";
import { LogEvent } from "../../utils/LogEvent";
import { ServiceInfo, ServiceKind } from "../../utils/service-vehicles/ServiceMetadata";
import { Dispatch } from "../Dispatch";
import { VehicleOption } from "./Redux/ConditionEntities";

/**
 * Update the vehicle selection by referencing only a service.
 * This is used for non-standard vehicle selection, e.g. picking a vehicle based on a service name from the a URL query string.
 */
export function SelectVehicleByService(service: ServiceInfo) {

    const vehicle = TryGetVehicleForService(service);

    if (vehicle) {
        Dispatch.Condition.SelectedCondition(vehicle);
        LogEvent.OnVehicleSelection(vehicle);
        Dispatch.Condition.HideVehicleSelectorUI();
    }
}

/**
 * Try to find a Vehicle Option (condition) matching the specified service.
 * Otherwise returns null.
 */
function TryGetVehicleForService(service: ServiceInfo): VehicleOption | null {

    // get matching conditions (vehicles)
    const conditionData = appstore.getState().condition;
    const vehicleOptions = conditionData.ConditionList.filter(i => i.Service.kind === service.kind);

    // no matches
    if (vehicleOptions.length == 0) return null;

    // multiple matches: should be Maxi Taxi. Pick a reasonable default
    if (service.kind === ServiceKind.MaxiTaxi) {

        // TODO: review and improve maxi taxi lookup in future.
        const condition = conditionData.MaxiTaxiLookUp[6];

        if (condition) {
            return {
                ApiVehicle: condition,
                Service: service,
            };
        }
    }

    // usually there will be just one
    return vehicleOptions[0];
}