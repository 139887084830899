import moment from "moment";
import { BookingStatus, BookingDataOwnership } from "../../../Services/BookingEntities";
import { BookingInfo } from "../MyBookingEntities";

/** Order bookings list by time in asc order */
export function OrderBookingsByTimeInAsc(bookings: BookingInfo[]): BookingInfo[] {
    
    const bookingList = bookings.sort((a,b) => {
        return moment(a.Time, "MM/DD/YYYY HH:mm:SS").diff(moment(b.Time, "MM/DD/YYYY HH:mm:SS"));
    });

    return bookingList;
}

/** Order bookings list by time in desc order */
export function OrderBookingsByTimeInDesc(bookings: BookingInfo[]): BookingInfo[] {
    
    const bookingList = bookings.sort((a,b) => {
        return moment(b.Time, "MM/DD/YYYY HH:mm:SS").diff(moment(a.Time, "MM/DD/YYYY HH:mm:SS"));
    });

    return bookingList;
}

/**
 * Order bookings list for mobile device 
 * Ordering rules:
 *     1) accepted (status = 5) 
 *     2) picked up (status = 6) 
 *     3) no job (status = 9) 
 *     4) dispatching (status = 2, 3) 
 *     5) completed (status = 7) 
 *     6) future (status = 1) 
 * 
 *  Order is following asc by time within per status.
 */
export function OrderBookingsForMobileDevice(bookings: BookingInfo[]): BookingInfo[] {

    // Do nothing if list is empty
    if (bookings.length === 0) return bookings;

    // First sort by the status ID in a pre-defined order, then by the date in ascending order.
    return bookings.sort((b1, b2) => GetScore(b1) - GetScore(b2) || moment(b1.Time, "MM/DD/YYYY HH:mm:SS").diff(moment(b2.Time, "MM/DD/YYYY HH:mm:SS")));
}

/** Assign a value for each booking status based on the required sorting order. Smallest score goes to the top of the list. */
function GetScore(booking: BookingInfo): number {

    if (booking.TrackingInfo.BookingStatusID == BookingStatus.Accepted) return 1;
    if (booking.TrackingInfo.BookingStatusID == BookingStatus.PickedUp) return 2;
    if (booking.TrackingInfo.BookingStatusID == BookingStatus.NoJob) return 3;
    if (booking.TrackingInfo.BookingStatusID == BookingStatus.Dispatching) return 4;
    if (booking.TrackingInfo.BookingStatusID == BookingStatus.Assigned) return 4;
    if (booking.TrackingInfo.BookingStatusID == BookingStatus.Acknowledged) return 4;
    if (booking.TrackingInfo.BookingStatusID == BookingStatus.Completed) return 5;
    if (booking.TrackingInfo.BookingStatusID == BookingStatus.NotDispatched) return 6;
    if (booking.TrackingInfo.BookingStatusID == BookingStatus.Cancelled && (booking.DataOwnership === BookingDataOwnership.ReadOnlyLink || booking.DataOwnership === BookingDataOwnership.WritableLink)) return 7;

    // Default
    return 0;
}
