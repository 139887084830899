import React from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';

export interface TextFieldEndIconProps {

    /** Callback function called on click of the end icon */
    onClick?: () => void;

    /** Path of the icon (e.g. clear, error) displayed */
    iconPath?: string;
}

/**
 * Render the icon at the end of the text field
 */
export const TextFieldEndIcon = (props: TextFieldEndIconProps) => {

    /**
     *  Do not render, if the icon path is not specified
     *  e.g. We do not show any icon, when the input field is empty and IsStrictValidationModeOn = false
     */
    if (!props.iconPath) return null;
    
    return (
        <InputAdornment position="end">
            <IconButton onClick={props.onClick}>
                <img src={props.iconPath} alt="endIcon" height="20" width="20" />
            </IconButton>
        </InputAdornment>
    )
}