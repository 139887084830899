import { ReduxStoreSlice } from "../../ReduxStoreSlice";
import { FeaturesState, FeaturesInitialState }  from './FeaturesState';
import { FeatureId } from "./FeaturesEntities";

const slice = new ReduxStoreSlice<FeaturesState>("Features", FeaturesInitialState);

/** Dispatcher for actions in the WhatsNew state slice. */
export const FeaturesDispatch = {
    
    /** Save new feature list. */
    SaveNewFeatureList: slice.Action("Save NewFeature List", SaveNewFeatureList),

    /** Mark viewed feature as acknowledged */
    AcknowledgeFeature: slice.Action("Mark Feature As Viewed", AcknowledgeFeature),

    /** Save acknowledged feature list. */
    SaveAcknowledgedFeatureList: slice.Action("Save Acknowledged List", SaveAcknowledgedFeatureList),
    
    /** Show whats new feature list. */
    ShowWhatsNewFeatures: slice.EmptyAction("Show Whats New Feature Cards", ShowWhatsNewFeatures),

    /** Hide whats new feature list. */
    HideWhatsNewFeatures: slice.EmptyAction("Hide Whats New Feature Cards", HideWhatsNewFeatures)
};

/** Reducer for the WhatsNew store slice */
export const FeaturesReducer = slice.MakeCombinedReducer();

/** Show whats new feature list. */
function ShowWhatsNewFeatures(state: FeaturesState): FeaturesState {
    return {
        ...state,
        IsFeatureListDisplayed: true
    }
}

/** Hide whats new feature list. */
function HideWhatsNewFeatures(state: FeaturesState): FeaturesState {
    return {
        ...state,
        IsFeatureListDisplayed: false
    }
}


/** Save new feature list. */
function SaveNewFeatureList(state: FeaturesState, features: FeatureId[]): FeaturesState {
    return {
        ...state,
        NewFeatureList: features
    }
}

/** Save acknowledged feature list. */
function SaveAcknowledgedFeatureList(state: FeaturesState, features: FeatureId[]): FeaturesState {
    return {
        ...state,
        AcknowledgedFeatureList: features
    }
}

/** Mark viewed feature as acknowledged */
function AcknowledgeFeature(state: FeaturesState, featureId: FeatureId): FeaturesState {

    // Not include acknowledged feature in new feature list
    const newFeatureList = state.NewFeatureList.filter(element => element !== featureId);

    return {
        ...state,
        NewFeatureList: newFeatureList,
        AcknowledgedFeatureList: [...state.AcknowledgedFeatureList, featureId]
    }
}