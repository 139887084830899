import React from 'react';
import { Marker, OverlayView } from 'react-google-maps';
import './TaxiMarker.scss';
import CustomOverlayView from "./CustomOverlay";
import { getContentUrl, ContentURL } from '../../modules/Utils/ContentURL';
import { GeoPoint } from '../../modules/Location/Entities';
import { toGoogleLatLng } from './toGoogleLatLng';

interface TaxiMarkerProps {
    carNumber: string | null;
    taxiLocation: GeoPoint;
}

interface TaxiMarkerState {
    displayedGeoPoint: GeoPoint;
    imageDataOrUrl: string;
}

/*
 * Google <Marker> with the Taxi icon, with dynamic image rotation and animated position changes and a custom marker.
 */
export default class TaxiMarker extends React.Component<TaxiMarkerProps, TaxiMarkerState> {  
    private animationDurationMillis: number;
    private animationStartTimestamp: number | null;
    private startGeoPoint: GeoPoint | null;

    // these will be initialised early on, in component did mount
    private Canvas: HTMLCanvasElement = null!;
    private Context: CanvasRenderingContext2D = null!;
    private Image: HTMLImageElement = null!;

    constructor(props: TaxiMarkerProps) {
        super(props);

        this.animationDurationMillis = 100;
        this.animationStartTimestamp = null;
        this.startGeoPoint = null;
        
        this.state = { 
            displayedGeoPoint: props.taxiLocation,
            imageDataOrUrl: getContentUrl(ContentURL.images.Map.Taxi)
        };

        // this.startJourney = this.startJourney.bind(this);
    }

    componentDidMount() { 
        this.Canvas = document.createElement("canvas");
        this.Canvas.width = 55;
        this.Canvas.height = 55;
        this.Context = this.Canvas.getContext("2d")!;
        this.Image = new Image();
        this.Image.crossOrigin = "Anonymous";
        this.Image.src = getContentUrl(ContentURL.images.Map.Taxi);
    }

    componentDidUpdate(prevProps: TaxiMarkerProps) {
        if (this.didGeoPointReallyChange(prevProps)) {
            // if so, commence animation from current position to goal.
            const journeyStartGeoPoint = this.state.displayedGeoPoint || prevProps.taxiLocation;
            this.startJourney(journeyStartGeoPoint);
        }
    }

    // deep check for geoPoint prop change
    didGeoPointReallyChange = (prevProps: TaxiMarkerProps) => {
        if (prevProps.taxiLocation == null) return true;
        if (prevProps.taxiLocation.latitude !== this.props.taxiLocation.latitude) return true;
        if (prevProps.taxiLocation.longitude !== this.props.taxiLocation.longitude) return true;
        return false;
    }

    startJourney = (originalGeoPoint: GeoPoint) => {
        this.startGeoPoint = originalGeoPoint;
        this.animationStartTimestamp = null;
        window.requestAnimationFrame(this.animationCallback);

        // set rotation
        const start = new google.maps.LatLng(originalGeoPoint.latitude, originalGeoPoint.longitude);
        const finish = new google.maps.LatLng(this.props.taxiLocation.latitude, this.props.taxiLocation.longitude);

        const heading = window.google.maps.geometry.spherical.computeHeading(start, finish);
        const data = this.getDataForRotatedTaxi(heading);
        this.setState({ imageDataOrUrl: data });
    }

    getDataForRotatedTaxi = (degrees: number) => {
        const radians = degrees * Math.PI / 180;

        const drawWidth = 29;
        const drawHeight = 45;

        const centerX = drawWidth / 2;
        const centerY = drawHeight / 2;

        this.Context.clearRect(0, 0, this.Canvas.width, this.Canvas.height);
        this.Context.save();

        // the effect of this is to rotate about the center of the image
        this.Context.translate(centerX, centerY);
        this.Context.rotate(radians);
        this.Context.translate(-centerX, -centerY);

        this.Context.drawImage(this.Image, 0, 0);
        this.Context.restore();

        const dataUrl = this.Canvas.toDataURL('image/png');
        return dataUrl;
    }

    animationCallback = (timestamp: number) => {
        if (this.animationStartTimestamp == null) {
            this.animationStartTimestamp = timestamp;
        }

        let progressFraction = (timestamp - this.animationStartTimestamp) / this.animationDurationMillis;

        // finished?
        let isAnimationFinished = false;

        if (progressFraction > 1) {
            progressFraction = 1;
            isAnimationFinished = true;
        }

        // update state
        const oldPositionWeight = 1 - progressFraction;
        const newPositionWeight = progressFraction;

        const nowLat = this.startGeoPoint!.latitude * oldPositionWeight + this.props.taxiLocation.latitude * newPositionWeight;
        const newLng = this.startGeoPoint!.longitude * oldPositionWeight + this.props.taxiLocation.longitude * newPositionWeight;
        const newGeoPoint = { latitude: nowLat, longitude: newLng };

        this.setState({ displayedGeoPoint: newGeoPoint });

        // keep animating!
        if (!isAnimationFinished) {
            window.requestAnimationFrame(this.animationCallback);
        }
    }

    render() {
        const { carNumber, taxiLocation } = this.props;

        const anchor = new google.maps.Point(14, 35);

        const getPixelPositionOffset = (width: number, height: number) => ({
            x: -(width / 2),
            y: -(height * 1.5),
        })

        const vehicleNumber = carNumber || "Taxi";

        return (
            <>
                <Marker position={toGoogleLatLng(taxiLocation)} icon={{ url: this.state.imageDataOrUrl, anchor }} ></Marker>
       
                <CustomOverlayView position={toGoogleLatLng(this.state.displayedGeoPoint)} mapPaneName={OverlayView.OVERLAY_LAYER} getPixelPositionOffset={getPixelPositionOffset} >
                    <div className="taxi-marker">
                        <div className="bubble">
                            <span>{vehicleNumber}</span>
                        </div>
                        <div className="arrow-down" />
                    </div>
                </CustomOverlayView>
            </>
        );
    }
}