import { Dialog2Demo } from "../../modules/Dialog2/Demos/Dialog2Demo";
import { ContentURL } from "../../modules/Utils/ContentURL";

/* The information about a tab: Name and Title. */
export interface TabItem {
    Name: WellKnownTabName,
    Title: string,
    Image: string,
    /** Determines to whom the tab is shown. True if this tab is shown only for the loggedin users. e.g: Trip History */
    NeedLogin?: boolean
}

/** An enum for each Tab in the main nav bar.
 * Many content items display depending on which tab is selected. */
export enum WellKnownTabName {

    /** No view visible. Just for the default state */
    None = "",

    /** Booking Creation with a centered Google Map */
    MapView = "mapView",

    /** Booking Schedule view with a list of cards*/
    ScheduleView = "scheduleView",

    /** Trip history in tabular view */
    TripHistory = "tripHistory",

    Dialog2Demo = "Dialog2Demo",

    /** Features view with a list of cards  */
    FeaturesView = "FeaturesView"
}

export const MapView: TabItem = {
    Title: "Map View",
    Name: WellKnownTabName.MapView,
    Image: ContentURL.images.headerAndNav.MapView
}

export const ScheduleView: TabItem = {
    Title: 'Schedule View',
    Name: WellKnownTabName.ScheduleView,
    Image: ContentURL.images.headerAndNav.ScheduleView
}

export const TripHistoryView: TabItem = {
    Title: 'Trip History',
    Name: WellKnownTabName.TripHistory,
    Image: ContentURL.images.headerAndNav.TripHistory,
    NeedLogin: true
}

export const FeaturesView: TabItem = {
    Title: 'What\'s New',
    Name: WellKnownTabName.FeaturesView,
    Image: ContentURL.images.headerAndNav.FeatureMenu
}

export const EmptyView : TabItem = {
    Title: "",
    Image: "",
    Name: WellKnownTabName.None
}