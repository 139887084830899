import { ContentURL, getContentUrl } from "../../modules/Utils/ContentURL";
import { GetValues } from "../../Config/MyAppConfig";
import { RouteUrls } from "../../RouteUrls";

/** Item displays based on device  */
export enum MenuItemDeviceKind {
    /** Display to desktop only */
    DesktopOnly =  "Desktop only",

    /** Display to mobile only */
    MobileOnly =  "Mobile only",

    /** Display to both */
    Both =  "Display to both"
}

/** Properties of a menu item in the main header menu.  */
export interface HeaderMenuItem {
    /** Display text. */
    Title: string;

    /** Link to open (href value) */
    ToLink: string;

    /** Whether the link is an external URL. */
    IsExternalLink: boolean;

    /** To which devices this item is visible */
    VisibleToDevice: MenuItemDeviceKind;

    /** This is used for mobile only for now */
    Icon?: string;

    /** Display only when user is logged-in */
    DisplayToLoggedInUserOnly?: boolean;
}

const BookATaxi: HeaderMenuItem = {
    Title: "Book a taxi",
    ToLink: "/booking",
    IsExternalLink: false,
    VisibleToDevice: MenuItemDeviceKind.DesktopOnly
};

const ContactUs: HeaderMenuItem = {
    Title: "Contact us",
    ToLink: GetValues().ExternalContactUsUrl ?? RouteUrls.ContactUs,
    IsExternalLink: !!GetValues().ExternalContactUsUrl,
    VisibleToDevice: MenuItemDeviceKind.Both,
    Icon: getContentUrl(ContentURL.images.UserProfile.Contact)
};

/** Menu item to open the wallet page in mobile layout. */
export const MyWallet: HeaderMenuItem = {
    Title: "Payment",
    IsExternalLink: false,
    ToLink: RouteUrls.PaymentWallet,
    DisplayToLoggedInUserOnly: true,
    VisibleToDevice: MenuItemDeviceKind.MobileOnly,
    Icon: getContentUrl(ContentURL.images.PaymentType.WalletIconBlack)
};

const MyProfile: HeaderMenuItem = {
    Title: "My profile",
    IsExternalLink: false,
    DisplayToLoggedInUserOnly: true,
    ToLink: RouteUrls.PersonalDetails,
    VisibleToDevice: MenuItemDeviceKind.MobileOnly,
    Icon: getContentUrl(ContentURL.images.Users.Portait)
};

/** Main menu items (in site header) */
export const MainMenu: HeaderMenuItem[] = [
    BookATaxi,
    ContactUs,
    MyWallet,
    MyProfile
];