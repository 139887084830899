import React from "react";
import { connect } from "react-redux";
import "../Account/AccountBookings.scss";
import { ApplicationState } from "../../../appState";
import { CanBookOnAccount } from "../../Utils/UserProfileValidation";
import { BookOnAccountToggle } from "../Account/BookOnAccountToggle";

interface BookOnAccountToggleWrapperProps {
    CanBookingOnAccount: boolean;
}

/**
 * Wrapper for BookOnAccountToggle for mobile, which is mostly for a better organisation of status and UIs.
 */
class MobileBookOnAccountToggleWrapper extends React.Component<BookOnAccountToggleWrapperProps> {

    render() {

        if (!this.props.CanBookingOnAccount) return null;

        return (
            <div className="mobile-booking-on-account-wrapper">
                <BookOnAccountToggle />
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): BookOnAccountToggleWrapperProps {
    return {
        CanBookingOnAccount: CanBookOnAccount(state.authentication.UserProfile, true)
    };
}

export default connect(mapStateToProps)(MobileBookOnAccountToggleWrapper);
