import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../appState';
import { getContentUrl, ContentURL } from '../Utils/ContentURL';
import { FeatureId, FeatureCardProps } from './FeaturesEntities';
import { LoginStatusKind } from '../Authentication/AuthEntities';
import { TitleStyle, MarkAsRead, SignupLinkHandler, SwitchToQuickBoookTab } from './FeaturesHelper';

interface PropsFromStore {
    LoginStatus: LoginStatusKind;
}

/**
 * Favourites feature card
 */
const FavouritesCardCore = (props: FeatureCardProps & PropsFromStore) => {

    const isGuestUser = props.LoginStatus === LoginStatusKind.LoggedOut;
    
    return (
        <React.Fragment key={FeatureId.Favourites}>
            <div className="feature-card">
                <div className="feature-date">
                    <span className="feature-date-img"></span>
                    <span>04.06.2020</span>
                </div>
                <div style={TitleStyle('#ff1a05')}>BOOK FROM FAVOURITES</div>
                <div className="feature-desc">
                    Create favourites, and quick book using your previous trips. <a href="#" className="feature-link" onClick={SwitchToQuickBoookTab}>Click here</a> to see.
                </div>
                <div className="feature-image">
                    <img src={getContentUrl(ContentURL.images.WhatsNew.favouriteFeature)} alt="Favourites" />
                </div>
                {
                    // Do not show, Signup link to logged-in users
                    isGuestUser &&
                    <div className="feature-desc">Not signed up? No problem. Simply click here <a href="#" className="feature-link" onClick={SignupLinkHandler}>Sign up</a> to access favourites and more.</div>
                }
                {
                    // Hide the button, if the card is acknowledged
                    props.CanShowAcknowledgeButton && 
                    <div className="feature-btn">
                        <button className="btn-OK" onClick={() => MarkAsRead(FeatureId.Favourites)}>OK, GOT IT</button>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

function mapStateToProps(state: ApplicationState): PropsFromStore  {
    return {
        LoginStatus: state.authentication.LoginStatus
    };
}

export default connect(mapStateToProps)(FavouritesCardCore);