import appstore from "../../appStore";
import { Dispatch } from "../Dispatch";
import { Api } from "../../Services/Api";
import { Config } from "../../Config/Config";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { CheckAddPlus } from '../../utils/Formattingutil';
import {  WellKnownMessageKind } from "../Utils/ErrorMessages";
import { LoginStatusKind } from "../Authentication/AuthEntities";
import services from '../../utils/service-vehicles/ServiceMetadata';
import { QuickBookExpandedSection } from "../UILogicControl/UILogicControlEntities";
import { Address, Passenger, ServiceCheckStatus } from '../Booking/BookingEntities';
import { ValidateContactNumberInput, FormatPassengerContactNumber } from "../Utils/ContactNumberHelper";
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { BookingTemplate, BookingWidgetModeKind, BookingTemplateLoadKind } from "./BookingTemplateEntities";
import { ClearAddresses } from '../Booking/NewBookingTabHelper';
import { LogEvent } from '../../utils/LogEvent';
import { ConsiderSatssInvalidation } from "../Payment/PaymentHandler";
import { SimpleUserProfile } from "../User/ProfileEntitiesV2";

/** Get the list of Booking Templates of the logged in user and updates the store. */
export async function GetMyTemplates() {
    
    let templateList : BookingTemplate[] = [];
    let quickBookExpandedSection = QuickBookExpandedSection.RecentBookings;

    // Set loading status before calling the API
    Dispatch.UILogicControl.OnBookingTemplateLoadingStatusChange(BookingTemplateLoadKind.Loading);
    
    const result = await Api.BookingTemplate.GetMyTemplates();

    if (result.isSuccess) {
            
        templateList = result.value;
            
        if (templateList.length > 0) {                     
            quickBookExpandedSection = QuickBookExpandedSection.Templates;
        }     
    };
    
    Dispatch.BookingTemplate.LoadTemplates(templateList);

    Dispatch.UILogicControl.ToggleQuickBookSection(quickBookExpandedSection);
    
    // Update loading status, inorder to hide the spinner animation
    Dispatch.UILogicControl.OnBookingTemplateLoadingStatusChange(BookingTemplateLoadKind.Loaded);        
}

/** Save booking template */
export async function CreateBookingTemplate() {
    
    const { booking, condition, authentication  } = appstore.getState();

    const { UserProfile } = authentication;

    const { pickup, dropoff, TemplateName, NotesToDriver, Passenger, AccountData, PaymentOption } = booking;
    
    // Do not proceed if, the template name is not provided
    if (!TemplateName) return;

    // Do not proceed if, the contact number is invalid
    if (!ValidateContactNumberInput()) return;    
   
    Dispatch.Verification.ShowLoaderInContactDetails();

    // Populate pickup address
    const pickupAddress = PopulateLocationDetails(pickup, Passenger, 0);

    // Populate dropoff address
    const dropoffAddress = PopulateLocationDetails(dropoff, Passenger, 1);

    // Add pickup and dropoff address
    let locationDetails = [pickupAddress, dropoffAddress];

    const templateDetails : BookingTemplate   = {
        BookingTemplateId: 0,
        Name: TemplateName,
        Locations: locationDetails,
        DriverNotes: NotesToDriver && NotesToDriver.Notes,
    }

    // Populate account details
    if (AccountData) {
        templateDetails.AccountReferenceText = AccountData.OrderNumber;
        templateDetails.AccountNumber = AccountData.SelectedAccount && AccountData.SelectedAccount.AccountNumber;
    }

    // Populate ride details
    if (condition.SelectedCondition.ApiVehicle) {
        templateDetails.Conditions = condition.SelectedCondition.ApiVehicle.ApiId;
    }
    
    // Populate company details
    if (UserProfile && !!UserProfile.CompanyId && UserProfile.CompanyId) {
        templateDetails.CompanyId = UserProfile.CompanyId;
    }

    // Populate card details
    if (PaymentOption?.Card?.CardId) {
        templateDetails.PaymentCardId = PaymentOption.Card.CardId;
    }
    
    const result = await Api.BookingTemplate.CreateMyTemplate(templateDetails);

    Dispatch.Verification.HideLoaderInContactDetails();

    // Update the list of booking templates
    if (result.isSuccess) 
    {      
        templateDetails.BookingTemplateId = result.value;
        LogEvent.BookingTemplateSaved(templateDetails);

        // Reset the booking form and load templates on Quickbook tab
        ResetBookingForm(BookingWidgetModeKind.Booking);

        // Fetch recent templates
        GetMyTemplates();               
    }
    else { 
        // Show error message, incase of failure
        ShowDialogSimpleErrorMessage(WellKnownMessageKind.CreateTemplateFailure); 
    }        
}

/** Populate the location list with pickup and dropoff address if exists. */
function PopulateLocationDetails(address: Address | undefined, passenger: Passenger, sequenceNo: number) {
    
    let placeId, placeText, contactName;

    // Populate contact name
    if (passenger && passenger.ContactName) {
        contactName = passenger.ContactName;
    }    

    // Populate contact number
    const contactNumber = FormatPassengerContactNumber(passenger.PassengerContactNumber);

    // Populate pickup/dropoff address
    if (address) {
        placeId = address.PlaceId;
        placeText = address.PlaceText;
    }    
        
    let templateAddress = {
        BookingTemplateId: 0,
        SequenceNumber: sequenceNo,       
        DisplayText: placeText,
        GoogleMapsPlaceId: placeId,
        ContactPhone: contactNumber,
        ContactName: contactName          
    }
    
    return templateAddress;
}

function ClearBookingPayload() {

    // Clear the template name
    Dispatch.Booking.SetTemplateName("");

    // Clear the pickup and dropoff address
    ClearAddresses();

    // Clear the account details
    Dispatch.Booking.ClearAccountDetails();

    // Clear the Passenger name and contact number
    const emptyPassengerDetails: Passenger = {
        ContactName: "",
        PassengerContactNumber: {
            Contactnumber: "",
            CountryInfo: Config.DefaultCountryInfo,
        }
    };

    Dispatch.Booking.UpdatePassengerDetails(emptyPassengerDetails);

    // Clear the driver notes
    Dispatch.Booking.AddNotesToDriver({ Notes: "" });

    // By default, the Next Available taxi is selected
    Dispatch.Condition.SelectedCondition({ Service: services.any });

    // Clear the error message for vehicle selection
    Dispatch.UILogicControl.ValidateVehicleOnPickupChange(true);

    // If service error for SATSS, remove the error.
    Dispatch.UILogicControl.ClearSatssError();
}

export function ResetBookingForm(templateMode: BookingWidgetModeKind) {

    // Reset the booking form
    ClearBookingPayload();

    Dispatch.UILogicControl.OnIsStrictValidationModeOnBookingFormChange(false);
    Dispatch.UILogicControl.OnBookingWidgetModeChange(templateMode);

    ConsiderSatssInvalidation();
    Dispatch.Condition.ClearPriceGuarantee();
    Dispatch.Condition.ClearFareEstimate();
    
    // Clear error messages
    Dispatch.Payment.SetError(null);
    Dispatch.UILogicControl.SetPickupValidity(true);
    Dispatch.UILogicControl.SetDropoffValidity(true);
    Dispatch.Verification.ContactNumberErrorMessage("");
}

/** Validate the booking template input before saving */
export function ValidateBookingTemplateInput() {
        
    const { booking, uiLogicControl, verification } = appstore.getState();

    const { pickup, dropoff, PickupServiceCheck, TemplateName } = booking;

    const { BookingForm, AddressStatus } = uiLogicControl;

    const { Pickup, Dropoff  } = AddressStatus;

    // Validate booking template name
    if (!TemplateName) return false;

    // Validate pickup address
    if (pickup && PickupServiceCheck.status !== ServiceCheckStatus.KnownGood) return false;

    // Validate the selected vehicle
    if (!BookingForm.IsSelectedVehicleServiceable) return false;
    
    // Invalid pickup adddress if input is entered but, no address is selected
    if (Pickup.DoesInputHaveValue && !pickup) return false;

    // Invalid dropoff address if input is entered but, no address is selected
    if (Dropoff.DoesInputHaveValue && !dropoff) return false;
    
    // Contact number has no error 
    if (verification.UserContactNumberInfo.ErrorMessage) return false;

    return true;
}

/**
 * Show booking templates when,
 * 1. Switch is ON
 * 2. User is logged-in
 * 3. Not a DVA user
 */
export function AreTemplatesAvailable() {

    const { LoginStatus, UserProfile } = appstore.getState().authentication;

    // Switch must be ON
    if (!FeatureFlags.BookingTemplates) return false;

    // User must be logged-in
    if (LoginStatus === LoginStatusKind.LoggedOut) return false;

    // Should not be DVA user
    if (IsDVAUser(UserProfile)) return false;
            
    return true;
}

/** Determine if the logged-in user is a DVA. */
export function IsDVAUser(userProfile: SimpleUserProfile | undefined) {

    // Retrieve all accounts, linked to the user's profile
    const accounts = userProfile && userProfile.Accounts;

    // Check if atleast one account is a DVA account, inorder to determine DVA user
    const isDVAUser = accounts && accounts.some(x => x.IsDVA === true);
        
    if (isDVAUser) return true;

    return false;
}

/**
 * Returns the css class name inorder to expand/collapse the list otherwise,
 * Returns an empty string, when the list can't be toggled
 */
export function GetToggleRecentTripListCssClass(recentTripsListState: QuickBookExpandedSection) {
    
    const { myBookings} = appstore.getState();

    const { RecentTrips } = myBookings;

    // Validate when templates are displayed
    if (!AreTemplatesAvailable()) return "";

    // When the list is empty and expanded, it's not collapsible
    if (RecentTrips.length === 0 && recentTripsListState === QuickBookExpandedSection.RecentBookings) return "";

    // Collapse the list
    if (recentTripsListState !== QuickBookExpandedSection.RecentBookings) return "arrow-expand arrow-collapse";

    // Expand the list
    if (recentTripsListState === QuickBookExpandedSection.RecentBookings) return "arrow-expand";

    return "";
}
