import { GetValues } from "../../Config/MyAppConfig";
import { TryAgainButtonOnClickActionKind } from "../Dialog/DialogEntities";
import { BrandedImage, GetBrandedUrl } from "./BrandedContentUrls";

/* Custom error messages for different types of errors.  */
export const CustomErrorMessages = {
    InvalidPhoneNumber: "Please check the number entered and retry",
    IncorrectCode: "Incorrect code entered. Please try again",
    PastDateTime: "Please ensure the date and time set is for the future",
    TooFarFutureDateTime: "Please select a date that’s no more than 7 days from now",
    NoServiceAvailable: "Sorry, we don't offer cabs in this area",
    StreetNameUnavailable: "We need more details on your pick up location",
    NoPickup: "A valid pickup address is required",
    NoDropoff: "A valid destination address is required",
    InvalidAddress: "Valid pickup and destination addresses are required",
    InvalidAccountNumber: "This account is not set up to be used in this region, please contact one of our sales representatives to assist.",
    CredentialContactNumberErrorMessage: "Please use a mobile or Australian landline number.",
    AccountValidationErrorMessage: "Unable to populate all account information",
    ServiceNotAvailableForSatss: "This service is not available for SATSS.",
    ServiceNotAvailable: "This service is not available for this location",
    PreAuthFailed: "The payment method could not be verified"
}

/* Descriptive error messages. These messages include Title, Image(image name) and MessageText. E.g. User enters incorrect mobile number 3 times. */
export const DescriptiveErrorMessages = {
    InvalidPhone: {
        Title: "Something's not right",
        ImageUrl: GetBrandedUrl(BrandedImage.ThinkingGirl),
        MessageText: `Please check that your mobile number's been entered correctly, or give us a call on <span style='font-weight: bold;'>${GetValues().BookingContactNumber}</span> to book a cab.`,
        ButtonText: "Try again",
        ImageWidth: "65"
    },    
    ResendCode: {
        Title: "Speak to a real human",
        ImageUrl: GetBrandedUrl(BrandedImage.WomanShrugging),
        MessageText: `Sorry, we can't verify your mobile number. But you can still book a cab by calling <span style='font-weight: bold;'>${GetValues().BookingContactNumber}</span>.`,
        ButtonText: "Try again",
        ImageWidth: "150"
    },
    CreateBookingFailed: {
        Title: "Speak to a real human",
        ImageUrl: GetBrandedUrl(BrandedImage.WomanShrugging),
        MessageText: `Sorry, something's not right. You can try again or simply call us on <span style='font-weight: bold;'>${GetValues().BookingContactNumber}</span> and we'll book a cab for you.`,
        ButtonText: "Try again",
        ImageWidth: "150"
    },
    CreateBookingFailedWithoutRetry: {
        Title: "Speak to a real human",
        ImageUrl: GetBrandedUrl(BrandedImage.WomanShrugging),
        MessageText: `Sorry, something's not right. You can try again or simply call us on <span style='font-weight: bold;'>${GetValues().BookingContactNumber}</span> and we'll book a cab for you.`,
        ButtonText: "OK",
        ImageWidth: "150"
    },
    CreateBookingTimeout: {
        Title: "Something's not right",
        ImageUrl: GetBrandedUrl(BrandedImage.ThinkingGirl),
        MessageText: `No problem, simply call us on <span style='font-weight: bold;'>${GetValues().BookingContactNumber}</span> to confirm your booking was made.`,
        ButtonText: "OK",
        ImageWidth: "61"
    },
    ContactFormSendFailed: {
        Title: "Something's not right",
        ImageUrl: GetBrandedUrl(BrandedImage.WomanShrugging),
        MessageText: `Sorry, something's not right. You can try again or simply call us on <span style='font-weight: bold;'>${GetValues().BookingContactNumber}</span>.`,
        ButtonText: "OK",
        ImageWidth: "150"
    }
}

/**
 * Simple error messages with on problem & solution
 * The popup which delivers this error message will only has OK button
 */
export const WellKnownMessageKind = {
    LongTrip: {
        ProblemText: 'That would be a long trip!',
        SolutionText: 'Just checking this is your intended destination?'
    },
    NoServiceAvailable: {
        ProblemText: 'Sorry, we don’t offer cabs in this area',
        SolutionText: 'Please contact the local cab company'
    },
    StreetNameUnavailable: {
        ProblemText: 'We need more details on your pick up location',
        SolutionText: 'Please enter the full pickup address and try again'
    },
    /**
     * TBC
     */
    GeneralFailure: {
        ProblemText: 'Uh Oh!',
        SolutionText: `Something’s not right. You can try again or give us a call now on <span style='font-weight: bold;'>${GetValues().BookingContactNumber}</span> to book a cab.`
    },
    BookingStrictValidation: {
        ProblemText: 'Whoops',
        SolutionText: 'Please check you’ve entered all required information for your booking to proceed'
    },
    NoPickupForCondition: {
        ProblemText: 'Please enter your pick up address first',
        SolutionText: 'We use this to determine the vehicles available in your area'
    },
    Auth0RenewFailure: {
        ProblemText: 'Uh Oh!',
        SolutionText: `Your login session expired. You can log in again or give us a call now on <span style='font-weight: bold;'>${GetValues().BookingContactNumber}</span> to book a cab.`
    },
    TryAgain: {
        ProblemText: 'Oops!',
        SolutionText: `Please try again or call us on <span style='font-weight: bold;'>${GetValues().BookingContactNumber}</span>`
    },
    ContactFormRequiredFields: {
        ProblemText: "Hold up!",
        SolutionText: "Please complete all the fields marked as required before submitting your enquiry."
    },
    InvalidCategory: {
        ProblemText: "Invalid Selected Category",
        SolutionText: "Please select all categories"
    },
    InvalidEmail: {
        ProblemText: "Invalid Email",
        SolutionText: "Please provide a valid email address"
    },
    InvalidPhone: {
        ProblemText: "Invalid Phone number",
        SolutionText: "Please provide a valid phone number"
    },
    LoginFailure: {
        ProblemText: 'Uh Oh!',
        SolutionText: `Something’s not right. You can try again or give us a call now on <span style='font-weight: bold;'>${GetValues().BookingContactNumber}</span> to book a cab.`
    },
    CreateTemplateFailure: {
        ProblemText: 'Uh Oh!',
        SolutionText: "Something’s not right. Please try again."
    },
    InserviceableVehicle: {
        ProblemText: 'Uh Oh!',
        SolutionText: "This service is not available for this location"
    },
    AccountValidationFailure: {
        ProblemText: 'Uh Oh!',
        SolutionText: CustomErrorMessages.AccountValidationErrorMessage
    },
    GpsNotSupported: {
        ProblemText: 'Sorry we can’t find your location',
        SolutionText: "Please enter pick-up address manually"
    },
    GpsTimeout: {
        ProblemText: 'We can’t find you',
        SolutionText: "Please turn on your location services in your privacy settings"
    },
    TripEnds: {
        ProblemText: 'Uh Oh!',
        SolutionText: "Your trip is completed or cancelled"
    },
    InvalidBookingLink: {
        ProblemText: 'Uh Oh!',
        SolutionText: "This booking link isn't formatted correctly."
    },
    LoadVehiclesFailed: {
        ProblemText: "Oops something went wrong",
        SolutionText: "Please try again"
    },
    PaymentPreAuthFailed: {
        ProblemText: "Payment authorisation has failed",
        SolutionText: "Please use a different card or contact your bank."
    }
}

/**
 * Retry error messages with on <problem & solution> object and action indicator
 * The popup which delivers this error message will only has 'Try again' button
 */
export const TryAgainMessageKind = {
    Auth0LoginTryAgain: {
        WellKnownErrorMessage: WellKnownMessageKind.TryAgain,
        OnClickAction: TryAgainButtonOnClickActionKind.Auth0LogIn
    },
    TncTryAgain: {
        WellKnownErrorMessage: WellKnownMessageKind.TryAgain,
        OnClickAction: TryAgainButtonOnClickActionKind.RefreshTncPage
    },
    PrivacyTryAgain: {
        WellKnownErrorMessage: WellKnownMessageKind.TryAgain,
        OnClickAction: TryAgainButtonOnClickActionKind.RefreshPrivacyPage
    },
    RetryLoadVehicles: {
        WellKnownErrorMessage: WellKnownMessageKind.LoadVehiclesFailed,
        OnClickAction: TryAgainButtonOnClickActionKind.ReloadVehicles
    }
}