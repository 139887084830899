import React from "react";
import "./MyBookingsListMobileWrapper.scss";
import ListOfBookingCards  from "../../MyBookings/UI/ListOfBookingCards";
import { RefreshTimer } from "../../MyBookings/RefreshTimer";
import TrackVisibility from 'react-on-screen';
import { TrackVisibilityBlock } from "./TrackVisibilityBlock";

/**
 * Wrapper component of my bookings for mobile device.
 * Also, it is embeded with components, such as RefreshTimer, to refresh bookings.
 */
export const MyBookingsListMobileWrapper: React.FC = () => {
    return (
        <>
            <RefreshTimer />
            <div className="mobile-active-booking-panel">
                <TrackVisibility partialVisibility>
                    <TrackVisibilityBlock/>
                </TrackVisibility>

                <ListOfBookingCards />
            </div>
        </>
    );
}
