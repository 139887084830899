import appstore from "../../appStore";
import { ApiVehicleOption, DataLoadingStatus } from "./Redux/ConditionEntities";
import { ServiceCheckStatus } from "../Booking/BookingEntities";
import { ParseVehicleConditionHelper } from "./ConditionHelper";
import { ComputeAsyncLoadState } from "./ComputeAsyncLoadStats";
import { ConsiderFareUpdate } from "./FareLoader";
import { Api } from "../../Services/Api";
import { Dispatch } from "../Dispatch";
import { AsyncUpdateOutcome } from "./AsyncUpdateOutcome";
import { Condition } from "../../Services/BookingEntities";

/** Caller signals that the pickup address has changed. Trigger a load of the condition data if necessary. */
export async function ConsiderConditionUpdate(): Promise<AsyncUpdateOutcome>
{
    // input: pickup address + suburb ID
    const appState = appstore.getState();
    const pickup = appState.booking.pickup;
    if (!pickup) return AsyncUpdateOutcome.InputsNotReady;

    // prerequisite: location known serviceable
    const cabService = appState.booking.PickupServiceCheck;
    if (cabService.status !== ServiceCheckStatus.KnownGood) return AsyncUpdateOutcome.InputsNotReady;

    // existing loading status
    const suburbIdToCheck = cabService.suburbId!;
    const currentStatus = appState.condition.LoadingStatus;

    if (currentStatus.LastInput && (currentStatus.LastInput === suburbIdToCheck) && (currentStatus.Status !== DataLoadingStatus.Error)) {
        // this is a duplicate request; not needed
        return AsyncUpdateOutcome.InputsUnchanged;
    }

    // OK!
    return await PerformConditionLoad(suburbIdToCheck);
}

/** Perform the Conditions data load, including setting the state to in progress and then complete / error. */
async function PerformConditionLoad(suburbId: number): Promise<AsyncUpdateOutcome> {

    Dispatch.Condition.ConditionDataLoadStatus({ Status: DataLoadingStatus.InProgress, LastInput: suburbId });

    const result = await Api.Booking.GetCarsBySuburbId(suburbId);

    const allConditionList = await Api.Booking.GetAllConditionsBySuburbId(suburbId);

    if (allConditionList.isSuccess) {
        const vehicleOptions = allConditionList.value.map(MakeVehicleOption);
        Dispatch.Condition.LoadAllRawV1Data(vehicleOptions);
    }
    
    // check for input drift (i.e. pickup changed to a new suburb), which would make the result no longer meaningful
    const pickupNow = appstore.getState().booking.PickupServiceCheck;

    if ((pickupNow.status !== ServiceCheckStatus.KnownGood) || (pickupNow.suburbId !== suburbId)) {
        return AsyncUpdateOutcome.InputChangedDuringLoad;
    }

    // push state changes
    const newState = ComputeAsyncLoadState(result, suburbId);
    
    if (result.isSuccess) {

        const vehicleOptions = result.value.map(MakeVehicleOption);
        const parsedLookupTables = ParseVehicleConditionHelper(vehicleOptions);
        Dispatch.Condition.RefreshConditions(parsedLookupTables);
        Dispatch.Condition.ConditionDataLoadStatus(newState);

        // TODO: ideally i would return the Fare's status as well as this one. But currently it would make the return value too awkward.
        await ConsiderFareUpdate();
        return AsyncUpdateOutcome.Success;
    } else {
        Dispatch.Condition.ConditionDataLoadStatus(newState);
        appInsights.trackEvent("Vehicle selection failure");
        return AsyncUpdateOutcome.LoadFailed;
    }
}

/**
 * Converts the V1 API format, Condition, into the standard internal format, ApiVehicleOption.
 */
function MakeVehicleOption(condition: Condition): ApiVehicleOption {
    return {
        ApiId: condition.ID,
        ApiVersion: 1,
        Description: condition.Description,
        MaxSeat: condition.MaxSeat,
        Name: condition.Name,
    };
}