
import { createStore, Store, Action, Reducer,applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/index'
import { composeWithDevTools } from 'redux-devtools-extension';
import { ApplicationState } from './appState';

const intitialState={};
const middleware = [thunk];
const appstore = createStore(rootReducer, intitialState, composeWithDevTools(applyMiddleware(...middleware))) as Store<ApplicationState>;

export default appstore;
