import * as LookupServices from "../../utils/service-vehicles/LookupServices";
import { VehicleOption, ConditionLookupTables, MaxiTaxiLookUpType, ApiVehicleOption, ConditionIds } from './Redux/ConditionEntities';
import { ServiceInfo } from "../../utils/service-vehicles/ServiceMetadata";
import services from '../../utils/service-vehicles/ServiceMetadata';
import { FeatureFlags } from "../../Config/FeatureFlags";
import appstore from "../../appStore";
import { Config } from "../../Config/Config";

export function MakeVehicleFromService(service: ServiceInfo, wheelChairCondition?: ApiVehicleOption): VehicleOption {
    return !!wheelChairCondition ? { Service: service, ApiVehicle : wheelChairCondition } : { Service: service, RequiresPaxCount: !FeatureFlags.BookingApiV2 && service.isMaxiTaxi };
}

export function ParseVehicleConditionHelper(conditions: ApiVehicleOption[] ) : ConditionLookupTables {
    var ConditionList: VehicleOption[] = [], MaxiTaxiLookUp: MaxiTaxiLookUpType = { };
    var hasWheelChair: boolean = false, hasMaxiTaxi: boolean = false, wheelChairPlaceHolder: any = { "MaxSeat" : 0 };
    const nextAvailable = conditions.some(v => /limo/i.test(v['Name'])) ? services.anySA : services.any;
    ConditionList.push(MakeVehicleFromService(nextAvailable));     

    for (var condition of conditions) {
        const metaInfo = LookupServices.byConditionDisplayName(condition.Name);
        if (metaInfo === undefined) { continue; }

        if (metaInfo === services.wheelchair) {
            // Allow wheelchair bookings only if the maximum number of seats is less than or equal to the configured value.
            if (condition.MaxSeat >= wheelChairPlaceHolder.MaxSeat && condition.MaxSeat <= Config.WheelchairMaxSeat) {
                hasWheelChair = true;
                wheelChairPlaceHolder = condition;
            }
        }
        else if (metaInfo === services.maxi) {
            hasMaxiTaxi = true;
            MaxiTaxiLookUp[condition.MaxSeat] = condition;
        }
        else {
            ConditionList.push({ Service: metaInfo, ApiVehicle : condition }); 
        }
    }

    /** Add wheelchair & MAXI TAXI*/
    if (hasWheelChair === true) { 
        ConditionList.push(MakeVehicleFromService(services.wheelchair, wheelChairPlaceHolder)); 
    }
    if (hasMaxiTaxi === true) { 
        ConditionList.push(MakeVehicleFromService(services.maxi)); 
    }
    
    /** Add Parcel condition */
    if (FeatureFlags.AllowParcelBooking) {
        
        const { AllRawV1Data } = appstore.getState().condition;

        const parcelPlaceholder = AllRawV1Data.find(x => x.ApiId === ConditionIds.parcel);
                
        if (parcelPlaceholder) ConditionList.push(MakeVehicleFromService(services.parcel, parcelPlaceholder));
    }

    return {ConditionList: ConditionList, MaxiTaxiLookUp: MaxiTaxiLookUp};
};

/**
 * @param selectedCondition Previously selected condition, default value is any for hard refresh
 * @param conditionsNew New condition list based on pick-up address
 * 
 * The purpose of this function is to find the index of vehicle in the new condition list, if any of them matches the previously selected condition.
 * 
 * Return 0 for below scenarios, and 0 means the store.comdition.SelectedCondition will be set to "Next Available":
 * 1> Previously selected condition is any;
 * 2> Current/old condition list is empty;
 * 3> No match found.
 */
export function FindVehicleIndexInList(selectedCondition: VehicleOption, conditionsNew: VehicleOption[]) : number {
    var selectedConditionIndex: number = 0, index = 0; 

    for (var condition of conditionsNew) { 
        if(condition.Service.displayName === selectedCondition.Service.displayName) { selectedConditionIndex = index; }
        index++;
    }
    return selectedConditionIndex;
};
