import React, { Fragment } from "react";
import { connect } from 'react-redux';
import BookingDateTime from "./BookingDateTime";
import { ApplicationState } from "../../appState";
import { BookingWidgetModeKind } from '../BookingTemplate/BookingTemplateEntities';
import BookingAddressGroup from './Widget/BookingAddressGroup';
import BookingConditionManager from "../Condition/UI/BookingConditionManager";

interface BookingDetailsPropsFromStore {
    BookingWidgetMode: BookingWidgetModeKind
}

class BookingDetails extends React.Component<BookingDetailsPropsFromStore> {    
    
    render() {
        return(
            <Fragment>
                <BookingAddressGroup />
                {this.props.BookingWidgetMode === BookingWidgetModeKind.Booking  && <BookingDateTime />}
                <BookingConditionManager />
            </Fragment>    
        )
    }
}

function mapStateToProps(state: ApplicationState) : BookingDetailsPropsFromStore {
    return {
        BookingWidgetMode: state.uiLogicControl.BookingForm.BookingWidgetMode
    }
}

export default connect(mapStateToProps)(BookingDetails);