import React from 'react';
import { TitleStyle, MarkAsRead, SignupLinkHandler } from './FeaturesHelper';
import { getContentUrl, ContentURL } from '../Utils/ContentURL';
import { FeatureId, FeatureCardProps } from './FeaturesEntities';
import { GetValues } from '../../Config/MyAppConfig';

export const DriverProfile = (props: FeatureCardProps) => {
    return (
        <React.Fragment key={FeatureId.DriverProfile}>
            <div className="feature-card">
                <div className="feature-date">
                    <span className="feature-date-img"></span>
                    <span>21.09.2020</span>
                </div>
                <div style={TitleStyle(GetValues().BrandColour)} >DRIVER PROFILE PICTURES</div>
                <div className="feature-desc">When your cab's on the way, put a face to your Driver's name.</div>
                <div className="feature-image">
                    <img src={getContentUrl(ContentURL.images.WhatsNew.DriverProfile)} alt="Driver Profile" />
                </div>
                <div className="feature-desc"><span>Not signed up?</span> No problem. <a href="#" className="feature-link" onClick={SignupLinkHandler}>Tap here</a> to access favourites, payments and more.</div>
                {
                    // Hide the button, if the card is acknowledged
                    props.CanShowAcknowledgeButton &&
                    <div className="feature-btn">
                        <button className="btn-OK" onClick={() => MarkAsRead(FeatureId.DriverProfile)}>OK, GOT IT</button>
                    </div>
                }
            </div>
        </React.Fragment>
    );
}