import React from "react";
import "./NotesToDriver.scss";
import $ from "jquery";
import { connect } from "react-redux";
import { ContentURL, getContentUrl } from "../../Utils/ContentURL";
import { Dispatch } from "../../Dispatch";
import { ApplicationState } from "../../../appState";
import { Config } from "../../../Config/Config";
import { TextField } from "@material-ui/core";
import { LogEvent } from "../../../utils/LogEvent";

const MaxLength = Config.DriverNotesMaxLength;

interface PropsFromStore {
    DriverNote: string;
}

interface NotesToDriverState {
    isFocus: boolean;
    NotesLength: number;
}

class NotesToDriver extends React.Component<PropsFromStore, NotesToDriverState> {
    private inputRef: React.RefObject<HTMLTextAreaElement>;

    constructor(props: PropsFromStore) {
        super(props);
        this.state = {
            isFocus: false,
            NotesLength: MaxLength
        };
        this.inputRef = React.createRef();
        this.CalculateCharactersLength = this.CalculateCharactersLength.bind(this);
    }

    componentDidMount() {
        const { DriverNote } = this.props;

        this.inputRef.current!.value = DriverNote;

        // Display the remaining character length when populating data from a different source (e.g: Favourites or book from history).
        this.CalculateCharactersLength();
    }

    UNSAFE_componentWillReceiveProps(nextProps: PropsFromStore) {
        const { DriverNote } = nextProps;

        if (this.inputRef.current!.value !== DriverNote) {
            this.inputRef.current!.value = DriverNote;

            this.CalculateCharactersLength();
        }
    }

    /** Condition check and dynamic css changes */
    CalculateCharactersLength() {
        var currentLength = this.inputRef.current!.value.length;

        if (currentLength >= MaxLength - 30) {
            $(".custom-label-notes").css("color", "red");
            $(".custom-img-layout-notes").css("display", "block");
        } else {
            $(".custom-label-notes").css("color", "black");
            $(".custom-img-layout-notes").css("display", "none");
        }
        var noteslength = MaxLength - currentLength;
        this.setState({ NotesLength: noteslength });
    }

    /** Call Action Creator  */
    CallStoreToUpdate(e: any) {
        Dispatch.Booking.AddNotesToDriver({ Notes: e.target.value });
        this.UpdateFocusStatus(false);
        if (e.target.value) {
            LogEvent.OnAddingDriverNotes();
        }
    }

    /** Set focus status for driver notes */
    UpdateFocusStatus = (isFocus: boolean) => this.setState({ isFocus: isFocus });

    render() {
        
        const canShrinkLabel = this.state.isFocus || !!this.props.DriverNote;
        
        return (
                <div className="booking-fields-panel drivernotes-panel">
                    <TextField
                        rowsMax={3}                    
                        multiline={true}
                        fullWidth={true}
                        variant="outlined"
                        inputRef={this.inputRef}
                        label="Notes For Driver"
                        className="simple-textfield"
                        id="outlined-multiline-static"
                        inputProps={{maxLength: MaxLength}}
                        onInput={this.CalculateCharactersLength}
                        InputLabelProps={{shrink: canShrinkLabel}}                
                        onBlur={(e1) => this.CallStoreToUpdate(e1)}                        
                        onFocus={() => this.UpdateFocusStatus(true)}
                        placeholder="e.g. unit, gate and floor numbers."
                    />
                    <div className="character-calculation-div">           
                        <img
                            alt="warning"
                            src={getContentUrl(ContentURL.images.Diagnostic.warning)}
                            className="custom-img-layout-notes"
                        />
                        <label className="input-label custom-label-notes">{this.state.NotesLength} characters left</label>
                    </div>
                </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState): PropsFromStore => {
    return {
        DriverNote: state.booking.NotesToDriver
            ? state.booking.NotesToDriver.Notes!
            : "",
    };
};

export default connect(mapStateToProps)(NotesToDriver);
