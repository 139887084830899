import { Dispatch } from "../Dispatch";
import { ConvertGeoCoderResultToPlaceResult, ApplyPickupLocationSelection } from '../Booking/BookingLocationHelper';
import { GeoPoint } from "../Location/Entities";
import { LogEvent } from '../../utils/LogEvent';
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { WellKnownMessageKind } from "../Utils/ErrorMessages";

/**
 * Successful callback function of window.navigator.geolocation.getCurrentPosition().
 */
export function GetGpsSuccessHandler(position: Position): void {
    LogEvent.GetGpsLocationSuccess();
    
    GeoCodingHandler(position.coords.latitude, position.coords.longitude);
}

/**
 * Failure callback function of window.navigator.geolocation.getCurrentPosition().
 */
export function GetGpsFailureHandler(error: PositionError): void {
    LogEvent.GetGpsLocationFailure(JSON.stringify(error));
    
    ShowDialogSimpleErrorMessage(WellKnownMessageKind.GeneralFailure);
}

/**
 * 1. Parse latitude & longitude to Google address result via google.maps.Geocode;
 * 2. Get PlaceResult by placeId;
 * 3. Call ApplyPickupLocationSelection() to let pickup and google be aware of this.
 */
async function GeoCodingHandler(latitude: number, longitude: number) {

    const request: google.maps.GeocoderRequest = {'location' : { lat: latitude, lng: longitude }};
    
    new google.maps.Geocoder().geocode(request, async (result: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {

        if (status === window.google.maps.GeocoderStatus.OK && result) {

            const location : GeoPoint = { latitude: latitude, longitude: longitude };
            
            const placeResult = await ConvertGeoCoderResultToPlaceResult(result[0], location);

            if (!placeResult) return;

            Dispatch.UILogicControl.SetPickupValidity(true);

            ApplyPickupLocationSelection(placeResult);

            Dispatch.UILogicControl.OnDoesPickupInputHaveValueChange(true);
        } 
        else {
            LogEvent.GoogleGeoCoderFailure();
            ShowDialogSimpleErrorMessage(WellKnownMessageKind.GeneralFailure);
        }
    });
}