import { BookingWithEta } from "./BookingWithEta";
import { ApiGeoPoint, BookingStatus } from "../../../Services/BookingEntities";
import { EtaKind, ExtendedEta } from "../MyBookingEntities";
import { GeoPoint } from "../../Location/Entities";
import { loadGoogleDirections } from "../../../widgets/google-maps/loadGoogleDirections";
import moment from "moment";
import { OptimismMultiplier } from "./Parameters";
import { Dispatch } from "../../Dispatch";

/** Compute the ETA of the specified booking using Google Directions API.
 *  Then push the data into Redux.
 *  In this function, the booking record is assumed valid, e.g. vehicle location known, etc. 
 *  This will have been checked previously by e.g. DoesEtaNeedRefresh.
 *  As a result, there will be a few non-null type assertions in the code. 
 *  Returns the newly loaded ETA, if it is successful.
 * */
export async function PerformEtaRefresh(booking: BookingWithEta): Promise<ExtendedEta | null> {

    // either dropoff or pickup
    let targetLocation: ApiGeoPoint; 
    let etaKind: EtaKind;

    const vehicleLocation = booking.Booking.TrackingInfo.VehicleLocation!;

    if (booking.Booking.TrackingInfo.BookingStatusID == BookingStatus.Accepted) {
        targetLocation = booking.Booking.PickupLocation;
        etaKind = EtaKind.ToPickup;
    }
    else {
        targetLocation = booking.Booking.DropoffLocation!;
        etaKind = EtaKind.ToDropoff;
    }

    const directions = await GetGoogleDirections(vehicleLocation, targetLocation);
    if (!directions) return null; // no further action possible

    // assume only one route and one leg, based on simple request
    const leg = directions.routes[0].legs[0];

    const TimeOfCalculation = moment();
    const BestGuessEtaSeconds = leg.duration.value;
    const OptimisticEtaSeconds = BestGuessEtaSeconds * OptimismMultiplier;
    const EtaDisplayText = ComputeEtaDisplayText(OptimisticEtaSeconds);

    const eta: ExtendedEta = {
        BookingId: booking.Booking.BookingID,
        BestGuessEtaSeconds,
        EtaDisplayText,
        Kind: etaKind,
        OptimisticEtaSeconds,
        TimeOfCalculation,
        VehicleLocation: vehicleLocation,
    };

    Dispatch.MyBookings.UpdateEta(eta);
    return eta;
}

/** Wraps the loadGoogleDirections() call with entity translation.
 * Returns null if the Google Maps API call fails. */
async function GetGoogleDirections(start: ApiGeoPoint, finish: ApiGeoPoint): Promise<google.maps.DirectionsResult | null> {

    // translate to GeoPoint
    const googleStart: GeoPoint = {
        latitude: start.Latitude,
        longitude: start.Longitude,
    };

    const googleFinish: GeoPoint = {
        latitude: finish.Latitude,
        longitude: finish.Longitude,
    };

    const result = await loadGoogleDirections(googleStart, googleFinish, true);
    if (!result) return null;

    return result.directions;
}

/** Human readable display text for ETA.
 *  Round up to whole minutes.
 *  e.g. "1 min" or "2 mins". */
function ComputeEtaDisplayText(seconds: number) {

    const mins = Math.ceil(seconds / 60);
    const units = mins == 1 ? "min" : "mins";
    return `${mins} ${units}`;
}