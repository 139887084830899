import { Reducer, AnyAction } from "redux";
import appstore from "./appStore";

/** 
 *  Utility class to help generate Redux reducers and dispatchers easily.
 *  {TState} is the store slice state. 
 *  Call AddAction to define an action, but you pass in the reducer function for that action. 
 *  Call MakeCombinedReducer to get the full reducer for this store slice.
 */
export class ReduxStoreSlice<TState> {

    /** Prefix for all actions in this slice. Usually the name of the feature, e.g. "MyBookings" or "CustomerBans". */
    private actionNamePrefix: string;

    /** All reducer functions, keyed by action name. */
    private reducers: Record<string, SemiTypedReducer<TState>> = {};

    /** Initial / fallback value of the state */
    private defaultState: TState;

    constructor(actionNamePrefix: string, defaultState: TState) {
        this.actionNamePrefix = actionNamePrefix;
        this.defaultState = defaultState;
    }

    /**
     * Define an action that has no payload. We fudge the action into one that takes a payload, then pass it to the normal Action() method.
     * The action name must be unique in this state slice.
     * Returns the dispatcher function (which creates the action and dispatches it).
     */
    public EmptyAction(actionName: string, reducer: (state: TState) => TState): () => void {

        // this is a standard 1-input reducer with a placeholder argument
        const dummyReducer = (state: TState, payload: string) => reducer(state);

        // here is the resultant dispatcher, which takes an argument
        const dummyDispatcher = this.Action(actionName, dummyReducer);

        // suitable dispatcher that hides the placeholder argument
        const realDispatcher = () => dummyDispatcher("dummy");

        return realDispatcher;
    }

    /**
     * Given an action name and the reducer function; returns the dispatcher function.
     */
    public Action<TPayload>(actionName: string, reducer: (state: TState, payload: TPayload) => TState): (payload: TPayload) => void {

        // add the slice's name to get a globally unique name for this action
        const fullActionName: string = `${this.actionNamePrefix}: ${actionName}`;

        // record the reducer
        if (this.reducers[fullActionName]) {
            throw Error(`Duplicate action name ${fullActionName}`);
        }

        this.reducers[fullActionName] = reducer;

        // Return the action creator / dispatcher
        const Dispatcher = (payload: TPayload) => {

            const action: GenericAction<TPayload> = {
                type: fullActionName,
                Payload: payload,
            };

            appstore.dispatch(action);
        };

        return Dispatcher;
    }

    /** Generates a single reducer function for this state slice. */
    public MakeCombinedReducer(): Reducer<TState> {

        // copy these values to local variables to avoid issues with "this". Not 100% sure it's needed though.
        const reducers = this.reducers;
        const initial = this.defaultState;

        const combined: Reducer<TState> = (state: TState | undefined, action: AnyAction) => {
            const realState = state || initial;

            const reducerToUse = reducers[action.type];

            if (!reducerToUse) {
                return realState;
            }

            // careful: untyped but it technically works
            const newState: TState = reducerToUse(realState, action.Payload);

            return newState;
        };

        return combined;
    }
}

/** 
 *  A reducer function which takes a single argument {payload}.
 *  This definition will cover multiple functions which take different payload types, so we can only type it to [payload] here. At least we are properly typing the first arg and return value.
 */
type SemiTypedReducer<TState> = (state: TState, payload: any) => TState;

/** Redux action with a single property of type {TPayload}. All our actions fit this pattern. */
interface GenericAction<TPayload> {

    /** This action name as mandated by Redux */
    type: string;

    /** The data we will push back into the reducer */
    Payload: TPayload;
}