import React from "react";
import { ConsiderRefreshingEtas } from "./ETA/RefreshBookingEtas";
import { GoogleMapsEnhance } from "../../widgets/google-maps/GooglePropsEnhancer";
import { ConsiderRefreshingBookings } from "./Logic/RefreshMyBookings";

/** Manages the timer that checks for booking changes. 
 * Each tick of the timer doesn't necessarily cause a refresh: that's managed by RefreshMyBookings.ts 
 * The reason we do that is that this timer is only ticking when the user has the schedule view open. So once they change to the schedule view, we want a tick to occur quickly. */
class RefreshTimerCore extends React.Component {

    // assigned in componentDidMount()
    timerId: number | undefined;

    componentDidMount() {
        this.timerId = window.setInterval(this.onTimerTick, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timerId);
    }

    async onTimerTick() {
        await ConsiderRefreshingBookings();
        await ConsiderRefreshingEtas();
    }

    /** This component doesn't render any JSX. It is just using the lifecycle events. */
    render() {
        return null;
    }
}

/** Adds a google maps API script, as required for ETA loading */
export const RefreshTimer = GoogleMapsEnhance(RefreshTimerCore);