import { GeoPoint } from "../Location/Entities";
import { DirectionsData } from "./GoogleMapEntities";

/** Compound type representing all possible GoogleMap actions. This will be the input to the reducer, for example. */
export type GoogleMapAction = PickupSelectedAction | DropoffSelectedAction | TaxiChangedAction | PickupClearedAction | DropoffClearedAction | DirectionsAvailableAction;

/** The user changes the Pickup location. */
export interface PickupSelectedAction {
    type: GoogleMapActionKind.PickupSelected;
    location: GeoPoint;
}

/** The user changes the Dropoff location. */
export interface DropoffSelectedAction {
    type: GoogleMapActionKind.DropoffSelected;
    location: GeoPoint;
}

/** The Taxi location or vehicle number has changed */
export interface TaxiChangedAction {
    type: GoogleMapActionKind.TaxiChanged;
    location: GeoPoint;
    vehicleNumber: string;
}

/** User clears the pickup address */
export interface PickupClearedAction {
    type: GoogleMapActionKind.PickupCleared;
}

/** User clears the dropoff address */
export interface DropoffClearedAction {
    type: GoogleMapActionKind.DropoffCleared;
}

/** Google Directions data has been received */
export interface DirectionsAvailableAction {
    type: GoogleMapActionKind.DirectionsAvailable;
    data: DirectionsData;
}

/** Used as the [type] field on GoogleMap actions. In TypeScript it is used to discriminate the type union. */
export enum GoogleMapActionKind {

	/** User sets the pickup location */
    PickupSelected = 'GoogleMap: Pickup Selected',

	/** User sets the dropoff location */
    DropoffSelected = 'GoogleMap: Dropoff Selected',

	/** Taxi location or vehicle number changes */
	TaxiChanged = 'GoogleMap: Taxi Changed',

	/** User clears the pickup address */
    PickupCleared = 'GoogleMap: Pickup Cleared',

	/** User clears the dropoff address */
	DropoffCleared = 'GoogleMap: Dropoff Cleared',

    /** Google Directions data has been received */
    DirectionsAvailable = 'GoogleMap: Directions Available',

	// TODO: maybe need a reset action, i guess
}