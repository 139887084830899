import MobileDetect from "mobile-detect";
import { MobileOSKind } from "../Config/Entities/DeviceKind";

/** Bail out with [null] when rendering on the server */
function Initialize(): MobileDetect | null {
    
    if (typeof window === 'undefined') return null;

    return new MobileDetect(window.navigator.userAgent);
}

/** Returns true if the user is on a mobile device. We consider tablet as a mobile device. */
export function IsMobileDevice(): boolean {

    const detection = Initialize();
    if (!detection) return false;
    
    return detection.mobile() !== null || detection.tablet() !== null;
}

/**
 * Attempt to determine the mobile operating system.
 * Used for custom device-specific UI e.g. date and timer pickers.
 */
export function TryGetMobileOsName(): MobileOSKind {

    const detection = Initialize();
    if (!detection) return MobileOSKind.Unknown;

    // this is some manual Typing based on observed data
    return detection.os() as MobileOSKind;
}