import React from 'react';
import './CommonSnackbar.scss';
import { connect } from 'react-redux';
import { Dispatch } from '../../Dispatch';
import { ApplicationState } from '../../../appState';
import { IconButton, Slide, Snackbar } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { MyStorage } from '../../../Storage';


interface PropsFromStore {
    shouldOpenSnackbar: boolean;
}

const message = "Price Guarantee is available in this area!";

const SnackbarForPriceGuaranteeTipCore: React.FC<PropsFromStore> = (props) => {
    return (
        <div className="common-snack-bar">          
            <Snackbar            
                message={message}
                onClose={handleClose}
                open={props.shouldOpenSnackbar}
                autoHideDuration={10000}
                key="Snackbar-Message-alert"
                TransitionComponent={SlideTransition}
                action={
                    <IconButton
                        color='inherit'
                        aria-label="ok"
                        onClick={handleClose}
                    >
                        OK
                    </IconButton>
                }
            />
        </div>
    )
}

/** 
 * Makes the snackbar is appear with slide upwards and disappear slide downwards.
 */
 const SlideTransition = (props: TransitionProps) => <Slide {...props} direction="up" />;

 const handleClose = () => {
    MyStorage.HasSeenPriceGuaranteeTip.StoreData(true);
    Dispatch.UILogicControl.HideSnackbarForPriceGuaranteeTip();
}

 function mapStateToProps(state: ApplicationState): PropsFromStore {
     return {
         shouldOpenSnackbar: state.uiLogicControl.BookingForm.ShouldShowSnackbarMesssage
     };
}
 
export const SnackbarForPriceGuaranteeTip = connect(mapStateToProps)(SnackbarForPriceGuaranteeTipCore);;
