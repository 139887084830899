import { GeoPoint } from "../../modules/Location/Entities";
import { BookingStatus } from "../../Services/BookingEntities";
import appstore from "../../appStore";
import { BookingInfo, BookingTrackingDetails } from "../../modules/MyBookings/MyBookingEntities";

/** Decide or calculate the taxi location from BookingTrackingResult object */
export function CalculateTaxiLocation(trackingDetails: BookingTrackingDetails): GeoPoint | undefined {

    if (!trackingDetails.VehicleLocation) return undefined;

    return { latitude: trackingDetails.VehicleLocation.Latitude, longitude: trackingDetails.VehicleLocation.Longitude } as GeoPoint;
}

/** Decide or calculate pickup location from BookingTrackingResult object */
export function CalculatePickupLocation(bookingDetails: BookingInfo): GeoPoint | undefined {

    if (bookingDetails.TrackingInfo.BookingStatusID !== BookingStatus.Accepted) return undefined;

    if (!bookingDetails.PickupLocation) return undefined;

    return { latitude: bookingDetails.PickupLocation.Latitude, longitude: bookingDetails.PickupLocation.Longitude } as GeoPoint;
}

/** Decide or calculate dropoff location from BookingTrackingResult object */
export function CalculateDropoffLocation(bookingDetails: BookingInfo): GeoPoint | undefined {

    if (bookingDetails.TrackingInfo.BookingStatusID !== BookingStatus.PickedUp) return undefined;

    if (!bookingDetails.DropoffLocation) return undefined;

    return { latitude: bookingDetails.DropoffLocation.Latitude, longitude: bookingDetails.DropoffLocation.Longitude } as GeoPoint;
}

/** Decide or calculate Google map center from BookingTrackingResult object */
export function CalculateTrackingMapCenter(trackingDetails: BookingTrackingDetails): GeoPoint {
    
    const taxiLocation = CalculateTaxiLocation(trackingDetails);

    if (!taxiLocation) return appstore.getState().location.reliableLocation.value.geoPoint;

    return taxiLocation;
}

