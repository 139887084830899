import { DialogKind } from "../../Dialog/DialogEntities";
import { VerificationDialogInput, VerificationDialogResult } from "../Verification/UIEntities";
import { DialogProps } from "../Entities";
import { RemoveDialogInput, RemoveDialogResult } from "../BookingTemplate/RemoveTemplateEntities";

/** The subset of the Application's state related to the Dialog (V2) feature. */
export interface Dialog2State {

    /** Which type of dialog is being rendered, or none. */
    ActiveDialog: DialogKind | null;

    /** A function to close the current dialog. Maybe. Not 100% sure. */
    CloseDialogCallback: (() => void) | null;

    /** Input to the verification dialog, if it is active */
    Verification?: DialogProps<VerificationDialogInput, VerificationDialogResult>;

    /** Input to the remove booking template dialog */
    RemoveTemplate?: DialogProps<RemoveDialogInput, RemoveDialogResult>;
}

/** Initial value of the Dialog (V2) state. */
export const DefaultDialog2State: Dialog2State = {

    ActiveDialog: null,
    CloseDialogCallback: null,
};