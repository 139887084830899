import React from "react";
import "./UserProfile.scss";
import SiteHeader from "../../widgets/Header/SiteHeader";
import UserProfileNav from "./UserProfileNav";
import NavBar from "../../widgets/NavBar/NavBar";
import HeaderProfilePanel from "../../widgets/Header/HeaderProfilePanel";
import { UserProfileNavbarItem, UserProfileNavBarNames } from "./UserProfileNavEntities"
import { connect } from "react-redux";
import { ApplicationState } from "../../appState";
import UserPersonalDetails from "./UserPersonalDetails";
import { allUserProfileNavItems, personalDetailsNavItem} from "./UserProfileNavItems" 
import { Dispatch } from "../Dispatch";
import { LoginStatusKind } from "../Authentication/AuthEntities";
import MyWalletTable from "../Cards/MyWalletTable";

interface UserProfileNavProps {
  UserProfileSelectedNav: UserProfileNavbarItem;
  LoginStatus: LoginStatusKind;    
}

class UserProfile extends React.Component<UserProfileNavProps> {

    constructor(props: UserProfileNavProps) {
        super(props);
    }
  
    /**
     * 1. Returns the component based on selected nav
     * 2. By default it returns empty string
     */
    renderSelectedComponent() {
        switch (this.props.UserProfileSelectedNav.Name) {
            case UserProfileNavBarNames.Wallet: 
                return <MyWalletTable />;
            case UserProfileNavBarNames.PersonalDetails:
                return <UserPersonalDetails />;
            default:
                return ""
        }    
    }

    /**
    * Set the default selected Item for User profile Menu
    */
    componentDidMount() {
        Dispatch.UserProfileNav.SelectNavItem(personalDetailsNavItem);
        appInsights.trackEvent("UserProfile page");    
    }

    /**
     * The LoginStatus is an additional check, incases where user is on userprofile page and signs out
     * there is another method which redirects it, but we found in some cases when the browser is slow to redirect, it throws an error for a tiny bit of a second
     * This additional check avoids that
     */
    render() { 
   
    return (
        <div>
            <SiteHeader />
            <NavBar NavItems={[]} />
            <div className="userProfilecontainer">
            <div className="userProfileNavContainer">
                <UserProfileNav UserProfileNavItems={allUserProfileNavItems} />
            </div>
            </div>
            <HeaderProfilePanel />
            {this.renderSelectedComponent()}       
        </div>
    );
  }
}



function mapStateToProps(state: ApplicationState): UserProfileNavProps {
  return {
    UserProfileSelectedNav: state.userProfileNav.UserProfileSelectedNav,
    LoginStatus: state.authentication.LoginStatus
  };
}

export default connect(mapStateToProps)(UserProfile);