import { ServiceResult } from './ServiceEntities';
import { GetManagementServiceRootUrl, Get } from './ServiceManager';
import { AddressLookupResult, ApiAddress, RoughUserLocation } from './LocationEntities';

/** Base URL for the LocationController in the Booking Management Service 
 *  I moved this outside the class to avoid issues with "this". */
function GetBaseUrl(): string {
    return `${GetManagementServiceRootUrl()}Location/`;
}

/** HTTP API Calls to the LocationController in the Booking Management Service. */
class LocationServiceClass {

    /** Checks whether the specified location (given by a Google Maps Place ID) is serviced by us. Make sure to check the properties of the ApiAddress too, e.g. Suburb.IsServiceable. */
    public CheckCabServiceByPlaceID(placeId: string): Promise<ServiceResult<ApiAddress>> {
        const url = `${GetBaseUrl()}CheckCabService?placeid=${placeId}`;
        return Get<ApiAddress>(url);
    }

    /** 
     * Given a Google Maps Place ID, loads address details and checks the location serviceability in the backend (V1) API.
     * This version (V1b) includes timezone information and has a new data contract.
     */
    public CheckCabServiceByPlaceIDV1b(placeId: string): Promise<ServiceResult<AddressLookupResult>> {
        const url = `${GetBaseUrl()}CheckCabServiceV1b?placeid=${placeId}`;
        return Get<AddressLookupResult>(url);
    }

    /** Rough (city-level) location for the current user based on their IP address. */
    public GetMyRoughLocation(): Promise<ServiceResult<RoughUserLocation>> {
        const url = `${GetBaseUrl()}GetMyRoughLocation`;
        return Get<RoughUserLocation>(url);
    }
}

/** Singleton export for static access to the operations */
export const LocationService = new LocationServiceClass();