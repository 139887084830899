import { UILogicControlState, RecentTripLoadKind, BookingTabKind, CredentialPopupTrigger, QuickBookExpandedSection, UILayoutMode } from "./UILogicControlEntities";
import { DataLoadingStatus } from "../Condition/Redux/ConditionEntities";
import { BookingWidgetModeKind, BookingTemplateLoadKind } from '../BookingTemplate/BookingTemplateEntities';

/** Initial value of UILogicControl */
export const InitUILogicControlState: UILogicControlState = {
    BookingForm: {
        ApiRunningStatus: DataLoadingStatus.Idle,
        IsStrictValidationModeOn: false,
        IsLandingPageFinished: false,
        RecentTripLoadingStatus: RecentTripLoadKind.Idle,
        SelectedBookingTab: BookingTabKind.NewBooking,
        IsSelectedVehicleServiceable: true,
        BookingWidgetMode: BookingWidgetModeKind.Booking,
        BookingTemplateLoadingStatus: BookingTemplateLoadKind.Idle,
        QuickBookExpandedStatus: QuickBookExpandedSection.RecentBookings,
        SpecifiedAccountNotFound: false,
        IsAddressFocused: true, // True by default as auto-focus on pickup
        ShouldShowHeader: true,        
        IsInvalidConditionForSatss: false,
        ShouldShowFareEstimateMesssage: false,
        ShouldShowSnackbarMesssage: false
    },
    AddressStatus: {
        Pickup: {
            IsFocus: false,
            DoesInputHaveValue: false,
            IsValid:true
        },
        Dropoff: {
            IsFocus: false,
            DoesInputHaveValue: false,
            IsValid: true
        }
    },
    Credentials: {
        PopupTriggerSource: CredentialPopupTrigger.FromMenu,
        ApiRunningStatus: DataLoadingStatus.Idle
    },
    LayoutMode: UILayoutMode.Desktop,
    ShowMenu: false
};