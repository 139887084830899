import React from 'react';
import { ContactCategory, CategoryDefinition } from './ContactFormCategories';
import { ContactDetailProps, ContactDetailState } from './ContactFormEntities';
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

/** This component consists of three levels of sub categories. These are apended based on the selected values of first 2 dropdowns. */
class ContactCategDetail extends React.Component<ContactDetailProps, ContactDetailState> {
    private contactCateDetailForm: HTMLElement | null = null;

    constructor(props: ContactDetailProps) {
        super(props);
        this.state = this.getDefaultState();
    }

    // Update the value of subcategory one. Field name is different based on the previous selections.
    public updateSubCategory(newValue: any) {
        this.setState({
            selectedsubCategory: (newValue.target && newValue.target.value) || 0,
            selectedsubCategory2: 0,
            selectedsubCategory3: 0,
        }, function (this: any) {
            this.sendState();
        });
    }

    // Update the value of subcategory two.
    public updateSubCategory2(newValue: any) {
        this.setState({
            selectedsubCategory2: (newValue.target.value && newValue.target.value) || 0,
            selectedsubCategory3: 0,
        }, function (this: any) {
            this.sendState();
        });
    }

    // Update the value of subcategory three.
    public updateSubCategory3(newValue: any) {
        this.setState({ selectedsubCategory3: (newValue.target.value && newValue.target.value) || 0 }, function (this: any) {
            this.sendState();
        });
    }

    public render() {
        return (
            <React.Fragment>                
                {this.getAnotherSubHeadSelection(this.props.currentData)}               
            </React.Fragment>
        );
    }

    /**
     * Get next sub category based on the selected category value.
     */
    private getAnotherSubHeadSelection(contactEntry: CategoryDefinition) {
        
        let labelText = "Sub Category";
        let subCatClass = 'section-selection subCategory';
        if (!contactEntry.subCategories || contactEntry.subCategories.length <= 0) {
            return null;
        }
        const subCateg: ContactCategory = contactEntry.subCategories[this.props.selectedCategory];
        if (!subCateg.subCategory || subCateg.subCategory.length <= 0) {
            return null;
        }

        if (subCateg.label === 'Lost Property') {
            labelText =  "Item lost";
            subCatClass = 'section-selection subCategoryLost';
        }

        const subCategorySelection = subCateg.subCategory.map((categories, index) => {
            return <MenuItem value={index} key={index}>{categories.label}</MenuItem>
        })
        
        return <>
            <div className={subCatClass}>
                <FormControl variant="outlined" className="custom-select" fullWidth={true}>
                    <InputLabel id="section-selection subCategory">{labelText}</InputLabel>
                    <Select
                        autoFocus
                        label = {labelText}                    
                        value={this.props.selectedSubCategory}
                        onChange={(e) => this.updateSubCategory(e)}                   
                    >
                        {subCategorySelection}
                    </Select>
                </FormControl>
            </div>
            {this.getThirdSubHeadSelection(contactEntry, subCateg)}
        </>;
    }

    private getThirdSubHeadSelection(contactEntry: CategoryDefinition, subCateg: ContactCategory) {
        if (!subCateg.subCategory || subCateg.subCategory.length <= 0) {
            return null;
        }
        const subCategory: ContactCategory = subCateg.subCategory[this.props.selectedSubCategory];
        if (!subCategory || !subCategory.subCategory2 || subCategory.subCategory2.length <= 0) {
            return null;
        }

        const subCategory2Selection = subCategory.subCategory2.map((categories, index) => {
            return <MenuItem value={index} key={index}>{categories.label} </MenuItem>
        });

        const labelText = !!subCategory2Selection ? "Sub category" : "" 
        return <>
            <div className="section-selection subCategory">
                <FormControl variant="outlined" className="custom-select" fullWidth={true}> 
                    <InputLabel id="selectedsubCategory">{labelText}</InputLabel>
                    <Select
                        autoFocus
                        label={labelText}
                        value={this.props.selectedSubCategory2}
                        onChange={(e) => this.updateSubCategory2(e)}
                    > 
                        {subCategory2Selection}
                    </Select>
                </FormControl>
            </div>
            {this.getFourthSubHeadSelection(contactEntry, subCategory)}
        </>;
    }

    private getFourthSubHeadSelection(contactEntry: CategoryDefinition, subCategory: ContactCategory) {
        if (!subCategory.subCategory2 || subCategory.subCategory2.length <= 0) {
            return null;
        }
        const subCategory2: ContactCategory = subCategory.subCategory2[this.props.selectedSubCategory2];
        if (!subCategory2 || !subCategory2.subCategory3 || subCategory2.subCategory3.length <= 0) {
            return null;
        }

        const subCategory3Selection = subCategory2.subCategory3.map((categories, index) => {
            return <MenuItem value={index} key={index}>{categories.label}</MenuItem>
        });

        const labelText = !!subCategory3Selection ? "Sub category" : "" 
        return  <div className="section-selection subCategory">
                    <FormControl variant="outlined" className="custom-select" fullWidth={true}> 
                        <InputLabel id="selectedsubCategory">{labelText}</InputLabel>
                        <Select
                            autoFocus
                            label={labelText}
                            value={this.props.selectedSubCategory3}
                            onChange={(e) => this.updateSubCategory3(e)}
                        >
                            {subCategory3Selection}
                        </Select>
                    </FormControl>
                </div>;
    }

    // Update state with the selected category value
    private sendState() {
        this.props.receiveState(this.state);
    }

    // Default state for each dropdown
    private getDefaultState() {
        return {
            selectedsubCategory: 0,
            selectedsubCategory2: 0,
            selectedsubCategory3: 0,
        };
    }
}

export default ContactCategDetail;
