import { Credentials } from "../AuthEntities";
import { SignupCredential } from "./LoginEntities";
import { DropLeadingZero } from '../../../utils/Formattingutil';
import { ResetPasswordRequest } from "../../../Services/UserEntities";
import { Api } from '../../../Services/Api';
import { LogEvent } from '../../../utils/LogEvent';
import appstore from "../../../appStore";
import { Dispatch } from '../../Dispatch';
import { ServiceCallOutcome } from "../../../Services/ServiceEntities";

/**
 * localStorage --> Add/update localStorage items for authentication related
 */
export function CreateCredentialForSignup(credential: Credentials) : SignupCredential {
    return {
        FirstName: credential.FirstName!,
        LastName: credential.LastName ? credential.LastName : "",
        ContactNumber: credential.CountryInfo!.CountryCode + DropLeadingZero(credential.ContactNumber!),
        Email: credential.Email!,
        Password: credential.Password!
    };
}
 
/**
 * This function is to validate if an email address is valid from it's format point of view using regular expression.
 */
export function IsValidEmailFormat(email: string): boolean {
    const regex = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    return regex.test(email);
}

/**
 * This function is generate a displayed profile name from first name & last name.
 */
export function ConstructProfileName(firstName: string | undefined, lastName: string | undefined): string {
    let names: Array<string> = [!!firstName ? firstName : "", !!lastName ? lastName : ""]; 
    return names.join(' ').trim();
}

/** 
 * Helper function to reset password for logged-in users
 */
export async function TryResetPassword(passwords: ResetPasswordRequest): Promise<boolean> {
    const { UserProfile } = appstore.getState().authentication;

    Dispatch.UILogicControl.StartCredentialsApiRequest();
    Dispatch.Auth.ClearLoginErrorMessage();

    const result = await Api.User.ResetPassword(passwords);
    Dispatch.UILogicControl.EndCredentialsApiRequest();

    // reset password failure: abort login
    if (!result.isSuccess) {
        var errorMessage = "Failed to reset password", detailedErrorMessage = "";

        if (result.outcome === ServiceCallOutcome.ApplicationError) {
            detailedErrorMessage = result.apiStatus.Message;
            if (/old password/i.test(detailedErrorMessage) || /block/i.test(detailedErrorMessage)) errorMessage = detailedErrorMessage;
        }

        Dispatch.Auth.LoginErrorMessage(errorMessage);
        LogEvent.ResetPasswordFailed({ Username: UserProfile!.Email, ErrorMessage: detailedErrorMessage });
        return false;
    }
    
    LogEvent.ResetPasswordSuccess({ Username: UserProfile!.Email });
    return true;
}
