import React from "react";
import { connect } from "react-redux";
import BookingCard from "./BookingCard";
import { BookingStatus, BookingDataOwnership, BookingAuthority } from "../../../Services/BookingEntities";
import { ApplicationState } from "../../../appState";
import { ExtendedEta, BookingInfo } from "../MyBookingEntities";
import { UILayoutMode } from "../../UILogicControl/UILogicControlEntities";
import { OrderBookingsByTimeInAsc, OrderBookingsForMobileDevice } from "../Logic/OrderMyBookings";

interface ListOfBookingCardsProps {
    BookingsList: BookingInfo[];
    EtaLookup: Record<number, ExtendedEta>;
    LayoutMode: UILayoutMode;
    BookingTrackingLink: BookingAuthority | null;
}

/** 
 * Return a list of BookingCard from myBookings, which is used by both mobile & desktop.
 * 
 * Business requirement:
 * If desktop, the list maps the column "Pending pick-up", which includes pending pick-up and no job with asc order;
 * If mobile, the list maps all bookings except for canceled with desc order.
 */
class ListOfBookingCards extends React.Component<ListOfBookingCardsProps> {

    render() {
        const { BookingsList, EtaLookup, LayoutMode } = this.props;

        let bookingList;

        // Display different status of bookings per device with required order by time
        if (LayoutMode === UILayoutMode.Mobile) {
            bookingList = OrderBookingsForMobileDevice(BookingsList);
        }
        else {
            let activeBookings = BookingsList.filter((booking) => booking.TrackingInfo.BookingStatusID <= BookingStatus.Accepted || booking.TrackingInfo.BookingStatusID == BookingStatus.NoJob);
            let cancelledLinkBookings = BookingsList.filter((booking) => booking.TrackingInfo.BookingStatusID == BookingStatus.Cancelled && (booking.DataOwnership === BookingDataOwnership.ReadOnlyLink || booking.DataOwnership === BookingDataOwnership.WritableLink));
            activeBookings.push(...cancelledLinkBookings);
            bookingList = OrderBookingsByTimeInAsc(activeBookings);
        }

        const TrackingLink = this.props.BookingTrackingLink;

        return (
            <>{bookingList.map((booking, index) => <BookingCard ShouldAutoOpen={index === 0 || (!!TrackingLink && TrackingLink.bookingId == booking.BookingID)} BookingDetails={booking} key={booking.BookingID} Eta={EtaLookup[booking.BookingID]} />)}</>      
        );
    }
}

function mapStateToProps(state: ApplicationState): ListOfBookingCardsProps {
    return {
        BookingsList: state.myBookings.All,
        EtaLookup: state.myBookings.Etas,
        LayoutMode: state.uiLogicControl.LayoutMode,
        BookingTrackingLink: state.trackingLinks.NewFromUrl?.BookingKey ?? null
    }
}

export default connect(mapStateToProps)(ListOfBookingCards);