
/** Application feature flags. Any nontrivial new feature should not take effect if its feature flag is off. */
export const FeatureFlags = {

    /** Show My Personal Details item in user profile menu */
    ShowMyPersonalDetailsUserProfile: true,

    /** Prefix driver notes to remarks */
    AccountsDriverNotePrefix: true,

    /** UI to control feature flags at runtime from /flags */
    UserFeatureFlags: true,

    /** Navigation panel link to the /flags UI. False by default. The Feature Flags UI will set it to true at runtime. */
    UserFlagsNavLink: false,

    /** Enable/disable the landing page which is on top of the map in booking page */
    LandingPage: false,

    /** 
     *  Show/Hide Parcel option while creating a booking
     *  TODO: is it time to remove this flag? It should be always true now.
     */
    AllowParcelBooking: true,

    /** Show/Hide the new customised UI interface for Auth0 API implementation */
    CustomAuth0Interface: true,

    /** Perform the Auth0 login and redirect in an iFrame, avoiding a reload of the application */
    Auth0RedirectInChildWindow: true,

    /** Retrieve the list of Booking Templates of the logged in user. */
    BookingTemplates: true,
    
    /** Enable Auth0 Custom Domain */
    CustomDomain: true,

    /** Shows a demo panel for the Dialog (V2) feature */
    Dialog2Demo: false,

    /** Shows a link to log in interface of old 13cabs website */
    GoToOldSite: false,

    /** Allow bookings with Fixed fare when true. */
    FixedFare: true,

    /** Allow book with Fixed fare with cash option. i.e.: guest users or signed in users with 'Pay driver directly' option selected. */
    FixedFareWithCash: true,

    /** Reset password for logged-in users. */
    ResetPassword: true,

    /** Enable mobile booking form url /mobilebooking for testing purpose */
    MobileBookingFormUrl: true,

    /** Show Whats new feature list */
    WhatsNew: true,

    /** Don't redirect mobile users. */
    KeepMobileUsers: true,

    /** Hide Sign up buttons and links. Only Log in is available. */
    NoSignup: false,

    /** Always force login. i.e. guest bookings are not allowed in the website when this is enabled. */
    ForceLogin: false,

    /** Skip the terms and conditions check. */
    NoTermsAndConditions: false,

    /** Check whether there is an SSO session on Auth0 server if enabled, via function TryRestoreLoginSession() */
    SingleSignOn: false,

    /** Forgot password feature for in login popup */
    ForgotPassword: true,

    /** Track taxi in booking card */
    ShowMapInBookingCard: true,

    /** Whether customers are able to add card in payment option dropdown */
    CardNotPresentPayment: true,

    /** When true, show 'Book again'/'Book return' button in the booking tracking UI. */
    BookAgainFromTracking: false,

    /** UI, logic and data operations are against BookingV2Controller (behind the scene is GlobalBooker implementation), if value is true; Otheriwse to BookingController. */
    BookingApiV2: false,

    /** Switch to enable booking on account, specifically for mobile. */
    MobileBookingOnAccount: true,

    /** Switch to enable multiple authentication tenant options during login (for showcase purpose for now). */
    MultiTenantLogin: false,

    /** When true, show the Downlaod app button on the site header */
    ShowDownloadMobileAppButton: true,

    /** Master flag required for book on account functionality. */
    BookOnAccount: true,

    /** When true, show price guarantee toggle to display fixed fare. */
    PriceGuarantee: false,

    /** Show Trip History tab in the nav bar. */
    TripHistory: true,

    /** Display 'Quick Book' tab (which opens Book from recents and Templates) along with New Booking tab at the top of the booking form. */
    QuickBook: true,

    /** Display 'Order Number' input field in the booking widget when booking on account. */
    OrderNumber: true,

    /** Use Legal Documents V2 (handled by the booking MGMT) operations. */
    LegalDocumentsV2: false,

    /** Disable login, signup and other logged in user features. */
    GuestOnly: false,

    /** Enable Price Guarantee bookings for account users. This is only applied if PriceGuarantee flag above is true. */
    PriceGuaranteeWithAccount: false,

    /** Pre authorise booking payments. The flag actually decides whether to make PaymentPreAuthOptIn true in booking request.  */
    PreAuth: false,

    /** Allow make fixed fare parcel bookings with cash. */
    ParcelFixedFareWithCash: true
}