import React, { FC } from 'react';
import { socialLinks } from '../../utils/media/Media-MetaData';
import "./site-footer.scss";
import { GetBrandedUrl, BrandedImage } from '../../modules/Utils/BrandedContentUrls';
import { FeatureFlags } from '../../Config/FeatureFlags';
import { RouteUrls } from '../../RouteUrls';
import { GetValues } from '../../Config/MyAppConfig';

/** 
 *  Renders the footer of the site... but only on the Contact Us page. 
 *  There is a separate mini-footer on the Booking Form.
 */
export const SiteFooter: FC = () => (
    <footer>
        <div className="site-footer-panel">
            <div className="site-footer-safe-container">
                <a href={`tel:${GetValues().BrandContactNumber}`} className="footer-call-us">
                    <img src={GetBrandedUrl(BrandedImage.PhoneIcon)} alt="" />
                    <span>{GetValues().BrandContactNumber}</span>
                </a>

                <div className="footer-logo">
                    <img className="logo" src={GetBrandedUrl(BrandedImage.Logo)} alt="logo" />

                    {!FeatureFlags.NoTermsAndConditions && (
                        <ul className="disclaimer">
                            <li><a href={RouteUrls.PrivacyPolicy}>Privacy Policy</a></li>
                            <li><a href={RouteUrls.TermsAndConditions}>Terms &amp; Conditions</a></li>
                        </ul>
                    )}

                    <p className="copy">{GetValues().BrandName} &copy; {(new Date().getFullYear())}</p>
                </div>

                <div className="footer-media">
                    <nav className="social">
                        {socialLinks.map((l, i) =>
                            <a href={l.href} target="_blank" key={i}><img src={l.img} alt={l.display} /></a>
                        )}
                    </nav>
                </div>
            </div>
        </div>
    </footer>
);
