import React from "react";
import "./BookingAddressComponent.scss";
import { connect } from "react-redux";
import { ApplicationState } from '../../appState';
import { GooglePlaceAutoComplete } from "../../widgets/google-maps/GooglePlaceAutoComplete";
import { GeoPoint } from "../Location/Entities";
import { getContentUrl, ContentURL }  from '../Utils/ContentURL';
import { PickupAddressStatus } from "./PickupAddressStatus";
import { ServiceCheckStatus, PickupServiceCheckState } from "./BookingEntities";
import { SimpleUserProfile } from "../User/ProfileEntitiesV2";
import { MapView } from "../../widgets/NavBar/TabEntities";
import { Dispatch } from "../Dispatch";
import { ConvertToPlaceResult, ApplyPickupLocationSelection, ApplyDropoffLocationSelection } from './BookingLocationHelper';
import { BookingWidgetModeKind } from '../BookingTemplate/BookingTemplateEntities';
import { VehicleOption } from "../Condition/Redux/ConditionEntities";
import { AddressStatusType } from "../UILogicControl/UILogicControlEntities";
import { GetBrandedUrl, BrandedImage } from "../Utils/BrandedContentUrls";
import { CustomErrorMessages, WellKnownMessageKind } from "../Utils/ErrorMessages";
import { LoadEndIconForTextField } from "./BookingHelper";
import { ServiceKind } from "../../utils/service-vehicles/ServiceMetadata";
import { AddressV2 } from "../../Services/MakeBookingContracts";

interface PropsFromStore {
    UserProfile : SimpleUserProfile | undefined,
    BookOnAccount: boolean,
    locationVicinity: GeoPoint;
    placeTextPickup: string;
    placeTextDropoff: string;
    PickupServiceCheckStatus: string;
    IsBookingFormStrictValidationModeOn: boolean;
    PickupStatus: AddressStatusType;
    DropoffStatus: AddressStatusType;
    SelectedVehicle: VehicleOption,
    BookingWidgetMode: BookingWidgetModeKind;
    PickupServiceCheck : PickupServiceCheckState;
    PickupV2: AddressV2 | null;
    DropoffV2: AddressV2 | null;
}

interface AddressProps { 
    isPickup: boolean;
}

interface BookingAddressComponentState {
    isQuickClearClicked: boolean; 
}

class BookingAddressComponent extends React.Component<PropsFromStore & AddressProps, BookingAddressComponentState> {
    constructor(props: PropsFromStore & AddressProps) {
        super(props);
        this.state = {             
            isQuickClearClicked: false
        };
    }

    updateFocusStatus = (isFocus: boolean, value: string | undefined) => {
        /** Update HTML focus status to store per address */
        if (this.props.isPickup) { Dispatch.UILogicControl.OnIsPickupFocusChange(isFocus); }
        else { Dispatch.UILogicControl.OnIsDropoffFocusChange(isFocus); }

        // View scrolls to google map if address is blurred from focus, and has a value (Redux value is not reliable, we use field value here)
        if (isFocus) {
            Dispatch.UILogicControl.MarkAddressAsFocus();
        }
        else if (!isFocus && value !== "") {
            Dispatch.UILogicControl.MarkAddressAsBlurred();
        }
    }

    onQuickClearClicked = (isQuickClearClicked: boolean) =>{ this.setState({ isQuickClearClicked : isQuickClearClicked }); }

    reportHasAddressInputValue = (doesInputHaveValue: boolean) =>{
        if (this.props.isPickup) {
            if (doesInputHaveValue !== this.props.PickupStatus.DoesInputHaveValue) Dispatch.UILogicControl.OnDoesPickupInputHaveValueChange(doesInputHaveValue);
        }
        else {
            if (doesInputHaveValue !== this.props.DropoffStatus.DoesInputHaveValue) Dispatch.UILogicControl.OnDoesDropoffInputHaveValueChange(doesInputHaveValue);
        }
    }

    /** Quick clear */
    onClearEvent = () => {   
        if (this.props.isPickup) {
            Dispatch.GoogleMap.PickupCleared();
            Dispatch.Booking.ClearPickup();
            Dispatch.UILogicControl.OnDoesPickupInputHaveValueChange(false);
            Dispatch.UILogicControl.ValidateVehicleOnPickupChange(true);
            this.onQuickClearClicked(true);
        } else {
            Dispatch.GoogleMap.DropoffCleared();
            Dispatch.Booking.ClearDropoff();
            Dispatch.UILogicControl.OnDoesDropoffInputHaveValueChange(false);
            this.onQuickClearClicked(true);
        }
        this.reportHasAddressInputValue(false);
        Dispatch.Condition.ClearPriceGuarantee();
        Dispatch.Condition.ClearFareEstimate();
    }

    onPlaceSelected = async (place: google.maps.places.PlaceResult, placeText: string) => {

        // new Google typings have raised the possibility of getting blanks from these values
        // these are places we cannot handle
        if (!place.geometry) return;
        if (!place.place_id) return;

        const placeResult = ConvertToPlaceResult(place, placeText);

        if (this.props.isPickup) {
            // Remove not valid error message if the user enters an address.
            Dispatch.UILogicControl.SetPickupValidity(true);
            ApplyPickupLocationSelection(placeResult);
        } else {
            Dispatch.UILogicControl.SetDropoffValidity(true);
            ApplyDropoffLocationSelection(placeResult);
        }        
    }    
    
    IsPickUpInputValid = () => {  

        const { PickupV2, BookingWidgetMode, PickupServiceCheckStatus, IsBookingFormStrictValidationModeOn, PickupStatus } = this.props;
        
        // Should select serviceable address
        if (PickupServiceCheckStatus === ServiceCheckStatus.KnownBad) return false;

        // Address can't be populated from an existing booking
        if (!PickupStatus.IsValid) return false;

        // Validate in strict validation mode
        if (!IsBookingFormStrictValidationModeOn) return true; 
        
        // Do not validate, if pickup input is focused
        if (PickupStatus.IsFocus) return true;
        
        // Validate input value for template booking
        if (BookingWidgetMode !== BookingWidgetModeKind.Booking) {
            
            // Invalid state if input is entered but, no address is selected
            if (PickupStatus.DoesInputHaveValue && !PickupV2) return false;            
        }
        else {
            // Pickup address is mandatory for booking only
            if (PickupServiceCheckStatus !== ServiceCheckStatus.KnownGood) return false;
        }       
                               
        return true;
    }
     
    IsDropOffInputValid = () => {

        const { DropoffV2, UserProfile, BookOnAccount, IsBookingFormStrictValidationModeOn, BookingWidgetMode, DropoffStatus } = this.props;

        // Cannot populate from an existing booking
        if (!DropoffStatus.IsValid) return false;

        // Validate in strict validation mode
        if (!IsBookingFormStrictValidationModeOn) return true;            
        
        // Do not validate, if dropoff input is focused
        if (DropoffStatus.IsFocus) return true;
        
        // Valid Dropoff is selected
        if (DropoffV2) return true;
        
        // Validate input value for template booking
        if (BookingWidgetMode !== BookingWidgetModeKind.Booking) {
            
            // Invalid state if the input is entered but, address is not selected
            if (!DropoffStatus.DoesInputHaveValue) return true;
        }
        else {

            const isParcelBooking = this.props.SelectedVehicle.Service.kind === ServiceKind.Parcel;

            const isBookingOnAccount = UserProfile != null && BookOnAccount;

            // Validate for account and parcel booking
            if (!isBookingOnAccount && !isParcelBooking) return true;
        };

        return false;
    }

     /**
     * There are only 2 situations show message:
     * 1> No pick when strict validation mode === true;
     * 2> Not enough details for pickup;
     */
    decidePickupErrorMessage = () => {

        const { PickupServiceCheck, IsBookingFormStrictValidationModeOn, BookingWidgetMode, PickupStatus } = this.props;
        
        // In strict validation mode, error message is displayed if Pickup address is empty or invalid
        if (IsBookingFormStrictValidationModeOn) {
            
            const noPickupSelected = PickupServiceCheck.status === ServiceCheckStatus.NoInputSelected;

            // Validate input value for template booking
            if (BookingWidgetMode !== BookingWidgetModeKind.Booking) {

                const isPickupInvalid = !PickupStatus.IsFocus && PickupStatus.DoesInputHaveValue && noPickupSelected;

                // Invalid pickup address if input is entered but, no address is selected
                if (isPickupInvalid) return CustomErrorMessages.NoPickup;
                
                return "";// All good
            }

            if(noPickupSelected) return CustomErrorMessages.NoPickup;   
        }

        if (PickupServiceCheck.status === ServiceCheckStatus.KnownBad && PickupServiceCheck.errorMessage.ProblemText === WellKnownMessageKind.StreetNameUnavailable.ProblemText && PickupServiceCheck.isPickupErrorMessageShown)
            return PickupServiceCheck.errorMessage.SolutionText;

        // Pickup address can't be populated from history/favourite
        if (!PickupStatus.IsValid) return CustomErrorMessages.NoPickup;

        return ""; // Valid
    }

    decideDropoffErrorMessage = () => {

        const { DropoffV2, BookOnAccount, IsBookingFormStrictValidationModeOn, BookingWidgetMode, DropoffStatus } = this.props; 

         /** In strict validation mode, error message is displayed if any of the below conditions are satisfied:
         * 1) When booking on accounts, Dropoff address is empty or invalid
         * 2) For parcel booking, Dropoff address is empty or invalid
         */
        if (IsBookingFormStrictValidationModeOn) {
            
            // Validate input value for template booking
            if (BookingWidgetMode !== BookingWidgetModeKind.Booking) {

                const isDropoffInvalid = !DropoffStatus.IsFocus && DropoffStatus.DoesInputHaveValue && !DropoffV2;

                // Invalid dropoff address if input is entered but, no address is selected
                if (isDropoffInvalid) return CustomErrorMessages.NoDropoff;

                return "";// All good
            }

            const isParcelBooking = this.props.SelectedVehicle.Service.kind === ServiceKind.Parcel;
            const isDropoffRequired = BookOnAccount || isParcelBooking;

            if (isDropoffRequired && !DropoffV2) return CustomErrorMessages.NoDropoff;
        }
        
        // Dropoff address can't be populated from history/favourite
        if (!DropoffStatus.IsValid) return CustomErrorMessages.NoDropoff;

        return "";// All good
    }

    /** Change to MapView on click of the field or on start of entering value. */
    changeView = () => Dispatch.Tab.SelectItem(MapView);

    render() {

        const { UserProfile, BookOnAccount, isPickup, BookingWidgetMode, PickupStatus, DropoffStatus } = this.props;
        
        const isBookingOnAccount = UserProfile != null && BookOnAccount;
        
        const isBookingModeOn = BookingWidgetMode === BookingWidgetModeKind.Booking;
        
        /**
         * Set focus on Pickup address input when,
         * 1. Not booking on accounts
         * 2. Not on Booking template mode
         */
        const allowFocus = isBookingModeOn && !isBookingOnAccount && isPickup ? true : undefined;

        const canShowClearTextIcon = this.props.isPickup ? PickupStatus.DoesInputHaveValue : DropoffStatus.DoesInputHaveValue;

        const errorMessage = this.props.isPickup ? this.decidePickupErrorMessage() : this.decideDropoffErrorMessage();                    

        // Drop-off (default)
        var placeText = this.props.placeTextDropoff;  
        var addressType = "Dropoff";
        var addressIcon = getContentUrl(ContentURL.images.address.b);
        var labelText = !isBookingModeOn ? "Destination" : "Add destination";
        var canShrinkLabelText = !isPickup && (DropoffStatus.IsFocus || DropoffStatus.DoesInputHaveValue) ? true : false;
        var isAddressInvalid = !this.IsDropOffInputValid();

        if (this.props.isPickup) {
            addressType = "Pickup";            
            labelText = "Add pickup";
            placeText = this.props.placeTextPickup;

            isAddressInvalid = !this.IsPickUpInputValid();
            addressIcon = GetBrandedUrl(BrandedImage.PickupAddressA);
            canShrinkLabelText = isPickup && (PickupStatus.IsFocus || PickupStatus.DoesInputHaveValue) ? true : false;
        }

        const endIconPath = LoadEndIconForTextField(canShowClearTextIcon, isAddressInvalid); 

        return (
            <div className="booking-address-field" onClick={this.changeView} onKeyDown={this.changeView}>
                                
                <GooglePlaceAutoComplete
                    EndIcon={endIconPath}
                    LabelText={labelText}
                    PlaceValue={placeText}
                    AutoFocus={allowFocus}
                    AddressIcon={addressIcon}
                    IsAddressInvalid={isAddressInvalid}
                    onClearEvent={this.onClearEvent}
                    AddressInputFieldType={addressType}
                    UpdateFocus={this.updateFocusStatus}
                    OnPlaceSelected={this.onPlaceSelected}
                    CanShrinkLabelText={canShrinkLabelText}
                    PreferNearbyTo={this.props.locationVicinity}
                    isQuickClearClicked={this.state.isQuickClearClicked}
                    onQuickClearClicked={this.onQuickClearClicked.bind(this)}
                    reportHasAddressInputValue={this.reportHasAddressInputValue}
                />
                
                { this.props.isPickup && <PickupAddressStatus/> }                
                { errorMessage && <div className="booking-address-message">{errorMessage}</div> }
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        UserProfile: state.authentication.UserProfile,
        BookOnAccount: state.booking.IsOnAccount,
        locationVicinity: state.location.reliableLocation.value.geoPoint,
        placeTextPickup: CalculateAddressDisplayText(state.booking.PickupV2),
        placeTextDropoff: CalculateAddressDisplayText(state.booking.DropoffV2),
        PickupServiceCheckStatus: state.booking.PickupServiceCheck.status,
        IsBookingFormStrictValidationModeOn: state.uiLogicControl.BookingForm.IsStrictValidationModeOn,
        PickupV2: state.booking.PickupV2,
        DropoffV2: state.booking.DropoffV2,
        PickupStatus: state.uiLogicControl.AddressStatus.Pickup,
        DropoffStatus: state.uiLogicControl.AddressStatus.Dropoff,
        SelectedVehicle: state.condition.SelectedCondition,
        BookingWidgetMode: state.uiLogicControl.BookingForm.BookingWidgetMode,
        PickupServiceCheck: state.booking.PickupServiceCheck 
    };
}

function CalculateAddressDisplayText(address: AddressV2 | null): string {

    if (!address) return "";

    if (!address.FullTextAddress) return "";

    return address.FullTextAddress;
}

export default connect(mapStateToProps)(BookingAddressComponent);