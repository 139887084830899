import { LoginStatusKind, AuthToken } from './AuthEntities';
import appstore from "../../appStore";
import { TryAgainMessageKind } from "../Utils/ErrorMessages";
import { ShowDialogRetryErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import Auth from "../../modules/Authentication/AuthImpl";
import { Api } from '../../Services/Api';
import { Dispatch } from '../Dispatch';
import { LogoutKind } from "../../modules/Authentication/AuthEntities";
import { ParseAndStoreContactNumber } from '../Booking/NewBookingTabHelper';
import { LogEvent } from '../../utils/LogEvent';
import { SimpleUserProfile, SimpleUserProfileBase } from '../User/ProfileEntitiesV2';
import { GetUserMpsProfile } from '../Payment/PaymentHelper';
import { CanBookOnAccount } from '../Utils/UserProfileValidation';
import { MyStorage } from '../../Storage';
import { UILayoutMode } from "../UILogicControl/UILogicControlEntities";

/**
 * The user has updated some detail of their profile, e.g. the phone number.
 * Reload it from the API.
 * However, we don't reload ancillary data like bookings or cards.
 */
export async function RefreshProfileAfterDataChange() {

    const profile = await TryLoadAndPersistProfile();
    if (!profile) {

        ShowDialogRetryErrorMessage(TryAgainMessageKind.Auth0LoginTryAgain);
        return;
    }

    // MPS profile is one thing that could be fixed by a profile data change, in particular when the phone number becomes non-null
    await GetUserMpsProfile();
}

/** 
 *  Try to restore the user profile data from a persisted login session that is now being resumed.
 *  Normally it will be in local storage along with our Auth token.
 *  Rarely (e.g. UserProfile V2 Upgrade) we will reload it from the API.
 */
export async function TryRestoreUserProfile(loginToken: AuthToken): Promise<SimpleUserProfile | null> {

    // ideal: get from storage
    const profile = MyStorage.UserProfileV2.LoadData();

    if (profile) {

        if (profile.TokenUserId === loginToken.sub!) {
            UpdateStoreWithUserData(profile);
            return profile;
        }
        else {
            LogEvent.LoginRestoreProfileMismatch(profile, loginToken);
            // don't use the persisted profile. Reload from API (below)
        }
    }

    // fallback: get from service
    const fromApi = await TryLoadAndPersistProfile();
    return fromApi;
}

/**
 * Loads the User Profile from the API and does minimal processing after that.
 * 1) API call to get data
 * 2) App insights success / failure logging
 * 3) Persist in local storage
 * 4) Push to Redux store.
 */
export async function TryLoadAndPersistProfile(): Promise<SimpleUserProfile | null> {

    Dispatch.Auth.LoginStatus(LoginStatusKind.GetUserProfileInProgress);

    const result = await Api.User.GetMyProfile();

    // failure
    if (!result.isSuccess) {
        LogEvent.GetUserProfileFailure();
        new Auth().logout(LogoutKind.Website);
        return null;
    }

    // success
    const profile: SimpleUserProfile = CompleteProfile(result.value);

    MyStorage.UserProfileV2.StoreData(profile)
    LogEvent.GetUserProfileSuccess(profile);

    UpdateStoreWithUserData(profile);

    return profile;
}

/** 
 * Add TokenUserId to profile which is derived from GetUserProfileV2()
 */
function CompleteProfile(profile: SimpleUserProfileBase): SimpleUserProfile {
    const completeProfile: SimpleUserProfile = {
        ...profile,

        // the [sub] field is always defined for newly created tokens
        TokenUserId: appstore.getState().authentication.AuthToken!.sub!
    }

    return completeProfile;
}

/**
 * After retrieving the user profile, update the store with user's data, invoke APIs to get data from the DB (user's active bookings, recent bookings etc) and send events to different analytic tools.
 */
function UpdateStoreWithUserData(userProfile: SimpleUserProfile): void {

    const profileContactName = userProfile.DisplayName, profileContactNumber = userProfile.ContactPhone;   
    Dispatch.Auth.UserProfile(userProfile);
    Dispatch.Auth.LoginStatus(LoginStatusKind.LoggedIn);   
    const { Passenger } = appstore.getState().booking;

    /**
     * Update the contact name field with the profile name, only when both the below scenarios are met:
     * 1. Contact name field is empty
     * 2. Logged-in user is not an account user 
     */
    const IsContactNameEmpty = !Passenger.ContactName && !userProfile.CompanyId;

    IsContactNameEmpty && Dispatch.Booking.UpdatePassengerDetails({ ContactName: profileContactName });

    const contactNumber = Passenger.PassengerContactNumber?.Contactnumber;

    // Default the contact number field to user profile's contact number, if the contact number is not entered.
    if (!contactNumber) ParseAndStoreContactNumber(profileContactNumber);

    // Check device mode
    const isMobileDevice = appstore.getState().uiLogicControl.LayoutMode === UILayoutMode.Mobile;

    // default the "Book on Account" option based on the user's profile.
    Dispatch.Booking.SetBookOnAccount(CanBookOnAccount(userProfile, isMobileDevice));
} 
