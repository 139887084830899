import { PaymentCard } from "../../Services/PaymentEntities";
import { PaymentCardErrorType } from "./PaymentEntities";
/** Application State  for payment */
export interface PaymentState {
    /** Unfiltered list of the user's cards, i.e. all cards */
    AllCards: PaymentCard[];

    /** card to be removed, this value is used by remove payment confirmation to pass to delete card service */
    CardToRemove?: PaymentCard;

    /** Specifies default card, this is used to  reference prev default card while manipulating default in all card list */
    DefaultCard: PaymentCard | null;

    /** If Card Registration Panel is Open , as it can be triggered from multiple places */
    IsCardRegistrationPanelOpen: boolean;

    /** Error type of the payment card if there is any. Null if no errors. */
    PaymentCardError: PaymentCardErrorType | null;

    /**
     * 1. Holds the status to display the add payment card screen after signup to the logged-in user
     * 2. The Signup process is triggered by clicking the "Add a new card" option from the payment method
     */
    ShouldDisplayAddPaymentCardScreenAfterSignup: boolean;

}

/** Initial value of the PaymentState state */
export const PaymentStateInitialState: PaymentState = {
    AllCards: [],
    DefaultCard: null,
    IsCardRegistrationPanelOpen: false,
    PaymentCardError: null,
    ShouldDisplayAddPaymentCardScreenAfterSignup: false
};