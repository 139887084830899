import { ReduxStoreSlice } from "../../../ReduxStoreSlice";
import { Dialog2State, DefaultDialog2State } from "./Dialog2State";
import { VerificationDialogInput, VerificationDialogResult } from "../Verification/UIEntities";
import { DialogSetup } from "../Entities";
import { DialogKind } from "../../Dialog/DialogEntities";
import { RemoveDialogInput, RemoveDialogResult } from "../BookingTemplate/RemoveTemplateEntities";

const slice = new ReduxStoreSlice<Dialog2State>("Dialog2", DefaultDialog2State);

/** Dispatcher for actions in the Dialog (V2) state slice. */
export const Dialog2Dispatch = {

    /** Activate a mobile phone number SMS verification input dialog. */
    Verification: slice.Action("Verification", Verification),

    /** Close the dialog */
    Close: slice.EmptyAction("Close", () => DefaultDialog2State),

    /** Activate the confirmation dialog to remove a booking template. */
    RemoveTemplate: slice.Action("Remove Booking Template", RemoveBookingTemplate),
}

/** Reducer for the Dialog (V2) store slice */
export const Dialog2Reducer = slice.MakeCombinedReducer();

/** Activate a mobile phone number SMS verification input dialog. */
function Verification(state: Dialog2State, setup: DialogSetup<VerificationDialogInput, VerificationDialogResult>): Dialog2State {

    return {
        ActiveDialog: DialogKind.Verification,
        CloseDialogCallback: setup.CancelCallback,
        Verification: setup.Props,
    };
}

/** Activate the comfirmation dialog to remove a booking template. */
function RemoveBookingTemplate(state: Dialog2State, setup: DialogSetup<RemoveDialogInput, RemoveDialogResult>): Dialog2State {
    return {
        ActiveDialog: DialogKind.RemoveBookingTemplate,
        CloseDialogCallback: setup.CancelCallback,
        RemoveTemplate: setup.Props
    };
}