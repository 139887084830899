import React from 'react';
import ReactDOM from 'react-dom';
import invariant from 'invariant';
import { OverlayView } from 'react-google-maps';
import { OVERLAY_VIEW } from 'react-google-maps/lib/constants';

// We keep this file as Js version, as OverlayView does not support typings very well
export default class CustomOverlayView extends OverlayView {
    /**
     * Override the draw function of react-google-maps to prevent errors with mapPanes being undefined.
     * The only change for this function is the conditional check for map panes and container in line 23.
     */
    draw() {
        const { mapPaneName } = this.props;

        invariant (
            !!mapPaneName,
            `OverlayView requires either props.mapPaneName or props.defaultMapPaneName but got %s`,
            mapPaneName);

        // https://developers.google.com/maps/documentation/javascript/3.exp/reference#MapPanes
        const mapPanes = this.state[OVERLAY_VIEW].getPanes();
    
        /* Check the existence of map panes and the container before drawing */
        if (mapPanes && this.containerElement) {
            mapPanes[mapPaneName].appendChild(this.containerElement);

            ReactDOM.unstable_renderSubtreeIntoContainer(
                this,
                React.Children.only(this.props.children),
                this.containerElement,
                this.onPositionElement);
        }
    }
}