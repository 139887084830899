import React, { useEffect } from "react";
import "./ExpandedSection.scss";
import { BookingDataOwnership, BookingStatus } from "../../../Services/BookingEntities";
import { ContentURL, getContentUrl } from "../../Utils/ContentURL";
import { byConditionId } from "../../../utils/service-vehicles/LookupServices";
import services, { ServiceKind, ServiceInfo } from "../../../utils/service-vehicles/ServiceMetadata";
import { GetBrandedUrl, BrandedImage } from "../../Utils/BrandedContentUrls";
import { GoogleMapWrappedForTaxiTracking } from "../../../widgets/google-maps/GoogleMapWrapped";
import { FeatureFlags } from "../../../Config/FeatureFlags";
import { TryCancelTheBooking, ShareTheBooking, RemoveBookingCard } from "../Logic/OperateOnBookingCard";
import { PopulateSelectedTripDetails } from "../../Booking/QuickBook/PopulateSelectedTripDetails";
import { RecentTripButtonKind } from "../../UILogicControl/UILogicControlEntities";
import { QuickBookSource } from "../../Booking/QuickBook/QuickBookEntities";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RouteUrls } from "../../../RouteUrls";
import { GetValues } from "../../../Config/MyAppConfig";
import { ConsiderRefreshingPODImageUrls, PerformPODImageUrlRefresh } from "../PodRefresh/RefreshPODImagesUrls";
import { ShouldWatchForPodImageUrls } from "../PodRefresh/PodRefreshValidations";
import { ProofOfDeliveryImageCarouselProps, ProofOfDeliveryImageContainer } from "./ProofOfDeliveryImageContainer";
import { PollingIntervalInMillis, } from "../PodRefresh/PodRefreshParameters";
import { BookingInfo } from "../MyBookingEntities";

import { GetVehicleDetails } from "../../Condition/VehicleToServiceTranslater";

export interface BookingTrackingProps {
    BookingDetails: BookingInfo;
}

/** Component contains the expanded section of a specific booking. Displaying additional details about the booking such as address details, vehicle type etc. */
export const ExpandedSection: React.FC<BookingTrackingProps & ProofOfDeliveryImageCarouselProps> = (props) => {

    /** Set the timer for loading the proof of delivery images for delivery bookings */
    useEffect(() => {

        if (!ShouldWatchForPodImageUrls(props.BookingDetails)) return;

        // Call the API to fetch the image urls whenever the booking card is expanded
        PerformPODImageUrlRefresh(props.BookingDetails);        

        var interval = setInterval(async () => await ConsiderRefreshingPODImageUrls(props.BookingDetails), PollingIntervalInMillis);

        return () => clearInterval(interval);
             
    }, []);

    return (
        <div className="expanded-section">
            <ProofOfDeliveryImageContainer {...props} />            
            {ShouldShowMapInBookingCard(props.BookingDetails) && <GoogleMapWrappedForTaxiTracking BookingDetails={props.BookingDetails} />}
            <BookingDetails BookingDetails={props.BookingDetails} /> 
            {!ShouldShowMapInBookingCard(props.BookingDetails) && <BookingCardImageBlock BookingDetails={props.BookingDetails} />}
            <BookingCardOperations BookingDetails={props.BookingDetails} />
        </div>          
    );
}

/**
 * Pickup & dropoff details
 */
const BookingDetails: React.FC<BookingTrackingProps> = (props) => {
    const shouldShowReadOnlyText = props.BookingDetails.DataOwnership === BookingDataOwnership.ReadOnlyLink;

    return (
        <div className="address-details">
            {shouldShowReadOnlyText && <div className="read-only-text">This is a shared booking.</div>}
            <div className="pickup-dest-address">
                <img src={GetBrandedUrl(BrandedImage.DotA)}></img>
                <div>{props.BookingDetails.PickupText}</div>
            </div>
            <div className="pickup-dest-address dropoff-address">
                <img src={getContentUrl(ContentURL.images.MyBookings.DotB)}></img>
                <div>{props.BookingDetails.DropoffText}</div>
             </div>
        </div>
    );
}

/**
 * Show vehicle/condition
 */
const BookingCardImageBlock: React.FC<BookingTrackingProps> = (props) => {

    // Vehicle info lookup differs based on the ApiVersion of the current booking.
    function MapConditionIdToServiceInfo(): ServiceInfo {
        // Booking is from V1 API
        if (props.BookingDetails.ApiVersion === 1) return byConditionId(props.BookingDetails.CarConditionID);

        // Booking from V2 API, no ConditionId
        if (!props.BookingDetails.CarConditionID) return services.any;

        // Booking from V2 API, CarConditionId presents
        return GetVehicleDetails(props.BookingDetails.CarConditionID);
    }

    const vehicleInfo = MapConditionIdToServiceInfo();
    const vehicleImage = vehicleInfo ? vehicleInfo.trackingImage : services.any.trackingImage;
    const vehicleName = vehicleInfo ? vehicleInfo.displayName : services.any.displayName;
    const isParcelBooking = vehicleInfo && vehicleInfo.kind === ServiceKind.Parcel;
        
    // Do not show max seat information for parcel booking 
    const maxSeat = isParcelBooking ? "Portable and fits in car" : `${props.BookingDetails.MaxSeat} Passengers`;

    // No service image for cancelled/completed bookings.
    if (props.BookingDetails.TrackingInfo.BookingStatusID == BookingStatus.Cancelled || props.BookingDetails.TrackingInfo.BookingStatusID == BookingStatus.Completed) {
        let statusImage = getContentUrl(ContentURL.images.RedCirleWithX);
        let statusText = "Booking Cancelled";

        if (props.BookingDetails.TrackingInfo.BookingStatusID == BookingStatus.Completed) {
            statusImage = getContentUrl(ContentURL.images.GreenCircleWithCheck);
            statusText = "Trip Completed";

        if (props.BookingDetails.IsDeliveryBooking) {
            statusText = "Delivery Completed";
            }
        }

        return (
            <div className="replace-condition-with-status">
                <img src={statusImage} className="status-image"></img>
                <div>{statusText}</div>
            </div>
        );
    }

    return (<div className="selected-vehicle active-booking-condition">
                <div><img src={vehicleImage} className="vehicle-image"></img></div>
                    <div className="condition-section light-font">
                    <div className="car-name">{vehicleName}</div>
                    <div className="max-seats">{maxSeat}</div>
                </div>
            </div>);
}

/**
 * Buttons of operations per booking in list
 */
const BookingCardOperationsCore: React.FC<BookingTrackingProps & RouteComponentProps> = (props) => {

    /** Open brand specific contact us page. */
    function OpenContactUs() {

        if (GetValues().ExternalContactUsUrl) {
            window.open(
                GetValues().ExternalContactUsUrl!, // This is not null inside the if block. TS still needs assertion.
                '_blank' // Open in a new tab
            );
            return;
        }

        props.history.replace(RouteUrls.ContactUs);
    }

    function CanCancelBooking() {
        if (props.BookingDetails.DataOwnership === BookingDataOwnership.ReadOnlyLink) return false;

        // Cannot cancel bookings after picked up by the driver.
        if (props.BookingDetails.TrackingInfo.BookingStatusID < BookingStatus.PickedUp) return true;
        return false;
    }

    // Completed/Cancelled
    function ShouldShowContactUs() {
        if (props.BookingDetails.DataOwnership === BookingDataOwnership.ReadOnlyLink) return false;
        if (props.BookingDetails.TrackingInfo.BookingStatusID == BookingStatus.Completed) return true;
        if (props.BookingDetails.TrackingInfo.BookingStatusID == BookingStatus.NoJob) return true;

        return false;
    }

    function ShouldShowBookReturn() {
        if (!FeatureFlags.BookAgainFromTracking) return false;
        if (props.BookingDetails.DataOwnership === BookingDataOwnership.ReadOnlyLink) return false;
        if (props.BookingDetails.TrackingInfo.BookingStatusID == BookingStatus.Completed) return true;

        return false;
    }

    function ShouldShowBookAgain() {
        if (!FeatureFlags.BookAgainFromTracking) return false;
        if (props.BookingDetails.DataOwnership === BookingDataOwnership.ReadOnlyLink) return false;
        if (props.BookingDetails.TrackingInfo.BookingStatusID == BookingStatus.Cancelled) return true;
        if (props.BookingDetails.TrackingInfo.BookingStatusID == BookingStatus.NoJob) return true;

        return false;
    }

    // Only active bookings are allowed to share. Currently only available for V1 API.
    function ShouldShowShare() {
        if (props.BookingDetails.DataOwnership === BookingDataOwnership.ReadOnlyLink) return false;
        if (props.BookingDetails.TrackingInfo.BookingStatusID < BookingStatus.Completed) return true;

        return false;
    }

    // Any read only booking can be removed from the list
    function ShouldShowRemove() {
        if (props.BookingDetails.DataOwnership === BookingDataOwnership.ReadOnlyLink) return true;
        if (props.BookingDetails.TrackingInfo.BookingStatusID >= BookingStatus.Completed) return true;

        return false;
    }
    
    // Only shows for non-test mode
    if (props.BookingDetails.DataOwnership === BookingDataOwnership.Test) return null;

    return (
        <div className="expanded-footer">
            {CanCancelBooking() && <button onClick={() => { TryCancelTheBooking(props.BookingDetails) }} className="cancel-booking-card">Cancel</button>}
            {ShouldShowContactUs() && <button onClick={() => { OpenContactUs() }} className="booking-card-outlined-btn">Contact Us</button>}
            {ShouldShowShare() && <button onClick={() => { ShareTheBooking(props.BookingDetails) }} className="share-booking-btn">Share</button>}
            {ShouldShowBookReturn() && <button onClick={() => { PopulateSelectedTripDetails(RecentTripButtonKind.BookReturn, props.BookingDetails, QuickBookSource.BookingCard) }} className="booking-card-outlined-btn">Book Return</button>}
            {ShouldShowBookAgain() && <button onClick={() => { PopulateSelectedTripDetails(RecentTripButtonKind.BookAgain, props.BookingDetails, QuickBookSource.BookingCard) }} className="booking-card-outlined-btn">Book Again</button>}
            {ShouldShowRemove() && <button onClick={() => { RemoveBookingCard(props.BookingDetails) }} className="booking-card-solid-btn">Remove</button>}
        </div>
    );
}

const BookingCardOperations = withRouter(BookingCardOperationsCore);

/**
 * Map will only show when:
 *   1) Feature flag enabled;
 *   2) Accepted by driver;
 *   3) Picked up;
 */
function ShouldShowMapInBookingCard(bookingDetails: BookingInfo): boolean {
    return FeatureFlags.ShowMapInBookingCard && (bookingDetails.TrackingInfo.BookingStatusID === BookingStatus.Accepted || bookingDetails.TrackingInfo.BookingStatusID === BookingStatus.PickedUp);
}
