
/** 
 *  URL segments used in routing.
 *  By defining these as named constants, we can make sure that any links match the route defined in App.tsx.
 */
export const RouteUrls = {

    /** This URL triggers the silent login / signup process. */
    SilentLogin: "/login",

    /** The URL Auth0 will redirect to after a login. */
    LoginCallbackV2: "/loginreturnv2",

    /** Terms and conditions statement. */
    TermsAndConditions: "/Terms",

    /** Privacy policy statement */
    PrivacyPolicy: "/Privacy",

    /** Contact details and the form to submit any inquiries. */
    ContactUs: "/contact-us",

    /* Payment wallet list */
    PaymentWallet: "/payment",
    
    /** User personal details */
    PersonalDetails: "/personal"
}