import { AccountBookingPayload, Address, NotesToDriver, Passenger, PickupServiceCheckState, ServiceCheckStatus, Verification } from "../BookingEntities";
import { Config } from "../../../Config/Config";
import { PaymentOption } from "../../Payment/PaymentEntities";
import { AddressV2 } from "../../../Services/MakeBookingContracts";
import { BookingTimeV2 } from "../../BookingV2/BookingV2Entities";

/** Shared application state for the booking under construction in the create booking UI */
export interface BookingWorkFlowState {
    pickup?: Address,
    dropoff?: Address,
    Verification: Verification,
    Passenger: Passenger,
    NotesToDriver?: NotesToDriver;
    PickupServiceCheck: PickupServiceCheckState;
    PaymentOption?: PaymentOption;

    /** When true, adds a debug UI component to show the internal loading status */
    ShowDiagnosticUI: boolean;

    /** Whether the User has selected to make the booking on Account. When true, additional booking input fields are available */
    IsOnAccount: boolean;

    /** Account specific information only for bookings with accounts. */
    AccountData: AccountBookingPayload | null;

    /** It is user's device information(used for fraud detection), it is set using braintree library,it is a required field to successfully do an app payment
     * Booking api decides whether it is valid card booking by checking existence of device data field.
     * MPS also sends this devicedata to braintree along with transaction request,braintree uses kount for fraud detection.
    */
    DeviceData?: string;

    /** Template name text for favourites */
    TemplateName?: string;

    /** Pickup address details to use for V2 booking service (Global Booker). */
    PickupV2: AddressV2 | null;

    /** Dropoff address details to use for V2 booking service. */
    DropoffV2: AddressV2 | null;

    /** Booking timing details to use for V2 booking service. */
    BookingTimeV2: BookingTimeV2;
}

/** Initial value of the Booking state */
export const DefaultBookingWorkFlowState: BookingWorkFlowState = {
    PickupServiceCheck: {
        status: ServiceCheckStatus.NoInputSelected
    },
    Verification: {},
    Passenger: {
        PassengerContactNumber: {
            CountryInfo: Config.DefaultCountryInfo,
        } 
    },
    ShowDiagnosticUI: false,
    IsOnAccount: false,
    AccountData: null,
    PickupV2: null,
    DropoffV2: null,
    BookingTimeV2: {
        IsImmediate: true
    },
};