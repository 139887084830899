import { AuthToken } from "../AuthEntities";

/** 
 * This enum is a typing which represents input widget in sign-up or log-in popup.
 * 
 * N.B.
 * In order to remove some check and unnecessary codes, We will use the value per item as the label name above per input widget in popup.
 */
export enum SignupLoginInputKind {
    ProfileName = 'Your profile name',
    ContactNumber = 'Your number',
    Email = 'Your email',
    Password = 'Password'
}

/**
 * This typing is used as a return type of a helper function which is used for sign-up password error message panel.
 * This is used for AuthHelper.DecideCredentialErrorMessagePerItemStyle().
 */
export interface PasswordErrorItemStyleDecisionObject {
    Icon: string;
    CssClass: string;
}

/** 
 * For credential password error message, per criterion (please see description of function AuthHelper.DecideCredentialErrorMessagePerItemStyle()), error type can be either "midding" or "incorrect".
 * Thus enum is used for the type of error.
 */
export enum PasswordErrorType {
    Missing = 'Missing value',
    Incorrect = 'Incorrect'
}

/** The data sent from the child (popup) back to the parent when log-in is happening */
export interface MessageFromChild {
    MessageKind: ChildMessageKind;
    LoginResult?: LoginResultMessage
}

/** To indicate the type of message from child */
export enum ChildMessageKind {
    /** Message 1: child has created its message handler. */
    ChildIsReady = "Child Is Ready",
    
    /** Message 2: child has logged in */
    LoginResult = "Login Result",
}

/** Login details from client to server */
export interface LoginResultMessage {
     IsSuccess: boolean;
     ErrorMessage?: string;
     AuthToken?: AuthToken;
}

/** Type of message between parent & child while login happening */
export interface LoginCredentialMessage {
    UserName: string;
    Password: string;
}

/** This enum typing indicates the user login/signup behaviour  */
export enum AuthType {
    Login = "Authenticate the user",
    Signup = "Register the user"
}

/**
 * This enum typing indicates the kind of contact number, validity has been guaranteed before this.
 */
export enum ContactNumberKind {
    Mobile = "Both international and domestic mobile number",
    Landline= "Only domestic landline number",
    NotAccepted= "Not Accepted"
}

/** 
 * This typing is used for the return type of ../AuthHelper.ValidateContactNumber()
 */
export interface ContactNumberValidationResult {
    IsValid: boolean;
    ContactNumberKind: ContactNumberKind;
}

/**
 * This typing will be used for where to show ../Authentication/AuthEntities.ErrorMessage.
 * For most case, error message will displayed in credential popup.
 * If it's for per credential input, this will be displayed as error message under that input.
 */
export enum ErrorMessagePlacementKind {
    Popup = "Error message will display in general place holder of credential popup, such as signup or login",
    Email= "Credential email/username"
}

/**
 * This is used for defining signup credential object.
 */
export interface SignupCredential {
    FirstName: string;
    LastName: string;
    ContactNumber: string;
    Email: string;
    Password: string;
}

/**
 * https://cabcharge.atlassian.net/wiki/spaces/CA/pages/1020002313/Verification+before+Signup
 */
export interface CredentialLoggingInput {
    Username: string;
    ErrorMessage?: string;
}