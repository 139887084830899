import React, { useEffect, useState } from 'react';
import { Card, CardMedia } from '@material-ui/core';
import { ContentURL, getContentUrl } from '../../Utils/ContentURL';
import Carousel, { CarouselProps } from 'react-material-ui-carousel';
import { BookingInfo } from '../MyBookingEntities';

interface ProofOfDeliveryImageCarouselProps {
    CloseCarousel: () => void;
    SelectedImageIndex: number;
    BookingDetails: BookingInfo;
}

/**
 * Displays the carousel for proof of delivery images
 */
export const ProofOfDeliveryImageCarousel: React.FC<ProofOfDeliveryImageCarouselProps> = (props) => {

    /**
     * Initially, the carousel animation is set as fade.
     * This fixes the image flicker behaviour, where the series of images appear starting from the first image and stopping at the last one.
     */
    const [animation, SetAnimation] = useState<CarouselProps["animation"]>("fade");

    // Change the carousel animation
    useEffect(() => SetAnimation('slide'), []);

    const booking = props.BookingDetails;
    const podUrls = booking.TrackingInfo.ProofOfDeliveryUrls;

    if (!podUrls) return null;

    // Hide navigator buttons if, only one image exist
    const hasOnlyOnePODImage = podUrls.length === 1;
    
    return (
        <>
            <Carousel
                timeout={300}
                autoPlay={false}
                indicators={true}
                animation={animation}
                className="pod-carousel"
                index={props.SelectedImageIndex}
                navButtonsAlwaysVisible={!hasOnlyOnePODImage}
                navButtonsAlwaysInvisible={hasOnlyOnePODImage}
            >
                {
                    podUrls.map((item, index) => {
                        return <Card key={index} className="pod-carousel-div">
                            <CardMedia className="pod-Media" image={item} component="img"></CardMedia>
                        </Card>
                    })
                }
            </Carousel>
            <img className="close-pod-carousel" src={getContentUrl(ContentURL.images.BlackCirleWithX)} alt="close" onClick={props.CloseCarousel} />
        </>
    )
}