import { Moment } from "moment";
import { GeoPoint } from '../modules/Location/Entities';
import { Address } from "../modules/Booking/BookingEntities";

/** A low-level data type roughly corresponding to a type of vehicle.
 *  Corresponds to the Bcc.Api.Model.ViewModel.Condition class in the Booking API. */
export interface Condition {

    /** Internal ID, from the database */
    ID: number;

    /** "Sedan" or "Wagon" etc */
    Name: string;

    /** Number of passengers this type of vehicle seats. */
    MaxSeat: number;

    /** "up to 7 passengers" */
    Description: string;
}

/** Result of TrackBookingByBookingID */
export interface BookingTrackingResult {

    /** 
     *  The recent history of the vehicle's GPS position.
     *  The array elements are in ascending time order. i.e. the last entry is the most current.
     *  Sorry about the typo; it's stuck in the API contract.
     */
    vechileLocation?: VehicleLocation[];

    /** Taxi number, e.g. T1234 */
    CarNumber?: string;

    /** Now vs Future Booking */
    TimeMode?: BookingTimeMode;

    /** Requested pickup time. DateTime formatted as string */
    Time: string;

    /** 
     * The requested pickup time, formatted using ISO 8601, including a UTC offset corresponding to the local time zone of the booking. 
     * It may rarely be null when the service could not determine the local time zone.
     */
    LocalTime: string | null;

    DriverName: string | null;

    /** URL to a photo of the driver */
    DriverImage: string | null;

    /** ID of the driver assigned to the booking. 
     * This is not in the initial tracking response and it is populated later with get status call. Therefore it is optional. */
    DriverID?: number | null;

    /** Human-readable pickup address */
    Pickup: string;

    /** Human-readable destination address. May be the string "To be confirmed" if not specified on the booking. */
    Dropoff?: string;

    /** A string in like "1 - 4" for a 4-passenger vehicle */
    MaxSeat: string;

    CarConditionID?: number;

    /** Seems to come from BccDispatchConditions.DispatchSystemConditionName */
    CarConditionName?: string;

    /** Booking Status name as string, e.g. "NotDispatched", "Assigned" etc. */
    BookingStatus: string;

    /** ID corresponding to the booking status, e.g. NotDispatched = 1, etc. */
    BookingStatusID: BookingStatus;

    /** Internal booking ID */
    DispatchBookingID: number;

    /** Main booking ID */
    BookingID: number;

    /** Lat and Long of the pickup location */
    PickupLocation: ApiGeoPoint

    /** Either a human-readable description of the distance from the taxi to the pickup, e.g. "4 km", or a placeholder value of "1 km" if the distance isn't known or the booking isn't Accepted. */
    Distance?: string;

    /** Either a human-readable description of the ETA of the taxi to the pickup, e.g. "3 min", or a placeholder value of "1 min" if the distance isn't known or the booking isn't Accepted. */
    Duration?: string;

    /** Internal hash of the booking details. This value is private to the owner of the booking and is used to prove ownership in the guest scenario. */
    HashCode: string;

    /** Passenger name. This can be different from the user name of the profile. */
    PassengerName: string;

    /** Lat and Long of the Dropoff location if available.  */
    DropoffLocation?: ApiGeoPoint;

    /** Who owns the booking. This influences what actions are available for the user to perform.
     * Populated by the Management service. */
    DataOwnership?: BookingDataOwnership;

    /** Contact name */
    ContactName: string;

    /** Driver remarks */
    NotesToDriver?: string;

    /** Number of passengers */
    Pax: number;

    /** Order number */
    OrderNumber?: string;

    /** Account number */
    AccountNumber?: string;

    /** Passenger/Contact phone number */
    CustomerPhone?: string;

    /** Access code used in the tracking URL in share booking. The users with this code has read only access to the particular booking. */
    ReadAccessCode?: string;

    /** Base URL of the booking tracking link. This is combined with the ReadAccessCode to make the share booking link. */
    BookingTrackingBaseUrl?: string;

    /** Whether this booking is a booking made via the Delivery API. */
    IsDeliveryBooking: boolean;

    /** List of proof of delivery image urls for completed bookings */
    ProofOfDeliveryUrls?: string[];

    /** The time when the booking status changed to completed for the first time. This is an temporary alternative to the MeterOffTime.
     * Detected and set by the client in the MyBookings reducer.*/
    DropoffTime?: Moment;
}

/** Who owns a booking */
export enum BookingDataOwnership {

    /** Created as a guest booking. Not associated with any user. */
    Guest = "Guest",

    /** Created while signed in. Owned by the current user. */
    User = "User",

    /** Test data load. Not owned by the current user. */
    Test = "Test",

    /** This booking has been opened via a shared link with read only access. */
    ReadOnlyLink = "ReadOnlyLink",

    /** A tracking link with write access. The user can cancel this booking, and may attempt to promote it. */
    WritableLink = "WriteableLink",
}

export interface BookingLocationResult {
    
    /** Driver notes */
    remark: string,

    /** Placeid for Pickup/Dropoff address. Can be null if the booking was created in a different channel. */
    googlePlaceId: string | null,

    isPickup: boolean
}

/** Best effort at getting Google maps place ID for pickup and dropoff. */
export interface AddressPlaceId {
    
    /** Placeid for Pickup address */
    PickupPlaceId: string | null,

    /** Placeid for Dropoff address */
    DropoffPlaceId: string | null
}


/** NotesToDriver Response from Booking Mgmt */
export interface NotesToDriverResult {
    Message: string
}

/** Booking Statuses from the API. Possibly don't need the bottom half */
export enum BookingStatus {
    /** In the future and not yet trying to find a cab */
    NotDispatched = 1,

    /** Looking for a cab to take this job */
    Dispatching = 2,

    /** Job has been offered to a cab */
    Assigned = 3,

    /** Cab has received offer */
    Acknowledged = 4,

    /** Cab has accepted the job, headed to pickup */
    Accepted = 5,

    /** Meter on */
    PickedUp = 6,

    /** Meter off */
    Completed = 7,

    /** Cancelled by user */
    Cancelled = 8,

    /** Customer could not be found at pickup point */
    NoJob = 9,

    // There's a bunch more statuses we don't handle
}

/** Whether the user booked for the future or now */
export enum BookingTimeMode {

    /** Future booking */
    SpecificTime = "SpecificTime",

    /** Now booking */
    NextAvailable = "NextAvailable",
}

/** GPS coordinates. Careful: it differs from the other GeoPoint type we use internally, which has lower case L's. */
export interface ApiGeoPoint {
    Latitude: number;
    Longitude: number;
}

/** The location of a Vehicle at some point in time */
export interface VehicleLocation extends ApiGeoPoint {

    /** Unfortunately the format of this is not well known. It comes out as a string from several API layers deep */
    Time: string;

    /** When the data was loaded on the client. Only populated at client side, from BulkStatusRefresh. */
    LocalDataLoadedTime?: Moment;
}

/** Minimal version of a Booking status, optimised for vehicle tracking polling.
 * It only contains the fields that can change over time, like status, car number, driver. */
export interface LightweightBookingStatus {

    /** The Booking ID, to help identify which record is which when you request multiple booking records in one query. */
    BookingId: number;

    /** Well-known ID for booking status */
    BookingStatusId: BookingStatus;

    /** Car number suitable for display to human.
     * May be null if there is no car defined, e.g. booking is pre-dispatching.*/
    CarNumberPlate: string | null;

    /** Internal Booking API Driver ID for the driver, or null if the Booking does not have a driver associated with it yet. */
    DriverId: number | null;

    /** GPS location of the car, if known. Only populated when the booking is Accepted or PickedUp. */
    VehicleLocation: VehicleLocation | null;
}

/** A Booking ID combined with the hashcode proves the client's ownership of the booking. */
export interface BookingAuthority {
    bookingId: number;
    hashCode: string;
}

/** Request to get the list of recent bookings of the logged in user. */
export interface MyBookingsRequest {
    /** A list of booking statuses. Useful to get active bookings or history based on user selection. */
    StatusIDs: number[];

    /** Number of bookings to be retrieved. */
    Limit: number;

    /** How many bookings to be skipped (offset). Useful for pagination. */
    Skip: number;
}

export interface AddressInput {   
    latitude?: number,
    longitude?: number,
    placeId: string | null,
    placeText: string
}

export interface PlaceResult {
    address: Address,
    location: GeoPoint,
    place : google.maps.places.PlaceResult
}

/** Request to share a booking via SMS */
export interface ShareBookingRequest {
    /** BookingID */
    BookingID: number;

    /** Mobile number to send the SMS */
    PhoneNumber: string;

    /** Dispatch booking ID */
    DispatchBookingID: string;

    /** Booking Hashcode (this is read only hashcode) */
    HashCode: string;

    /** Booking date and time */
    BookingDateTime: string;

    /** API version of the booking. This is used in the backend for validation and to determine the tracking link. */
    ApiVersion: number;
}

/** Read only hashcode and the share URL for share booking */
export interface ReadOnlyHash {
    /** Read only hashcode */
    HashCode: string;

    /** Corresponding mobile website's URL to display the share URL */
    UrlBase: string;
}

/** Minimal details of the driver assigned to a particular booking. */
export interface BookingDriverDetails {
    BookingId: number;

    /** Display name of the driver. */
    DriverName: string | null;

    /** URL of the location where the driver's image is stored. */
    DriverImageURL: string | null;
}

/** Create booking request payload. */
export interface BookingRequestV1b {

    /** Pickup location details including address, contact details etc. */
    PickupLocation: BookingLocationV1;

    /** Optional dropoff location details including address, contact details etc. */
    DropoffLocation: BookingLocationV1 | null;

    /** Booking's main contact details. */
    ContactInfo: BookingContactDetails;

    /** Payment details including payment method, fare etc */
    Payment: BookingPaymentV1;

    /** Number of passengers travelling in this booking. */
    Pax: number;

    /** Timing information */
    Timing: BookingTimingV1;

    /** User selected conditions. */
    Conditions: ConditionV1[] | null;

    /** The ID of an already verified SMS challenge, which proves ownership of the [contactPhone] phone number. 
     * This is required for guest bookings. */
    SmsChallengeId: string | null;

    /** Meta information about the client. */
    Client: ClientDetails;
}

/** Booking location details. */
export interface BookingLocationV1 {

    /** Google Maps place ID for the location. */
    PlaceId: string;

    /** Contact person and details for this location. i.e.: pickup and dropoff locations can have different contacts */
    ContactDetails: BookingContactDetails;

    /** Optional instructions to the driver. */
    DriverInstructions: string | null;
}

/** Contact details of various parties involved in a booking. e.g.: main contact person (booker), pickup contact, dropoff contact */
export interface BookingContactDetails {
    Name: string;
    PhoneNumber: string;
}

/** Payment related data associated with a booking. */
export interface BookingPaymentV1 {

    /** User's device information(used for fraud detection), it is set using braintree library, required to successfully do an app payment.
     * Booking api decides whether it is valid card booking by checking existence of device data field.
     * MPS also sends this devicedata to braintree along with transaction request,braintree uses kount for fraud detection.
     */
    DeviceData: string | null;

    /** Selected payment card's ID (not the card number). */
    PaymentCardId: string | null;

    /** Fare details. Null only if fixed fare details are not available for the booking location. */
    Fare: FareEstimate | null;

    /** Account details if the booking is paid with an account. */
    AccountInfo: BookingAccountV1 | null;

    /** Whether this booking/payment should be pre-authorised. Should be true for all CNP bookings if feature flag is enabled. */
    PaymentPreAuthOptIn: boolean;

    /** Whether the client chose this booking to be fixed fare.
     * This is required in addition to FixedFareId and FixedFarePartitionKey to specify a booking is actually fixed fare since other fields are also populated for meter fare bookings for pre auth purpose.*/
    IsFixedFare: boolean;
}

/** When the booking occurs (pickup time details) */
export interface BookingTimingV1 {

    /** String representation of a datetime. This is the requested pickup time.
     * [Obsolete] will be removed after all the clients are released. */
    Time: string | null;

    /**
     * The requested time, expressed in the local timezone of the booking, formatted using ISO 8601 with a time zone offset.
     * This field is optional and replaces (supersedes) [Time] when populated.
     */
    LocalTime: string | null;

    /** Whether the pickup is ASAP. When false (future booking), the pickup time must be populated. */
    IsImmediate: boolean;
}

/** Meta information about the current client. */
export interface ClientDetails {

    /** Whether the client is in mobile mode. */
    IsMobile: boolean;
}

/** Account related details in create booking request. */
export interface BookingAccountV1 {
    AccountNumber: string;

    /** Order number if applicable. */
    OrderNumber: string | null;
}

/** Fare estimate details. Populated for all bookings (fixed fare/meter fare) if available for the booking location. */
export interface FareEstimate {

    /** Unique ID of the selected fixed fare (unique for each vehicle type). */
    FixedFareId: string;

    /** CosmosDB partition key where the selected Fixed fare is stored. */
    FixedFarePartitionKey: string;
}

/** Selected conditions details. */
export interface ConditionV1 {

    /** Condition ID from the BCC database. */
    ID: number;
}