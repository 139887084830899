import { Reducer } from 'redux';
import { GoogleMapState } from './GoogleMapState';
import { GoogleMapAction, GoogleMapActionKind } from './GoogleMapActions';
import { GeoPoint } from '../Location/Entities';
import { DirectionsData } from './GoogleMapEntities';
import { areLocationSourcesEqual } from '../../widgets/google-maps/areLocationSourcesEqual';

/** Reducer for the Google Maps feature. This method just dispatches each case to their own methods. */
export const GoogleMapReducer: Reducer<GoogleMapState, GoogleMapAction> = (state = {}, action) => {
    if (action.type === GoogleMapActionKind.PickupSelected) {
        return HandlePickupSelected(state, action.location);
    }
    else if (action.type === GoogleMapActionKind.DropoffSelected) {
        return HandleDropoffSelected(state, action.location);
    }
    else if (action.type === GoogleMapActionKind.PickupCleared) {
        return HandlePickupCleared(state);
    }
    else if (action.type === GoogleMapActionKind.DropoffCleared) {
        return HandleDropoffCleared(state);
    }
    else if (action.type === GoogleMapActionKind.TaxiChanged) {
        return HandleTaxiChanged(state, action.location, action.vehicleNumber);
    }
    else if (action.type === GoogleMapActionKind.DirectionsAvailable) {
        return HandleDirectionsAvailable(state, action.data);
    }
    else {
        return state;
    }
}

/** Handler for the PickupSelected action. As well as the pickup location, it might also change the map center. */
function HandlePickupSelected(state: GoogleMapState, pickupLocation: GeoPoint): GoogleMapState {

    // direct change to pickup:
    state = { ...state, pickupLocation: pickupLocation };

    // redo computed state
    return RecomputeMapBounds(state);
}

/** Handler for the DropoffSelected action. As well as the dropoff location, it might also change the map center. */
function HandleDropoffSelected(state: GoogleMapState, dropoffLocation: GeoPoint): GoogleMapState {
    // direct change to dropoff:
    state = { ...state, dropoffLocation: dropoffLocation };

    // redo computed state
    return RecomputeMapBounds(state);
}

/** Removes the pickup location and recalculates the map center. */
function HandlePickupCleared(state: GoogleMapState): GoogleMapState {

    // clear pickup (from newState)
    const { pickupLocation, directions, ...newState } = state;

    // redo computed state
    return RecomputeMapBounds(newState);
}

/** Removes the dropoff location and recalculates the map center. */
function HandleDropoffCleared(state: GoogleMapState): GoogleMapState {

    // clear dropoff (from newState)
    const { dropoffLocation, directions, ...newState } = state;

    // redo computed state
    return RecomputeMapBounds(newState);
}

/** No side effects on other properties; just direct substitutions of the values. */
function HandleTaxiChanged(state: GoogleMapState, taxiLocation: GeoPoint, vehicleName: string): GoogleMapState {
    return {
        ...state,
        taxiNumber: vehicleName,
        taxiLocation,
    };
}

/** As long as the directions still match the location inputs, update it in the state. */
function HandleDirectionsAvailable(state: GoogleMapState, directions: DirectionsData): GoogleMapState {

    if (!areLocationSourcesEqual(state, directions)) {
        // directions no longer apply to these locations; ignore
        // it should be OK to do this silently
        return state;
    }
    else {
        return { ...state, directions}
    }
}

/** Recompute the MapCenter and MapZoom properties based on the existence of the pickup and destination addresses. */
function RecomputeMapBounds(state: GoogleMapState): GoogleMapState {

    let newCenter: GeoPoint | undefined;
    let zoomLevel: number | undefined;

    if (!state.pickupLocation && !state.dropoffLocation) {
        newCenter = undefined;
        zoomLevel = undefined;
    }
    else if (!state.pickupLocation) {
        newCenter = state.dropoffLocation;
        zoomLevel = 18;
    }
    else if (!state.dropoffLocation) {
        newCenter = state.pickupLocation;
        zoomLevel = 18;
    }
    else {
        newCenter = GetMidpoint(state.pickupLocation, state.dropoffLocation);
        zoomLevel = undefined;
    }

    state = { ...state, mapCenter: newCenter, zoomLevel: zoomLevel };
    return state;
}

/** Returns the middle of two GeoPoints. I'm not worried about wrapping effects since we are Aus only. */
function GetMidpoint(point1: GeoPoint, point2: GeoPoint): GeoPoint {

    const latitude: number = (point1.latitude + point2.latitude) / 2;
    const longitude: number = (point1.longitude + point2.longitude) / 2;

    const result: GeoPoint = {
        latitude,
        longitude,
    };

    return result;
}