import React from "react";
import { connect } from "react-redux";
import './StepControl.scss';
import { BookingStepperKind } from '../BookingEntities';
import { BookingWidgetModeKind } from '../../BookingTemplate/BookingTemplateEntities';
import { ApplicationState } from "../../../appState";
import { UILayoutMode } from "../../UILogicControl/UILogicControlEntities";
import { SimpleUserProfile } from "../../User/ProfileEntitiesV2";
import { CanBookOnAccount } from "../../Utils/UserProfileValidation";
import { CalculateIsBookingOnAccount } from "../NewBookingForm";

interface StepControlProps {    
    StepKind: BookingStepperKind;
}

export interface StepControlPropsFromStore {
    IsBookingOnAccount: boolean;
    BookingWidgetMode: BookingWidgetModeKind;
    IsMobileDevice: boolean;
    UserProfile?: SimpleUserProfile;
}

/**
 * To describe which step it is in booking form
 */
class StepControl extends React.Component<StepControlProps & StepControlPropsFromStore> {

    calculateStepNumber = (stepKind: BookingStepperKind) : number => {

        let stepNumberForBookingDetails : number = 1;
        let stepNumberForContactDetails : number = 2;
        let stepNumberForDriverInstructions : number = 3;     

        // When booking on accounts, account details section should appear at the top
        if(this.props.IsBookingOnAccount) {
            stepNumberForBookingDetails++;
            stepNumberForContactDetails++;
            stepNumberForDriverInstructions++;            
        }

        // Construct a map for each step kind and its calculated step number
        var table = new Map<BookingStepperKind, number>();

        table.set(BookingStepperKind.AccountDetails, 1);
        table.set(BookingStepperKind.BookingDetails, stepNumberForBookingDetails);
        table.set(BookingStepperKind.ContactDetails, stepNumberForContactDetails);
        table.set(BookingStepperKind.DriverInstruction, stepNumberForDriverInstructions);
        table.set(BookingStepperKind.PaymentMethod, 4);

        return table.get(stepKind)!;
    } 
    
    render() {
        const { StepKind, BookingWidgetMode, UserProfile, IsMobileDevice } = this.props;

        const isTemplateModeOn = BookingWidgetMode !== BookingWidgetModeKind.Booking;

        const totalSteps : number = 4, stepNumber: number = this.calculateStepNumber(StepKind);
    
        // Decrease margin-top value for Step 1
        const marginTopClass = stepNumber === 1 && (isTemplateModeOn || CanBookOnAccount(UserProfile, IsMobileDevice)) ? 'step1-header' : '';

        // Hide step details for booking template
        const stepDetail = !isTemplateModeOn ? `Step ${stepNumber} of ${totalSteps} ` : "";

        // Display black text colour for booking template
        const textColorClass = !isTemplateModeOn ? "brand-color" : "";

        return (            
            <div className={`form-section-header ${marginTopClass}`}>
                {stepDetail}
                <span className={`${textColorClass}`}>
                    {StepKind}
                </span>
            </div>            
        )
    }
}

function mapStateToProps(state: ApplicationState): StepControlPropsFromStore {
    return {
        UserProfile: state.authentication.UserProfile,
        BookingWidgetMode: state.uiLogicControl.BookingForm.BookingWidgetMode,
        IsMobileDevice: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile,
        IsBookingOnAccount: CalculateIsBookingOnAccount(state.authentication.LoginStatus, state.booking.IsOnAccount, state.uiLogicControl.LayoutMode === UILayoutMode.Mobile)
    }  
}

export default connect (mapStateToProps)(StepControl);

