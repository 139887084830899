import { FeatureId } from "./FeaturesEntities";

/** Application State that tracks the Whats new features. */
export interface FeaturesState {

    /** The feature IDs that are not acknowledged by the user */
    NewFeatureList: FeatureId[];

    /** The feature IDs that are acknowledged by the user */
    AcknowledgedFeatureList: FeatureId[];

    /** Hold the visibility status of the feature list  */
    IsFeatureListDisplayed: boolean;
    
}

export const FeaturesInitialState: FeaturesState = {
    
    NewFeatureList: [],
    AcknowledgedFeatureList: [],
    IsFeatureListDisplayed: false
}