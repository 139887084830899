import { Dispatch } from "../Dispatch";
import { ValidateServicesForSatss } from "./ConditionValidation";
import { CustomErrorMessages } from "../Utils/ErrorMessages";
import { VehicleOption } from "./Redux/ConditionEntities";
import { ApplicationState } from "../../appState";

/** After selecting a service, update various entities in the store and validate it against the location and payment method to display the error message. */
export function ProcessAndValidateSelectedService(selectedService: VehicleOption) {
    Dispatch.Condition.SelectedCondition(selectedService);
    Dispatch.UILogicControl.ValidateVehicleOnPickupChange(true);
    ValidateServicesForSatss();
}

/** 
 *  Derive the error message to be displayed along with the service selector.
 *  This method is suitable to be called from MapStateToProps()
 */
export function DeriveServiceErrorMessage(state: ApplicationState): string | null {
    const bookingFormUIState = state.uiLogicControl.BookingForm;

    if (!bookingFormUIState.IsSelectedVehicleServiceable) return CustomErrorMessages.ServiceNotAvailable;
    if (bookingFormUIState.IsInvalidConditionForSatss) return CustomErrorMessages.ServiceNotAvailableForSatss;

    return null;
}