import { GoogleMapRaw, GoogleMapProps } from './GoogleMapRaw';
import { areLocationSourcesEqual } from './areLocationSourcesEqual';
import { loadGoogleDirections } from './loadGoogleDirections';
import { Dispatch } from '../../modules/Dispatch';

/** Extends GoogleMapRaw with the following behaviour: when the pickup and dropoff locations are selected, automatically request directions from Google. */
export class DirectionsGoogleMap extends GoogleMapRaw {

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.considerRefreshingDirections();
    }

    componentDidUpdate(oldProps: Readonly<GoogleMapProps>) {

        if (this.haveLocationsChanged(oldProps)) {
            this.considerRefreshingDirections();
        }
    }

    /** Perform a refresh if needed */
    considerRefreshingDirections() {

        if (this.hasBothLocations() && this.doDirectionsNeedRefresh()) {
            this.refreshDirections();
        }
    }

    /** A requirement for directions is that both pickup and dropoff are populated */
    hasBothLocations(): boolean {
        return !!(this.props.pickupLocation && this.props.dropoffLocation);
    }

    /** Returns true when we are sure one of the location props has changed. Value equality. */
    haveLocationsChanged(oldProps: Readonly<GoogleMapProps>): boolean {

        return !areLocationSourcesEqual(this.props, oldProps);
    }

    /** Directions should be populated when the location inputs used to generate the directions no longer match the values in props */
    doDirectionsNeedRefresh(): boolean {

        // no existing data
        if (!this.props.directions) return true;

        return !areLocationSourcesEqual(this.props, this.props.directions);
    }

    async refreshDirections() {

        // pickup and dropoff are known to be defined at this point, since the code will have passed through hasBothLocations()
        const result = await loadGoogleDirections(this.props.pickupLocation!, this.props.dropoffLocation!, false);

        // google API fail: no further action possible
        if (result === null) return; 

        // dispatch!
        Dispatch.GoogleMap.DirectionsAvailable(result);
    }
}