import React from 'react';
import { TitleStyle, MarkAsRead, ParcelLinkHandler } from './FeaturesHelper';
import { getContentUrl, ContentURL } from '../Utils/ContentURL';
import { FeatureId, FeatureCardProps } from './FeaturesEntities';
import { GetValues } from '../../Config/MyAppConfig';
    
export const ParcelCard = (props: FeatureCardProps) => {
    return (
        <React.Fragment key={FeatureId.Parcel}>
            <div className="feature-card">
                <div className="feature-date">
                    <span className="feature-date-img"></span>
                    <span>04.06.2020</span>
                </div>
                <div style={TitleStyle(GetValues().BrandColour)} >NEED A THING DELIVERED NOW?</div>
                <div className="feature-desc">From grocery things to pharmacy things and all sorts of other things, we’ll bring!</div>
                <div className="feature-image">
                    <img src={getContentUrl(ContentURL.images.WhatsNew.parcelFeature)} alt="Parcel" />
                </div>
                <div className="feature-desc">Simply click here <a href="#" className="feature-link" onClick={ParcelLinkHandler}>Parcel Delivery</a> or choose from ‘Select your service’ when booking next.</div>
                {
                    // Hide the button, if the card is acknowledged
                    props.CanShowAcknowledgeButton && 
                    <div className="feature-btn">
                        <button className="btn-OK" onClick={() => MarkAsRead(FeatureId.Parcel)}>OK, GOT IT</button>
                    </div>
                }
            </div>
        </React.Fragment>
    );
}