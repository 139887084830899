import appstore from "../../appStore";
import { LoginStatusKind } from "../Authentication/AuthEntities";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { PaymentOptionKind } from "../Payment/PaymentEntities";
import { IsDVAUser } from "../BookingTemplate/BookingTemplateHelper";
import { DateTime } from "luxon";

/**
 * The basic idea is to ignore small changes in times and consider them the same, for the purposes of getting a new fare, at least.
 */
export function RoundTime(rawTime: DateTime): DateTime {
    return rawTime.set({
        second: 0,
        minute: 0,
    });
};

/** Decide if the user can book with Fixed fare based on different criteria. */
export function canBookWithFixedFare() {
    const currentState = appstore.getState();
    const selectedAccount = currentState.booking.AccountData?.SelectedAccount;

    // Fixed fare booking with cash is allowed. Hence no need further checking.
    if (FeatureFlags.FixedFareWithCash) return true;

    // Guest user
    if (currentState.authentication.LoginStatus === LoginStatusKind.LoggedOut) return false;

    // DVA user
    if (IsDVAUser(currentState.authentication.UserProfile)) return false;

    // Book with account. But the selected account has no permission to book with fixed price.
    if (selectedAccount && !selectedAccount.AllowFixedPriceBookings) return false;

    // Book with account, PG bookings on account is disabled.
    if (selectedAccount && !FeatureFlags.PriceGuaranteeWithAccount) return false;

    // Book on account
    if (selectedAccount) return true;

    // Signed in user, but no cards added in the profile
    if (currentState.payment.AllCards.length === 0) return false;

    // Payment card option is not selected
    if (currentState.booking.PaymentOption?.Kind !== PaymentOptionKind.Card) return false;

    return true;
}

/** Converting Cents to Dollars for display purposes. e.g: Display fixed fare which receives in cents. */
export function ConvertCentsToDollars(centAmount: number) {
    return (centAmount / 100).toFixed(2);
}
