import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import { Passenger } from "../BookingEntities";
import { Dispatch } from "../../Dispatch";
import { SimpleUserProfile } from "../../User/ProfileEntitiesV2";
import { BookingWidgetModeKind } from '../../BookingTemplate/BookingTemplateEntities';
import { VehicleOption } from "../../Condition/Redux/ConditionEntities";
import { OutlinedTextField, CustomTextFieldProps } from "./OutlinedTextField";
import { LogEvent } from "../../../utils/LogEvent";
import { ServiceKind } from "../../../utils/service-vehicles/ServiceMetadata";

interface PassengerDetailsProps {
	Passenger: Passenger;
	SelectedVehicle: VehicleOption,
	BookingWidgetMode: BookingWidgetModeKind;
	UserProfile : SimpleUserProfile | undefined,
	IsBookingFormStrictValidationModeOn: boolean;
}

interface PassengerDetailsState {
	isFocus: boolean;
}

class PassengerName extends React.Component<PassengerDetailsProps,PassengerDetailsState> {

	private inputRef: React.RefObject<HTMLInputElement>;

	constructor(props: PassengerDetailsProps) {
		super(props);
		this.inputRef = React.createRef();

		this.state = {
			isFocus: false
		};

		this.updateName = this.updateName.bind(this);
		this.inputValueCheckUpdate = this.inputValueCheckUpdate.bind(this);
	}

	updateFocusStatus = (isFocus: boolean) => {
		this.setState({ isFocus: isFocus });
	};

	updateName(e: React.FocusEvent<HTMLInputElement>) {
		this.updateFocusStatus(false);
		const ContactName = e.target.value.trim();
		Dispatch.Booking.UpdatePassengerDetails({ ContactName: ContactName });
		if (ContactName) {
			LogEvent.OnAddingPassengerName();
		}
	}

	/**
	 * 4 factors together drive invalid effect
	 * 1) Either Passenger.ContactName === undefined || Passenger.ContactName === "";
	 * 2) In strict validation mode;
	 * 3) Input not focused;
	 * 4) Not on booking template mode;
	 */
	IsInputValid = () => {

		const { Passenger, BookingWidgetMode, IsBookingFormStrictValidationModeOn } = this.props;

		if (BookingWidgetMode !== BookingWidgetModeKind.Booking) return true;

		if (!IsBookingFormStrictValidationModeOn) return true;

		if (this.state.isFocus) return true;

		if (Passenger.ContactName) return true;		

		return false;
				
	};

	inputValueCheckUpdate(contactName: string) {    
		this.inputRef.current!.value = contactName;
	}

	UNSAFE_componentWillReceiveProps(nextProps: PassengerDetailsProps) {
		const contactName = nextProps.Passenger.ContactName ?? "";
		this.inputValueCheckUpdate(contactName);
    }
    
	/** Update empty value for passenger name in store */
	clearText = () => Dispatch.Booking.UpdatePassengerDetails({ ContactName: '' });

	componentDidMount() {
		const contactName = this.props.Passenger.ContactName ?? "";
		this.inputValueCheckUpdate(contactName);		
	}

	render() {

		let label : string = "";
		const { UserProfile, Passenger  } = this.props;			
		
		/**
		 * When booking on accounts, display custom label if present
		 * Placeholder is only for non-custom name label fields
		 */				
		if (UserProfile && UserProfile.CustomerNameLabel) {
			label = UserProfile.CustomerNameLabel;
		}
		// For parcel booking  
		else if (this.props.SelectedVehicle.Service.kind === ServiceKind.Parcel) {
			label = "Parcel contact name";
		}
		else {
			label = "Passenger name";
		}

        // Validate the input
        const isInputInvalid = !this.IsInputValid();
        
        const errorText = `Please enter a ${label.toLowerCase()}`;

        const inputProp: CustomTextFieldProps  = {
            LabelText: label,
            Name: "PassengerName",
            ErrorMessage: errorText,
            onClearEvent: this.clearText,
            onBlurEvent: this.updateName,
            IsInputInvalid: isInputInvalid,
            IsInputFocussed: this.state.isFocus,
            onFocusEvent: this.updateFocusStatus,
            DoesInputHasValue: !!Passenger.ContactName
        }

		return (
			<div className="booking-fields-panel">
                <OutlinedTextField ref={this.inputRef} {...inputProp} />	
			</div>
		);
	}
}

function mapStateToProps(state: ApplicationState): PassengerDetailsProps {
	return {
		UserProfile: state.authentication.UserProfile,
		Passenger: state.booking.Passenger,
		SelectedVehicle: state.condition.SelectedCondition,
		BookingWidgetMode: state.uiLogicControl.BookingForm.BookingWidgetMode,
		IsBookingFormStrictValidationModeOn: state.uiLogicControl.BookingForm.IsStrictValidationModeOn
	};
}

export default connect(mapStateToProps)(PassengerName);
