import { ReduxStoreSlice } from "../../ReduxStoreSlice";
import { AuthenticationState, LoginStatusKind, AuthToken, NameSplit } from "./AuthEntities";
import { InitAuthenticationState } from "./AuthState";
import { SimpleUserProfile } from "../User/ProfileEntitiesV2";
import { CountryInfo } from "../Verification/VerificationEntities";
import { Config } from "../../Config/Config";
import { ErrorMessagePlacementKind } from "./Login/LoginEntities";
import { ParseWashProfileName } from "./ProcessUserProfile";

const slice = new ReduxStoreSlice<AuthenticationState>("Auth", InitAuthenticationState);

/** Dispatcher for actions in the Auth state slice. */
export const AuthDispatchV2 = {

    /** Show a floating Profile card in the top right corner of the screen, below the login box. */
    ShowProfilePanel: slice.EmptyAction("Show Profile Panel", ShowProfilePanel),

    /** Hide (close) the floating card UI with the user's profile details. */
    HideProfilePanel: slice.EmptyAction("Hide Profile Panel", HideProfilePanel),

    /** Update the user's login status: (logged in / out / loading) */
    LoginStatus: slice.Action("Login Status", LoginStatus),

    /** Store a newly created Auth0 login token */ 
    Auth0TokenCreated: slice.Action("Auth0 Token Created", Auth0TokenCreated),

    /** Remove the Auth0 login token because the user has logged out or it is expired */
    ClearAuth0Token: slice.EmptyAction("Clear Auth0 Token", ClearAuth0Token),

    /** Store the user's profile details loaded from the 13cabs Client API */
    UserProfile: slice.Action("User Profile", UserProfile),

    /** Remove the user's profile information when they log out. */
    ClearUserProfile: slice.EmptyAction("Clear User Profile", ClearUserProfile),

    /** Indicate whether the Verification dialog should create a booking at the end */
    IsBookingNeededAfterVerification: slice.Action("Is Booking Needed After Verification", IsBookingNeededAfterVerification),

    /** Record the user's name(s) as they enter it in the Signup dialog */
    SignupProfileName: slice.Action("Signup Profile Name", SignupProfileName),

    /** Clear any stored user name from the Signup dialog */
    ClearSignupProfileName: slice.EmptyAction("Clear Signup Profile Name", ClearSignupProfileName),

    /** Record the phone number entered while the user is doing signup. */
    SignupPhoneNumber: slice.Action("Signup Phone Number", SignupPhoneNumber),

    /** Clear any stored value for the user's phone number that was entered during the Signup process. */
    ClearSignupPhoneNumber: slice.EmptyAction("Clear Signup Phone Number", ClearSignupPhoneNumber),

    /** Record the country of the phone number the user enters during Signup. */
    SignupPhoneCountry: slice.Action("Signup Phone Country", SignupPhoneCountry),

    /** Record the email address the users enteres during Login / Signup / Lost Password */
    CredentialEmail: slice.Action("Credential Email", CredentialEmail),

    /** Clear any stored value for the user's email that was entered during Login / Signup. */
    ClearCredentialEmail: slice.EmptyAction("Clear Credential Email", ClearCredentialEmail),

    /** Clear all details the user has entered during Login / Signup */
    ClearCredentials: slice.EmptyAction("Clear Credentials", ClearCredentials),

    /** Set the error message associated with Login / Signup. */
    LoginErrorMessage: slice.Action("Login Error Message", LoginErrorMessage),

    /** Clear any error message associated with Login / Signup. */
    ClearLoginErrorMessage: slice.EmptyAction("Clear Login Error Message", ClearLoginErrorMessage),

    /** Record a success or informational message issued by Auth0. Only used by the Reset Password dialog. */
    Auth0SuccessMessage: slice.Action("Auth0 Success Message", Auth0SuccessMessage),

    /** Clear any success or informational message from Auth0 during Reset Password. */
    ClearAuth0SuccessMessage: slice.EmptyAction("Clear Auth0 Success Message", ClearAuth0SuccessMessage),

    /** Record the password entered in the Login / Signup dialogs. */
    CredentialPassword: slice.Action("Credential Password", CredentialPassword),

    /** Remove any stored password entered during the Login / Signup dialogs. */
    ClearCredentialPassword: slice.EmptyAction("Clear Credential Password", ClearCredentialPassword),

    /** Set the placement of the error message on the UI of the Login / Signup dialogs. */
    PlaceErrorMessage: slice.Action("Place Error Message", PlaceErrorMessage),

    /** Set the status to show the SilentLogin component */
    ShowSilentLogin: slice.EmptyAction("Show The Silent Login Component", ShowSilentLogin),

    /** Set the status to hide the SilentLogin component */
    HideSilentLogin: slice.EmptyAction("Hide The Silent Login Component", HideSilentLogin),    
};

/** Reducer for the Auth store slice */
export const AuthReducerV2 = slice.MakeCombinedReducer();

/** Show a floating card UI with the user's profile details in the top right corner of the screen, below the login box. */
function ShowProfilePanel(state: AuthenticationState): AuthenticationState {
    return {
        ...state,
        IsProfilePanelShown: true,
    };
}

/** Hide (close) the floating card UI with the user's profile details. */
function HideProfilePanel(state: AuthenticationState): AuthenticationState {
    return {
        ...state,
        IsProfilePanelShown: false,
    };
}

/** Update the user's login status: (logged in / out / loading) */
function LoginStatus(state: AuthenticationState, newState: LoginStatusKind): AuthenticationState {
    return {
        ...state,
        LoginStatus: newState,
    };
}

/** Store a newly created Auth0 login token */
function Auth0TokenCreated(state: AuthenticationState, token: AuthToken): AuthenticationState {
    return {
        ...state,
        AuthToken: token,
    };
}

/** Remove the Auth0 login token because the user has logged out or it is expired */
function ClearAuth0Token(state: AuthenticationState): AuthenticationState {
    let { AuthToken, ...newState } = state;
    return newState;
}

/** Store the user's profile details loaded per brand */
function UserProfile(state: AuthenticationState, profile: SimpleUserProfile): AuthenticationState {
    return {
        ...state,
        UserProfile: profile,
    };
}

/** Remove the user's profile information when they log out. */
function ClearUserProfile(state: AuthenticationState): AuthenticationState {
    let { UserProfile, ...newState } = state;
    return newState;
}

/** Indicate whether the Verification dialog should create a booking at the end */
function IsBookingNeededAfterVerification(state: AuthenticationState, newValue: boolean): AuthenticationState {
    return {
        ...state,
        IsBookingNeededAfterVerification: newValue
    };
}

/** Record the user's name(s) as they enter it in the Signup dialog */
function SignupProfileName(state: AuthenticationState, name: string): AuthenticationState {

    const nameObj: NameSplit = ParseWashProfileName(name);

    let credentials = {
        ...state.Credentials,
        FirstName: nameObj.FirstName
    };

    // Only assign last name if there is
    if (nameObj.LastName) {
        credentials = {
            ...credentials,
            LastName: nameObj.LastName
        };
    }
    else {
        if (state.Credentials.LastName) {
            const { LastName, ...newCredentials } = credentials;
            credentials = newCredentials;
        }
    }

    return {
        ...state,
        Credentials: credentials
    }
}

/** Clear any stored user name from the Signup dialog */
function ClearSignupProfileName(state: AuthenticationState): AuthenticationState {
    const { FirstName, LastName, ...newCredentials } = state.Credentials;

    return {
        ...state,
        Credentials: newCredentials
    };
}

/** Record the phone number entered while the user is doing signup. 
 * It can be entered in the Signup dialog or ContactDetails dialog */
function SignupPhoneNumber(state: AuthenticationState, phoneNumber: string): AuthenticationState {
    return {
        ...state,
        Credentials: {
            ...state.Credentials,
            ContactNumber: phoneNumber
        }
    }
}

/** Clear any stored value for the user's phone number that was entered during the Signup process. */
function ClearSignupPhoneNumber(state: AuthenticationState): AuthenticationState {
    const { ContactNumber, ...newCredentials } = state.Credentials;

    return {
        ...state,
        Credentials: newCredentials
    };
}

/** Record the country of the phone number the user enters during Signup. */
function SignupPhoneCountry(state: AuthenticationState, payload: CountryInfo): AuthenticationState {
    return {
        ...state,
        Credentials: {
            ...state.Credentials,
            CountryInfo: payload
        }
    };
}

/** Record the email address the users enteres during Login / Signup / Lost Password */
function CredentialEmail(state: AuthenticationState, email: string): AuthenticationState {
    return {
        ...state,
        Credentials: {
            ...state.Credentials,
            Email: email
        }
    };
}

/** Clear any stored value for the user's email that was entered during Login / Signup. */
function ClearCredentialEmail(state: AuthenticationState): AuthenticationState {
    const { Email, ...newCredentials } = state.Credentials;

    return {
        ...state,
        Credentials: newCredentials
    };
}

/** Clear all details the user has entered during Login / Signup */
function ClearCredentials(state: AuthenticationState): AuthenticationState {
    return {
        ...state,
        Credentials: { CountryInfo: Config.DefaultCountryInfo }
    }
}

/** Set the error message associated with Login / Signup. */
function LoginErrorMessage(state: AuthenticationState, errorMessage: string): AuthenticationState {
    return {
        ...state,
        ErrorMessage: errorMessage
    }
}

/** Clear any error message associated with Login / Signup. */
function ClearLoginErrorMessage(state: AuthenticationState): AuthenticationState {
    let { ErrorMessage, ...newState } = state;
    return newState;
}

/** Record a success or informational message from Auth0. Only used by the Reset Password dialog. */
function Auth0SuccessMessage(state: AuthenticationState, message: string): AuthenticationState {
    return {
        ...state,
        Auth0SuccessMessage: message
    };
}

/** Clear any success or informational message from Auth0 during Reset Password. */
function ClearAuth0SuccessMessage(state: AuthenticationState): AuthenticationState {
    let { Auth0SuccessMessage, ...newState } = state;
    return newState;
}

/** Record the password entered in the Login / Signup dialogs. */
function CredentialPassword(state: AuthenticationState, password: string): AuthenticationState {
    return {
        ...state,
        Credentials: {
            ...state.Credentials,
            Password: password
        }
    };
}

/** Remove any stored password entered during the Login / Signup dialogs. */
function ClearCredentialPassword(state: AuthenticationState): AuthenticationState {
    const { Password, ...newCredentials } = state.Credentials;

    return {
        ...state,
        Credentials: newCredentials
    };
}

/** Set the placement of the error message on the UI of the Login / Signup dialogs. */
function PlaceErrorMessage(state: AuthenticationState, placement: ErrorMessagePlacementKind): AuthenticationState {
    return {
        ...state,
        ErrorMessagePlacement: placement
    };
}

/** Set the status to show the SilentLogin component */
function ShowSilentLogin(state: AuthenticationState): AuthenticationState {
    return {
        ...state,
        IsSilentLoginActive: true
    };
}

/** Set the status to hide the SilentLogin component */
function HideSilentLogin(state: AuthenticationState): AuthenticationState {
    return {
        ...state,
        IsSilentLoginActive: false
    };
}