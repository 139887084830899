import React from 'react';
import { TitleStyle, MarkAsRead} from './FeaturesHelper';
import { getContentUrl, ContentURL } from '../Utils/ContentURL';
import { FeatureId, FeatureCardProps } from './FeaturesEntities';
import { GetValues } from '../../Config/MyAppConfig';

export const DriverVaxed = (props: FeatureCardProps) => {
    return (
        <React.Fragment key={FeatureId.DriverProfile}>
            <div className="feature-card">
                <div className="feature-date">
                    <span className="feature-date-img"></span>
                    <span>15.11.2021</span>
                </div>
                <div style={TitleStyle(GetValues().BrandColour)} > RIDE WITH FULLY VACCINATED DRIVERS </div>
                <div className="feature-desc">When you book with 13cabs your Driver will be vaccinated against Covid-19. 
                It's peace of mind, on top of our industry-leading taxi sanitisation program, mask wearing and supply of Aqium hospital grade hand sanitiser in every vehicle.</div>
                <div className="feature-image-for-Vaxed-Driver">
                    <img src={getContentUrl(ContentURL.images.WhatsNew.VaccinatedDrivers)} alt="Vaccinated Drivers" />
                </div>
                <div className="feature-desc-for-Vaxed-Driver"><span> * Live in Sydney and Melbourne, coming soon to other states. </span></div>
                {
                    // Hide the button, if the card is acknowledged
                    props.CanShowAcknowledgeButton &&
                    <div className="feature-btn">
                        <button className="btn-OK" onClick={() => MarkAsRead(FeatureId.DriverVaxed)}>OK, GOT IT</button>
                    </div>
                }
            </div>
        </React.Fragment>
    );
}