import { ProgressAndEtaDisplay, ExtendedEta, EtaKind, BookingInfo } from "../MyBookingEntities";
import { BookingStatus } from "../../../Services/BookingEntities";
import { Config } from "../../../Config/Config";
import { SetProgressAccepted, SetProgressPickedup } from "./ProgressCalculator";

/**
 * Process the display values for progress percentage and ETA display text based on the BookingStatusID and ETA.
 */
export function ProcessProgressAndEtaDisplay(booking: BookingInfo, eta?: ExtendedEta): ProgressAndEtaDisplay {
    let progressAndEta: ProgressAndEtaDisplay = {
        Progress: {
            WidthPercent: 0,
            Colour: "orange",
            IsHidden: false
        },
        Eta: {
            PrefixText: ""
        }
    };


    if (eta && booking.TrackingInfo.BookingStatusID < BookingStatus.Completed) {
        // Show ETA value only if the status and the EtaKind are same.
        if (!(booking.TrackingInfo.BookingStatusID == BookingStatus.PickedUp && eta.Kind == EtaKind.ToPickup)) {
            progressAndEta.Eta = {
                PrefixText: "ETA", MainText: eta.EtaDisplayText
            }
        }
        if (booking.TrackingInfo.BookingStatusID == BookingStatus.Accepted) {
            // Progress after accepted does not go beyond MaxProgressBeforeAccepted (e.g: 30%)
            if (eta.OptimisticEtaSeconds > Config.TMaxForPickupInSeconds) {
                progressAndEta.Progress.WidthPercent = Config.MaxProgressBeforeAccepted;
            } else {
                progressAndEta.Progress.WidthPercent = SetProgressAccepted(eta);
            }
        } else if (booking.TrackingInfo.BookingStatusID == BookingStatus.PickedUp) {
            progressAndEta.Progress.Colour = "green";
            // If the EtaKind is ToPickup or eta value is greater than TMaxForDropoffInSeconds, progress will be 0
            if (eta.Kind == EtaKind.ToPickup || eta.OptimisticEtaSeconds > Config.TMaxForDropoffInSeconds) {
                progressAndEta.Progress.WidthPercent = 0;
                // Hide pprogress bar if no destination.
                if (!booking.DropoffLocation || booking.DropoffLocation.Latitude === 0) {
                    progressAndEta.Eta.PrefixText = "Destination not specified";
                    progressAndEta.Progress.IsHidden = true;
                }
            } else {
                progressAndEta.Progress.WidthPercent = SetProgressPickedup(eta);
            }
        }
    } else if (booking.TrackingInfo.BookingStatusID == BookingStatus.Accepted) {// Progress would be 30% on first load before get the actual ETA
        progressAndEta.Progress.WidthPercent = 30;
    } else if (booking.TrackingInfo.BookingStatusID == BookingStatus.PickedUp && (!booking.DropoffLocation || booking.DropoffLocation.Latitude === 0)) {
        progressAndEta.Eta.PrefixText = "Destination not specified";
        progressAndEta.Progress.IsHidden = true;
    } else if (booking.TrackingInfo.BookingStatusID == BookingStatus.Completed) {
        progressAndEta = {
            Progress: {
                WidthPercent: 100,
                Colour: "green",
                IsHidden: false
            },
            Eta: { PrefixText: "Arrived" }
        };
        if (booking.IsDeliveryBooking) {
            progressAndEta.Eta.PrefixText = "Delivered";
        }
    } else if (booking.TrackingInfo.BookingStatusID == BookingStatus.NoJob) {
        progressAndEta = {
            Progress: {
                WidthPercent: 100,
                Colour: "red",
                IsHidden: false
            },
            Eta: { PrefixText: "No pick-up" }
        };
    } else if (booking.TrackingInfo.BookingStatusID == BookingStatus.Cancelled) {
        progressAndEta = {
            Progress: {
                WidthPercent: 100,
                Colour: "red",
                IsHidden: false
            },
            Eta: { PrefixText: "Cancelled" }
        };
    }

    return progressAndEta;

}