import React from "react";
import "./NewBooking.scss";
import { connect } from "react-redux";
import { ApplicationState } from "../../appState";
import { BookOnAccountToggle } from "./Account/BookOnAccountToggle";
import { Dispatch } from "../Dispatch";
import NewBookingForm from "./NewBookingForm";
import CardRegistration from "../Cards/CardRegistration";
import { BookingWidgetModeKind } from "../BookingTemplate/BookingTemplateEntities";
import BookingTemplateNameField from '../BookingTemplate/BookingTemplateNameField';
import BookingPageLoading from "./Widget/BookingPageLoading";
import BookingButton from "./Widget/BookingButton";
import { TncFooter } from "./Widget/TncFooter";
import { CanBookOnAccount } from "../Utils/UserProfileValidation";

interface NewBookingProps {
    CanBookingOnAccount: boolean;    
    BookingWidgetMode: BookingWidgetModeKind;
    IsCardRegsitrationPanelOpen: boolean;
}

/**
 * All the form fields go in this section. eg: pick-up drop-off addresses, passenger details, conditions, time etc.
 */
export class NewBooking extends React.Component<NewBookingProps> {
    
    constructor(props: NewBookingProps) {
        super(props);
    }    

    onTransparentBlackBgClicked = () => {
        Dispatch.Condition.HideVehicleSelectorUI();
    }

    render() {

        const isTemplateModeOn = this.props.BookingWidgetMode !== BookingWidgetModeKind.Booking;

        return (
            <div>            
                <div className="booking-form-panel">                   
                    <div className="booking-form-content">
                        { isTemplateModeOn && <BookingTemplateNameField /> }
                        {this.props.CanBookingOnAccount && 
                            <React.Fragment>
                                <BookOnAccountToggle />
                                <hr className="key-line"></hr>
                            </React.Fragment>}
                        
                        <NewBookingForm />                        
   
                        <BookingButton />
                    </div>

                    {!isTemplateModeOn && <TncFooter /> }                    
                </div>

                {this.props.IsCardRegsitrationPanelOpen && <CardRegistration CardRegistrationPanelParent="BookingForm" />}  
                <BookingPageLoading/>              
            </div>           
        );
    }
}

function mapStateToProps(state: ApplicationState): NewBookingProps {
    return {
        CanBookingOnAccount: CanBookOnAccount(state.authentication.UserProfile, false),
        BookingWidgetMode: state.uiLogicControl.BookingForm.BookingWidgetMode,
        IsCardRegsitrationPanelOpen: state.payment.IsCardRegistrationPanelOpen
    };
}

export default connect(mapStateToProps)(NewBooking);