import { Moment } from "moment";

/** All Fixed fare details including Distance, ETA etc. */
export interface FixedFare {

    /** The API returns this as FixedFareId. But this is included in CreateBooking request as FixedFarePartitionKey. */
    FixedFarePartitionKey: string;

    /** Distance between Pickup and Destination locations. */
    Distance: number;

    /** Polyline of the route. */
    Polyline: string;

    /** Estimated ETA for the trip. */
    Eta: number;

    /** Created time of the fixed fare. */
    CreatedAt: Moment;

    /** Updated time of the specified fixed fare. */
    UpdatedAt: Moment;

    /** Calculated fare values of each vehicle type. */
    Fare: FixedFareLookup;
}

/** Custom type for Fixed fare values against Vehicle type. */
export type FixedFareLookup = Partial<Record<VehicleType, Fare>>;

/** These enum values match the hard coded values originating from the Booking API. */
export enum VehicleType {
    Sedan = "Sedan",
    Wagon = "Wagon",
    MaxiTaxi = "MAXI",
    Wheelchair = "WheelChair",
    SilverService = "SilverService",
    Limo = "Limo",
}

/** Fare value per vehicle. */
export interface Fare {

    /** Fixed fare ID. Required in CreateBooking request. */
    Id: string;

    /** Estimated fare range between given locations. */
    Range: Range;

    /** Breakdown of the fare. */
    FixedFareBreakdown: FixedFareBreakdown;
}

/** Estimated range for the fare. */
interface Range {

    /** Minimum estimated fare in cents. */
    Minimum: number;

    /** Maximum estimated fare in cents. */
    Maximum: number;
}

/** Cost breakdown of the calculated Fixed fare. */
interface FixedFareBreakdown {

    /** Extra costs such as levies. */
    Extras: Extra[];

    /** Actual trip fare. */
    Fare: number;

    /** Total amount which will be charged from the customer. This is the value displayed in the UI. */
    TotalAmount: number;

    /** Service fee (~5% of the trip fare) */
    ServiceFee: number;

    /** GST. This will be included in the trip fare (Fare property above). */
    GST: number;
}

/** Extra costs included in the Fixed fare (e.g: Levies). */
interface Extra {

    /** Extra cost label. */
    Label: string;

    /** Extra cost amount. */
    Amount: number;
}

/** Fixed fare request data. */
export interface FixedFareRequest {
    PickupLatitude: number;
    PickupLongitude: number;
    DropoffLatitude: number;
    DropoffLongitude: number;

    /** Unix timestamp of booking requested time if specified. Default to current time. */
    DepartureTime?: number;
}