import { ServiceInfo } from "../../../utils/service-vehicles/ServiceMetadata";
import { Address } from "../../Booking/BookingEntities";
import { Fare } from "../../../Services/FixedFareEntities";
import { BookingTimeV2 } from "../../BookingV2/BookingV2Entities";

/** A vehicle option selectable from the UI. */
export interface VehicleOption {
   
    /** UI-centric representation of the service. Sedan, Maxi, Silver Service, etc. */
    Service: ServiceInfo,

    /** 
     *  API-specific data describing this option.
     *  This is undefined exactly when [Service] is the "any" option.
     *  This data will go back to the API during CreateBooking.
     */
    ApiVehicle?: ApiVehicleOption,

    Fare?: string,

    // Fixed fare per vehicle
    FixedFare?: Fare, 

    /**
     * Determine if MAXI TAXI seater selector should show or not
     * Why optional boolean?
     * Normally boolean type is mandatory, but in our implementation, there are a large number of place use default value of VehiclePayload.
     * In order to "not touch", after discussed with team, we define here is optional boolean.
     */
    RequiresPaxCount?: boolean;
}

/** An API representation of a Vehicle. Could be a bit internal in the API. */
export interface ApiVehicleOption {

    /** Internal API type. V1 vs V2. */
    ApiVersion: number;

    /** Internal ID, from the particular API */
    ApiId: number;

    /** "Sedan" or "Wagon" etc */
    Name: string;

    /** Number of passengers this type of vehicle seats. */
    MaxSeat: number;

    /** "up to 7 passengers" */
    Description: string;
}

/** A table of Condition indexed by the MaxSeat field. */
export type MaxiTaxiLookUpType = Record<number, ApiVehicleOption>;

export interface ConditionLookupTables {
    ConditionList: VehicleOption[],
    MaxiTaxiLookUp: MaxiTaxiLookUpType /** Lookup using MaxSeat as the key */
}

export interface MaxiTaxiSeaterInfoPayload {
    Minimum: number,
    Average: number,
    Maximum: number
}

/** Tracks whether there is a call in progress of some API, as well as the success/failure result at the end. UI components may consume this to display loader or error panels. */
export enum DataLoadingStatus {

    /** No load in progress. Either the previous load completed successfully, or there has been no load. */
    Idle = "Idle",

    /** A load is in progress. Don't start a new load with the same input, but it's OK to start a new load with a different input if the input has genuinely changed. */
    InProgress = "In Progress",

    /** An error occurred with the last attempt to load the data. */
    Error = "Error",
}

/** Tracks the status of an arbitrary API call. TInput is the type of the entity  */
export interface AsyncLoadState<TInput> {

    /** Idle / In Progress / Error */
    Status: DataLoadingStatus;

    /** The error message, defined when the status is Error */
    ErrorMessage?: string;

    /** What input was sent to the current call. Null at first. */
    LastInput: TInput | null;
}

/** The information that uniquely defines a GetFare request. This is used to block duplicate calls. */
export interface FareRequestInput {
    Pickup: Address;
    Dropoff: Address;
    Time: BookingTimeV2;
}

/**
 * ConditionIds for ride/service selection
 */
export enum ConditionIds {
    silver = 3,
    parcel = 6,
    wagon = 11,
    sedan = 12,
    wheelchair = 14
}

/**
 * Vehicle condition type mapping from BookingControllerV2 (behind the scene is GB)
 */
export enum VehicleTypeV2 {
    Limousine = 11,
    SilverService = 21,
    StandardTaxi = 31,
    WheelchairTaxi = 32,
    MaxiTaxi = 33,
    Parcel = 34,
    Airport = 35,
    Car = 41
}