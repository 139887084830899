import { NetworkFailResult, ApplicationErrorResult, ServiceCallOutcome } from "./ServiceEntities";
import appstore from "../appStore";
import { ApiFailureMode } from "../modules/ApiFailureTesting/FailureTestingState";

/** Perform API Failure testing if it has been enabled. Returns null otherwise. */
export async function InjectApiTesting(): Promise<NetworkFailResult | ApplicationErrorResult | null> {

    // normal case: skip
    const config = appstore.getState().apiFailureTesting;
    if (!config.IsEnabled) return null;

    // delay
    if (config.IsDelayEnabled) {
        await Delay(config.DelayMilliseconds);
    }

    // no injected error
    if (config.IsFailureModeEnabled === false) {
        return null;
    }

    // timeout
    if (config.FailureMode === ApiFailureMode.Timeout) {
        const timeout: NetworkFailResult = {
            isSuccess: false,
            outcome: ServiceCallOutcome.NoResponseFromServer,
            isTimeout: true,
        }

        return timeout;
    }

    // connect error
    if (config.FailureMode === ApiFailureMode.UnableToConnect) {
        const connectionError: NetworkFailResult = {
            isSuccess: false,
            outcome: ServiceCallOutcome.NoResponseFromServer,
            isTimeout: false,
        }

        return connectionError;
    }

    // business logic error
    if (config.FailureMode === ApiFailureMode.BusinessRule) {
        const appError: ApplicationErrorResult = {
            isSuccess: false,
            outcome: ServiceCallOutcome.ApplicationError,
            httpStatus: {
                Code: 500,
                Text: 'Error Occur!',
            },
            apiStatus: {
                Message: "Something's not right!",
            },
        };

        return appError;
    }

    return null;
}

/** Async wait function. */
async function Delay(milliseconds: number) {
    return new Promise(resolve => {
        setTimeout(resolve, milliseconds);
    });
}