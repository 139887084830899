import { ReduxStoreSlice } from "../../ReduxStoreSlice";
import { LegalDocuments, UserConsentStatus } from "../../Services/LegalDocumentsContracts";
import { LegalDocumentsInitialState, LegalDocumentsState } from "./LegalDocumentsState";

const slice = new ReduxStoreSlice<LegalDocumentsState>("LegalDocuments", LegalDocumentsInitialState);

/** Dispatcher for actions in the LegalDocuments store slice. */
export const LegalDocumentsDispatch = {

    /** Update the latest legal documents pack (URLs to each available document type) */
    Refresh: slice.Action("Refresh Legal Documents", RefreshLegalDocuments),

    /** Update user's legal document consent status for the current brand. */
    ConsentStatus: slice.Action("Refresh Legal Docs Consent Status", RefreshConsentStatus)
};

/** Reducer for the LegalDocuments store slice */
export const LegalDocumentsReducer = slice.MakeCombinedReducer();

/** Update the latest legal documents pack (URLs to each available document type) */
function RefreshLegalDocuments(state: LegalDocumentsState, legalDocsPack: LegalDocuments): LegalDocumentsState {
    
    return {
        ...state,
        LegalDocumentsPack: legalDocsPack
    };
}

/** Update user's legal document consent status. */
function RefreshConsentStatus(state: LegalDocumentsState, consentStatus: UserConsentStatus): LegalDocumentsState {

    if (consentStatus.LegalDocumentsToConsent) state.LegalDocumentsPack = consentStatus.LegalDocumentsToConsent;

    return {
        ...state,
        IsConsentRequired: consentStatus.IsConsentRequired
    };
}